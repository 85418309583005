import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSegment } from 'react-segment-hooks';
import { Loader } from '../../Components';
import { useCreateParticipant, useFetchParticipants } from '../../Hooks/useParticipants';
import usePermissions from '../../Hooks/usePermissions';
import ParticipantDetail from './ParticipantDetail';
import ParticipantDetailEmpty from './ParticipantDetailEmpty';
import ParticipantFilter, { LAST_PARTICIPATED_OPTIONS, SORT_BY_OPTIONS } from './ParticipantFilter';
import ParticipantsList from './ParticipantsList';

import { PlusIcon, SearchIcon } from '@heroicons/react/outline';
import Button from '../../Components/Button';
import { Input } from '@chakra-ui/react';
import ParticipantImportMenu from '../../Components/ParticipantImportMenu/ParticipantImportMenu';

export type ParticipantFilter = {
  company: string;
  role: string;
  lastParticipated: string;
  sortBy: string;
};

export type Participant = {
  id: string;
  name: string;
  picture: string;
  createdAt: string;
  company: string;
  role: string;
  color: string;
  lastParticipated: string;
  description: string;
  notesConnection: {
    totalCount: number;
  };
  documentsConnection: {
    totalCount: number;
  };
  transcriptionsConnection: {
    totalCount: number;
  };
};

const daysFromNow = (dateString: string): number => {
  const then = new Date(dateString);
  const now = new Date();
  const msBetweenDates = Math.abs(then.getTime() - now.getTime());
  return msBetweenDates / (24 * 60 * 60 * 1000);
};

const ParticipantsWrapper = (): JSX.Element => {
  const { participantId } = useParams<{ participantId: string }>();
  const [createParticipant] = useCreateParticipant();
  const [loading, participants, ,] = useFetchParticipants();
  const { canCreateParticipants } = usePermissions();
  const [filteredParams, setFilteredParams] = useState<ParticipantFilter>({
    company: 'All companies',
    role: 'All roles',
    lastParticipated: 'Any date',
    sortBy: SORT_BY_OPTIONS.newest,
  });
  const [companies, setCompanies] = useState<string[]>([]);
  const [roles, setRoles] = useState<string[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const history = useHistory();

  const analytics = useSegment();
  useEffect(() => {
    analytics.page({
      name: 'Participants',
    });
  }, []);

  useEffect(() => {
    if (!loading && participants) {
      setCompanies([
        ...new Set(participants.map((item) => item?.company?.trim()).filter((x) => x)),
      ]);
      setRoles([...new Set(participants.map((item) => item?.role?.trim()).filter((x) => x))]);
    }
  }, [participants, loading]);

  const filteredParticipants = useMemo(() => {
    const search = searchText.toLowerCase();
    return participants
      .filter((item) => {
        if (filteredParams.company !== 'All companies' && item.company !== filteredParams.company) {
          return false;
        }
        if (filteredParams.role !== 'All roles' && item.role !== filteredParams.role) {
          return false;
        }
        if (
          searchText &&
          !item.name?.toLowerCase().includes(search) &&
          !item.role?.toLowerCase().includes(search) &&
          !item.company?.toLowerCase().includes(search) &&
          !item.description?.toLowerCase().includes(search)
        )
          return false;
        return true;
      })
      .filter((item) => {
        if (filteredParams.lastParticipated === LAST_PARTICIPATED_OPTIONS.any) return true;
        if (
          filteredParams.lastParticipated === LAST_PARTICIPATED_OPTIONS.never &&
          !item.lastParticipated
        )
          return true;
        if (!item.lastParticipated) return false;
        const daysPassed = daysFromNow(item.lastParticipated);
        if (
          filteredParams.lastParticipated === LAST_PARTICIPATED_OPTIONS.last30 &&
          daysPassed <= 30
        )
          return true;
        if (
          filteredParams.lastParticipated === LAST_PARTICIPATED_OPTIONS.last6m &&
          daysPassed <= 180
        )
          return true;
        if (
          filteredParams.lastParticipated === LAST_PARTICIPATED_OPTIONS.lastYear &&
          daysPassed <= 360
        )
          return true;
        return false;
      })
      .sort((a, b) => {
        if (filteredParams.sortBy === SORT_BY_OPTIONS.oldest)
          return a.createdAt > b.createdAt ? 1 : -1;
        if (filteredParams.sortBy === SORT_BY_OPTIONS.abc)
          return a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1;
        if (filteredParams.sortBy === SORT_BY_OPTIONS.lastParticipated) {
          return a.lastParticipated ? (a.lastParticipated < b.lastParticipated ? 1 : -1) : 1;
        }
        if (filteredParams.sortBy === SORT_BY_OPTIONS.impact) {
          const aImpact =
            a.documentsConnection.totalCount +
            a.transcriptionsConnection.totalCount +
            a.notesConnection.totalCount;
          const bImpact =
            b.documentsConnection.totalCount +
            b.transcriptionsConnection.totalCount +
            b.notesConnection.totalCount;
          return aImpact < bImpact ? 1 : -1;
        }
        return a.createdAt < b.createdAt ? 1 : -1;
      });
  }, [participants, filteredParams, searchText]);

  const handleCreate = async (e: React.MouseEvent) => {
    e.stopPropagation();
    const participant = await createParticipant();
    history.push(`/participants/${participant.id}`);
  };

  if (loading) return <Loader />;

  return (
    <div className={'flex-auto w-full flex h-[calc(100vh-49px)]'}>
      <ParticipantFilter
        companies={companies}
        roles={roles}
        filteredParams={filteredParams}
        setFilteredParams={setFilteredParams}
      />

      <div className={'flex flex-col w-80 border-r border-gray-200 shrink-0'}>
        <div className="text-md font-medium mt-1 px-2 flex justify-between items-center">
          <div className="flex items-center rounded-md">
            <SearchIcon className="w-6 h-6" />
            <div className="h-12 flex flex-1 items-center ml-2">
              <Input
                variant="unstyled"
                autoFocus={true}
                value={searchText}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const newText = event.target.value;
                  setSearchText(newText);
                }}
                placeholder="Search"
              />
            </div>
          </div>
          {canCreateParticipants && (
            <>
              <div className="mr-2">
                <ParticipantImportMenu />
              </div>
              <div>
                <Button type={'primary'} onClick={handleCreate}>
                  <PlusIcon className="text-white w-5 h-5 mr-1" />
                  New
                </Button>
              </div>
            </>
          )}
        </div>
        <div className="ml-2 mb-2 font-medium">
          {filteredParticipants.length}{' '}
          {filteredParticipants.length !== 1 ? 'Participants' : 'Participant'}
        </div>

        <div className="overflow-y-scroll mb-2 border-t border-t-primary-purple-light">
          <ParticipantsList participants={filteredParticipants} selected={participantId} />
        </div>
      </div>

      {participantId ? (
        <ParticipantDetail key={participantId} participantId={participantId} />
      ) : (
        <ParticipantDetailEmpty
          canCreateParticipants={canCreateParticipants}
          handleCreate={handleCreate}
        />
      )}
    </div>
  );
};

export default ParticipantsWrapper;
