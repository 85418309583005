import { Node, mergeAttributes } from '@tiptap/core';
import { Editor, ReactNodeViewRenderer } from '@tiptap/react';
import { SummaryGenerationData } from '../../../PostyAIButton/PostyAIButton';

import SummaryComponent from './SummaryComponent';

export interface SummaryOptions {
  HTMLAttributes: Record<string, any>;
  parentContainer?: HTMLDivElement | null;
  getSummaryGenerationData?(editor: Editor | null): SummaryGenerationData;
  editable?: boolean;
}

const Summary = Node.create<SummaryOptions>({
  name: 'summary',
  group: 'block',
  content: 'block*',
  defining: true,
  isolating: true,
  selectable: false,

  addOptions() {
    return {
      HTMLAttributes: {},
      getSummaryGenerationData: () => ({
        content: [],
        documentId: '',
      }),
      parentContainer: null,
      editable: true,
    };
  },

  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element: HTMLElement) => element.getAttribute('data-id') || null,
        renderHTML: (attributes: any) => {
          if (!attributes.id) {
            return {};
          }

          return {
            'data-id': attributes.id,
          };
        },
      },
      jobId: {
        default: null,
        parseHTML: (element: HTMLElement) => element.getAttribute('data-job-id') || null,
        renderHTML: (attributes: any) => {
          if (!attributes.jobId) {
            return {};
          }

          return {
            'data-job-id': attributes.jobId,
          };
        },
      },
      status: {
        default: 'inactive',
        parseHTML: (element: HTMLElement) => element.getAttribute('data-status') || null,
        renderHTML: (attributes: any) => {
          if (!attributes.status) {
            return {};
          }

          return {
            'data-status': attributes.status,
          };
        },
      },
      templateId: {
        default: null,
        parseHTML: (element: HTMLElement) => element.getAttribute('data-template-id') || null,
        renderHTML: (attributes: any) => {
          if (!attributes.templateId) {
            return {};
          }

          return {
            'data-template-id': attributes.templateId,
          };
        },
      },
      templateTitle: {
        default: '',
        parseHTML: (element: HTMLElement) => element.getAttribute('data-template-title') || null,
        renderHTML: (attributes: any) => {
          if (!attributes.templateTitle) {
            return {};
          }

          return {
            'data-template-title': attributes.templateTitle,
          };
        },
      },
      language: {
        default: '',
        parseHTML: (element: HTMLElement) => element.getAttribute('data-summary-language') || null,
        renderHTML: (attributes: any) => {
          if (!attributes.language) {
            return {};
          }

          return {
            'data-summary-language': attributes.language,
          };
        },
      },
      collapsed: {
        default: false,
        parseHTML: (element: HTMLElement) => element.getAttribute('data-collapsed') || null,
        renderHTML: (attributes: any) => {
          if (!attributes.collapsed) {
            return {};
          }

          return {
            'data-collapsed': attributes.collapsed,
          };
        },
      },
      enableSmartAnalysis: {
        default: null,
        parseHTML: (element: HTMLElement) =>
          element.getAttribute('data-enable-smart-analysis') || null,
        renderHTML: (attributes: any) => {
          if (!attributes.enableSmartAnalysis) {
            return {};
          }

          return {
            'data-enable-smart-analysis': attributes.enableSmartAnalysis,
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `summary`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }: any) {
    return ['summary', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  addKeyboardShortcuts() {
    return {
      Backspace: ({ editor }) => {
        const { state } = editor;
        const { selection } = state;
        const { $from, empty } = selection;

        if (!empty || $from.parent.type !== this.type) {
          return false;
        }

        const isAtStart = $from.parentOffset === 0;
        if (isAtStart) return true;
        return false;
      },
      Enter: ({ editor }) => {
        const { state } = editor;
        const { selection } = state;
        const { $from, empty } = selection;

        if (!empty || $from.parent.type !== this.type) {
          return false;
        }

        const isAtStart = $from.parentOffset === 1;
        if (isAtStart) return false;
        return editor.commands.setHardBreak();
      },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(SummaryComponent);
  },
});

export default Summary;
