import { Editor } from '@tiptap/react';
import React, { FC, useEffect, useState } from 'react';
import { Icon16, Icon24 } from '../../Icons/Icon';
import Input from '../Input/Input';
import {
  ButtonsContainer,
  ChevronButtonBottom,
  ChevronButtonTop,
  CloseButton,
  Container,
  Divier,
  InputContainer,
  InputStats,
  ReplaceButton,
  ReplaceButtonWrapper,
  ReplaceInputContainer,
  SearchRow,
} from './styles';

interface SearchAndReplacePanelProps {
  editor: Editor;
  onClose(): void;
}

const SearchAndReplacePanel: FC<SearchAndReplacePanelProps> = ({ editor, onClose }) => {
  const [searchValue, setSearchValue] = useState('');
  const [replaceValue, setReplaceValue] = useState('');

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.keyCode === 27) {
        handleClosePanel();
      }

      if (e.keyCode === 13) {
        editor.commands.nextSearchResult();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => editor.commands.setSearchTerm(searchValue), 0);
  }, [searchValue]);

  useEffect(() => {
    setTimeout(() => editor.commands.setReplaceTerm(replaceValue), 0);
  }, [replaceValue]);

  const handleClosePanel = () => {
    editor.commands.setSearchTerm('');
    editor.commands.setReplaceTerm('');
    onClose();
  };

  const handleNextSearchResult = () => {
    editor.commands.nextSearchResult();
  };

  const handlePreviousSearchResult = () => {
    editor.commands.previousSearchResult();
  };

  const handleReplace = () => {
    editor.commands.replace();
  };

  const handleReplaceAll = () => {
    editor.commands.replaceAll();
  };

  const searchResultsCount = editor.storage.searchAndReplace?.results?.length;
  const searchResultsIndex = editor.storage.searchAndReplace?.resultIndex;

  return (
    <Container>
      <SearchRow>
        <InputContainer>
          <Input
            inputType="ghost"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder="Find"
            smallSize
            autoFocus
          />
          <InputStats show={!!searchValue}>
            {searchResultsIndex + 1} of {searchResultsCount}
          </InputStats>
        </InputContainer>
        <ButtonsContainer>
          <ChevronButtonTop enabled={!!searchValue} onClick={handlePreviousSearchResult}>
            <Icon24.ChevronSimple />
          </ChevronButtonTop>

          <ChevronButtonBottom enabled={!!searchValue} onClick={handleNextSearchResult}>
            <Icon24.ChevronSimple />
          </ChevronButtonBottom>
        </ButtonsContainer>

        <Divier />

        <ButtonsContainer>
          <CloseButton onClick={handleClosePanel}>
            <Icon16.Close />
          </CloseButton>
        </ButtonsContainer>
      </SearchRow>

      <SearchRow>
        <ReplaceInputContainer>
          <Input
            value={replaceValue}
            onChange={(e) => setReplaceValue(e.target.value)}
            placeholder="Replace with"
            smallSize
          />
        </ReplaceInputContainer>
        <ButtonsContainer>
          <ReplaceButtonWrapper>
            <ReplaceButton onClick={handleReplace}>Replace</ReplaceButton>
          </ReplaceButtonWrapper>
          <ReplaceButton onClick={handleReplaceAll}>Replace all</ReplaceButton>
        </ButtonsContainer>
      </SearchRow>
    </Container>
  );
};

export default SearchAndReplacePanel;
