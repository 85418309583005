import styled from 'styled-components';
import { breakpoint } from '../../Shared';

export const IconContainer = styled.div<{ src: string }>`
  width: 48px;
  height: 48px;
  border-radius: 6px;
  background-image: url('${({ src }) => src}');
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 10px;
  flex-shrink: 0;
  margin-right: 5px;
`;

export const Container = styled.div`
  width: 100%;
`;

export const TemplatesContainer = styled.div`
  width: 100%;
  justify-content: left;
  margin-bottom: 16px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;

  ${breakpoint.Tablet`
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  `}
`;

export const ButtonWrapper = styled.div`
  opacity: 0;
  pointer-events: none;
  justify-self: flex-end;
  transition: opacity 0.15s ease;

  ${breakpoint.Tablet`
    display: none;
  `}
`;

export const TemplateCardBorderWrapper = styled.div<{ selected: boolean; isSmart: boolean }>`
  position: relative;
  padding: 1px;
  display: flex;
  align-items: center;
  background: #d7d3dc;
  border-radius: 8px;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);

  ${({ selected, isSmart }) =>
    selected &&
    `
      background: ${isSmart ? 'linear-gradient(180deg, #3B2651 0%, #8E84EE 100%)' : '#3B2651'} ;
  `};
`;

export const TemplateCard = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  padding: 20px;
  border-radius: 7px;
  background-color: #ffffff;
  z-index: 1;
  cursor: pointer;

  &:hover ${ButtonWrapper} {
    opacity: 1;
    pointer-events: all;
  }

  &:last-child {
    margin: 0;
  }

  ${breakpoint.Tablet`
    padding: 10px;
  `}
`;

export const ChevronIcon = styled.div<{ show: boolean }>`
  display: ${({ show }) => (show ? 'block' : 'none')};
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  right: -8px;
  top: -8px;
  color: #ffffff;
  background-color: #382152;
  z-index: 1;
`;

export const TemplateNameWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

export const TemplateName = styled.div`
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  padding-right: 14px;
`;

export const TemplateDescription = styled.div`
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: rgba(59, 38, 81, 0.6);
  margin-bottom: 10px;
  flex: 1;
`;

export const Title = styled.div`
  font-size: 20px;
  line-height: 22px;
  font-weight: 600;
  margin-bottom: 15px;

  ${breakpoint.Tablet`
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-transform: uppercase;
    color: rgba(59, 38, 81, 0.4);

  `}
`;

export const Subtitle = styled.div`
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  margin-bottom: 20px;

  ${breakpoint.Tablet`
    display: none;
  `}
`;

export const SeearchWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`;

export const FilterDropdown = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
  flex-shrink: 0;

  ${breakpoint.Tablet`
    display: none;
  `}
`;

export const FilterText = styled.div`
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  margin: 0 5px;
  flex-shrink: 0;
`;

export const DropdownPanel = styled.div<{ show: boolean }>`
  position: absolute;
  top: 30px;
  right: 0;
  white-space: nowrap;
  display: none;
  ${({ show }) => show && `display: block;`}

  border-radius: 3px;
  border: 1px solid #d7d3dc;
  background: #ffffff;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.1);
`;

export const DropdownPanelItem = styled.div<{ active?: boolean; itemColor?: string }>`
  width: 100%;
  padding: 6px 10px;
  cursor: pointer;
  user-select: none;
  border-radius: 3px;

  ${({ itemColor }) => itemColor === 'red' && `color: #EA4335;`}
  ${({ active }) => active && `background: rgba(56, 33, 82, 0.06);`}
  &:hover {
    background: #f8f8f8;
  }
`;

export const SmartTemplateIconWrapper = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
`;
