import { openDB } from 'idb';

const DB_NAME = 'audioDB';
const STORE_NAME = 'recordings';

export type Recording = {
  id: string;
  created: Date;
  blob: Blob;
  name: string;
  participant?: any;
  participantId?: string | null;
};

export type RecordingInput = {
  id?: string;
  created?: Date;
  blob?: Blob;
  name?: string;
  participantId?: string | null;
};

export const initDB = async () => {
  return await openDB(DB_NAME, 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME, { keyPath: 'id', autoIncrement: true });
      }
    },
  });
};

export const saveRecordingToDB = async (id: string, data: RecordingInput): Promise<void> => {
  const db = await initDB();
  await db.add(STORE_NAME, { id, created: new Date(), ...data });
};

export const updateOrInsertRecording = async (id: string, data: RecordingInput): Promise<void> => {
  const db = await initDB();
  const recording = await db.get(STORE_NAME, id);
  if (recording) {
    const updatedRecording = { ...recording, ...data };
    await db.put(STORE_NAME, updatedRecording);
  } else {
    await db.add(STORE_NAME, { id, created: new Date(), ...data });
  }
};

export const getRecordingsFromDB = async (): Promise<Recording[]> => {
  const db = await initDB();
  return await (await db.getAll(STORE_NAME)).reverse();
};

export const getRecordingById = async (id: string): Promise<Recording> => {
  const db = await initDB();
  return await db.get(STORE_NAME, id);
};

export const deleteRecordingFromDB = async (id: string): Promise<void> => {
  const db = await initDB();
  await db.delete(STORE_NAME, id);
};
