import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SpeakersContext, TSpeakerBlock } from '../../Context/SpeakersContext';
import { useCreateParticipant, useFetchParticipantsLazy } from '../../Hooks/useParticipants';
import {
  useDeleteParticipantLink,
  useTranscriptLinkedParticipants,
} from '../../Hooks/useTranscripts';
import { Icon16 } from '../../Icons/Icon';
import Input from '../Input/Input';
import LetteredAvatar from '../LetteredAvatar';
import Avatar from '../Avatar';

import {
  Container,
  CreateParticipantButton,
  CreateParticipantText,
  Divider,
  DropdownTitle,
  InputWrapper,
  NestedDropdown,
  NestedDropdownContainer,
  NestedDropdownIcon,
  NestedDropdownItem,
  NestedDropdownList,
  NestedDropdownTitle,
  PaddingWrapper,
  SearchInputWrapper,
  SpeakerName,
  SpinnerWrapper,
  UnlinkIconWrapper,
  UnlinkParticipantButton,
  UnlinkParticipantText,
} from './styles';
import Spinner from '../Spinner';

interface SpeakerDropdownProps {
  isOpen: boolean;
  currentSpeakerName: string;
  currentSpeakerBlock: TSpeakerBlock;
  onClose(): void;
  onEditName(newName: string): void;
  onCreateSpeaker?(name: string): void;
  onMerge?(speakerBlock: TSpeakerBlock): void;
  onLinkParticipant(participant: any): void;
  onUnlinkParticipant(): void;
}

const SpeakerDropdown: React.FC<SpeakerDropdownProps> = ({
  isOpen,
  currentSpeakerName,
  currentSpeakerBlock,
  onLinkParticipant,
  onUnlinkParticipant,
  onClose,
  onEditName,
  onCreateSpeaker,
  onMerge,
}) => {
  const [fetch, { loading, called }, participants] = useFetchParticipantsLazy();
  const [editedName, setEditedName] = useState(currentSpeakerName);
  const { speakerBlocks } = useContext(SpeakersContext);
  const { transcriptId } = useParams<{ dashboardId: string; transcriptId: string }>();
  const [, , , getLinkedParticipant, getRowIdByParticipantId] = useTranscriptLinkedParticipants(
    transcriptId
  );
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen && !called) fetch();
  }, [isOpen, called]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
  }, [isOpen]);

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (containerRef.current && containerRef.current.contains(e.target as Node)) return;
      setTimeout(onClose, 10);
    };
    window.addEventListener('mousedown', handleClick);

    return () => {
      window.removeEventListener('mousedown', handleClick);
    };
  }, [containerRef]);

  const saveEditingChanges = () => {
    if (currentSpeakerName && editedName && editedName !== currentSpeakerName) {
      onEditName(editedName);
      onClose();
    }
  };

  const handleMerge = (chosenSpeaker: TSpeakerBlock) => {
    onMerge?.(chosenSpeaker);
    onClose();
  };

  const handleCreateSpeaker = (name: string) => {
    onCreateSpeaker?.(name);
    onClose();
  };

  const speakers = useMemo(() => {
    return speakerBlocks
      .filter((speakerBlock) => speakerBlock.speakerName !== currentSpeakerName)
      .map((speakerBlock) => ({
        linkedParticipant: getLinkedParticipant(speakerBlock.id),
        ...speakerBlock,
      }));
  }, [speakerBlocks, currentSpeakerName]);

  return (
    <Container isOpen={isOpen} ref={containerRef}>
      <>
        <div>
          <PaddingWrapper>
            <InputWrapper>
              <Input
                smallSize
                value={editedName}
                onChange={(e) => setEditedName(e.target.value)}
                onBlur={() => {
                  saveEditingChanges();
                }}
                placeholder="Add new speaker"
                onKeyDown={(e) => {
                  if (e.key == 'Enter') {
                    e.preventDefault();
                    saveEditingChanges();
                  }
                }}
              />
            </InputWrapper>
            {speakerBlocks.length > 1 && <DropdownTitle>Change speaker</DropdownTitle>}
          </PaddingWrapper>

          {speakerBlocks.length > 1 &&
            speakers.map((speakerBlock) => (
              <NestedDropdownItem key={speakerBlock.id} onClick={() => handleMerge(speakerBlock)}>
                {speakerBlock.linkedParticipant ? (
                  <Avatar user={speakerBlock.linkedParticipant} />
                ) : (
                  <LetteredAvatar name={speakerBlock.speakerName} />
                )}
                <SpeakerName>{speakerBlock.speakerName}</SpeakerName>
              </NestedDropdownItem>
            ))}
          <Divider />
          <PaddingWrapper>
            <CreateParticipantButton
              onClick={() => handleCreateSpeaker(`Speaker ${speakerBlocks.length + 1}`)}
            >
              <CreateParticipantText>Add</CreateParticipantText>
              <Avatar />
              <CreateParticipantText>New Speaker</CreateParticipantText>
            </CreateParticipantButton>
          </PaddingWrapper>
        </div>
      </>
    </Container>
  );
};

export default SpeakerDropdown;
