import React, { useRef, useEffect, useState } from 'react';
import { Circle, Group, Transformer, RegularPolygon, Rect } from 'react-konva';
import * as KonvaUtils from 'react-konva-utils';
import Konva from 'konva';
import { KonvaEventObject } from 'konva/lib/Node';
import ShapeMenu from './ShapeMenu';
import { shapeHash } from '../../Consts/canvasActions';

export type ShapesStyles = {
  shapeType: string;
  fill: string;
  sides: number;
  type: string;
  radius: number;
  width?: number;
  height?: number;
  stroke: string;
  strokeWidth: number;
  rotation: number;
  scaleX: number;
  scaleY: number;
  skewX: number;
  skewY: number;
  zIndex?: number;
};

interface Props {
  id: string;
  x: number;
  y: number;
  styles: ShapesStyles;
  onUpdate: (updates: {
    points?: number[];
    styles?: ShapesStyles;
    x?: number;
    y?: number;
  }) => Promise<void>;
  onDelete: () => void;
  setSelected: () => void;
  selected: boolean;
  stageScale: number;
  draggable?: boolean;
}

export function sidesToShapeConversion(sides: number) {
  let shape = '';
  switch (sides) {
    case 0:
      shape = 'circle';
      break;
    case 3:
      shape = 'triangle';
      break;
    case 4:
      shape = 'square';
      break;
  }

  return shape;
}

export function shapeToSidesConversion(shape: string) {
  let sides;
  switch (shape) {
    case shapeHash.circle:
      sides = 0;
      break;
    case shapeHash.triangle:
      sides = 3;
      break;
    case shapeHash.square:
    case shapeHash.rhombus:
      sides = 4;
      break;
  }

  return sides;
}

function ShapeComponent({
  id,
  x,
  y,
  styles,
  onUpdate,
  onDelete,
  setSelected,
  selected,
  stageScale,
  draggable = true,
}: Props) {
  const shapeGroupRef = useRef<Konva.Group>(null);
  const polygonRef = useRef<Konva.RegularPolygon>(null);
  const rectRef = useRef<Konva.Rect>(null);
  const circleRef = useRef<Konva.Circle>(null);
  const trRef = useRef<Konva.Transformer>(null);

  useEffect(() => {
    const transformNode = trRef.current;
    if (
      transformNode &&
      transformNode.nodes &&
      shapeGroupRef.current &&
      circleRef.current &&
      polygonRef.current &&
      rectRef.current &&
      transformNode.getLayer
    ) {
      transformNode.nodes([shapeGroupRef.current]);
      transformNode.getLayer()?.batchDraw();
    }
  }, [trRef, shapeGroupRef, circleRef, polygonRef, rectRef, styles.sides, styles.shapeType]);

  return (
    <Group
      id={id}
      ref={shapeGroupRef}
      draggable={draggable}
      onClick={(e) => {
        e.cancelBubble = true;
        setSelected();
      }}
      onDragEnd={() => {
        const node = shapeGroupRef.current;
        onUpdate({
          x: node?.attrs.x,
          y: node?.attrs.y,
        });
      }}
      x={x}
      y={y}
      onMouseEnter={(e: KonvaEventObject<MouseEvent>) => {
        const container = e.target.getStage()?.container();
        if (container) {
          if (container.style.cursor == 'default') {
            container.style.cursor = 'pointer';
          }
        }
      }}
      onMouseLeave={(e: KonvaEventObject<MouseEvent>) => {
        const container = e.target.getStage()?.container();
        if (container) {
          if (container.style.cursor == 'pointer') {
            container.style.cursor = 'default';
          }
        }
      }}
      onTransformEnd={() => {
        const node = shapeGroupRef.current;
        onUpdate({
          x: node?.attrs.x,
          y: node?.attrs.y,
          styles: {
            ...styles,
            rotation: node?.attrs.rotation,
            scaleX: node?.attrs.scaleX,
            scaleY: node?.attrs.scaleY,
            skewX: node?.attrs.skewX,
            skewY: node?.attrs.skewY,
          },
        });
      }}
      rotation={styles.rotation}
      scaleX={styles.scaleX}
      scaleY={styles.scaleY}
      skewX={styles.skewX}
      skewY={styles.skewY}
    >
      {selected && (
        <KonvaUtils.Html
          divProps={{ style: { zIndex: 100 } }}
          transformFunc={({ x, ...attrs }) => {
            return {
              ...attrs,
              scaleX: 1,
              scaleY: 1,
              x: x + attrs.scaleX,
              rotation: 0,
            };
          }}
        >
          <ShapeMenu onUpdate={onUpdate} onDelete={onDelete} styles={styles} />
        </KonvaUtils.Html>
      )}

      <Rect
        width={styles.width || styles.radius * 2}
        height={styles.height || styles.radius * 2}
        x={styles.width ? -styles.width / 2 : -styles.radius}
        y={styles.height ? -styles.height / 2 : -styles.radius}
        onClick={(e) => {
          e.cancelBubble = true;
          setSelected();
        }}
        fill={styles.fill}
        stroke={styles.stroke}
        strokeWidth={styles.strokeWidth}
        visible={styles.sides > 0 && styles.shapeType === shapeHash.square}
        ref={rectRef}
      />

      <RegularPolygon
        radius={styles.radius}
        sides={styles.sides ? styles.sides : 1}
        onClick={(e) => {
          e.cancelBubble = true;
          setSelected();
        }}
        fill={styles.fill}
        stroke={styles.stroke}
        strokeWidth={styles.strokeWidth}
        visible={styles.sides > 0 && styles.shapeType !== shapeHash.square}
        strokeScaleEnabled={false}
        ref={polygonRef}
      />
      <Circle
        radius={styles.radius}
        onClick={(e) => {
          e.cancelBubble = true;
          setSelected();
        }}
        fill={styles.fill}
        stroke={styles.stroke}
        strokeWidth={styles.strokeWidth}
        visible={styles.sides === 0}
        strokeScaleEnabled={false}
        ref={circleRef}
      />
      <Transformer
        borderStroke="#382152"
        anchorStroke="#382152"
        anchorSize={8}
        visible={selected}
        ref={trRef}
        borderStrokeWidth={2}
        ignoreStroke={true}
      />
    </Group>
  );
}

export default ShapeComponent;
