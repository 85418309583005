import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { NodeViewWrapper, NodeViewContent } from '@tiptap/react';

import {
  SpeakerInfo,
  SpeakerNameWrapper,
  LetterAvatarWrapper,
  SpeakerName,
  SpeakerTiming,
  SpeakerDropdownWrapper,
} from './styles';
import LetteredAvatar from '../../../LetteredAvatar';
import { formatTimestamp } from 'subtitle';
import Portal from '../../../PortalNew/PortalNew';
import SpeakerDropdown from '../../../SpeakerDropdown';
import { SpeakersContext, TSpeakerBlock } from '../../../../Context/SpeakersContext';
import { useTranscriptLinkedParticipants } from '../../../../Hooks/useTranscripts';
import { useParams } from 'react-router-dom';
import Avatar from '../../../Avatar';
import { EditorContext } from '../../../../Context/EditorContext';
import { Node } from 'prosemirror-model';

const beautifyTimestamp = (timestamp: number): string => {
  if (!timestamp) return '00:00:00';
  return formatTimestamp(timestamp).split(',')[0];
};

const SpeakerComponent: FC = (props: any) => {
  const { transcriptId } = useParams<{ dashboardId: string; transcriptId: string }>();
  const [isChangeNameModalOpen, setIsChangeNameModalOpen] = useState(false);
  const startTime = beautifyTimestamp(props.node.attrs.startTime);
  const endTime = beautifyTimestamp(props.node.attrs.endTime);
  const speakerNameRef = useRef<HTMLDivElement>(null);
  const { speakerBlocks } = useContext(SpeakersContext);
  const { updateSpeakerNames, linkParticipant } = useContext(EditorContext);
  const [
    ,
    ,
    refetchLinkedParticipants,
    getLinkedParticipant,
    getRowIdByParticipantId,
  ] = useTranscriptLinkedParticipants(transcriptId);
  const [conversionFinished, setConversionFinished] = useState(false);
  const [idUpdated, setIdUpdated] = useState(false);

  const currentId = props.node.attrs.id;
  const currentParticipant = getLinkedParticipant(currentId);
  const speakerName = props.node.attrs.speakerName || 'Unknown Speaker';

  useEffect(() => {
    const nodeContent = props.node.content.content;
    if (
      !nodeContent ||
      !nodeContent[0] ||
      !nodeContent[0].type ||
      nodeContent[0].type.name === 'paragraph'
    ) {
      setConversionFinished(true);
      return;
    }

    const newNode = {
      ...props.node.toJSON(),
      content: [
        {
          type: 'paragraph',
          content: props.node.toJSON().content,
        },
      ],
    };

    setTimeout(() => {
      setConversionFinished(true);
      props.editor
        .chain()
        .insertContentAt(
          {
            from: props.getPos(),
            to: props.getPos() + props.node.nodeSize,
          },
          newNode
        )
        .run();
    }, 0);
  }, []);

  useEffect(() => {
    if (!conversionFinished || idUpdated) return;
    if (!currentId && speakerBlocks.length) {
      const block = speakerBlocks.find((block) => block.speakerName === speakerName);
      if (!block) return;
      setIdUpdated(true);
      setTimeout(() => props.updateAttributes({ id: block.id }));
    }
  }, [props, currentId, speakerBlocks, speakerName, conversionFinished, idUpdated]);

  const handleChooseAnotherSpeaker = (speakerBlock: TSpeakerBlock) => {
    props.updateAttributes({ id: speakerBlock.id, speakerName: speakerBlock.speakerName });

    if (!speakerBlock.id) return;

    let editorUpdateChain = props.editor.chain();
    props.node.descendants((node: Node, pos: number) => {
      if (node.type.name === 'highlightTag') {
        const newNode = {
          ...node.toJSON(),
          attrs: { ...node.attrs, speakerId: speakerBlock.id },
        };

        editorUpdateChain = editorUpdateChain.insertContentAt(
          {
            from: props.getPos() + pos + 1,
            to: props.getPos() + pos + node.nodeSize,
          },
          newNode
        );
      }
    });
    editorUpdateChain.run();
  };

  const handleEditName = (newName: string) => {
    updateSpeakerNames(speakerName, newName);
  };

  const handleNewSpeaker = (newName: string) => {
    props.updateAttributes({
      id: Math.floor(Math.random() * Date.now()).toString(16),
      speakerName: newName,
    });
  };

  const handleLinkParticipant = async (participant: any) => {
    const rowId = currentParticipant ? getRowIdByParticipantId(currentParticipant.id) : '';
    await linkParticipant(transcriptId, props.node.attrs, rowId, participant);
    refetchLinkedParticipants();
  };

  const handleUnlinkParticipant = async () => {
    refetchLinkedParticipants();
  };

  const handleTimestampClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    const event = new CustomEvent('timestampClick', {
      detail: { startTime: props.node.attrs.startTime / 1000, shouldPlay: true },
    });
    document.dispatchEvent(event);
  };

  return (
    <NodeViewWrapper>
      <SpeakerInfo contentEditable={false} onClick={() => setIsChangeNameModalOpen(true)}>
        <SpeakerNameWrapper ref={speakerNameRef}>
          <LetterAvatarWrapper>
            {currentParticipant ? (
              <Avatar user={currentParticipant} />
            ) : (
              <LetteredAvatar name={speakerName} />
            )}
          </LetterAvatarWrapper>
          <SpeakerName>{speakerName}</SpeakerName>
        </SpeakerNameWrapper>
        <SpeakerTiming onClick={handleTimestampClick}>
          {startTime} - {endTime}
        </SpeakerTiming>
      </SpeakerInfo>

      {isChangeNameModalOpen && speakerNameRef.current && (
        <Portal wrapperId="speakerNameModal" containerElement={speakerNameRef.current}>
          <SpeakerDropdownWrapper>
            <SpeakerDropdown
              isOpen={isChangeNameModalOpen}
              currentSpeakerName={speakerName}
              currentSpeakerBlock={{ id: currentId, speakerName }}
              onUnlinkParticipant={handleUnlinkParticipant}
              onLinkParticipant={handleLinkParticipant}
              onEditName={handleEditName}
              onMerge={handleChooseAnotherSpeaker}
              onCreateSpeaker={handleNewSpeaker}
              onClose={() => setIsChangeNameModalOpen(false)}
            />
          </SpeakerDropdownWrapper>
        </Portal>
      )}

      <NodeViewContent as="span" />
    </NodeViewWrapper>
  );
};

export default SpeakerComponent;
