import { Reference, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  CREATE_TRANSCRIPT,
  CREATE_TRANSCRIPT_PARTICIPANT_LINK,
  DELETE_TRANSCRIPT,
  UPDATE_TRANSCRIPT,
  UPDATE_TRANSCRIPT_PARTICIPANT_LINK,
  DELETE_TRANSCRIPT_PARTICIPANT_LINK,
} from '../GraphQL/mutations';

import {
  FETCH_ALL_TRANSCRIPTS,
  FETCH_FILES,
  FETCH_TRANSCRIPT,
  FETCH_TRANSCRIPTS,
  FETCH_TRANSCRIPTS_WITH_TEXT,
  GET_SAMPLE_TRANSCRIPT,
  FETCH_TRANSCRIPT_PARTICIPANTS,
} from '../GraphQL/queries';
import { ID } from '../Models';
import { Node, NodeCue, parseSync } from 'subtitle';
import { TPrompt } from './usePrompts';
import { useContext } from 'react';
import { ProjectChecklistContext } from '../Context/ProjectChecklistContext';
import { PropmptTemplate } from '../Consts/promptTemplates';

export const useFetchTranscript = (id: string): [boolean, any, () => void] => {
  const { loading, data, refetch } = useQuery(FETCH_TRANSCRIPT, {
    variables: { id },
  });
  return [loading, data?.transcription, refetch];
};

export const useFetchSampleTranscript = (): [boolean, any, () => void] => {
  const { loading, data, refetch } = useQuery(GET_SAMPLE_TRANSCRIPT);
  return [loading, data?.transcription, refetch];
};

export default function useTranscripts() {
  const [createTranscriptMutation] = useMutation(CREATE_TRANSCRIPT);
  const [updateTranscriptMutation] = useMutation(UPDATE_TRANSCRIPT);
  const [deleteTranscriptMutation] = useMutation(DELETE_TRANSCRIPT);
  const [fetchTranscriptsQuery, { loading, data, called }] = useLazyQuery(FETCH_TRANSCRIPTS);
  const [
    fetchAllTranscriptsQuery,
    { loading: allLoading, data: allData, called: allCalled },
  ] = useLazyQuery(FETCH_ALL_TRANSCRIPTS);
  const [fetchTranscriptsWithTextQuery, transcriptsWithTextData] = useLazyQuery(
    FETCH_TRANSCRIPTS_WITH_TEXT
  );
  const [
    fetchTranscriptQuery,
    { loading: loadingOne, data: dataOne, called: calledOne, refetch },
  ] = useLazyQuery(FETCH_TRANSCRIPT);
  const { dataAdded, markDataAdded } = useContext(ProjectChecklistContext);

  async function createTranscript(dashboardId: ID, input: any) {
    const {
      data: {
        createTranscription: { transcription },
      },
    } = await createTranscriptMutation({
      variables: {
        input: {
          ...input,
          dashboardId,
        },
      },
      refetchQueries: [
        {
          query: FETCH_FILES,
          variables: { dashboardId },
        },
        {
          query: FETCH_TRANSCRIPTS,
          variables: { dashboardId },
        },
      ],
    });
    if (!dataAdded) {
      markDataAdded();
    }

    return transcription;
  }

  async function updateTranscript(id: ID, input: any) {
    await updateTranscriptMutation({
      variables: {
        id,
        input,
      },
      refetchQueries: [
        {
          query: FETCH_TRANSCRIPT,
          variables: { id },
        },
      ],
    });
  }

  function fetchTranscripts(dashboardId: ID) {
    if (!called) {
      fetchTranscriptsQuery({
        variables: {
          dashboardId,
        },
      });
    }
    return [loading, data?.transcriptions];
  }

  function fetchAllTranscripts() {
    if (!allCalled) {
      fetchAllTranscriptsQuery();
    }
    return [allLoading, allData?.transcriptions];
  }

  function fetchTranscriptsWithText(dashboardId: ID) {
    if (!transcriptsWithTextData.called) {
      fetchTranscriptsWithTextQuery({
        variables: {
          dashboardId,
        },
      });
    }
    return [transcriptsWithTextData.loading, transcriptsWithTextData.data?.transcriptions];
  }

  function fetchTranscript(id: ID) {
    if (!calledOne) {
      fetchTranscriptQuery({
        variables: {
          id,
        },
      });
    }
    return [loadingOne, dataOne?.transcription, refetch];
  }

  type TContent = {
    type: string;
    text?: string;
    content?: TContent[];
  };

  type LabelBlock = {
    type: string;
    content: TContent[];
    attrs: any;
  };

  type SpeakerBlock = {
    type: string;
    content: TContent[];
    attrs: {
      startTime: number;
      endTime: number;
      speakerName: string;
    };
  };

  type SummaryBlock = {
    type: string;
    content: TContent[];
    attrs: {
      id: string;
      templateId: string;
      templateTitle: string;
      status: string;
    };
  };

  type ContentBlock = SpeakerBlock | SummaryBlock | LabelBlock;

  function srtToTiptap(
    rawStr: string,
    template?: PropmptTemplate | null
  ): { type: string; content: ContentBlock[] } {
    const msTeamsVttReg = /(\n\n|^)([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}-\d+)\n\d{2}:\d{2}:\d{2}\.\d{3} --> \d{2}:\d{2}:\d{2}\.\d{3}/g;

    let str = rawStr.replace(/(\n){3,}/gim, '\n\n');
    str = str.replace(/(\r)+/gim, '');

    if (str.includes('WEBVTT') && str.match(msTeamsVttReg)) {
      str = removeUUIDsFromVTT(str);
    }

    function isNodeCue(x: Node): x is NodeCue {
      return x.type === 'cue';
    }

    const blocks = parseSync(str)
      .filter(isNodeCue)
      .reduce((blocks: ContentBlock[], { data }) => {
        if (!blocks) {
          blocks = [];
        }

        const { text, start, end } = data;

        let personName = 'Unknown Speaker',
          actualText = text;

        if (text.indexOf(':') > 0) {
          [personName, actualText] = text.split(':');

          const block = {
            type: 'speaker',
            content: [
              {
                type: 'paragraph',
                content: [
                  {
                    type: 'text',
                    text: actualText.replaceAll('\n', ' '),
                  },
                ],
              },
            ],
            attrs: {
              startTime: start,
              endTime: end,
              speakerName: personName,
            },
          };

          blocks.push(block);
        } else {
          if (blocks.length === 0) {
            blocks.push({
              type: 'speaker',
              content: [
                {
                  type: 'paragraph',
                  content: [
                    {
                      type: 'text',
                      text: '',
                    },
                  ],
                },
              ],
              attrs: {
                startTime: start,
                endTime: end,
                speakerName: personName,
              },
            });
          }
          const existingBlock = blocks[blocks.length - 1];
          const existingBlockContentText = existingBlock.content[0].content
            ? existingBlock.content[0].content[0].text?.trim()
            : '';

          blocks[blocks.length - 1] = {
            ...existingBlock,
            content: [
              {
                type: 'paragraph',
                content: [
                  {
                    type: 'text',
                    text: (existingBlockContentText + ' ' + text.trim()).replaceAll('\n', ' '),
                  },
                ],
              },
            ],
            attrs: {
              ...existingBlock.attrs,
              endTime: end,
            },
          } as SpeakerBlock;
        }

        return blocks;
      }, []);

    blocks.unshift({ type: 'transcriptLabel', content: [], attrs: {} } as LabelBlock);

    if (template) {
      blocks.unshift({
        type: 'summary',
        content: [],
        attrs: {
          id: Math.floor(Math.random() * Date.now()).toString(16),
          templateTitle: template.title,
          templateId: template.id,
          status: 'readyToStartGeneration',
        },
      } as SummaryBlock);
    }

    return { type: 'doc', content: blocks };
  }

  async function deleteTranscript(id: ID) {
    await deleteTranscriptMutation({
      variables: { id },
      update(cache) {
        cache.modify({
          fields: {
            transcriptions(existingRefs, { readField }) {
              return existingRefs.filter((ref: Reference) => id !== readField('id', ref));
            },
          },
        });
      },
    });
  }

  return {
    fetchTranscript,
    createTranscript,
    updateTranscript,
    fetchTranscripts,
    fetchAllTranscripts,
    fetchTranscriptsWithText,
    srtToTiptap,
    deleteTranscript,
  };
}

function removeUUIDsFromVTT(input: string): string {
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}-\d+$/gim;
  const output = input.replace(uuidRegex, '');
  return output.replace(/\n{3,}/g, '\n\n');
}

export const useTranscriptLinkedParticipants = (transcriptionId: ID) => {
  const { loading, data, refetch } = useQuery(FETCH_TRANSCRIPT_PARTICIPANTS, {
    variables: { transcriptionId },
  });

  const getLinkedParticipant = (speakerId: string) => {
    const participant = data?.transcriptionsParticipants?.find(
      (item: any) => item.speakerEntityId === speakerId
    )?.participant;
    return participant;
  };

  const getRowIdByParticipantId = (participantId: string) => {
    const rowId = data?.transcriptionsParticipants?.find(
      (item: any) => item.participant.id === participantId
    )?.id;
    return rowId;
  };

  return [
    loading,
    data?.transcriptionsParticipants,
    refetch,
    getLinkedParticipant,
    getRowIdByParticipantId,
  ];
};

export const useAddParticipantLink = (): [
  (transcriptionId: string, speakerEntityId: string, participantId: string) => any
] => {
  const [createMutation] = useMutation(CREATE_TRANSCRIPT_PARTICIPANT_LINK);

  return [
    async (transcriptionId: string, speakerEntityId: string, participantId: string) => {
      const res = await createMutation({
        variables: {
          transcriptionId,
          speakerEntityId,
          participantId,
        },
        refetchQueries: [
          {
            query: FETCH_TRANSCRIPT_PARTICIPANTS,
            variables: { transcriptionId },
          },
        ],
      });

      return res?.data?.createTranscriptionsParticipant?.transcriptionsParticipant;
    },
  ];
};

export const useUpdateParticipantLink = (): [
  (id: ID, transcriptionId: string, speakerEntityId: string, participantId: string) => void
] => {
  const [updateMutation] = useMutation(UPDATE_TRANSCRIPT_PARTICIPANT_LINK);

  return [
    async (id: ID, transcriptionId: string, speakerEntityId: string, participantId: string) => {
      const res = await updateMutation({
        variables: {
          id,
          transcriptionId,
          speakerEntityId,
          participantId,
        },
      });

      return res;
    },
  ];
};

export const useDeleteParticipantLink = (): [(id: string) => void] => {
  const [deleteMutation] = useMutation(DELETE_TRANSCRIPT_PARTICIPANT_LINK);

  return [
    async (id: string) => {
      const res = await deleteMutation({
        variables: {
          id,
        },
      });
      return res;
    },
  ];
};
