import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #d7d3dc;
  overflow: hidden;
`;

export const Option = styled.div<{ active: boolean }>`
  padding: 10px;
  background: #efedf1;

  color: rgba(59, 38, 81, 0.4);
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  border-right: 1px solid #d7d3dc;
  cursor: pointer;

  &:last-child {
    border-right: none;
  }

  ${({ active }) =>
    active &&
    `
    background: #ffffff;
    color: #3B2651;
  `}
`;
