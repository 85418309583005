import React, { useEffect, useState } from 'react';

import { Wrapper, Overlay, Container, Header, Title, CloseIcon, TasksContainer } from './styles';
import { Icon24 } from '../../Icons/Icon';
import TasksTable from '../TasksTable';
import { ITask } from '../TaskManager/TaskManager';
import AITemplates from '../AITemplates';
import { PropmptTemplate } from '../../Consts/promptTemplates';

interface TasksModalProps {
  isOpen: boolean;
  tasks: ITask[];
  currentTasks: ITask[];
  currentTask: ITask | null;
  onClose(): void;
  onChooseTask(task: ITask, template?: PropmptTemplate | null): void;
}

const TasksModal: React.FC<TasksModalProps> = ({
  isOpen,
  tasks,
  currentTasks,
  currentTask,
  onClose,
  onChooseTask,
}) => {
  const [showTemplates, setShowTemplates] = useState(false);
  const [maxHeight, setMaxHeight] = useState(0);
  const [chosenTask, setChosenTask] = useState<ITask | null>(null);

  useEffect(() => {
    if (currentTask?.id === 'summarize') {
      setShowTemplates(true);
      setChosenTask(currentTask);
    }
  }, [currentTask]);

  useEffect(() => {
    const handleResize = () => {
      setMaxHeight(
        window.innerHeight - 240 > 600 ? window.innerHeight - 240 : window.innerHeight - 120
      );
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
  }, [isOpen]);

  const handleChooseTask = (task: ITask) => {
    if (task.id === 'summarize') {
      setShowTemplates(true);
      setChosenTask(task);
    } else {
      onChooseTask(task);
    }
  };

  const handleTemplateCancel = () => {
    onClose();
    setShowTemplates(false);
  };

  const handleChooseTemplate = (template: PropmptTemplate) => {
    chosenTask && onChooseTask(chosenTask, template);
    setShowTemplates(false);
  };

  return (
    <Wrapper isOpen={isOpen}>
      <Overlay isOpen={isOpen} onClick={handleTemplateCancel} />
      <Container isOpen={isOpen} maxHeight={maxHeight} maxWidth={showTemplates ? 1100 : 1000}>
        <Header>
          <Title>{showTemplates ? 'Add Summary' : 'Add Posty Task'}</Title>
          <CloseIcon onClick={handleTemplateCancel}>
            <Icon24.Close />
          </CloseIcon>
        </Header>

        {!showTemplates && (
          <TasksContainer>
            <TasksTable
              tasks={tasks}
              currentTasks={currentTasks}
              onChooseTask={handleChooseTask}
              hasNoCredits={false}
            />
          </TasksContainer>
        )}

        {showTemplates && (
          <AITemplates
            type="transcript"
            onConfirm={handleChooseTemplate}
            onCancel={handleTemplateCancel}
            hideSidebarTitle
            hideCloseButton
            confirmButtonText="Add Summary"
          />
        )}
      </Container>
    </Wrapper>
  );
};

export default TasksModal;
