import { createContext, useContext } from 'react';
import { useQuery, gql, useMutation } from '@apollo/client';
import { FETCH_USERS } from '../GraphQL/queries';
import {
  DELETE_USER,
  INVITE_USER,
  MERGE_LOGINS,
  RESET_PASSWORD,
  UPDATE_USER,
} from '../GraphQL/mutations';
import { ID, User } from '../Models';
import { ProjectChecklistContext } from '../Context/ProjectChecklistContext';

const CURRENT_USER = gql`
  query currentUsr {
    currentUsr {
      id
      avatar
      picture
      name
      role
      createdAt
      systemNotifications
      marketingNotifications
      phone
    }
  }
`;

export const CurrentUserContext = createContext<User | null | undefined>(null);

export default function useUsers() {
  const { data, loading } = useQuery(CURRENT_USER);
  const { data: usersData, loading: loadingUsers, refetch: refetchUsers } = useQuery(FETCH_USERS);

  const [mutation] = useMutation(INVITE_USER);
  const [updateUserMutation] = useMutation(UPDATE_USER);
  const [deleteUserMutation] = useMutation(DELETE_USER);
  const [mergeLoginsMutation] = useMutation(MERGE_LOGINS);
  const { teammateAdded, markTeammateAdded } = useContext(ProjectChecklistContext);

  function currentUser() {
    return [loading, data?.currentUsr];
  }

  function fetchUsers() {
    return [loadingUsers, usersData?.users, refetchUsers];
  }

  async function inviteUser(email: string, role = 'user') {
    const result = await mutation({
      variables: {
        email: email.toLowerCase(),
        role,
      },
      refetchQueries: [
        {
          query: FETCH_USERS,
        },
      ],
    });

    if (!teammateAdded) {
      markTeammateAdded();
    }

    return result.data.success;
  }

  async function deleteUser(id: ID) {
    const { data } = await deleteUserMutation({
      variables: {
        id: id,
      },
      refetchQueries: [
        {
          query: FETCH_USERS,
        },
      ],
    });

    return data.deleteUser.user;
  }

  async function updateUser(id: ID, input: any) {
    const { data } = await updateUserMutation({
      variables: {
        id: id,
        input,
      },
      refetchQueries: [
        {
          query: FETCH_USERS,
        },
      ],
    });

    return data.updateUser.user;
  }

  async function mergeLogins(email: string) {
    const { data } = await mergeLoginsMutation({
      variables: {
        email,
      },
    });

    return data;
  }

  return {
    updateUser,
    deleteUser,
    currentUser,
    fetchUsers,
    inviteUser,
    mergeLogins,
  };
}

export function useCurrentUser(): [boolean, User?] {
  const { data, loading } = useQuery(CURRENT_USER);
  return [loading, data?.currentUsr];
}

export const useResetPassword = (): (() => Promise<string>) => {
  const [mutation] = useMutation(RESET_PASSWORD);

  return async () => {
    const result = await mutation();
    return result.data?.resetPassword?.url;
  };
};
