import styled from 'styled-components';

export const Container = styled.div<{
  type: 'success' | 'error' | 'message';
}>`
  display: flex;
  align-items: center;
  position: absolute;
  top: 96px;
  left: calc(50% - 358px);
  z-index: 1000;
  width: 716px;
  padding: 10px;
  border-radius: 8px;
  background-color: ${({ type }) =>
    type === 'success' ? 'rgba(227, 247, 239, 1)' : type === 'error' ? '#F87D7D' : '#ffffff'};
  ${({ type }) => type === 'message' && 'border: 1px solid rgba(215, 211, 220, 1);'}
`;

export const Message = styled.div<{ messageStyle: 'posty' | 'normal' }>`
  ${({ messageStyle }) =>
    messageStyle === 'posty'
      ? `
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    background: linear-gradient(90deg, #8E84EE 0%, #351A8A 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  `
      : `
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  `}
`;

export const IconWrapper = styled.div`
  margin-right: 10px;
`;
