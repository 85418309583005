import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import ProfileForm from '../Components/ProfileForm';
import SecondaryToolbar from '../Components/SecondaryToolbar/SecondaryToolbar';
import { onboardingPages } from '../Consts/onboardingPages';
import { CoverProps } from '../Models';
import useUsers from '../Hooks/useUsers';
import { defaultUserImages } from '../Consts/userImages';
import usePermissions from '../Hooks/usePermissions';
import Button from '../Components/Button';
import { useResetPassword } from '../Hooks/useUsers';

const ProfileSettings = (): JSX.Element | null => {
  const [, setValue] = useState('');
  const { currentUser, updateUser } = useUsers();
  const [loading, user] = currentUser();
  const resetPassword = useResetPassword();

  const handleUpdateUserName = (name: string) => {
    updateUser(user.id, { name: name });
  };

  const currentPage = {
    setValue: setValue,
    ...onboardingPages[0],
  };

  const [selectedImage, setSelectedImage] = useState<null | CoverProps>(null);

  useEffect(() => {
    const currDefaultImage = defaultUserImages.find((image) => image.name === user?.picture);
    if (!loading && currDefaultImage) {
      setSelectedImage(currDefaultImage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  if (loading) {
    return null;
  }

  const onImageClick = (image: CoverProps) => () => {
    setSelectedImage(image);
    updateUser(user.id, { picture: image.name });
  };

  const onRemoveImage = () => {
    setSelectedImage(null);
    updateUser(user.id, { picture: null });
  };

  return (
    <div>
      <SecondaryToolbar>
        <div className="flex w-full flex-row py-3 px-4 justify-between">
          <h1 className={'text-l font-medium mt-1'}>Settings / Profile</h1>
        </div>
      </SecondaryToolbar>
      <div className="flex flex-col justify-center">
        <div className="w-full ml-52 sm:w-500">
          <div className="bg-white py-10">
            <ProfileForm
              currentPage={currentPage}
              handleUpdateUserName={handleUpdateUserName}
              isOnboarding={false}
              loading={false}
              onImageClick={onImageClick}
              onRemoveImage={onRemoveImage}
              selectedImage={selectedImage}
              userName={user?.name ?? ''}
            />
          </div>
          <div className="text-xl font-medium py-2">Password</div>
          <Button
            type="secondary"
            className="mt-4"
            onClick={async () => {
              try {
                const url = await resetPassword();
                window.location.href = url;
              } catch (error) {
                toast.warning('You are logged in with Google account');
              }
            }}
          >
            Reset password
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProfileSettings;
