import React, { useMemo } from 'react';

import {
  useCreateTag,
  useGlobalTags,
  useFetchGlobalTagGroups,
  useFetchGlobalTagGroupSorting,
} from '../../Hooks/useTags';
import { TTagGroupWithTags, TagWithDetails } from '../../Models';
export interface WithTagManagementOptons {
  value?: TagWithDetails[];
  selectedText?: string;
  hideManageButton?: boolean;
  reverse?: boolean;
  onChange?(tags: TagWithDetails[]): void;
  onCreate?(tag: TagWithDetails): void;
  onDelete?(tag: TagWithDetails): void;
  onBlur?(): void;
}

export interface PassedComponentProps {
  groups: TTagGroupWithTags[];
  tagsWithoutGroup: TagWithDetails[];
  value?: TagWithDetails[];
  dashboardId?: string;
  suggestedTags?: TagWithDetails[];
  isLoading?: boolean;
  hideManageButton?: boolean;
  reverse?: boolean;
  onRefetchTags(): void;
  onChange?(tags: TagWithDetails[]): void;
  onCreate?(tagName: string): Promise<TagWithDetails>;
  onBlur?(): void;
  onUpdate?(): void;
}

const withGlobalTagManagement = (
  PassedComponent: React.ComponentType<PassedComponentProps>
): React.FC<WithTagManagementOptons> => {
  const WrappedComponent: React.FC<WithTagManagementOptons> = ({
    value,
    hideManageButton,
    reverse,
    onChange,
    onCreate,
    onBlur,
  }) => {
    const createTag = useCreateTag();
    const [loadingTags, tags, refetchTags] = useGlobalTags(true);
    const [tagGroupsLoading, externalTagGroups, refetchTagGroups] = useFetchGlobalTagGroups();
    const [globalGroupSortingLoading, globalGroupSorting, ,] = useFetchGlobalTagGroupSorting();

    const groupsWithTags = useMemo(() => {
      const filteredTagGroups =
        externalTagGroups
          ?.map((group) => {
            const tagsInGroup = tags
              .filter((tag) => tag.groupId === group.id)
              .sort((firstTag, secondTag) =>
                firstTag.name.toLowerCase() < secondTag.name.toLowerCase() ? -1 : 1
              );

            return {
              ...group,
              tags: tagsInGroup as TagWithDetails[],
            };
          })
          .filter((group) => group.tags.length) || [];

      const globalGroups = globalGroupSorting
        ? globalGroupSorting.sorting
            .map((id) => filteredTagGroups.find((group) => group.id === id) as TTagGroupWithTags)
            .filter((group) => !!group)
        : filteredTagGroups.filter((group) => group.isGlobal);
      return globalGroups;
    }, [externalTagGroups, tags, globalGroupSorting]);

    const tagsWithoutGroup = useMemo(() => {
      return tags.filter((tag) => !tag.groupId && tag.isGlobal) as TagWithDetails[];
    }, [tags]);

    const handleCreate = async (tagName: string): Promise<TagWithDetails> => {
      const newTag = await createTag({ name: tagName, isGlobal: true });
      onCreate && onCreate(newTag as TagWithDetails);
      refetchTags();
      return newTag as TagWithDetails;
    };

    return (
      <PassedComponent
        groups={groupsWithTags}
        tagsWithoutGroup={tagsWithoutGroup}
        value={value}
        onRefetchTags={() => {
          refetchTagGroups();
          refetchTags();
        }}
        onChange={onChange}
        onCreate={handleCreate}
        onBlur={onBlur}
        isLoading={loadingTags || tagGroupsLoading || globalGroupSortingLoading}
        hideManageButton={hideManageButton}
        reverse={reverse}
      />
    );
  };

  return WrappedComponent;
};

export default withGlobalTagManagement;
