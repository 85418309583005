import { gql, useMutation } from '@apollo/client';
import {
  FETCH_CANVAS_IMAGES,
  FETCH_NOTES,
  FETCH_SHAPES,
  FETCH_TEXT_NOTES,
} from '../GraphQL/queries';

export type TMiroBoard = {
  id?: string;
  name?: string;
  description?: string;
  viewLink?: string;
};

const IMPORT_BOARD = gql`
  mutation importMiroBoardMutation($input: ImportMiroBoardInput!) {
    importMiroBoard(input: $input) {
      success
      internalId
      isNew
    }
  }
`;

const UNDO_IMPORT_BOARD = gql`
  mutation undoImportMiroBoardMutation($input: UndoImportMiroBoardInput!) {
    undoImportMiroBoard(input: $input) {
      success
    }
  }
`;

export const useImportMiroBoard = (): [(dashboardId: string, board: TMiroBoard) => any] => {
  const [importMutation] = useMutation(IMPORT_BOARD);

  return [
    async (dashboardId: string, board: TMiroBoard) => {
      const res = await importMutation({
        variables: {
          input: {
            dashboardId,
            boardId: board.id,
            description: board.description,
            name: board.name,
            viewLink: board.viewLink,
            forceRewrite: false,
          },
        },
        refetchQueries: [
          {
            query: FETCH_NOTES,
            variables: { condition: { dashboardId } },
          },
          {
            query: FETCH_TEXT_NOTES,
            variables: { dashboardId },
          },
          {
            query: FETCH_CANVAS_IMAGES,
            variables: { dashboardId },
          },
          {
            query: FETCH_SHAPES,
            variables: { dashboardId },
          },
        ],
      });

      return res.data?.importMiroBoard;
    },
  ];
};

export const useUndoImportMiroBoard = (): [(miroBoardId: string, dashboardId: string) => any] => {
  const [undoImportMutation] = useMutation(UNDO_IMPORT_BOARD);

  return [
    async (miroBoardId: string, dashboardId: string) => {
      const res = await undoImportMutation({
        variables: {
          input: {
            boardId: miroBoardId,
          },
        },
        refetchQueries: [
          {
            query: FETCH_NOTES,
            variables: { condition: { dashboardId } },
          },
          {
            query: FETCH_TEXT_NOTES,
            variables: { dashboardId },
          },
          {
            query: FETCH_CANVAS_IMAGES,
            variables: { dashboardId },
          },
          {
            query: FETCH_SHAPES,
            variables: { dashboardId },
          },
        ],
      });

      return res.data?.undoImportMiroBoard;
    },
  ];
};
