import React, { FC } from 'react';

import { Container, IconWrapper, Message } from './styles';

interface PopupMessageProps {
  message: string;
  icon?: React.ReactNode;
  type: 'success' | 'error' | 'message';
  messageStyle?: 'posty' | 'normal';
}

const PopupMessage: FC<PopupMessageProps> = ({ message, icon, type, messageStyle = 'normal' }) => {
  return (
    <Container type={type}>
      {icon && <IconWrapper>{icon}</IconWrapper>}
      <Message messageStyle={messageStyle}>{message}</Message>
    </Container>
  );
};

export default PopupMessage;
