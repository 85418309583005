import { useContext } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import { FETCH_AUDIO_RECORDINGS, FETCH_AUDIO_RECORDING } from '../GraphQL/queries';
import {
  CREATE_AUDIO_RECORDING,
  UPDATE_AUDIO_RECORDING,
  DELETE_AUDIO_RECORDING,
} from '../GraphQL/mutations';
import { ID } from '../Models';

import useAnalytics from './useAnalytics';

export const useFetchAudioRecordings = (): [boolean, any[]] => {
  const { loading, data } = useQuery(FETCH_AUDIO_RECORDINGS);
  return [loading, data?.audioRecordings || []];
};

export const useFetchAudioRecording = (id: string): [boolean, any] => {
  const { loading, data } = useQuery(FETCH_AUDIO_RECORDING, {
    variables: { id },
  });
  return [loading, data?.audioRecording];
};

export const useCreateAudioRecording = (): [(recording?: any) => any] => {
  const [createMutation] = useMutation(CREATE_AUDIO_RECORDING);

  return [
    async (recording?: any) => {
      const res = await createMutation({
        variables: {
          input: {
            ...(recording || {}),
          },
        },
        refetchQueries: [
          {
            query: FETCH_AUDIO_RECORDINGS,
          },
        ],
      });
      return res?.data?.createAudioRecording?.audioRecording;
    },
  ];
};

export const useUpdateAudioRecording = (): [(id: ID, input: any) => void] => {
  const [updateMutation] = useMutation(UPDATE_AUDIO_RECORDING);

  return [
    (id: ID, input: any) => {
      return updateMutation({
        variables: {
          id,
          input: { ...input, updatedAt: new Date().toISOString() },
        },
        refetchQueries: [
          {
            query: FETCH_AUDIO_RECORDING,
            variables: { id },
          },
        ],
      });
    },
  ];
};

export const useDeleteAudioRecording = (): [(id: string) => void] => {
  const [deleteMutation] = useMutation(DELETE_AUDIO_RECORDING);

  return [
    (id: string) => {
      return deleteMutation({
        variables: {
          id,
        },
        refetchQueries: [
          {
            query: FETCH_AUDIO_RECORDINGS,
          },
        ],
      });
    },
  ];
};

export default {
  useFetchAudioRecordings,
  useFetchAudioRecording,
  useCreateAudioRecording,
  useUpdateAudioRecording,
  useDeleteAudioRecording,
};
