import React, { FC, useMemo, useState } from 'react';
import { OptionTypeBase } from 'react-select';
import { PropmptTemplate } from '../../Consts/promptTemplates';
import { useOrganization } from '../../Hooks/useOrganization';
import { Icon16, Icon24 } from '../../Icons/Icon';
import Icon68 from '../../Icons/Icon/Icon68';
import TaskDetails from '../TaskDetails';
import TaskModal from '../TasksModal';
import TasksTable from '../TasksTable';
import {
  ArrowIcon,
  Container,
  EditTaskButton,
  Header,
  HeaderIcon,
  HeaderText,
  PostySubtitle,
  PostyTitle,
  RemoveTaskButton,
  SummaryTemplate,
  SummaryTemplateIcon,
  SummaryTemplateName,
  Task,
  TaskContainer,
  TaskIcon,
  TaskLeftSide,
  TaskName,
  TaskPrice,
  TaskRightSide,
  TasksWrapper,
} from './styles';

export interface ITask {
  id: string;
  name: string;
  description: string;
  icon: React.ReactNode;
  creditsPrice?: number;
  withSettings?: boolean;
  optionsList?: string[];
}

interface TaskManagerProps {
  tasks: ITask[];
  currentTasks: ITask[];
  hasVideo?: boolean;
  currentTemplate: PropmptTemplate | null;
  currentLanguage?: OptionTypeBase;
  currentHighlightPrompt?: string;
  currentTagsEnabled?: boolean;
  onChangeHighlightPrompt: (highlightPrompt: string) => void;
  onTagsEnabledChange: (tagsEnabled: boolean) => void;
  onTasksChange: (tasks: ITask[]) => void;
  onTemplateChange: (template: PropmptTemplate | null) => void;
  onCurrentLanguageChange?: (language: OptionTypeBase) => void;
}

const trancribeTask: ITask = {
  id: 'transcribe',
  name: 'Transcribe',
  description: `Posty transcribes in 20+ languages and accents. Choose your file's language below for the best results.`,
  icon: <Icon24.TaskTranscription />,
};

const TaskManager: FC<TaskManagerProps> = ({
  tasks,
  currentTasks,
  currentTemplate,
  hasVideo,
  currentLanguage,
  currentHighlightPrompt,
  currentTagsEnabled,
  onCurrentLanguageChange,
  onTasksChange,
  onTemplateChange,
  onChangeHighlightPrompt,
  onTagsEnabledChange,
}) => {
  const [taskModalOpen, setTaskModalOpen] = useState(false);
  const [currentTask, setCurrentTask] = useState<ITask | null>(null);
  const [, org] = useOrganization();
  const hasCredits = org.aiCredits > 0;

  const handleChooseTask = (task: ITask, template?: PropmptTemplate | null) => {
    const existingTask = currentTasks.find((t) => t.id === task.id);
    !existingTask && onTasksChange([...currentTasks, task]);
    if (template) {
      onTemplateChange(template);
    }
    setTaskModalOpen(false);
    setCurrentTask(null);
  };

  const handleRemoveTask = (task: ITask) => {
    onTasksChange(currentTasks.filter((t) => t.id !== task.id));
    setCurrentTask(null);
  };

  const handleClickTask = (task: ITask) => {
    setCurrentTask(task);
  };

  const currentCreditsSum = useMemo(
    () => currentTasks.reduce((acc, task) => acc + (task.creditsPrice || 0), 0),
    [currentTasks]
  );

  const creditWord = (credits: number) => (credits === 1 ? 'credit' : 'credits');

  return (
    <Container>
      <Header>
        <HeaderIcon>
          <Icon68.Posty />
        </HeaderIcon>

        <HeaderText>
          <PostyTitle>Posty Tasks</PostyTitle>
          <PostySubtitle>Choose tasks to automatically run on your data</PostySubtitle>
        </HeaderText>
      </Header>
      <TasksWrapper>
        <TaskContainer hasCurrentTasks={!!currentTasks.length}>
          {hasVideo && (
            <>
              <Task key="transcribe" onClick={() => handleClickTask(trancribeTask)}>
                <TaskLeftSide>
                  <TaskIcon>{trancribeTask.icon}</TaskIcon>
                  <TaskName>{trancribeTask.name}</TaskName>
                </TaskLeftSide>

                <TaskRightSide>
                  <EditTaskButton>
                    <Icon24.Settings />
                  </EditTaskButton>
                </TaskRightSide>
              </Task>

              {!!currentTasks.length && (
                <ArrowIcon>
                  <Icon24.TaskArrow />
                </ArrowIcon>
              )}
            </>
          )}

          {currentTasks.map((task, index: number) => (
            <>
              <Task key={task.id} onClick={() => handleClickTask(task)}>
                <TaskLeftSide>
                  <TaskIcon>{task.icon}</TaskIcon>
                  <TaskName>{task.name}</TaskName>
                  {task.creditsPrice && (
                    <TaskPrice>
                      {task.creditsPrice} {creditWord(task.creditsPrice)}
                    </TaskPrice>
                  )}
                </TaskLeftSide>

                <TaskRightSide>
                  {task.id === 'summarize' && currentTemplate && (
                    <SummaryTemplate>
                      <SummaryTemplateIcon src={currentTemplate.icon} />
                      <SummaryTemplateName>{currentTemplate.title}</SummaryTemplateName>
                    </SummaryTemplate>
                  )}

                  {task.id !== 'summarize' && (
                    <EditTaskButton>
                      <Icon24.Settings />
                    </EditTaskButton>
                  )}

                  <RemoveTaskButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemoveTask(task);
                    }}
                  >
                    <Icon16.Close />
                  </RemoveTaskButton>
                </TaskRightSide>
              </Task>
              {index < currentTasks.length - 1 && (
                <ArrowIcon>
                  <Icon24.TaskArrow />
                </ArrowIcon>
              )}
            </>
          ))}
        </TaskContainer>

        <TasksTable
          tasks={tasks}
          currentTasks={currentTasks}
          onChooseTask={(task) => {
            if (task.id === 'summarize') {
              setCurrentTask(task);
            } else {
              handleChooseTask(task);
              if (task.id === 'highlight') {
                setCurrentTask(task);
              }
            }
          }}
          hasNoCredits={!hasCredits || currentCreditsSum >= org.aiCredits}
        />
      </TasksWrapper>

      <TaskModal
        isOpen={taskModalOpen || currentTask?.id === 'summarize'}
        onClose={() => {
          setTaskModalOpen(false);
          setCurrentTask(null);
        }}
        onChooseTask={handleChooseTask}
        tasks={tasks}
        currentTasks={currentTasks}
        currentTask={currentTask}
      />

      <TaskDetails
        isOpen={!!currentTask && currentTask.id !== 'summarize'}
        currentTask={currentTask}
        onClose={() => setCurrentTask(null)}
        currentLanguage={currentLanguage}
        onCurrentLanguageChange={onCurrentLanguageChange}
        onRemoveTask={handleRemoveTask}
        onChangeHighlightPrompt={onChangeHighlightPrompt}
        onTagsEnabledChange={onTagsEnabledChange}
        currentHighlightPrompt={currentHighlightPrompt}
        currentTagsEnabled={currentTagsEnabled}
      />
    </Container>
  );
};

export default TaskManager;
