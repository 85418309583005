import React, { useState } from 'react';
import { Popover } from '@headlessui/react';
import { useDebouncedCallback } from 'use-debounce';
import { PencilIcon, TrashIcon } from '@heroicons/react/outline';
import ArrowOpenIcon from '../Icons/ArrowOpenIcon';
import { STICKY_COLORS } from '../Consts';
import { StickyDuplicateIcon } from '../Icons';
import Input from './Input';

export default function StickyMenu({
  color,
  onCopy,
  onOpen,
  onDelete,
  onColorChange,
  onEdit,
  colorChangeEnabled = true,
  readOnly = false,
}: {
  color: string;
  onColorChange: (value: string) => void;
  onEdit: () => void;
  onOpen: () => void;
  onCopy: () => void;
  onDelete: () => void;
  readOnly?: boolean;
  colorChangeEnabled?: boolean;
}): JSX.Element {
  const buttonStrokeColor = readOnly ? 'rgba(59, 38, 81, 0.6)' : '#3B2651';
  const [colorString, setColorString] = useState(color);

  const updateColor = useDebouncedCallback((color: string) => {
    onColorChange(color);
  }, 300);
  return (
    <div
      className={'-mt-12 z-100'}
      style={{
        marginLeft: '-50%',
      }}
    >
      <span className="relative z-0 inline-flex shadow-md rounded-md">
        <button
          disabled={readOnly}
          onClick={() => onCopy()}
          className="relative inline-flex items-center px-2 py-1 rounded-l-md border border-gray-300 bg-white text-md font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
        >
          <StickyDuplicateIcon stroke={buttonStrokeColor} />
        </button>
        <button
          disabled={readOnly}
          onClick={() => onEdit()}
          className="-ml-px relative inline-flex items-center px-2 py-1.5 border border-gray-300 bg-white text-md font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
        >
          <PencilIcon stroke={buttonStrokeColor} className={'h-5 w-5'} />
        </button>
        {!readOnly && colorChangeEnabled && (
          <Popover className="relative">
            <Popover.Button className="-ml-px relative inline-flex items-center px-3 py-2 border border-gray-300 bg-white text-md font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 h-full">
              <div
                className={'h-4 w-4 rounded-lg'}
                style={{
                  backgroundColor: color,
                }}
              />
            </Popover.Button>
            <Popover.Panel className="absolute z-10 mt-1 p-2 bg-white border shadow-md">
              <div className="grid gap-1 grid-cols-5 w-[132px]">
                {STICKY_COLORS.map((x, index) => (
                  <a
                    href="#"
                    onClick={() => {
                      setColorString(x);
                      return onColorChange(x);
                    }}
                    key={index}
                  >
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="11" cy="11" r="11" fill={x} />
                      {color == x && (
                        <path
                          d="M7 10.143L10.4286 13.5714L15 8.42871"
                          stroke="#382152"
                          strokeWidth="1.5"
                        />
                      )}
                    </svg>
                  </a>
                ))}
              </div>
              <div className="flex mt-3 items-center">
                <div className="mr-2">
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="11" cy="11" r="11" fill={colorString} />
                  </svg>
                </div>
                <div>
                  <Input
                    value={colorString}
                    onChange={(e) => {
                      const color = e.target.value;
                      updateColor(color);
                      return setColorString(color);
                    }}
                    className="px-1 py-1"
                  />
                </div>
              </div>
            </Popover.Panel>
          </Popover>
        )}
        <button
          onClick={() => onOpen()}
          className="-ml-px relative inline-flex items-center px-2 py-1.5 border border-gray-300 bg-white text-md font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
        >
          <ArrowOpenIcon className={'h-4 w-4'} />
        </button>
        <button
          disabled={readOnly}
          onClick={() => onDelete()}
          className="-ml-px relative inline-flex items-center px-3 py-2 border rounded-r-md border-gray-300 bg-white text-md font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
        >
          <TrashIcon stroke={buttonStrokeColor} className={'h-5 w-5'} />
        </button>
      </span>
    </div>
  );
}
