import React, { useEffect, useState } from 'react';
import { useHistory, useParams, Route } from 'react-router-dom';
import { useEffectOnce, useInterval } from 'usehooks-ts';
import { nanoid } from 'nanoid';
import {
  DocumentTextIcon,
  DocumentIcon,
  PlusIcon,
  TrashIcon,
  UploadIcon,
  DocumentDuplicateIcon,
} from '@heroicons/react/outline';
import { Loader } from '../../Components';
import Well from '../../Components/Well';
import SecondaryToolbar from '../../Components/SecondaryToolbar/SecondaryToolbar';
import Button from '../../Components/Button';
import useFiles from '../../Hooks/useFiles';
import { VideoCameraIcon, MusicNoteIcon } from '@heroicons/react/outline';
import useTranscripts from '../../Hooks/useTranscripts';
import ReactTooltip from 'react-tooltip';

import {
  useFetchDocuments,
  useDeleteDocument,
  useCreateDocument,
  useUpdateDocument,
} from '../../Hooks/useDocuments';
import { checkOrganizationLimits, useOrganization } from '../../Hooks/useOrganization';
import {
  EmptyStateButton,
  EmptyStatePlusIcon,
  EmptyStateSecondaryText,
  EmptyStateContainer,
  EmptyState,
  EmptyStateTitle,
  EmptyStateText,
  HeroImage,
} from '../../Components/EmptyState2';
import dataEmptyImage from '../../assets/empty-states/data.png';
import usePermissions from '../../Hooks/usePermissions';
import ListWithFolders from '../../Components/ListWithFolders';
import {
  useFetchFolders,
  useCreateFolder,
  useUpdateFolder,
  useDeleteFolder,
} from '../../Hooks/useDataFolders';
import { useApolloClient } from '@apollo/client';
import { FETCH_DOCUMENT, FETCH_TRANSCRIPT } from '../../GraphQL/queries';
import { Tag } from '../../Models';
import UploadFileModal from '../../Components/UploadFileModal';
import useAnalytics from '../../Hooks/useAnalytics';
import {
  Container,
  DataButtons,
  SecondaryLabel,
  UploadCsvButton,
  UploadCsvButtonIcon,
} from './styles';
import { useCSVImport } from '../../Hooks/useDataImport';
import { Icon24 } from '../../Icons/Icon';
import { isMobile } from '../../Consts/screen';

function Data(): JSX.Element {
  const history = useHistory();
  const { dashboardId } = useParams<{ dashboardId: string }>();
  const { analytics } = useAnalytics();
  const { canCreateData, canEditData } = usePermissions();
  const client = useApolloClient();
  const [foldersLoading, folders] = useFetchFolders(dashboardId);
  const [createFolder] = useCreateFolder(dashboardId);
  const [updateFolder] = useUpdateFolder(dashboardId);
  const [deleteFolder] = useDeleteFolder(dashboardId);
  const { fetchFiles, deleteFile, createFile, updateFile } = useFiles();
  const {
    fetchTranscripts,
    deleteTranscript,
    createTranscript,
    updateTranscript,
  } = useTranscripts();
  const [loading, files, refetchFiles] = fetchFiles(dashboardId);
  const [loadingTranscripts, transcripts] = fetchTranscripts(dashboardId);
  const [loadingDocuments, documents] = useFetchDocuments(dashboardId);
  const [, org] = useOrganization();
  const [createDocument] = useCreateDocument();
  const [deleteDocument] = useDeleteDocument();
  const [updateDoc] = useUpdateDocument();
  const [handleImportFromCsv] = useCSVImport(dashboardId);
  const [showUploadModal, setShowUploadModal] = useState(false);

  const processingFiles = files?.filter((file: any) => file.status === 'PROCESSING') || [];

  useEffectOnce(() => {
    analytics.sendEvent('pageView.Data', {
      projectId: dashboardId,
    });
  });

  useInterval(
    () => {
      if (loading || !files) {
        return;
      }
      refetchFiles();
    },
    // Delay in milliseconds or null to stop it
    processingFiles.length > 0 ? 1000 : null
  );

  const handleCreateDocument = async (e: React.MouseEvent) => {
    e.stopPropagation();
    const doc = await createDocument(dashboardId);
    history.push(`/projects/${dashboardId}/data/docs/${doc.id}`);
  };

  if (loading || !files || foldersLoading) {
    return <Loader />;
  }

  if (loadingTranscripts || !transcripts || loadingDocuments) {
    return <Loader />;
  }

  const fileItems = files?.map((x: any) => ({
    id: x.id,
    originalItem: x,
    itemType: 'file',
    icon: {
      element:
        x.type === 'audio' ? (
          <MusicNoteIcon className={'w-6 h-6 mr-2'} />
        ) : (
          <VideoCameraIcon className={'w-6 h-6 mr-2'} />
        ),
    },
    name: x.name || 'Untitled',
    status: x.status,
    url: x.transcription ? `/projects/${dashboardId}/data/${x.transcription?.id}` : null,
    user: x.userByCreatedBy,
    timestamp: x.createdAt,
    folderId: x.folderId,
    actions: canEditData
      ? [
          {
            icon: <DocumentDuplicateIcon className="mr-3 h-5 w-5" />,
            name: 'Copy',
            handler: async () => {
              if (!x.transcription) return;
              const random = nanoid(4);
              const newFile = await createFile(dashboardId, {
                name: x.name + ' copy',
                type: x.type,
                mimeType: x.mimeType,
                s3VideoPath: x.s3VideoPath ? x.s3VideoPath + `?copy=${random}` : null,
                s3AudioPath: x.s3AudioPath ? x.s3AudioPath + `?copy=${random}` : null,
                status: x.status,
                duration: null,
                metadata: x.metadata,
                size: null,
                statusText: x.statusText,
                isCopy: true,
              });

              const {
                data: { transcription },
              } = await client.query({
                query: FETCH_TRANSCRIPT,
                variables: { id: x.transcription.id },
              });
              const res = await createTranscript(dashboardId, {
                name: transcription.name + ' copy',
                text: transcription.text,
                fileId: newFile.id,
                participantId: transcription.participantId,
                tagsTranscriptions: {
                  create: transcription.tagsList?.map((tag: Tag) => ({ tagId: tag.id })) || [],
                },
              });

              history.push(`/projects/${dashboardId}/data/${res.id}`);
            },
          },
          {
            icon: <TrashIcon className="mr-3 h-5 w-5" />,
            name: 'Delete',
            handler: async () => {
              if (confirm(`Are you sure you want to delete "${x.name}"?. This cannot be undone.`)) {
                await deleteFile(x.id);
              }
            },
          },
        ]
      : [],
  }));

  const transcriptItems = transcripts
    ?.filter((x: any) => !x.fileId)
    .map((x: any) => ({
      id: x.id,
      originalItem: x,
      itemType: 'transcript',
      icon: { element: <DocumentTextIcon className={'w-6 h-6 mr-2'} /> },
      name: x.name || 'Untitled',
      status: x.status,
      url: `/projects/${dashboardId}/data/${x.id}`,
      user: x.userByCreatedBy,
      timestamp: x.createdAt,
      folderId: x.folderId,
      actions: canEditData
        ? [
            {
              icon: <DocumentDuplicateIcon className="mr-3 h-5 w-5" />,
              name: 'Copy',
              handler: async () => {
                const {
                  data: { transcription },
                } = await client.query({
                  query: FETCH_TRANSCRIPT,
                  variables: { id: x.id },
                });
                const res = await createTranscript(dashboardId, {
                  name: transcription.name + ' copy',
                  text: transcription.text,
                  participantId: transcription.participantId,
                  tagsTranscriptions: {
                    create: transcription.tagsList?.map((tag: Tag) => ({ tagId: tag.id })) || [],
                  },
                });

                history.push(`/projects/${dashboardId}/data/${res.id}`);
              },
            },
            {
              icon: <TrashIcon className="mr-3 h-5 w-5" />,
              name: 'Delete',
              handler: async () => {
                if (
                  confirm(`Are you sure you want to delete "${x.name}"?. This cannot be undone.`)
                ) {
                  await deleteTranscript(x.id);
                }
              },
            },
          ]
        : [],
    }));

  const documentItems = documents.map((x: any) => ({
    id: x.id,
    originalItem: x,
    itemType: 'document',
    icon: { element: <DocumentIcon className={'w-6 h-6 mr-2'} /> },
    name: x.name || 'Untitled',
    url: `/projects/${dashboardId}/data/docs/${x.id}`,
    user: x.userByCreatedBy,
    timestamp: x.createdAt,
    folderId: x.folderId,
    actions: canEditData
      ? [
          {
            icon: <DocumentDuplicateIcon className="mr-3 h-5 w-5" />,
            name: 'Copy',
            handler: async () => {
              const {
                data: { document },
              } = await client.query({
                query: FETCH_DOCUMENT,
                variables: { id: x.id },
              });
              const doc = await createDocument(dashboardId, {
                content: document.content,
                name: document.name + ' copy',
                participantId: document.participantId,
                tagsDocuments: {
                  create: document.tagsList?.map((tag: Tag) => ({ tagId: tag.id })) || [],
                },
                createdBy: document.createdBy,
              });
              history.push(`/projects/${dashboardId}/data/docs/${doc.id}`);
            },
          },
          {
            icon: <TrashIcon className="mr-3 h-5 w-5" />,
            name: 'Delete',
            handler: async () => {
              if (confirm(`Are you sure you want to delete "${x.name}"?. This cannot be undone.`)) {
                await deleteDocument(dashboardId, x.id);
              }
            },
          },
        ]
      : [],
  }));

  const allItems = [...transcriptItems, ...fileItems, ...documentItems].sort((x, y) =>
    x.timestamp >= y.timestamp ? -1 : 1
  );

  async function handleUploadFile(file: File) {
    analytics.sendEvent('AddData', {
      ext: file.name.split('.').pop(),
    });

    refetchFiles();
  }

  const handleShowUploadModal = () => {
    if (!checkOrganizationLimits(org, history, ['duration', 'storage'])) {
      return false;
    }

    history.push(`/projects/${dashboardId}/upload`);
  };

  const handleUploadCsv = async () => {
    handleImportFromCsv();
    history.push(`/projects/${dashboardId}/notes`);
  };

  return (
    <Container>
      <SecondaryToolbar>
        <div className="flex w-full flex-row py-3 justify-between">
          <div>
            <h1 className={'text-l font-medium mt-1'}>Data</h1>
          </div>
          {canCreateData && (
            <DataButtons>
              {!isMobile() && (
                <UploadCsvButton onClick={handleUploadCsv}>
                  <UploadCsvButtonIcon>
                    <Icon24.SpreadsheetCsv />
                  </UploadCsvButtonIcon>
                  Upload CSV
                </UploadCsvButton>
              )}
              <Button onClick={handleShowUploadModal} type="secondary">
                <UploadIcon className="w-5 h-5 mr-1" />
                Import data
              </Button>

              <Button className="ml-3" onClick={handleCreateDocument}>
                <PlusIcon className="text-white w-5 h-5 mr-1" />
                New document
              </Button>
            </DataButtons>
          )}
        </div>
      </SecondaryToolbar>
      <Well wellKey="transcript-well">
        In this Data section is where you import raw data such as video, audio or transcript files.
        Once processed, you can highlight parts of your data to add as notes for analysis.
      </Well>
      {!allItems.length && (
        <EmptyStateContainer>
          <EmptyState>
            <HeroImage src={dataEmptyImage} wide></HeroImage>
            <EmptyStateTitle>Add data to get started</EmptyStateTitle>
            <EmptyStateText>Data includes raw notes, videos, and transcripts.</EmptyStateText>
            {canCreateData && (
              <>
                <EmptyStateButton onClick={handleCreateDocument}>
                  <EmptyStatePlusIcon />
                  New document
                </EmptyStateButton>
                <EmptyStateText>or</EmptyStateText>
                <Button onClick={handleShowUploadModal} type="secondary">
                  <UploadIcon className="w-5 h-5 mr-1" />
                  Import data
                </Button>
                <EmptyStateSecondaryText>
                  supported file types are .mp4, .mov, .mp3, .m4a, .docx, .pdf
                  <SecondaryLabel>Beta</SecondaryLabel> , .txt, .srt, .vtt.
                </EmptyStateSecondaryText>
              </>
            )}
          </EmptyState>
        </EmptyStateContainer>
      )}
      {allItems.length > 0 && (
        <ListWithFolders
          defaultItemTitle="document"
          items={allItems}
          folders={folders}
          setItemFolder={async (item, folderId) => {
            if (item.itemType === 'file') {
              await updateFile(item.id, {
                folderId,
              });
            }
            if (item.itemType === 'document') {
              await updateDoc(item.id, {
                folderId,
              });
            }
            if (item.itemType === 'transcript') {
              await updateTranscript(item.id, {
                folderId,
              });
            }
          }}
          onCreateFolder={
            canCreateData
              ? () => {
                  createFolder();
                }
              : null
          }
          onRenameFolder={
            canCreateData
              ? (id: string, name: string) => {
                  updateFolder(id, name);
                }
              : null
          }
          onDeleteFolder={
            canCreateData
              ? (id: string) => {
                  deleteFolder(id);
                }
              : null
          }
          onCreateItem={
            canCreateData
              ? async (folderId) => {
                  const doc = await createDocument(dashboardId, { folderId });
                  history.push(`/projects/${dashboardId}/data/docs/${doc.id}`);
                }
              : null
          }
        />
      )}
      <ReactTooltip place={'top'} effect={'solid'} />
    </Container>
  );
}

export default Data;
