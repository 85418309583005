import React, { useEffect } from 'react';
import { useSegment } from 'react-segment-hooks';
import projectsEmptyImage from '../assets/empty-states/projects.png';
import { Loader } from '../Components';
import { EmptyState, EmptyStateText, EmptyStateTitle, HeroImage } from '../Components/EmptyState2';
import { NewProjectButton } from '../Components/NewProjectButton';
import { ProjectsList } from '../Components/ProjectsList';
import SecondaryToolbar from '../Components/SecondaryToolbar/SecondaryToolbar';
import Well from '../Components/Well';
import useBoards, { useFetchFolders } from '../Hooks/useBoards';
import usePermissions from '../Hooks/usePermissions';

function Projects(): JSX.Element {
  const analytics = useSegment();

  useEffect(() => {
    analytics.page({
      name: 'Projects',
    });
  }, []);

  const { fetchBoards } = useBoards();
  const [boardsLoading, dashboards] = fetchBoards();

  const [foldersLoading, folders] = useFetchFolders();

  const { canCreateProjects } = usePermissions();

  if (boardsLoading || foldersLoading) {
    return <Loader />;
  }

  if (!dashboards?.length) {
    return (
      <EmptyState>
        <HeroImage src={projectsEmptyImage} wide></HeroImage>
        <EmptyStateTitle>Create a new project</EmptyStateTitle>
        <EmptyStateText>
          Projects are how you organize all of your research in Notably.
        </EmptyStateText>
        <NewProjectButton />
      </EmptyState>
    );
  }

  return (
    <div className="flex h-full justify-center">
      <div className="w-full px-5">
        {canCreateProjects && (
          <Well wellKey="project-well">
            This is where all of your research projects live. View a sample project below to see how
            Notably works or create a new project to add your own data.
          </Well>
        )}
        {!canCreateProjects && (
          <Well wellKey="project-well-viewonly">
            You’ve been added to this workspace as a viewer. You can view projects, but cannot edit
            or create new projects.
          </Well>
        )}
        <SecondaryToolbar>
          <div className="flex w-full flex-row py-7 justify-between items-center">
            <div>
              <h1 className={'text-md font-medium mt-2'}>Projects</h1>
            </div>
            <NewProjectButton />
          </div>
        </SecondaryToolbar>
        <ProjectsList
          dashboards={dashboards}
          folders={folders || []}
          loading={boardsLoading || foldersLoading}
        />
      </div>
    </div>
  );
}

export default Projects;
