import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: center;
  padding: 50px 20px 20px;
`;

export const Image = styled.div<{ src: string }>`
  width: 150px;
  height: 150px;
  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: 20px;
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

export const Text = styled.div<{ isGenerating: boolean }>`
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  ${({ isGenerating }) =>
    isGenerating
      ? css`
          background: linear-gradient(
            90deg,
            rgba(142, 132, 238, 0.5) 0%,
            rgba(53, 26, 138, 0.5) 100%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          margin-left: 10px;
          margin-bottom: 0;
        `
      : css`
          color: rgba(59, 38, 81, 0.4);
          margin-bottom: 20px;
        `}
  text-align: center;
`;

export const FAQLink = styled.a`
  width: 100%;
  font-size: 12px;
  line-height: 24px;
  font-weight: 500;
  color: rgba(59, 38, 81, 0.4);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
`;

export const QuestionIconWrapper = styled.div`
  margin-right: 4px;
`;

export const HighlightAndTagButton = styled.div`
  padding: 5px;
  background-color: #ffffff;
  border-radius: 5px;
  display: flex;
  align-items: center;
`;

export const HighlightAndTagButtonText = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  background: linear-gradient(90.96deg, #8e84ee 0.82%, #514b88 56.57%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-left: 5px;
`;

export const HighlightAndTagButtonWrapper = styled.div<{ disabled: boolean }>`
  position: relative;
  padding: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
  ${({ disabled }) =>
    disabled
      ? css`
          background: rgba(215, 211, 220, 1);

          & ${HighlightAndTagButton} {
            cursor: default;
            pointer-events: none;
          }

          & ${HighlightAndTagButtonText} {
            background: none;
            -webkit-background-clip: unset;
            -webkit-text-fill-color: unset;
            color: rgba(215, 211, 220, 1);
          }
        `
      : css`
          background: linear-gradient(0deg, #8e84ee 0%, #d7d3dc 100%);
        `}
  cursor: pointer;
`;

export const HighlightPopupWrapper = styled.div<{ top: number; left: number }>`
  position: absolute;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  z-index: 100;
`;
