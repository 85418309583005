import styled, { keyframes } from 'styled-components';
import { breakpoint } from '../../Shared';

export const Wrapper = styled.div<{ isOpen: boolean }>`
  position: fixed;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;

  ${({ isOpen }) =>
    isOpen &&
    `
    width: 100%;
    height: 100%;
  `}
`;

export const SecondaryLabel = styled.span`
  margin-left: 2px;
  margin-right: 2px;
  /* display: flex; */
  padding: 3px 3px;
  /* align-items: center; */
  gap: 5px;
  border-radius: 3px;
  background: rgba(56, 33, 82, 0.2);
  color: #382152;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
`;

export const Overlay = styled.div<{ isOpen: boolean }>`
  position: fixed;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  background-color: #a39ea9;
  transition: opacity 0.25s ease;
  opacity: 0;
  z-index: -1;

  ${({ isOpen }) =>
    isOpen &&
    `
    width: 100%;
    height: 100%;
    opacity: 0.4;
    z-index: 999;
  `}
`;

export const Container = styled.div<{
  isOpen: boolean;
  isTemplatesStep: boolean;
  maxHeight: number;
}>`
  width: 100%;
  max-width: 600px;
  background-color: #ffffff;
  border-radius: 6px;
  filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.1));
  transition: opacity 0.12s ease-in;
  pointer-events: none;
  z-index: -1;
  opacity: 0;

  ${({ isTemplatesStep, maxHeight }) =>
    isTemplatesStep &&
    `
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 1020px;
    max-height: ${maxHeight}px;
    left: calc(50% - 510px);
  `}

  ${({ isOpen }) =>
    isOpen &&
    `
    z-index: 9999;
    pointer-events: all;
    opacity: 1;
  `}
`;

export const Header = styled.div`
  width: 100%;
  padding: 14px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderTitle = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
`;

export const CloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  cursor: pointer;
`;

export const UploadArea = styled.div<{ dragActive: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 14px 72px;
  transition: opacity 0.1s ease;
  opacity: ${({ dragActive }) => (dragActive ? 0.1 : 1)};
`;

export const Illustration = styled.div<{ src: string }>`
  width: 400px;
  height: 200px;
  background-image: url(${({ src }) => src});
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 12px;

  ${breakpoint.Tablet`
    width: 360px;
    height: 180px;
    margin: 0 auto;
  `}
`;

export const Title = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 12px;
`;

export const Subtitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 10px;
  margin-top: 16px;
  color: rgba(59, 38, 81, 0.4);

  ${breakpoint.Tablet`
    text-align: center;
  `}
`;

export const BrowseFilesButton = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

export const UploadIcon = styled.div<{ show: boolean }>`
  position: absolute;
  left: calc(50% - 24px);
  top: calc(50% - 24px);
  width: 48px;
  height: 48px;
  display: ${({ show }) => (show ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
`;

export const UploadedContent = styled.div`
  width: 100%;
`;

export const FileItems = styled.div`
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  padding: 10px 20px 0;
`;

export const UploadSettings = styled.div`
  padding: 0 20px 32px;
`;

export const FileItem = styled.div<{ minimized?: boolean }>`
  position: relative;
  width: 100%;
  padding: 10px 10px 15px;
  border-radius: 6px;
  border: 1px solid #d7d3dc;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
  ${({ minimized }) =>
    !minimized &&
    `
    margin-bottom: 16px;
  `};
`;

export const FileItemDeleteButton = styled.div`
  position: absolute;
  right: -10px;
  top: -10px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #3b2651;
  color: #ffffff;
  cursor: pointer;
`;

export const FileItemContent = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const FileItemTitleIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;

export const FileItemTitle = styled.div`
  margin-top: 5px;
`;

export const FileItemTitleText = styled.div`
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  margin-bottom: 10px;
`;

export const FileItemSize = styled.div`
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  color: rgba(59, 38, 81, 0.4);
`;

export const FileItemDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #d7d3dc;
  margin-bottom: 24px;
`;

export const SelectWrapper = styled.div`
  width: 100%;
  max-width: 260px;
  margin-bottom: 20px;
`;

export const SelectTitle = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  margin-bottom: 5px;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
`;

export const ButtonWrapper = styled.div`
  margin-right: 10px;

  &:last-child {
    margin: 0;
  }
`;

export const ProgressBarContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 4px;
  overflow: hidden;
  border-radius: 0 0 6px 6px;
`;

export const ProgressBarLineUnderlayer = styled.div`
  width: 100%;
  height: 100%;
  background: rgb(255, 44, 0);
  background: linear-gradient(
    90deg,
    rgba(255, 44, 0, 0.5) 0%,
    rgba(255, 224, 0, 0.4976584383753502) 35%,
    rgba(0, 212, 255, 0.5) 100%
  );
`;

export const ProgressBarLine = styled.div<{ progress: number }>`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  transform-origin: center right;
  transform: scaleX(${({ progress }) => 1 - progress});
  transition: transform 0.2s ease;
`;

export const ButtonUploadingState = styled.div`
  display: flex;
  align-items: center;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  animation: ${rotate} 0.5s linear infinite;
`;

export const ButtonUploadingStateText = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
`;

export const TextDivider = styled.div`
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  margin: 10px 0;
`;

export const ZoomButton = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 10px;
  border-radius: 3px;
  border: 1px solid #d7d3dc;
  box-shadow: 0px 1px 0px #d7d3dc, 2px 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.2s ease;
  margin-right: 10px;

  &:hover {
    background-color: #f3f4f6;
  }

  ${breakpoint.Tablet`
    width: 100%;
    margin: 0 0 10px;
  `}
`;

export const ZoomButtonImage = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
`;

export const ZoomButtonText = styled.div`
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
`;

export const ContainerMinimized = styled.div<{ hidden: boolean }>`
  position: relative;
  width: 100%;
  max-width: 560px;
  background-color: #ffffff;
  margin-bottom: 20px;
  display: ${({ hidden }) => (hidden ? 'none' : 'block')};
`;

export const FileItemCloseButton = styled.div`
  position: absolute;
  top: 15px;
  right: 10px;
  cursor: pointer;
`;

export const DropdownMenu = styled.div<{ show: boolean }>`
  position: absolute;
  right: 0;
  top: 16px;
  background-color: #ffffff;
  border: 1px solid #d7d3dc;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
  display: ${({ show }) => (show ? 'block' : 'none')};
  z-index: 999;
`;

export const DropDownItem = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  min-width: 160px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  border-bottom: 1px solid #d7d3dc;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: rgba(215, 211, 220, 1);
  }

  &:last-child {
    border: none;
  }
`;

export const DropDownItemText = styled.div`
  margin-left: 5px;
`;

export const FAQLink = styled.a`
  color: rgba(59, 38, 81, 0.4);
  cursor: pointer;
  margin-left: 4px;
`;

export const QuestionIconWrapper = styled.div`
  margin-right: 4px;
`;

export const GoogleButton = styled(ZoomButton)``;

export const UploadButton = styled.div`
  padding: 8px 10px;
  font-weight: 500;
  line-height: 24px;
  font-size: 16px;
  background-color: #ffffff;
  border-radius: 6px;
  border: 1px solid #d7d3dc;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.1), 0px 1px 0px 0px #d7d3dc;
  cursor: pointer;
`;

export const CloudButtons = styled.div`
  display: flex;
  align-items: center;

  ${breakpoint.Tablet`
    flex-direction: column;
  `}
`;
