import { useContext } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import {
  FETCH_DOCUMENTS,
  FETCH_DOCUMENT,
  FETCH_DOCUMENTS_WITH_TEXT,
  FETCH_ALL_DOCUMENTS,
  FETCH_LIGHT_DOCUMENTS,
  FETCH_LIGHT_DOCUMENT,
} from '../GraphQL/queries';
import {
  CREATE_DOCUMENT,
  UPDATE_DOCUMENT,
  DELETE_DOCUMENT,
  CREATE_LIGHT_DOCUMENT,
  UPDATE_LIGHT_DOCUMENT,
  DELETE_LIGHT_DOCUMENT,
} from '../GraphQL/mutations';
import { ID } from '../Models';
import { ProjectChecklistContext } from '../Context/ProjectChecklistContext';
import useAnalytics from './useAnalytics';

export const useFetchAllDocuments = (): [boolean, any[]] => {
  const { loading, data } = useQuery(FETCH_ALL_DOCUMENTS);
  return [loading, data?.documents || []];
};

export const useFetchDocuments = (dashboardId: string): [boolean, any[]] => {
  const { loading, data } = useQuery(FETCH_DOCUMENTS, {
    variables: { dashboardId },
  });
  return [loading, data?.documents || []];
};

export const useFetchLightDocuments = (): [boolean, any[]] => {
  const { loading, data } = useQuery(FETCH_LIGHT_DOCUMENTS);
  return [loading, data?.lightDocuments || []];
};

export const useFetchLightDocument = (id: string): [boolean, any] => {
  const { loading, data } = useQuery(FETCH_LIGHT_DOCUMENT, {
    variables: { id },
  });
  return [loading, data?.lightDocument];
};

export const useFetchDocumentsWithText = (dashboardId: string): [boolean, any[]] => {
  const { loading, data } = useQuery(FETCH_DOCUMENTS_WITH_TEXT, {
    variables: { dashboardId },
  });
  return [loading, data?.documents || []];
};

export const useFetchDocument = (id: string): [boolean, any] => {
  const { loading, data } = useQuery(FETCH_DOCUMENT, {
    variables: { id },
  });
  return [loading, data?.document];
};

export const useCreateDocument = (): [(dashboardId: string, document?: any) => any] => {
  const { analytics } = useAnalytics();
  const [createMutation] = useMutation(CREATE_DOCUMENT);
  const { dataAdded, markDataAdded } = useContext(ProjectChecklistContext);

  return [
    async (dashboardId: string, document?: any) => {
      const res = await createMutation({
        variables: {
          input: {
            dashboardId,
            ...(document || {}),
          },
        },
        refetchQueries: [
          {
            query: FETCH_DOCUMENTS,
            variables: { dashboardId },
          },
        ],
      });
      analytics.sendEvent('Hooks_AddedDocument', { dashboardId });
      if (!dataAdded) {
        markDataAdded();
      }

      return res?.data?.createDocument?.document;
    },
  ];
};

export const useUpdateDocument = (): [(id: ID, input: any) => void] => {
  const [updateMutation] = useMutation(UPDATE_DOCUMENT);

  return [
    (id: ID, input: any) => {
      return updateMutation({
        variables: {
          id,
          input: { ...input, updatedAt: new Date().toISOString() },
        },
        refetchQueries: [
          {
            query: FETCH_DOCUMENT,
            variables: { id },
          },
        ],
      });
    },
  ];
};

export const useDeleteDocument = (): [(dashboardId: string, id: string) => void] => {
  const [deleteMutation] = useMutation(DELETE_DOCUMENT);

  return [
    (dashboardId: string, id: string) => {
      return deleteMutation({
        variables: {
          id,
        },
        refetchQueries: [
          {
            query: FETCH_DOCUMENTS,
            variables: { dashboardId },
          },
        ],
      });
    },
  ];
};

export const useCreateLightDocument = (): [(document?: any) => any] => {
  const [createMutation] = useMutation(CREATE_LIGHT_DOCUMENT);

  return [
    async (document?: any) => {
      const res = await createMutation({
        variables: {
          input: {
            ...(document || {}),
          },
        },
        refetchQueries: [
          {
            query: FETCH_LIGHT_DOCUMENTS,
          },
        ],
      });
      return res?.data?.createLightDocument?.lightDocument;
    },
  ];
};

export const useUpdateLightDocument = (): [(id: ID, input: any) => void] => {
  const [updateMutation] = useMutation(UPDATE_LIGHT_DOCUMENT);

  return [
    (id: ID, input: any) => {
      return updateMutation({
        variables: {
          id,
          input: { ...input, updatedAt: new Date().toISOString() },
        },
        refetchQueries: [
          {
            query: FETCH_LIGHT_DOCUMENT,
            variables: { id },
          },
        ],
      });
    },
  ];
};

export const useDeleteLightDocument = (): [(id: string) => void] => {
  const [deleteMutation] = useMutation(DELETE_LIGHT_DOCUMENT);

  return [
    (id: string) => {
      return deleteMutation({
        variables: {
          id,
        },
        refetchQueries: [
          {
            query: FETCH_LIGHT_DOCUMENTS,
          },
        ],
      });
    },
  ];
};

export default {
  useFetchDocuments,
  useFetchDocument,
  useCreateDocument,
  useUpdateDocument,
  useDeleteDocument,
};
