import styled from 'styled-components';
import { breakpoint } from '../../Shared';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  padding-bottom: 40px;

  ${breakpoint.Tablet`
    flex: 1;
    padding-bottom: 100px;
  `}
`;

export const EditTaskButton = styled.div`
  margin-left: 10px;
  cursor: pointer;
  color: #b2a8b9;
`;

export const RemoveTaskButton = styled.div`
  cursor: pointer;
  color: rgba(59, 38, 81, 0.4);
  margin-left: 10px;
`;

export const Task = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border: 1px solid rgba(215, 211, 220, 1);
  border-radius: 8px;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
`;

export const TaskLeftSide = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

export const TaskRightSide = styled.div`
  display: flex;
  align-items: center;
`;

export const TaskIcon = styled.div`
  margin-right: 10px;
`;

export const TaskName = styled.div`
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  margin-right: 5px;
`;

export const TaskPrice = styled.div`
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  color: rgba(59, 38, 81, 0.4);

  ${breakpoint.Tablet`
    display: none;
  `}
`;

export const ArrowIcon = styled.div`
  margin: 10px 0;
`;

export const AddTaskButton = styled.div`
  display: flex;
  align-items: center;
  color: rgba(59, 38, 81, 0.4);
  margin-top: 20px;
  cursor: pointer;
`;

export const AddTaskText = styled.div`
  margin-left: 5px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
`;

export const TaskContainer = styled.div<{ hasCurrentTasks?: boolean }>`
  width: 100%;
  max-width: 576px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${breakpoint.Tablet`
    max-width: 100%;
    margin-top: 20px;
    padding: 20px 20px 0;
  `}

  ${({ hasCurrentTasks }) =>
    hasCurrentTasks &&
    breakpoint.Tablet`
    border-top: 1px solid rgba(215, 211, 220, 1);
  `}
`;

export const SummaryTemplate = styled.div`
  display: flex;
  align-items: center;
  margin-right: 32px;

  ${breakpoint.Tablet`
    margin-right: 10px;
  `}
`;

export const SummaryTemplateIcon = styled.div<{ src: string }>`
  width: 24px;
  height: 24px;
  background-image: url(${({ src }) => src});
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 5px;
`;

export const SummaryTemplateName = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;

  ${breakpoint.Tablet`
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

export const Divider = styled.div`
  width: 80%;
  margin: 60px auto 40px;
  height: 1px;
  background-color: rgba(215, 211, 220, 1);
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 472px;
  margin: 0 auto 20px;

  ${breakpoint.Tablet`
    max-width: 100%;
    margin-bottom: 20px;
    padding: 20px 20px 0;
    flex: 0;
  `}
`;

export const HeaderIcon = styled.div`
  margin-right: 20px;
`;

export const HeaderText = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PostyTitle = styled.div`
  font-size: 18px;
  line-height: 32px;
  font-weight: 600;
  margin-bottom: 5px;
`;

export const PostySubtitle = styled.div`
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  color: rgba(59, 38, 81, 0.4);
`;

export const TasksWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${breakpoint.Tablet`
    flex-direction: column-reverse;
    justify-content: space-between;
    flex: 1;
  `}
`;
