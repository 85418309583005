import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Icon24 } from '../../Icons/Icon';
import Slider from '../Slider';
import {
  ControlPanel,
  ControlButton,
  GrabberEnd,
  GrabberStart,
  GrabberTimeContainer,
  ProgressBarWrapper,
  ProgressBarContainer,
  ProgressSegment,
  ProgressBar,
  Time,
  ControlRightButtons,
  VolumePanel,
} from './styles';
import VideoHelper from './VideoHelper';

interface Props {
  onPlayPause(): void;
  isPlaying: boolean;
  startTime: number;
  endTime: number;
  videoDuration: number;
  videoProgress: number;
  currentTime: number;
  editable?: boolean;
  onMoveGrabber(currentTime: number): void;
  onStartTimeChange(newStartTime: number): void;
  onEndTimeChange(newEndTime: number): void;
  onCurrentTimeChange(newCurrentTime: number): void;
  onVolumeChange(newVolume: number): void;
  onSpeedChange(newSpeed: number): void;
}

const GRABBER_WIDTH = 8;

const VideoPlayerControls: FC<Props> = ({
  isPlaying,
  startTime,
  endTime,
  videoDuration,
  videoProgress,
  editable,
  currentTime,
  onMoveGrabber,
  onStartTimeChange,
  onEndTimeChange,
  onCurrentTimeChange,
  onPlayPause,
  onVolumeChange,
  onSpeedChange,
}) => {
  const progressBarContainerRef = useRef<HTMLDivElement>(null);
  const progressSegmentRef = useRef<HTMLDivElement>(null);
  const leftGrabberRef = useRef<HTMLDivElement>(null);
  const rightGrabberRef = useRef<HTMLDivElement>(null);
  const [editModeOn, setEditModeOn] = useState(false);
  const [currentVolume, setCurrentVolume] = useState(100);
  const [showVolumePanel, setShowVolumePanel] = useState(false);
  const speedValues = ['0.5', '1', '1.5', '2'];
  const [currentSpeedIndex, setCurrentSpeedIndex] = useState(1);

  const addGrabberMoveHandler = (handleMove: (event: MouseEvent) => void): void => {
    const handleMouseUp = (): void => {
      window.removeEventListener('mousemove', handleMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };

    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('mousemove', handleMove);
  };

  const handleGrabberMove = (clientX: number, grabberType: string): void => {
    const progressBarContainerElt = progressBarContainerRef.current;
    if (!progressBarContainerElt) return;

    const progressBarContainerRect = progressBarContainerElt.getBoundingClientRect();
    let currentTime = VideoHelper.timeFromCursorPosition(
      clientX,
      progressBarContainerRect.left,
      progressBarContainerRect.width,
      videoDuration
    );

    if (grabberType === 'left') {
      currentTime = currentTime >= 0 ? currentTime : 0;

      if (currentTime < endTime - 1) {
        onMoveGrabber(currentTime);
        onStartTimeChange(currentTime);
      }
    } else {
      currentTime = currentTime < videoDuration ? currentTime : videoDuration;

      if (currentTime > startTime + 1) {
        onMoveGrabber(currentTime);
        onEndTimeChange(currentTime);
      }
    }
  };

  const handleLeftGrabberMove = (clientX: number): void => {
    handleGrabberMove(clientX, 'left');
  };

  const handleRightGrabberMove = (clientX: number): void => {
    handleGrabberMove(clientX, 'right');
  };

  const handleProgressBarClick = (event: React.MouseEvent<HTMLElement>): void => {
    const progressBarContainerElt = progressBarContainerRef.current;
    const progressSegmentElt = progressSegmentRef.current;
    if (!progressBarContainerElt || !progressSegmentElt) return;

    const progressBarContainerRect = progressBarContainerElt.getBoundingClientRect();

    const currentTime = VideoHelper.timeFromCursorPositionInMode(
      event.clientX,
      progressBarContainerRect.left,
      progressBarContainerRect.width,
      startTime,
      endTime,
      videoDuration,
      editModeOn
    );

    if (currentTime >= startTime && currentTime <= endTime) {
      onCurrentTimeChange(currentTime);
    }
  };

  const handleResize = useCallback((): void => {
    const leftGrabberElt = leftGrabberRef.current;
    const rightGrabberElt = rightGrabberRef.current;
    const progressBarContainerElt = progressBarContainerRef.current;

    if (!leftGrabberElt || !rightGrabberElt || !progressBarContainerElt) return;
    const progressBarContainerWidth = progressBarContainerElt.getBoundingClientRect().width;

    leftGrabberElt.style.transform = `translateX(${
      VideoHelper.timeToOffset(progressBarContainerWidth, videoDuration, startTime) - GRABBER_WIDTH
    }px)`;
    rightGrabberElt.style.transform = `translateX(${VideoHelper.timeToOffset(
      progressBarContainerWidth,
      videoDuration,
      endTime
    )}px)`;
  }, [startTime, endTime, videoDuration]);

  useEffect(() => {
    window.addEventListener('resize', handleResize, true);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [startTime, endTime, handleResize]);

  const handleVolumeChange = (volume: number): void => {
    setCurrentVolume(volume);
    onVolumeChange(volume / 100);
  };

  const handleSpeedChange = (): void => {
    const length = speedValues.length;
    if (currentSpeedIndex === length - 1) {
      setCurrentSpeedIndex(0);
      onSpeedChange(parseFloat(speedValues[0]));
      return;
    }
    setCurrentSpeedIndex(currentSpeedIndex + 1);
    onSpeedChange(parseFloat(speedValues[currentSpeedIndex + 1]));
  };

  const renderGrabbers = (): JSX.Element => {
    const progressBarContainerElt = progressBarContainerRef.current;
    if (!progressBarContainerElt || !videoDuration) return <></>;
    const progressBarContainerWidth = progressBarContainerElt.getBoundingClientRect().width;

    return (
      <>
        <GrabberStart
          style={{
            transform: `translateX(${VideoHelper.timeToOffset(
              progressBarContainerWidth,
              videoDuration,
              startTime
            )}px)`,
          }}
          onMouseDown={(event) => {
            event.preventDefault();
            addGrabberMoveHandler((event) => handleLeftGrabberMove(event.clientX));
          }}
          onTouchMove={(event) => {
            event.preventDefault();
            handleLeftGrabberMove(event.touches[0].clientX);
          }}
          ref={leftGrabberRef}
          data-testid="leftGrabber"
        >
          <GrabberTimeContainer>{VideoHelper.formatTime(startTime * 1000)}</GrabberTimeContainer>
        </GrabberStart>
        <GrabberEnd
          style={{
            transform: `translateX(${
              VideoHelper.timeToOffset(progressBarContainerWidth, videoDuration, endTime) -
              GRABBER_WIDTH
            }px)`,
          }}
          onMouseDown={(event) => {
            event.preventDefault();
            addGrabberMoveHandler((event) => handleRightGrabberMove(event.clientX));
          }}
          onTouchMove={(event) => {
            event.preventDefault();
            handleRightGrabberMove(event.touches[0].clientX);
          }}
          ref={rightGrabberRef}
        >
          <GrabberTimeContainer>{VideoHelper.formatTime(endTime * 1000)}</GrabberTimeContainer>
        </GrabberEnd>
      </>
    );
  };

  const progressBarWidth = () => {
    if (editModeOn) return videoProgress;
    const newProgress = (videoProgress * videoDuration) / (endTime - startTime);
    return newProgress < 100 ? newProgress : 100;
  };

  return (
    <ControlPanel>
      <ControlButton onClick={onPlayPause}>
        {isPlaying ? <Icon24.Pause /> : <Icon24.Play />}
      </ControlButton>

      <Time>
        {VideoHelper.formatTime(editModeOn ? startTime * 1000 : (currentTime - startTime) * 1000)}/
        {VideoHelper.formatTime(editModeOn ? endTime * 1000 : (endTime - startTime) * 1000)}
      </Time>

      <ProgressBarWrapper>
        {editModeOn && renderGrabbers()}
        <ProgressBarContainer ref={progressBarContainerRef} />

        <ProgressSegment
          ref={progressSegmentRef}
          onClick={handleProgressBarClick}
          editModeOn={editModeOn}
          style={{
            left: `${!editModeOn ? 0 : (startTime / videoDuration) * 100}%`,
            width: `${(!editModeOn ? 1 : (endTime - startTime) / videoDuration) * 100}%`,
          }}
        />
        <ProgressBar
          style={{
            width: `${progressBarWidth()}%`,
            left: `${!editModeOn ? 0 : (startTime / videoDuration) * 100}%`,
          }}
        />
      </ProgressBarWrapper>

      <ControlRightButtons>
        {editable && (
          <ControlButton onClick={() => setEditModeOn(!editModeOn)} active={editModeOn}>
            <Icon24.Scissors />
          </ControlButton>
        )}
        <ControlButton onClick={handleSpeedChange} isText>
          {speedValues[currentSpeedIndex]}x
        </ControlButton>
        <ControlButton
          onClick={() => setShowVolumePanel(!showVolumePanel)}
          active={showVolumePanel}
        >
          {currentVolume ? <Icon24.Speaker /> : <Icon24.SpeakerMute />}

          <VolumePanel show={showVolumePanel}>
            <Slider onChange={handleVolumeChange} />
          </VolumePanel>
        </ControlButton>
      </ControlRightButtons>
    </ControlPanel>
  );
};

export default VideoPlayerControls;
