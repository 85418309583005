import * as React from 'react';
import { useCallback, useContext, useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { useEffectOnce, useInterval } from 'usehooks-ts';
import { isEmpty } from 'lodash-es';
import { Link } from 'react-router-dom';
import { nanoid } from 'nanoid';
import { useDebouncedCallback } from 'use-debounce';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useParams } from 'react-router-dom';
import useNotes from '../../Hooks/useNotes';
import { useHistory } from 'react-router';
import useTranscripts, {
  useFetchTranscript,
  useTranscriptLinkedParticipants,
} from '../../Hooks/useTranscripts';
import { Loader } from '../../Components';
import Button from '../../Components/Button';
import SecondaryToolbar from '../../Components/SecondaryToolbar/SecondaryToolbar';
import Well from '../../Components/Well';
import { toast } from 'react-toastify';
import Avatar from '../../Components/Avatar';

import { TrashIcon, DocumentDuplicateIcon, ExclamationCircleIcon } from '@heroicons/react/outline';
import moment from 'moment';
import useFiles from '../../Hooks/useFiles';
import AudioPlayer from '../../Components/AudioPlayer';
import TagsPicker from '../../Components/TagsPicker';
import usePermissions from '../../Hooks/usePermissions';

import { Tag } from '../../Models';
import TipTapEditor from '../../Components/TipTapEditor';
import { HighlightsContext, HighlightsContextProvider } from '../../Context/HighlightsContext';
import { SpeakersContext, SpeakersContextProvider } from '../../Context/SpeakersContext';
import {
  CalendarIconI,
  Details,
  DetailsRow,
  EditorContainer,
  EditorLeftPanel,
  EditorRightPanel,
  Label,
  LabelWithIcon,
  NameInput,
  PostyButtonWrapper,
  TagIconI,
  ToolbarButtons,
  ToolbarContainer,
  TranscribingSubtitle,
  TranscribingTitle,
  TranscriptionStatus,
  TranscriptionStatusText,
  UserIconI,
  UserName,
} from './styles';
import TagsPanel from '../../Components/TagsPanel/TagsPanel';
import NavigationPrompt from '../../Components/NavigationPrompt';
import { Editor } from '@tiptap/react';
import { SummaryFeedbackContextProvider } from '../../Context/SummaryFeedbackContext';
import useAnalytics from '../../Hooks/useAnalytics';
import { useParticipantGet } from '../../Hooks/useParticipants';
import LegacyParcipantBlock from './LegacyParticipantBlock';
import { EditorContext, EditorContextProvider } from '../../Context/EditorContext';
import ParticipantBlockView from './ParticipantBlockView';
import VideoClip from '../../Components/VideoClip';
import Spinner from '../../Components/Spinner';
import PostyAIButton from '../../Components/PostyAIButton';
import DotsMenu from '../../Components/DotsMenu';
import { Icon24 } from '../../Icons/Icon';
import { PostyTasksContextProvider } from '../../Context/PostyTasksContext';

export type TimeOffset = {
  offset: number | null;
  shouldPlay?: boolean;
};

function Transcript(): JSX.Element {
  const { dashboardId, transcriptId } = useParams<{ dashboardId: string; transcriptId: string }>();

  const [editorContent, setEditorContent] = useState('');
  const { canEditTranscripts } = usePermissions();
  const [initialized, setInitialized] = useState(false);
  const [hasNewHighlights, setHasNewHighlights] = useState(false);
  const [enableStopNavigation, setEnableStopNavigation] = useState(true);
  const [showReaddPrompt, setShowReaddPrompt] = useState(false);
  const [containerElement, setContainerElement] = useState<HTMLDivElement | null>(null);
  const [timeOffset, setTimeOffset] = useState<TimeOffset>({ offset: 0.9 });

  const [, , , getLinkedParticipant, ,] = useTranscriptLinkedParticipants(transcriptId);
  const { updateTranscript, deleteTranscript, createTranscript } = useTranscripts();
  const { updateFile, deleteFile, createFile } = useFiles();
  const { createNotesFromEntitiesWithTags } = useNotes(dashboardId);
  const [loading, transcript, refetch] = useFetchTranscript(transcriptId);
  const {
    highlights,
    setHighlightsAddedToAnalysis,
    updateDocumentTags,
    refetchHighlights,
  } = useContext(HighlightsContext);
  const { linkParticipant } = useContext(EditorContext);

  const { speakerBlocks } = useContext(SpeakersContext);

  const participantsKey =
    speakerBlocks.map((block) => block.id + ',' + getLinkedParticipant(block.id)?.id).join(',') ||
    '12345';

  const getParticipant = useParticipantGet();
  const history = useHistory();
  const { analytics } = useAnalytics();
  // Default offset to _slightly_ after video start in order to show the first frame as the thumbnail, but '0:00' as the time

  useEffectOnce(() => {
    refetchHighlights();
  });

  useInterval(
    () => {
      if (loading || !transcript) {
        return;
      }
      if (transcript?.file?.status !== 'READY' || !transcript?.text) {
        refetch();
      }
    },
    // Delay in milliseconds or null to stop it
    !transcript?.file || (transcript?.file?.status === 'READY' && transcript?.text) ? null : 3000
  );

  useEffectOnce(() => {
    analytics.sendEvent('pageView.Transcript', {
      id: transcriptId,
      projectId: dashboardId,
    });
  });

  useEffect(() => {
    if (initialized || loading || !transcript || !transcript.text) return;
    const parsedTranscript = JSON.parse(transcript.text);

    setInitialized(true);
    setEditorContent(parsedTranscript);
  }, [loading, transcript, initialized]);

  useEffect(() => {
    const handleTimestampClick = (e: any) => {
      setTimeOffset({ offset: e.detail.startTime, shouldPlay: e.detail.shouldPlay || false });
    };
    document.addEventListener('timestampClick', handleTimestampClick);

    return () => {
      document.removeEventListener('timestampClick', handleTimestampClick);
    };
  }, []);

  const debouncedSave = useDebouncedCallback(async (content: any) => {
    const transcriptJSON = JSON.stringify(content);

    if (isEmpty(transcriptJSON)) {
      return;
    }

    try {
      await updateTranscript(transcriptId, {
        text: transcriptJSON,
      });
    } catch (error) {
      toast.error(
        'An unexpected error occurred. Please refresh the page and try again. If the error persists, please contact support.'
      );
    }
  }, 500);

  useEffect(() => {
    if (highlights) {
      setHasNewHighlights(
        !highlights.every((highlight) => {
          return highlight.addedToAnalysis === true;
        })
      );
    }
  }, [highlights]);

  useEffect(() => {
    updateDocumentTags(transcript?.tagsList || []);
  }, [transcript?.tagsList]);

  async function createNotes(force = false) {
    if (highlights.length) {
      const toastId = toast.loading('Creating notes from highlighted text...');
      setEnableStopNavigation(false);

      await createNotesFromEntitiesWithTags(highlights, {
        transcriptId,
        tags: transcript.tagsList,
        getLinkedParticipant,
        participantId: transcript?.participantId,
        // force: !hasNewHighlights,
      });

      setHighlightsAddedToAnalysis(highlights);

      toast.update(toastId, {
        render: 'Successfully created notes from transcript!',
        type: 'success',
        isLoading: false,
        autoClose: 1000,
      });
    }
  }

  const addHighlightsToAnalysis = async () => {
    // if (!hasNewHighlights) {
    //   setShowReaddPrompt(true);
    // } else {
    await createNotes();
    history.push('/projects/' + dashboardId + '/notes');
    // }
  };

  async function updateName(newName: string) {
    if (newName === transcript.name) {
      return;
    }
    await updateTranscript(transcriptId, {
      name: newName,
    });
    if (!transcript.fileId) {
      return;
    }
    await updateFile(transcript.fileId, {
      name: newName,
    });
  }

  const debouncedUpdateName = useDebouncedCallback(updateName, 600);

  async function handleCopy() {
    const random = nanoid(4);
    const file = transcript.file;
    let newFile;
    if (file) {
      newFile = await createFile(dashboardId, {
        name: file.name + ' copy',
        type: file.type,
        mimeType: file.mimeType,
        s3VideoPath: file.s3VideoPath ? file.s3VideoPath + `?copy=${random}` : null,
        s3AudioPath: file.s3AudioPath ? file.s3AudioPath + `?copy=${random}` : null,
        status: file.status,
        duration: null,
        metadata: file.metadata,
        size: null,
        statusText: file.statusText,
        isCopy: true,
      });
    }

    const res = await createTranscript(dashboardId, {
      name: transcript.name + ' copy',
      fileId: file ? newFile.id : null,
      text: transcript.text,
      participantId: transcript.participantId,
      tagsTranscriptions: {
        create: transcript.tagsList?.map((tag: Tag) => ({ tagId: tag.id })) || [],
      },
    });

    history.push(`/projects/${dashboardId}/data/${res.id}`);
  }

  async function handleDelete() {
    if (!confirm(`Are you sure you want to delete "${transcript.name}"? This cannot be undone.`)) {
      return;
    }
    setEnableStopNavigation(false);
    await deleteTranscript(transcriptId);
    if (transcript.fileId) {
      await deleteFile(transcript.fileId);
    }

    analytics.sendEvent('DeleteTranscript', {
      id: transcriptId,
    });

    history.push('/projects/' + dashboardId + '/data');
  }

  async function updateTags(tags: Array<Tag> | null) {
    updateTranscript(transcriptId, {
      tagsTranscriptions: {
        deleteOthers: true,
        create: tags?.map((tag) => ({ tagId: tag.id })) || [],
      },
    });
    updateDocumentTags(tags || []);
  }

  const handleContainerRef = useCallback((node) => {
    setContainerElement(node);
  }, []);

  const getWellText = () => {
    if (transcript?.file?.type === 'video') {
      return `
        Analyze video and transcripts to discover emerging patterns. Click “Summarize” 
        to summarize this video using AI. Highlight and tag important parts to analyze then click “Add highlights to analysis.”`;
    } else if (transcript?.file?.type === 'audio') {
      return `Analyze audio and transcripts to discover emerging patterns. Click “Summarize” to summarize this recording using AI. 
        Highlight and tag important parts to analyze then click “Add highlights to analysis.”`;
    }

    return `Your data was added below. Click and drag on the text to highlight the content you want to turn into notes.`;
  };

  if (loading || !transcript) {
    return <Loader />;
  }

  const createEnabled = !!highlights.length;

  return (
    <div className={'flex-col h-full'}>
      <SecondaryToolbar sticky>
        <ToolbarContainer>
          <h1 className={'text-l font-medium mt-1'}>{transcript.name ?? 'Untitled Transcript'}</h1>
          {canEditTranscripts && (
            <ToolbarButtons>
              <PostyButtonWrapper>
                <PostyAIButton
                  dashboardId={dashboardId}
                  getSummaryGenerationData={(editor: Editor | null) => {
                    if (!editor) return { content: [], documentId: '' };
                    const quotes: string[] = [];

                    editor.state.doc.content.nodesBetween(
                      0,
                      editor.state.doc.content.size,
                      (node: any) => {
                        if (node.type.name === 'speaker') {
                          const speakerName = node.attrs.speakerName;
                          quotes.push(
                            `${speakerName ? speakerName + ':\n' : ''}${node?.textContent || ''}`
                          );
                        }
                      }
                    );
                    return {
                      content: quotes,
                      transcriptId,
                    };
                  }}
                  getHighlightsGenerationData={(editor: Editor | null) => {
                    if (!editor) return { content: '' };
                    let content = '';
                    editor.state.doc.content.nodesBetween(
                      0,
                      editor.state.doc.content.size,
                      (node: any) => {
                        if (node.type.name === 'summary') {
                          content += `${node?.textContent || ''}\n\n`;
                        }

                        if (node.type.name === 'speaker') {
                          content += `${node?.textContent || ''}\n\n`;
                        }
                      }
                    );
                    return {
                      content,
                    };
                  }}
                />
              </PostyButtonWrapper>

              <Button
                className={createEnabled ? '' : 'pointer-events-none opacity-75'}
                onClick={addHighlightsToAnalysis}
              >
                Add to Analysis
              </Button>

              <DotsMenu
                items={[
                  {
                    label: 'Copy data',
                    icon: <Icon24.Copy />,
                    action: () => handleCopy(),
                  },
                  {
                    label: 'Delete',
                    icon: <Icon24.Trash />,
                    color: 'red',
                    action: () => handleDelete(),
                  },
                ]}
              />
            </ToolbarButtons>
          )}
        </ToolbarContainer>
      </SecondaryToolbar>
      <EditorContainer>
        <EditorLeftPanel ref={handleContainerRef}>
          <Well wellKey="transcript-record-well">{getWellText()}</Well>
          {transcript.file?.signedVideoUrl && transcript.file.type === 'video' && (
            <div className={' bg-white max-w-[800px] mx-auto'}>
              <VideoClip
                src={transcript.file?.signedVideoUrl}
                offset={timeOffset.offset}
                playOnOffsetChange={timeOffset.shouldPlay}
                scrollingContainer={containerElement}
              />
            </div>
          )}
          {transcript.file?.signedVideoUrl && transcript.file.type === 'audio' && (
            <AudioPlayer
              src={transcript.file?.signedVideoUrl}
              srcType={transcript.file.mimeType}
              timeOffset={timeOffset}
              scrollingContainer={containerElement}
            />
          )}

          <Details>
            <NameInput
              defaultValue={transcript?.name}
              onBlur={(e) => updateName(e.target.value)}
              onKeyDown={(e) => e.code === 'Enter' && e.currentTarget.blur()}
              onKeyUp={(e) => debouncedUpdateName(e.currentTarget?.value)}
              placeholder="Untitled"
              className={'text-3xl my-6 w-full'}
              autoFocus={!transcript?.name}
              readOnly={!canEditTranscripts}
            />
            <DetailsRow>
              <LabelWithIcon>
                <UserIconI />
                <Label>Created by</Label>
              </LabelWithIcon>
              <div className="flex items-center">
                <Avatar user={transcript?.userByCreatedBy} />
                <UserName>{transcript?.userByCreatedBy?.name || ''}</UserName>
              </div>
            </DetailsRow>
            {transcript.participantId && (
              <LegacyParcipantBlock
                participant={transcript.participant}
                speakers={speakerBlocks}
                onChoose={async (speakerId) => {
                  await linkParticipant(transcriptId, speakerId, '', transcript.participant);
                  await updateTranscript(transcriptId, {
                    participantId: null,
                  });
                }}
              />
            )}
            {!transcript.participantId && (
              <DetailsRow>
                <LabelWithIcon className="shrink-0">
                  <div className={'cursor-pointer'}>
                    <UserIconI />
                  </div>
                  <Label>Participant</Label>
                </LabelWithIcon>

                <ParticipantBlockView
                  transcriptId={transcriptId}
                  containerElement={containerElement}
                />
              </DetailsRow>
            )}
            <DetailsRow>
              <LabelWithIcon>
                <CalendarIconI />
                <Label>Date</Label>
              </LabelWithIcon>
              <div className="cursor-pointer">
                <DatePicker
                  onChange={async (date) => {
                    await updateTranscript(transcriptId, {
                      customDate: date,
                    });
                  }}
                  customInput={
                    <div>
                      {moment(transcript?.customDate || transcript?.createdAt).format('MM/DD/YYYY')}
                    </div>
                  }
                  selected={new Date(transcript?.customDate || transcript?.createdAt)}
                  popperPlacement="bottom"
                />
              </div>
            </DetailsRow>
            <DetailsRow>
              <LabelWithIcon>
                <div className={'cursor-pointer'}>
                  <TagIconI />
                </div>
                <Label>Tags</Label>
              </LabelWithIcon>
              <div>
                <TagsPicker
                  tags={transcript.tagsList}
                  onChange={updateTags}
                  dashboardId={dashboardId}
                  readOnly={!canEditTranscripts}
                />
              </div>
            </DetailsRow>
          </Details>
          {transcript?.file?.status === 'PROCESSING' && (
            <TranscriptionStatus>
              <TranscribingTitle>
                <Spinner size="small" />
                <TranscriptionStatusText>Transcribing...</TranscriptionStatusText>
              </TranscribingTitle>

              <TranscribingSubtitle>
                {`Posty is busy at work processing this file. This could take a couple minutes. Feel free to leave and come back – we’ll email you when it’s ready.`}
              </TranscribingSubtitle>
            </TranscriptionStatus>
          )}
          {transcript?.file?.status === 'ERRORED' && (
            <TranscriptionStatus>
              <ExclamationCircleIcon className={'w-6 h-6 text-red-500'} />
              <TranscriptionStatusText>
                Unable to transcribe video. Re-upload the file to try again.
              </TranscriptionStatusText>
            </TranscriptionStatus>
          )}
          {initialized && containerElement && (
            <TipTapEditor
              content={editorContent}
              onChange={debouncedSave}
              editable={canEditTranscripts}
              containerElt={containerElement}
              withHighlights
              withSpeakers
              withToolbar
              withTidyUp={canEditTranscripts}
              getDownloadData={(editor: Editor | null) => {
                const filename =
                  transcript?.name?.replace(/[^a-z0-9]/gi, '_').toLowerCase() || 'transcript';
                let content = '';
                if (!editor) return { content, filename };

                const summaryEls = editor?.view?.dom?.querySelectorAll('.node-summary');
                const texts = [...(summaryEls || [])].map((el: any) => `${el.innerText}\n\n\n`);
                content += texts.join('');
                content += 'Transcript:\n\n';
                editor.state.doc.content.nodesBetween(
                  0,
                  editor.state.doc.content.size,
                  (node: any) => {
                    if (node.type.name === 'speaker') {
                      const speakerName = node.attrs.speakerName;
                      content += `${speakerName ? speakerName + ':\n' : ''}${
                        node?.textContent || ''
                      }\n\n`;
                    }
                  }
                );

                return {
                  content,
                  filename,
                };
              }}
              getSummaryGenerationData={(editor: Editor | null) => {
                if (!editor) return { content: [], documentId: '' };
                const quotes: string[] = [];

                editor.state.doc.content.nodesBetween(
                  0,
                  editor.state.doc.content.size,
                  (node: any) => {
                    if (node.type.name === 'speaker') {
                      const speakerName = node.attrs.speakerName;
                      quotes.push(
                        `${speakerName ? speakerName + ':\n' : ''}${node?.textContent || ''}`
                      );
                    }
                  }
                );
                return {
                  content: quotes,
                  transcriptId,
                };
              }}
              getHighlightsGenerationData={(editor: Editor | null) => {
                if (!editor) return { content: '' };
                let content = '';
                editor.state.doc.content.nodesBetween(
                  0,
                  editor.state.doc.content.size,
                  (node: any) => {
                    if (node.type.name === 'summary') {
                      content += `${node?.textContent || ''}\n\n`;
                    }

                    if (node.type.name === 'speaker') {
                      content += `${node?.textContent || ''}\n\n`;
                    }
                  }
                );
                return {
                  content,
                };
              }}
            />
          )}
        </EditorLeftPanel>
        <EditorRightPanel>
          <TagsPanel
            key={participantsKey}
            editable={canEditTranscripts}
            getLinkedParticipant={getLinkedParticipant}
            disabled={
              !canEditTranscripts ||
              transcript?.file?.status === 'PROCESSING' ||
              transcript?.file?.status === 'ERRORED'
            }
          />
        </EditorRightPanel>
      </EditorContainer>

      <NavigationPrompt
        name="prompt-start-analysis"
        title="Ready to start analysis?"
        text="Click “Add to analysis” to add new highlights to the Analysis section of a project. 
          Every highlight becomes a sticky note on a canvas and row in a table to analyze."
        confirmationText="Yes, add to analysis"
        cancelText="Not yet"
        onConfirm={createNotes}
        when={hasNewHighlights && enableStopNavigation}
        locationAfterConfirm={'/projects/' + dashboardId + '/notes'}
      />

      <NavigationPrompt
        name="prompt-readd-highlights"
        title="Add highlights to analysis?"
        text="Highlights have already been added as notes to analysis. Do you want to add them again?"
        confirmationText="Yes, add to analysis"
        cancelText="Not yet"
        onCancel={() => setShowReaddPrompt(false)}
        onConfirm={() => createNotes(true)}
        when={showReaddPrompt}
        basedOnNavigation={false}
        locationAfterConfirm={'/projects/' + dashboardId + '/notes'}
      />
    </div>
  );
}

const TranscriptPage = () => {
  const { dashboardId, transcriptId } = useParams<{ dashboardId: string; transcriptId: string }>();
  return (
    <HighlightsContextProvider
      key={dashboardId + transcriptId}
      dashboardId={dashboardId}
      transcriptionId={transcriptId}
    >
      <EditorContextProvider
        type="transcript"
        dashboardId={dashboardId}
        transcriptId={transcriptId}
      >
        <PostyTasksContextProvider dashboardId={dashboardId} documentId={transcriptId}>
          <SpeakersContextProvider>
            <SummaryFeedbackContextProvider>
              <Transcript key={dashboardId + transcriptId} />
            </SummaryFeedbackContextProvider>
          </SpeakersContextProvider>
        </PostyTasksContextProvider>
      </EditorContextProvider>
    </HighlightsContextProvider>
  );
};

export default TranscriptPage;
