import styled, { css } from 'styled-components';
import { breakpoint } from '../../Shared';

export const Container = styled.div<{ type: 'ghost' | 'primary' }>`
  position: relative;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  background-color: #ffffff;
  cursor: pointer;

  ${({ type }) =>
    type === 'primary' &&
    css`
      border: 1px solid rgba(215, 211, 220, 1);
      box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      padding: 5px 5px 5px 10px;
    `}
`;

export const Icon = styled.div`
  color: rgba(177, 168, 185, 1);
  margin-right: 5px;
`;

export const Title = styled.div<{
  posty?: boolean;
  withMargin?: boolean;
  type: 'ghost' | 'primary';
}>`
  min-width: ${({ type }) => (type === 'primary' ? 'auto' : '100px')};
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  ${({ posty }) =>
    posty &&
    css`
      background: linear-gradient(90deg, #8e84ee 0%, #351a8a 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    `}
  ${({ withMargin }) => withMargin && 'margin-left: 5px;'}
  margin-right: ${({ type }) => (type === 'primary' ? '5px' : '10px')};

  ${breakpoint.Tablet`
    display: none;
  `}
`;

export const DropdownList = styled.div`
  background-color: #ffffff;
  border: 1px solid #d7d3dc;
  border-radius: 6px;
  position: absolute;
  top: calc(100% + 2px);
  right: 0;
  width: 248px;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.1);
`;

export const DropdownListHidden = styled(DropdownList)`
  width: 100%;
  max-width: 210px;
  display: none;
  position: absolute;
  top: calc(100% - 44px);
  left: calc(100% - 2px);
`;

export const DropdownItem = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: rgba(133, 95, 168, 0.1);
  }

  ${({ disabled }) =>
    disabled &&
    `
    color: rgba(59, 38, 81, 0.4);
    cursor: default;

    &:hover {
      background-color: transparent;
    }
  `}

  &:hover ${DropdownListHidden} {
    display: block;
  }
`;

export const DropdownItemInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const DropdownRightSide = styled.div`
  display: flex;
  align-items: center;
`;

export const SpinnerWrapper = styled.div`
  position: absolute;
  top: calc(50% - 12px);
  right: 36px;
`;
