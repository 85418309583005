import React, { FC } from 'react';

interface Props {
  children?: React.ReactNode;
}

const Icon68: FC<Props> = ({ children }) => {
  return (
    <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
      {children}
    </svg>
  );
};

const PostyGray: FC = () => {
  return (
    <Icon68>
      <path
        d="M39.7527 61.97H21.1366C13.3752 61.97 7.08334 55.6782 7.08334 47.9168V24.2742C7.08334 16.6219 13.2868 10.4185 20.9391 10.4185H50.0969C54.0301 10.4185 57.8226 11.8822 60.7359 14.5248L63.5338 17.0627C64.9815 18.3759 65.8072 20.2395 65.8072 22.1941V35.9155C65.8072 50.305 54.1422 61.97 39.7527 61.97Z"
        fill="url(#paint0_linear_7174_11341)"
      />
      <g clipPath="url(#clip0_7174_11341)">
        <path
          d="M50.2594 10.4185H15.5721C10.9054 10.4185 7.08322 14.2299 7.08322 18.8836V46.3154C7.08322 50.9727 10.9054 54.7805 15.5721 54.7805H38.0612C49.4877 54.7805 58.752 45.5422 58.752 34.1477V18.8836C58.752 14.2263 54.9298 10.4185 50.2631 10.4185H50.2594Z"
          fill="#D7D3DC"
        />
        <path
          d="M11.0836 48.8041C11.0836 49.9981 12.0592 50.9773 13.2568 50.9773H19.4306C20.2679 50.9773 20.9449 50.3003 20.9449 49.463V46.0121C20.9449 45.1749 20.2679 44.4978 19.4306 44.4978H12.598C11.7607 44.4978 11.0836 45.1749 11.0836 46.0121V48.8041Z"
          fill="#F3F2F5"
        />
        <path
          d="M19.4015 34.4253H12.6271C11.7747 34.4253 11.0836 35.1163 11.0836 35.9687V39.4306C11.0836 40.283 11.7747 40.974 12.6271 40.974H19.4015C20.2539 40.974 20.9449 40.283 20.9449 39.4306V35.9687C20.9449 35.1163 20.2539 34.4253 19.4015 34.4253Z"
          fill="#F3F2F5"
        />
        <path
          d="M19.416 24.4226H12.6125C11.7682 24.4226 11.0836 25.1071 11.0836 25.9515V29.3733C11.0836 30.2176 11.7682 30.9021 12.6125 30.9021H19.416C20.2604 30.9021 20.9449 30.2176 20.9449 29.3733V25.9515C20.9449 25.1071 20.2604 24.4226 19.416 24.4226Z"
          fill="#F3F2F5"
        />
        <path
          d="M11.0836 16.5376V19.2095C11.0836 20.145 11.8408 20.9022 12.7763 20.9022H20.2387C20.6282 20.9022 20.9449 20.5855 20.9449 20.196V15.0561C20.9449 14.6666 20.6282 14.3499 20.2387 14.3499H13.2677C12.0665 14.3499 11.0836 15.3327 11.0836 16.534V16.5376Z"
          fill="#F3F2F5"
        />
        <path
          d="M53.2184 24.4226H25.9934C25.149 24.4226 24.4645 25.1071 24.4645 25.9515V29.3733C24.4645 30.2176 25.149 30.9021 25.9934 30.9021H53.2184C54.0628 30.9021 54.7473 30.2176 54.7473 29.3733V25.9515C54.7473 25.1071 54.0628 24.4226 53.2184 24.4226Z"
          fill="#F3F2F5"
        />
        <path
          d="M52.5486 14.3535H26.0844C25.1889 14.3535 24.4645 15.0779 24.4645 15.9734V19.2823C24.4645 20.1778 25.1889 20.9022 26.0844 20.9022H53.131C54.0265 20.9022 54.7509 20.1778 54.7509 19.2823V16.5522C54.7509 15.34 53.7608 14.3499 52.5486 14.3499V14.3535Z"
          fill="#F3F2F5"
        />
        <path
          d="M26.0851 50.9742H38.0904C38.9859 50.9742 39.7103 50.2498 39.7103 49.3543V44.6002C39.7103 44.5638 39.7139 44.531 39.7139 44.4946H26.0814C25.1859 44.4946 24.4615 45.219 24.4615 46.1145V49.3543C24.4615 50.2498 25.1859 50.9742 26.0814 50.9742H26.0851Z"
          fill="#F3F2F5"
        />
        <path
          d="M54.7509 35.8814C54.7509 35.0769 54.0993 34.4253 53.2948 34.4253H26.0334C25.1671 34.4253 24.4645 35.1279 24.4645 35.9942V39.4051C24.4645 40.2714 25.1671 40.974 26.0334 40.974H39.9353C40.4195 40.974 40.8636 40.7337 41.1584 40.3515C42.3852 38.7608 44.2744 37.7342 46.4003 37.7342H54.7473"
          fill="#F3F2F5"
        />
        <path
          d="M43.2338 44.6V50.8393C48.4976 50.1003 52.8039 46.2817 54.2345 41.2546H46.4008C44.6535 41.2546 43.2302 42.7544 43.2302 44.6H43.2338Z"
          fill="#F3F2F5"
        />
        <path
          d="M19.576 27.3203C19.576 27.3203 20.5156 29.5249 23.5772 29.5249C26.6388 29.5249 27.6594 27.3203 27.6594 27.3203"
          stroke="#A9A0B3"
          strokeWidth="2.125"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M35.7428 27.3203C35.7428 27.3203 36.6824 29.5249 39.7441 29.5249C42.8057 29.5249 43.8262 27.3203 43.8262 27.3203"
          stroke="#A9A0B3"
          strokeWidth="2.125"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M21.25 35.4167C21.25 35.4167 23.8848 41.0834 32.4699 41.0834C41.055 41.0834 43.9167 35.4167 43.9167 35.4167"
          stroke="#A9A0B3"
          strokeWidth="2.125"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </g>
      <path
        d="M18.0188 2.83325L19.1195 6.60683L21.871 7.78607L19.1195 8.96534L18.0188 12.7389L16.9182 8.96534L14.1667 7.78607L16.9182 6.60683L18.0188 2.83325Z"
        fill="#A9A0B3"
        stroke="#A9A0B3"
        strokeWidth="1.76245"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.49999 9.91675L10.5238 16.3929L15.5833 18.4167L10.5238 20.4406L8.49999 26.9167L6.47619 20.4406L1.41666 18.4167L6.47619 16.3929L8.49999 9.91675Z"
        fill="#C9C3CF"
        stroke="#C9C3CF"
        strokeWidth="1.76245"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_7174_11341"
          x1="61.7879"
          y1="15.9362"
          x2="8.1869"
          y2="60.2359"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D7D3DC" />
          <stop offset="1" stopColor="#B1A8B9" />
        </linearGradient>
        <clipPath id="clip0_7174_11341">
          <rect
            width="57.6247"
            height="49.328"
            fill="white"
            transform="translate(7.08334 10.4185)"
          />
        </clipPath>
      </defs>
    </Icon68>
  );
};

const Posty: FC = () => {
  return (
    <Icon68>
      <path
        d="M39.7527 61.9701H21.1366C13.3752 61.9701 7.08334 55.6783 7.08334 47.9169V24.2744C7.08334 16.622 13.2868 10.4186 20.9391 10.4186H50.0969C54.0301 10.4186 57.8226 11.8824 60.7359 14.5249L63.5338 17.0628C64.9815 18.376 65.8072 20.2396 65.8072 22.1942V35.9157C65.8072 50.3051 54.1422 61.9701 39.7527 61.9701Z"
        fill="url(#paint0_linear_6983_72396)"
      />
      <g clipPath="url(#clip0_6983_72396)">
        <path
          d="M50.2594 10.4186H15.5721C10.9054 10.4186 7.08322 14.2301 7.08322 18.8837V46.3155C7.08322 50.9728 10.9054 54.7807 15.5721 54.7807H38.0612C49.4877 54.7807 58.752 45.5423 58.752 34.1478V18.8837C58.752 14.2264 54.9298 10.4186 50.2631 10.4186H50.2594Z"
          fill="#8E84EE"
        />
        <path
          d="M11.0836 48.8041C11.0836 49.9981 12.0592 50.9773 13.2568 50.9773H19.4306C20.2679 50.9773 20.9449 50.3003 20.9449 49.463V46.0121C20.9449 45.1749 20.2679 44.4978 19.4306 44.4978H12.598C11.7607 44.4978 11.0836 45.1749 11.0836 46.0121V48.8041Z"
          fill="#736DE4"
        />
        <path
          d="M19.4015 34.4254H12.6271C11.7747 34.4254 11.0836 35.1164 11.0836 35.9688V39.4306C11.0836 40.283 11.7747 40.9741 12.6271 40.9741H19.4015C20.2539 40.9741 20.9449 40.283 20.9449 39.4306V35.9688C20.9449 35.1164 20.2539 34.4254 19.4015 34.4254Z"
          fill="#736DE4"
        />
        <path
          d="M19.416 24.4224H12.6125C11.7682 24.4224 11.0836 25.1069 11.0836 25.9513V29.3731C11.0836 30.2175 11.7682 30.902 12.6125 30.902H19.416C20.2604 30.902 20.9449 30.2175 20.9449 29.3731V25.9513C20.9449 25.1069 20.2604 24.4224 19.416 24.4224Z"
          fill="#736DE4"
        />
        <path
          d="M11.0836 16.5377V19.2096C11.0836 20.1451 11.8408 20.9023 12.7763 20.9023H20.2387C20.6282 20.9023 20.9449 20.5856 20.9449 20.1961V15.0561C20.9449 14.6666 20.6282 14.3499 20.2387 14.3499H13.2677C12.0665 14.3499 11.0836 15.3328 11.0836 16.534V16.5377Z"
          fill="#736DE4"
        />
        <path
          d="M53.2184 24.4224H25.9934C25.149 24.4224 24.4645 25.1069 24.4645 25.9513V29.3731C24.4645 30.2175 25.149 30.902 25.9934 30.902H53.2184C54.0628 30.902 54.7473 30.2175 54.7473 29.3731V25.9513C54.7473 25.1069 54.0628 24.4224 53.2184 24.4224Z"
          fill="#736DE4"
        />
        <path
          d="M52.5486 14.3534H26.0844C25.1889 14.3534 24.4645 15.0778 24.4645 15.9733V19.2823C24.4645 20.1777 25.1889 20.9021 26.0844 20.9021H53.131C54.0265 20.9021 54.7509 20.1777 54.7509 19.2823V16.5521C54.7509 15.3399 53.7608 14.3498 52.5486 14.3498V14.3534Z"
          fill="#736DE4"
        />
        <path
          d="M26.0851 50.9739H38.0904C38.9859 50.9739 39.7103 50.2495 39.7103 49.354V44.5999C39.7103 44.5635 39.7139 44.5307 39.7139 44.4943H26.0814C25.1859 44.4943 24.4615 45.2187 24.4615 46.1142V49.354C24.4615 50.2495 25.1859 50.9739 26.0814 50.9739H26.0851Z"
          fill="#736DE4"
        />
        <path
          d="M54.7509 35.8814C54.7509 35.0769 54.0993 34.4254 53.2948 34.4254H26.0334C25.1671 34.4254 24.4645 35.1279 24.4645 35.9943V39.4051C24.4645 40.2715 25.1671 40.9741 26.0334 40.9741H39.9353C40.4195 40.9741 40.8636 40.7338 41.1584 40.3516C42.3852 38.7608 44.2744 37.7343 46.4003 37.7343H54.7473"
          fill="#736DE4"
        />
        <path
          d="M43.2338 44.5999V50.8391C48.4976 50.1002 52.8039 46.2816 54.2345 41.2545H46.4008C44.6535 41.2545 43.2302 42.7543 43.2302 44.5999H43.2338Z"
          fill="#736DE4"
        />
        <path
          d="M21.25 35.4167C21.25 35.4167 23.8848 41.0834 32.4699 41.0834C41.055 41.0834 43.9167 35.4167 43.9167 35.4167"
          stroke="#351A8A"
          strokeWidth="2.125"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </g>
      <path
        d="M18.0188 2.83331L19.1195 6.60689L21.871 7.78613L19.1195 8.9654L18.0188 12.7389L16.9182 8.9654L14.1667 7.78613L16.9182 6.60689L18.0188 2.83331Z"
        fill="#D2CEF8"
        stroke="#D2CEF8"
        strokeWidth="1.76245"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.49999 9.91669L10.5238 16.3929L15.5833 18.4167L10.5238 20.4405L8.49999 26.9167L6.47619 20.4405L1.41666 18.4167L6.47619 16.3929L8.49999 9.91669Z"
        fill="#351A8A"
        stroke="#351A8A"
        strokeWidth="1.76245"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="21.4681" cy="27.1348" r="4.46806" fill="#351A8A" />
      <path
        d="M20.237 28.054C21.0908 28.054 21.7829 27.3619 21.7829 26.5082C21.7829 25.6544 21.0908 24.9623 20.237 24.9623C19.3833 24.9623 18.6912 25.6544 18.6912 26.5082C18.6912 27.3619 19.3833 28.054 20.237 28.054Z"
        fill="white"
      />
      <circle cx="44.1828" cy="27.1348" r="4.46806" fill="#351A8A" />
      <path
        d="M42.9521 28.054C43.8058 28.054 44.4979 27.3619 44.4979 26.5082C44.4979 25.6544 43.8058 24.9623 42.9521 24.9623C42.0983 24.9623 41.4062 25.6544 41.4062 26.5082C41.4062 27.3619 42.0983 28.054 42.9521 28.054Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_6983_72396"
          x1="61.7879"
          y1="15.9363"
          x2="8.1869"
          y2="60.236"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8E84EE" />
          <stop offset="1" stopColor="#351A8A" />
        </linearGradient>
        <clipPath id="clip0_6983_72396">
          <rect
            width="57.6247"
            height="49.328"
            fill="white"
            transform="translate(7.08334 10.4186)"
          />
        </clipPath>
      </defs>
    </Icon68>
  );
};

export default {
  PostyGray,
  Posty,
};
