import React, { FC } from 'react';
import { Icon24 } from '../../Icons/Icon';
import { SmartIcon, SmartIconTooltip, TooltipIcon } from './styles';

const SmartTemplateIcon: FC = () => {
  return (
    <SmartIcon>
      <Icon24.PostySmall />

      <SmartIconTooltip>
        <TooltipIcon>
          <Icon24.PostyGray />
        </TooltipIcon>
        Smart Analysis with Posty
      </SmartIconTooltip>
    </SmartIcon>
  );
};

export default SmartTemplateIcon;
