import styled from 'styled-components';
import { breakpoint } from '../../Shared';

export const Container = styled.div`
  width: 100%;
  padding: 20px;

  ${breakpoint.Tablet`
    padding: 0 20px;
  `}
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 472px;
  margin: 0 auto;
`;

export const SearchIcon = styled.div`
  margin-right: 20px;
`;

export const Title = styled.div`
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: rgba(59, 38, 81, 0.4);
  margin-bottom: 20px;

  ${breakpoint.Tablet`
    margin-bottom: 10px;
  `}
`;

export const TasksContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;

  ${breakpoint.Tablet`
    grid-template-columns: repeat(2, 1fr);
  `}
`;

export const TaskItem = styled.div<{ disabled: boolean }>`
  position: relative;
  width: 100%;
  padding: 20px;
  border: 1px solid rgba(215, 211, 220, 1);
  border-radius: 8px;
  display: flex;
  align-items: flex-start;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  background-color: ${(props) => (props.disabled ? 'rgba(215, 211, 220, 0.2)' : '#ffffff')};

  ${breakpoint.Tablet`
    padding: 10px;
  `}
`;

export const TaskIcon = styled.div`
  margin-right: 10px;
`;

export const TaskInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export const TaskTitle = styled.div`
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  margin-bottom: 5px;
`;

export const TaskDescription = styled.div`
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  color: rgba(59, 38, 81, 0.6);

  ${breakpoint.Tablet`
    display: none;
  `}
`;

export const PostyTitle = styled.div`
  font-size: 20px;
  line-height: 32px;
  font-weight: 600;
`;

export const OutOfCreditsText = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  color: rgba(59, 38, 81, 0.4);
  background-color: #ffffff;
  border-radius: 0 7px 0 4px;
  z-index: 1;
  padding: 5px;
`;
