import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFetchDocumentsWithText } from '../../Hooks/useDocuments';
import {
  useCreateTag,
  useDashboardTags,
  useFetchTagGroupSorting,
  useFetchTagGroups,
} from '../../Hooks/useTags';
import useTranscripts from '../../Hooks/useTranscripts';
import { TTagGroupWithTags, TagWithDetails } from '../../Models';
import sortTags from './tagsSuggestions';
import { useFetchHighlights } from '../../Hooks/useHighlights';

interface WithTagManagementOptons {
  value?: TagWithDetails[];
  selectedText?: string;
  hideManageButton?: boolean;
  reverse?: boolean;
  onChange?(tags: TagWithDetails[]): void;
  onCreate?(tag: TagWithDetails): void;
  onDelete?(tag: TagWithDetails): void;
  onBlur?(): void;
}

export interface PassedComponentProps {
  groups: TTagGroupWithTags[];
  tagsWithoutGroup: TagWithDetails[];
  value?: TagWithDetails[];
  dashboardId?: string;
  suggestedTags?: TagWithDetails[];
  isLoading?: boolean;
  hideManageButton?: boolean;
  reverse?: boolean;
  onRefetchTags(): void;
  onChange?(tags: TagWithDetails[]): void;
  onCreate?(tagName: string): Promise<TagWithDetails>;
  onBlur?(): void;
  onUpdate?(): void;
}

const withGlobalAndProjectTagManagement = (
  PassedComponent: React.ComponentType<PassedComponentProps>
): React.FC<WithTagManagementOptons> => {
  const WrappedComponent: React.FC<WithTagManagementOptons> = ({
    value,
    selectedText,
    hideManageButton,
    reverse,
    onChange,
    onCreate,
    onBlur,
  }) => {
    const { dashboardId } = useParams<{ dashboardId: string; documentId: string }>();
    const createTag = useCreateTag();
    const { fetchTranscriptsWithText } = useTranscripts();
    const [loadingTags, tags, refetchTags] = useDashboardTags(dashboardId, true, true);
    const [suggestedTags, setSuggestedTags] = useState<TagWithDetails[]>([]);
    const [loadingTranscripts, transcripts] = fetchTranscriptsWithText(dashboardId);
    const [loadingDocuments, documents] = useFetchDocumentsWithText(dashboardId);
    const [tagGroupsLoading, externalTagGroups, refetchTagGroups] = useFetchTagGroups(dashboardId);
    const [tagGroupSortingsLoading, tagGroupSortings, ,] = useFetchTagGroupSorting(dashboardId);
    const [loadingHighlights, rawHighlights, refetchHighlights] = useFetchHighlights({
      dashboardId,
    });

    useEffect(() => {
      if (
        !loadingTranscripts &&
        !loadingDocuments &&
        !loadingTags &&
        !loadingHighlights &&
        tags.length &&
        transcripts &&
        documents &&
        selectedText
      ) {
        setSuggestedTags(
          sortTags(
            tags as TagWithDetails[],
            rawHighlights,
            selectedText || '',
            transcripts
              .map((item: any) => JSON.parse(item.text))
              .concat(documents.map((item) => JSON.parse(item.content)))
          )
        );
      }
    }, [
      loadingTranscripts,
      loadingDocuments,
      loadingHighlights,
      documents,
      transcripts,
      loadingTags,
      tags,
      rawHighlights,
      selectedText,
    ]);

    const groupsWithTags = useMemo(() => {
      const filteredTagGroups =
        externalTagGroups
          ?.map((group) => {
            const tagsInGroup = tags
              .filter((tag) => tag.groupId === group.id)
              .sort((firstTag, secondTag) =>
                firstTag.name.toLowerCase() < secondTag.name.toLowerCase() ? -1 : 1
              );

            return {
              ...group,
              tags: tagsInGroup as TagWithDetails[],
            };
          })
          .filter((group) => group.tags.length) || [];
      const projectSorting = tagGroupSortings.find((item) => !item.isGlobal);
      const globalSorting = tagGroupSortings.find((item) => item.isGlobal);
      const projectGroups = projectSorting
        ? projectSorting.sorting
            .map((id) => filteredTagGroups.find((group) => group.id === id) as TTagGroupWithTags)
            .filter((group) => !!group)
        : filteredTagGroups.filter((group) => !group.isGlobal);
      const globalGroups = globalSorting
        ? globalSorting.sorting
            .map((id) => filteredTagGroups.find((group) => group.id === id) as TTagGroupWithTags)
            .filter((group) => !!group)
        : filteredTagGroups.filter((group) => group.isGlobal);
      return projectGroups.concat(globalGroups);
    }, [externalTagGroups, tags, tagGroupSortings]);

    const tagsWithoutGroup = useMemo(() => {
      return tags.filter((tag) => !tag.groupId) as TagWithDetails[];
    }, [tags]);

    const handleCreate = async (tagName: string): Promise<TagWithDetails> => {
      const newTag = await createTag({ name: tagName, dashboardId });
      onCreate && onCreate(newTag as TagWithDetails);
      refetchTags();
      return newTag as TagWithDetails;
    };

    return (
      <PassedComponent
        groups={groupsWithTags}
        tagsWithoutGroup={tagsWithoutGroup}
        suggestedTags={suggestedTags}
        value={value}
        dashboardId={dashboardId}
        onRefetchTags={() => {
          refetchTagGroups();
          refetchTags();
        }}
        onChange={onChange}
        onCreate={handleCreate}
        onBlur={onBlur}
        isLoading={loadingTags || tagGroupsLoading || tagGroupSortingsLoading}
        hideManageButton={hideManageButton}
        reverse={reverse}
      />
    );
  };

  return WrappedComponent;
};

export default withGlobalAndProjectTagManagement;
