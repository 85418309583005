import React, { FC, useContext, useEffect, useMemo, useRef } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { TInnerHighlight, HighlightsContext } from '../../Context/HighlightsContext';
import { Icon16, Icon24 } from '../../Icons/Icon';
import TagsPanelEmptyState from '../TagsPanelEmptyState';

import {
  Container,
  HighlightText,
  TagsBlock,
  Tags,
  Tag,
  CloseIcon,
  DraggableArea,
  ScrollableWrapper,
  StartTime,
  ParticipantContainer,
  ParticipantName,
  CreatedByText,
  SmartStarsIcon,
} from './styles';
import { formatTimestamp } from 'subtitle';
import { ID } from '../../Models';
import { useParams } from 'react-router-dom';
import Avatar from '../Avatar';

type ElementWithId = {
  el: HTMLDivElement;
  entityId: ID;
};

const beautifyTimestamp = (timestamp: number): string => {
  if (!timestamp) return '00:00:00';
  return formatTimestamp(timestamp).split(',')[0];
};

interface TagsPanelProps {
  editable?: boolean;
  disabled?: boolean;
  getLinkedParticipant?(speakerId: string): any;
}

const TagsPanel: FC<TagsPanelProps> = ({ getLinkedParticipant, disabled, editable = true }) => {
  const { transcriptId } = useParams<{ dashboardId: string; transcriptId: string }>();
  const {
    highlights,
    selectedEntityId,
    documentTags,
    selectEntity,
    addHighlightForUnset,
  } = useContext(HighlightsContext);
  const scrollableContainerRef = useRef<HTMLDivElement>(null);
  const itemRefs = useRef<ElementWithId[]>([]);
  const [width, setWidth] = useLocalStorage('documentTagsPanelWidth', 300);

  const handleContainerResize = (mouseDownEvent: React.MouseEvent<HTMLDivElement>) => {
    document.body.style.userSelect = 'none';
    const startWidth = width;
    const startPosition = mouseDownEvent.pageX;

    function onMouseMove(mouseMoveEvent: MouseEvent) {
      setWidth((_) => {
        const newWidth = startWidth + startPosition - mouseMoveEvent.pageX;
        if (newWidth < 200) return 200;
        if (newWidth > 600) return 600;
        return newWidth;
      });
    }
    function onMouseUp() {
      document.body.removeEventListener('mousemove', onMouseMove);
      document.body.style.userSelect = 'auto';
      document.body.removeEventListener('mouseup', onMouseUp);
    }

    document.body.addEventListener('mousemove', onMouseMove);
    document.body.addEventListener('mouseup', onMouseUp);
  };

  const handleHihlightClick = (highlight: TInnerHighlight) => {
    if (highlight.startTime) {
      const event = new CustomEvent('timestampClick', {
        detail: { startTime: highlight.startTime / 1000 },
      });
      document.dispatchEvent(event);
    }
    selectEntity(highlight.entityId);
  };

  useEffect(() => {
    const highlightedElement = itemRefs.current.find((item) => item.entityId === selectedEntityId)
      ?.el;
    if (!highlightedElement || !scrollableContainerRef.current) return;

    const visible =
      highlightedElement.offsetTop >= scrollableContainerRef.current.scrollTop &&
      highlightedElement.offsetTop + highlightedElement.offsetHeight <=
        scrollableContainerRef.current.scrollTop + scrollableContainerRef.current.offsetHeight;

    highlightedElement &&
      !visible &&
      scrollableContainerRef.current.scrollTo({
        top: highlightedElement.offsetTop,
        behavior: 'smooth',
      });
  }, [selectedEntityId]);

  const currentHighlights = useMemo(() => {
    return highlights.map((highlight) => {
      const speakerId = highlight.speakerId || '';
      const currentParticipant = getLinkedParticipant ? getLinkedParticipant(speakerId) : null;

      return {
        ...highlight,
        participant: currentParticipant,
      };
    });
  }, [highlights]);

  const renderParticipant = (participant: any) => {
    if (!participant) return <></>;
    return (
      <ParticipantContainer>
        <Avatar user={participant} />
        <ParticipantName>{participant.name}</ParticipantName>
      </ParticipantContainer>
    );
  };

  return (
    <Container width={width}>
      <DraggableArea onMouseDown={handleContainerResize} />
      <ScrollableWrapper ref={scrollableContainerRef}>
        {!currentHighlights.length && <TagsPanelEmptyState disabled={disabled} />}
        {currentHighlights.map((highlight) => {
          return (
            <TagsBlock
              key={highlight.entityId}
              selected={selectedEntityId === highlight.entityId}
              onClick={() => handleHihlightClick(highlight)}
              ref={(el) => {
                const existingElt = !!itemRefs.current.find(
                  (item) => item.entityId === highlight.entityId
                );
                el && !existingElt && itemRefs.current.push({ el, entityId: highlight.entityId });
              }}
            >
              {editable && (
                <CloseIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    addHighlightForUnset(highlight.entityId);
                  }}
                >
                  <Icon16.Close />
                </CloseIcon>
              )}
              {highlight.startTime && (
                <StartTime>{beautifyTimestamp(highlight.startTime)}</StartTime>
              )}
              <HighlightText>{highlight.texts.join(' ')}</HighlightText>
              {renderParticipant(highlight.participant)}
              <Tags>
                {highlight.tags
                  .concat(
                    documentTags.filter(
                      (tag) => !highlight.tags.map((item) => item.id).includes(tag.id)
                    )
                  )
                  .concat(highlight.participant?.tagsList || [])
                  .map((tag) => {
                    if (!tag.name) return <></>;
                    return (
                      <Tag key={highlight.entityId.toString() + tag.id} color={tag.color}>
                        {tag.name}
                      </Tag>
                    );
                  })}
              </Tags>
              {highlight.createdByAi && (
                <CreatedByText>
                  <SmartStarsIcon>
                    <Icon24.PostyGray />
                  </SmartStarsIcon>
                  Made with Posty
                </CreatedByText>
              )}
            </TagsBlock>
          );
        })}
      </ScrollableWrapper>
    </Container>
  );
};

export default TagsPanel;
