import React, { useRef, useEffect, useState } from 'react';
import { Arrow, Circle, Group, Line, Transformer, Image } from 'react-konva';
import * as KonvaUtils from 'react-konva-utils';
import Konva from 'konva';
import { KonvaEventObject } from 'konva/lib/Node';
import useImage from 'use-image';
import ImageMenu from './ImageMenu';

export type ImageStyles = {
  rotation: number;
  scaleX: number;
  scaleY: number;
  skewX: number;
  skewY: number;
  zIndex?: number;
  width?: number;
  height?: number;
};

interface Props {
  id: string;
  x: number;
  y: number;
  onUpdate: (updates: { styles?: ImageStyles; x?: number; y?: number }) => Promise<void>;
  onDelete: () => void;
  onUpdateImage: () => void;
  setSelected: () => void;
  selected: boolean;
  stageScale: number;
  src: string;
  styles: ImageStyles;
  draggable?: boolean;
}

function ImageComponent({
  id,
  x,
  y,
  onUpdate,
  onDelete,
  onUpdateImage,
  setSelected,
  selected,
  stageScale,
  src,
  styles,
  draggable = true,
}: Props) {
  const [image] = useImage(src, 'anonymous');
  const imageRef = useRef<Konva.Image>(null);
  const trRef = useRef<Konva.Transformer>(null);
  const imageGroupRef = useRef<Konva.Group>(null);
  useEffect(() => {
    if (trRef.current && trRef.current.nodes && imageGroupRef.current) {
      trRef.current.nodes([imageGroupRef.current]);
      trRef.current.getLayer()?.batchDraw();
    }
  }, [selected, trRef, imageGroupRef, image]);
  return (
    <Group
      id={id}
      ref={imageGroupRef}
      draggable={draggable}
      onDragEnd={() => {
        const node = imageGroupRef.current;
        onUpdate({
          x: node?.attrs.x,
          y: node?.attrs.y,
        });
      }}
      x={x}
      y={y}
      onClick={(e) => {
        e.cancelBubble = true;
        setSelected();
      }}
      onMouseEnter={(e: KonvaEventObject<MouseEvent>) => {
        const container = e.target.getStage()?.container();
        if (container) {
          if (container.style.cursor == 'default') {
            container.style.cursor = 'pointer';
          }
        }
      }}
      onMouseLeave={(e: KonvaEventObject<MouseEvent>) => {
        const container = e.target.getStage()?.container();
        if (container) {
          if (container.style.cursor == 'pointer') {
            container.style.cursor = 'default';
          }
        }
      }}
      onTransformEnd={() => {
        const node = imageGroupRef.current;
        onUpdate({
          x: node?.attrs.x,
          y: node?.attrs.y,
          styles: {
            ...styles,
            rotation: node?.attrs.rotation,
            scaleX: node?.attrs.scaleX,
            scaleY: node?.attrs.scaleY,
            skewX: node?.attrs.skewX,
            skewY: node?.attrs.skewY,
          },
        });
      }}
      rotation={styles.rotation}
      scaleX={styles.scaleX}
      scaleY={styles.scaleY}
      skewX={styles.skewX}
      skewY={styles.skewY}
    >
      {selected && (
        <KonvaUtils.Html
          divProps={{ style: { zIndex: 100 } }}
          transformFunc={({ x, ...attrs }) => {
            return {
              ...attrs,
              scaleX: 1,
              scaleY: 1,
              x: x + attrs.scaleX,
              rotation: 0,
            };
          }}
        >
          <ImageMenu
            onDelete={onDelete}
            onUpdateImage={onUpdateImage}
            styles={styles}
            onUpdateStyles={(styles) => {
              onUpdate({
                styles,
              });
            }}
          />
        </KonvaUtils.Html>
      )}
      {image && (
        <Image height={styles?.height} width={styles?.width} image={image} ref={imageRef} />
      )}
      <Transformer
        borderStroke="#382152"
        anchorStroke="#382152"
        anchorSize={8}
        visible={selected}
        ref={trRef}
        borderStrokeWidth={2}
      />
    </Group>
  );
}

export default ImageComponent;
