import { useEffect } from 'react';

const useAutosizeTextArea = (
  textAreaRef: HTMLTextAreaElement | null,
  value: string,
  minHeight?: number
): void => {
  useEffect(() => {
    if (textAreaRef) {
      textAreaRef.style.height = '0px';
      const scrollHeight = textAreaRef.scrollHeight;
      const finalHeight = minHeight ? Math.max(scrollHeight, minHeight) : scrollHeight;
      textAreaRef.style.height = finalHeight + 'px';
    }
  }, [textAreaRef, value]);
};

export default useAutosizeTextArea;
