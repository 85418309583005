import React, { FC, useContext, useEffect, useState } from 'react';
import { NodeViewWrapper } from '@tiptap/react';
import { Container, Text } from './styles';
import VideoClip from '../../../VideoClip';
import { EvidenceContext } from '../../../../Context/EvidenceContext';
import Loader from '../../../Loader';

const TranscriptBlockComponent: FC = (props: any) => {
  const { isInitialized, findEvidenceById } = useContext(EvidenceContext);
  const [transcript, setTranscript] = useState<any | null>(null);

  useEffect(() => {
    if (isInitialized) {
      const transcript = findEvidenceById(props.node.attrs.id)?.transcript || null;
      transcript && setTranscript(transcript);
    }
  }, [isInitialized]);

  return (
    <NodeViewWrapper>
      {!transcript ? (
        <Loader />
      ) : (
        <Container contentEditable={false}>
          {transcript.file?.id && (
            <VideoClip src={transcript.file?.signedVideoUrl} offset={0.9} disableMinimization />
          )}
          <Text
            onClick={() => {
              const url = `${window.location.origin}/projects/${transcript?.dashboardId}/data/${transcript?.id}`;
              window?.open(url, '_blank')?.focus();
            }}
          >
            {transcript?.name}
          </Text>
        </Container>
      )}
    </NodeViewWrapper>
  );
};

export default TranscriptBlockComponent;
