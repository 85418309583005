import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 20px;
  border-bottom: 1px solid #e2e8f0;
  position: sticky;
  top: 49px;
  background-color: #ffffff;
  z-index: 3;
  min-height: 51px;
`;

export const PageTitle = styled.div`
  font-weight: 500;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
`;

export const ContentContainer = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  margin-bottom: 20px;
  height: 100%;
`;

export const GhostButton = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
  color: #3b265166;
  cursor: pointer;
  transition: color 0.2s ease;
  margin-bottom: 20px;
  user-select: none;

  &:last-child {
    margin: 0;
  }

  &:hover {
    color: #7f738d;
  }
`;

export const ButtonWrapper = styled.div`
  position: relative;
  margin-left: 12px;
`;

export const ButtonText = styled.div`
  margin-right: 4px;
  line-height: 24px;
`;

export const EmptyState = styled.div<{ isSimple: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${({ isSimple }) => (isSimple ? 'padding-top: 20px' : 'height: 80%')};
  width: 100%;
`;

export const EmptyStateImage = styled.div<{ src: string }>`
  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 400px;
  height: 200px;
  margin-bottom: 24px;
`;

export const EmptyStateTitle = styled.div`
  font-weight: 500;
  font-size: 20px;
  color: #382152;
  margin-bottom: 8px;
`;

export const EmptyStateText = styled.div`
  max-width: 400px;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: rgba(59, 38, 81, 0.6);
`;

export const EmptyStateActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
`;

export const WarningMessage = styled.div`
  width: 100%;
  max-width: 716px;
  margin: 20px auto 24px;
  display: flex;
  align-items: center;
  padding: 12px 10px;
  background-color: #f3eff6;
  color: #382152;
  border-radius: 8px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
`;

export const WarningIconWrapper = styled.div`
  margin-right: 8px;
`;

export const SpinnerWrapper = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
`;

export const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

export const TableTitle = styled.div`
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
  margin-bottom: 10px;
`;

export const NewTagPanel = styled.div<{ isOpen: boolean }>`
  min-width: 128px;
  position: absolute;
  right: 0;
  top: calc(100% + 5px);
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #d7d3dc;
  box-shadow: 0px 0px 0px #d7d3dc, 2px 2px 4px rgba(0, 0, 0, 0.1);
  transition: opacity 0.1s ease, transform 0.12s ease;
  z-index: 2;

  ${({ isOpen }) =>
    isOpen
      ? `
      opacity: 1;
      pointer-events: all;
      transform: translateY(0);
    `
      : `
      transform: translateY(-50px);
      opacity: 0;
      pointer-events: none
    `}
`;

export const NewTagButton = styled.div`
  padding: 10px 6px 8px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  cursor: pointer;

  &:last-child {
    padding: 8px 6px 10px;
    margin: 0;
  }

  &:hover {
    background-color: rgba(56, 33, 82, 0.1);
  }
`;

export const ModalTitle = styled.div`
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  margin-bottom: 4px;
`;

export const ModalText = styled.div`
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 16px;
`;

export const ModalSmallText = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  margin-bottom: 24px;
`;

export const ModalSmallTextLink = styled.a`
  margin-right: 4px;
  text-decoration: underline;
`;

export const SelectWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 200px;
`;

export const TagsWithoutGroupContainer = styled.div<{ isOver: boolean }>`
  position: relative;
  width: 100%;
  min-height: 40px;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    background-color: #3b2651;
    opacity: 0.1;
    display: none;
    ${({ isOver }) => (isOver ? { display: 'block' } : { display: 'none' })}
  }
`;

export const TagGroupWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const FAQLink = styled.a`
  width: 100%;
  font-size: 12px;
  line-height: 24px;
  font-weight: 500;
  color: rgba(59, 38, 81, 0.4);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
`;

export const QuestionIconWrapper = styled.div`
  margin-right: 4px;
`;

export const OrDivider = styled.div`
  margin: 10px 0;
`;
