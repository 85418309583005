import React, { FC, useEffect, useRef, useState } from 'react';
import {
  CloseButton,
  Container,
  CreditInfo,
  Divider,
  HighlightButton,
  HighlightButtonWrapper,
  MessageBlock,
  MessageTextArea,
  PostyIcon,
  PostySmallIcon,
  SendButton,
  TagControls,
  TagControlsLeftSide,
  TagControlsText,
  TagIconWraper,
  TextareaWrapper,
  Title,
  TitleContainer,
} from './styles';

import Icon24 from '../../Icons/Icon/Icon24';
import useAutosizeTextArea from '../../Hooks/useAutosizeTextArea';
import CheckboxSlider from '../CheckboxSlider';
import { Icon16 } from '../../Icons/Icon';
import { useOrganization } from '../../Hooks/useOrganization';
import PopupSuggestedPrompts from '../PopupSuggestedPrompts';
import { useLocalStorage } from 'usehooks-ts';
import { LS_RECENT_PROMPTS_BASE } from '../PopupSuggestedPrompts/PopupSuggestedPrompts';
import { TShortPrompt, useShortPrompts } from '../../Hooks/usePrompts';

const LS_KEY = 'highlightPopup';

interface HighlightPopupProps {
  withoutBorder?: boolean;
  withoutButton?: boolean;
  hidePriceText?: boolean;
  currentMessage?: string;
  currentTagsEnabled?: boolean;
  onGenerateHighlights?: (message: string, withTags: boolean) => void;
  onClose?: () => void;
  onChangeMessage?: (message: string) => void;
  onChangeTags?: (tags: boolean) => void;
}

const HighlightPopup: FC<HighlightPopupProps> = ({
  withoutBorder,
  withoutButton,
  hidePriceText,
  currentMessage,
  currentTagsEnabled,
  onGenerateHighlights,
  onClose,
  onChangeMessage,
  onChangeTags,
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [message, setMessage] = useState('');
  const [enableTags, setEnableTags] = useState(true);
  const [, org] = useOrganization();
  const hasCredits = org.aiCredits > 0;
  const [recentlyUsedPrompts, setRecentlyUsedPrompts] = useLocalStorage<TShortPrompt[]>(
    `${LS_RECENT_PROMPTS_BASE}_${LS_KEY}`,
    []
  );
  const [currentSuggestedPrompt, setCurrentSuggestedPrompt] = useState<TShortPrompt | null>(null);
  const [loading, suggestedPrompts] = useShortPrompts({ promptType: 'highlighting' });

  useAutosizeTextArea(textAreaRef.current, message, withoutBorder ? 202 : undefined);

  useEffect(() => {
    setMessage(currentMessage || '');
    setEnableTags(currentTagsEnabled !== undefined ? currentTagsEnabled : true);
  }, [currentMessage, currentTagsEnabled]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose && onClose();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleChooseSuggestedPrompt = (prompt: TShortPrompt) => {
    onChangeMessage && onChangeMessage(prompt.content);
    setMessage(prompt.content);
    setCurrentSuggestedPrompt(prompt);
  };

  const handleGenerateHighlights = () => {
    if (!hasCredits) return;
    onGenerateHighlights && onGenerateHighlights(message, enableTags);
    setRecentlyUsedPrompts(
      [
        currentSuggestedPrompt ||
          ({ name: message.slice(0, 30) + '...', content: message } as TShortPrompt),
        ...recentlyUsedPrompts.filter((p) =>
          currentSuggestedPrompt ? p.name !== currentSuggestedPrompt.name : true
        ),
      ].slice(0, 5)
    );
  };

  return (
    <Container withoutBorder={withoutBorder}>
      {onClose && (
        <CloseButton onClick={onClose}>
          <Icon16.CloseSmall />
        </CloseButton>
      )}
      <TitleContainer>
        <Title>Highlight</Title>
      </TitleContainer>

      <MessageBlock>
        <PostySmallIcon>
          <Icon24.PostyPen />
        </PostySmallIcon>

        <TextareaWrapper>
          <MessageTextArea
            onChange={(e) => {
              setMessage(e.target.value);
              onChangeMessage && onChangeMessage(e.target.value);
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                if (!hasCredits) return;
                e.preventDefault();
                handleGenerateHighlights();
              }
            }}
            value={message}
            placeholder="Highlight key takeaways in the data..."
            ref={textAreaRef}
            rows={withoutBorder ? 8 : 2}
          />

          {!withoutBorder && (
            <SendButton onClick={handleGenerateHighlights}>
              {hasCredits ? <Icon24.PostySend /> : <Icon24.PostySendGray />}
            </SendButton>
          )}
        </TextareaWrapper>
      </MessageBlock>

      <TagControls>
        <TagControlsLeftSide>
          <TagIconWraper>{enableTags ? <Icon24.PostyTag /> : <Icon24.Tag />}</TagIconWraper>
          <TagControlsText>
            {enableTags ? 'Posty will create and tag highlights' : 'Posty tagging is turned off'}
          </TagControlsText>
        </TagControlsLeftSide>

        <CheckboxSlider
          checked={enableTags}
          onChange={(checked) => {
            setEnableTags(checked);
            onChangeTags && onChangeTags(checked);
          }}
          disabled={!hasCredits}
        />
      </TagControls>

      <Divider />

      {!loading && suggestedPrompts.length > 0 && (
        <PopupSuggestedPrompts
          lsKey={LS_KEY}
          prompts={suggestedPrompts}
          onChoosePrompt={handleChooseSuggestedPrompt}
        />
      )}

      <HighlightButtonWrapper>
        <CreditInfo>
          {!hidePriceText && (
            <>
              <PostyIcon>
                <Icon24.PostyGray />
              </PostyIcon>
              {!hasCredits ? `You're out of AI credits` : `1 of ${org.aiCredits} credits`}
            </>
          )}
        </CreditInfo>

        {hasCredits && !withoutButton && (
          <HighlightButton disabled={!hasCredits} onClick={handleGenerateHighlights}>
            Start Analysis
          </HighlightButton>
        )}
      </HighlightButtonWrapper>
    </Container>
  );
};

export default HighlightPopup;
