import React, { FC, useEffect, useRef, useState } from 'react';
import { Icon16, Icon24 } from '../../Icons/Icon';
import Spinner from '../Spinner';
import {
  Container,
  DropdownItem,
  DropdownItemInfo,
  DropdownList,
  DropdownListHidden,
  DropdownRightSide,
  Icon,
  SpinnerWrapper,
  Title,
} from './styles';

export type TNestedDropdownItem = {
  icon: React.ReactNode;
  title: string;
  items?: TNestedDropdownItem[];
  showSpinner?: boolean;
  onClick?: () => void;
};

interface NestedDropdownProps {
  titleIcon: React.ReactNode;
  title: string;
  items: TNestedDropdownItem[];
  showSpinner?: boolean;
  posty?: boolean;
  type?: 'ghost' | 'primary';
}

const NestedDropdown: FC<NestedDropdownProps> = ({
  titleIcon,
  title,
  items,
  posty,
  type = 'ghost',
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target as Node)) {
        e.stopPropagation();
        setIsOpen(false);
      }
    };

    window.addEventListener('mousedown', handleClick);
    return () => {
      window.removeEventListener('mousedown', handleClick);
    };
  }, []);

  return (
    <Container onClick={() => setIsOpen(true)} type={type}>
      <Icon>{titleIcon}</Icon>
      <Title posty={posty} type={type}>
        {title}
      </Title>
      <Icon24.ChevronDown />

      {isOpen && (
        <DropdownList ref={dropdownRef}>
          {items.map((item) => (
            <DropdownItem
              key={item.title}
              onClick={(e) => {
                e.stopPropagation();
                item.onClick && !item.showSpinner && item.onClick();
                setIsOpen(false);
              }}
              disabled={item.showSpinner}
            >
              <DropdownItemInfo>
                <Icon>{item.icon}</Icon>
                <Title withMargin type={type}>
                  {item.title}
                </Title>
              </DropdownItemInfo>

              <DropdownRightSide>
                {item.showSpinner && <Spinner size="small" />}
                {item.items && <Icon16.Arrow />}
              </DropdownRightSide>

              {item.items && (
                <DropdownListHidden>
                  {item.items.map((subItem) => (
                    <DropdownItem
                      key={subItem.title}
                      onClick={(e) => {
                        e.stopPropagation();
                        subItem.onClick && subItem.onClick();
                        setIsOpen(false);
                      }}
                    >
                      <DropdownItemInfo>
                        <Icon>{subItem.icon}</Icon>
                        <Title withMargin type={type}>
                          {subItem.title}
                        </Title>
                      </DropdownItemInfo>
                    </DropdownItem>
                  ))}
                </DropdownListHidden>
              )}
            </DropdownItem>
          ))}
        </DropdownList>
      )}
    </Container>
  );
};

export default NestedDropdown;
