import styled from 'styled-components';
import Button from '../Button';
import TextareaAutosize from 'react-textarea-autosize';

export const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Overlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #382152;
  opacity: 0.2;
  z-index: -1;
`;

export const ModalWindow = styled.div`
  width: 95vw;
  height: 95vh;
  max-width: 1000px;
  max-height: 745px;
  /* padding: 20px; */
  border-radius: 6px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div``;

export const Buttons = styled.div`
  button {
    margin-bottom: 8px;
  }
`;

export const ButtonDanger = styled(Button)`
  color: #e92100;
`;

export const Panels = styled.div`
  display: flex;
  justify-content: center;
  flex: 1 1 auto;
  overflow: auto;
`;

export const LeftPanel = styled.div`
  border-right: 1px solid #d7d3dc;
  width: 280px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const RightPanel = styled.div`
  height: 100%;
  width: 100%;
  padding: 20px;
  overflow-y: scroll;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #3b2651;
  padding: 12px;
  border-bottom: 1px solid #d7d3dc;
`;

export const CloseButton = styled.div`
  cursor: pointer;
  transition: color 0.2s ease;

  &:hover {
    color: #331652;
  }
`;

export const ButtonText = styled.div`
  margin-left: 4px;
`;

export const InputWrapper = styled.span`
  display: inline-grid;
  vertical-align: top;
  align-items: center;
  position: relative;
  padding: 8px 12px;
  border-radius: 4px;
  background-color: ${({ color }) => (color ? color : '#d8c0f880')};
  color: #3b2651;
  font-weight: 500;
  font-size: 22px;
  line-height: 18px;

  &::after,
  input {
    width: auto;
    min-width: 1em;
    grid-area: 1 / 2;
    font: inherit;
    margin: 0;
    resize: none;
    background: none;
    appearance: none;
    border: none;
    word-wrap: normal;
  }

  &::after {
    content: attr(data-value);
    visibility: hidden;
    white-space: nowrap;
  }
`;

export const InputField = styled.input`
  outline: none;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const InputTitle = styled.h3`
  font-weight: 600;
  font-size: 14px;
  /* line-height: 28px; */
  display: flex;
  align-items: center;
  color: #3b2651;
  margin-top: 12px;
  margin-bottom: 12px;
`;
export const DetailsText = styled.span`
  font-weight: 500;
  font-size: 14px;
`;

export const InputTitleText = styled.span`
  margin-right: 8px;
`;

export const TextArea = styled(TextareaAutosize)`
  outline-color: rgba(59, 38, 81, 0.4);
  outline-width: 1px;
  width: 100%;
  max-height: 128px;
  font-weight: 500;
  font-size: 14px;
  resize: none;
  padding: 4px;
  margin-left: -4px;
  color: #3b2651;
  &::placeholder {
    color: rgba(56, 33, 82, 0.4);
  }
`;

export const EmptyStateImage = styled.div<{ src: string }>`
  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 400px;
  height: 200px;
  margin-bottom: 24px;
`;

export const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80%;
  width: 100%;
`;

export const EmptyStateText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: rgba(59, 38, 81, 0.4);
`;

export const ColorIcon = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 12px;
  border-width: 1.5px;
  border-color: #3b2651;
  margin-right: 10px;
`;

export const EditableRowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 128px;
`;

export const CreatedByWrapper = styled.div`
  width: 128px;
`;

export const SmartAnalysisTextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const SmartAnalysisIcon = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    140.36deg,
    rgba(142, 132, 238, 0.3) 3.78%,
    rgba(210, 206, 248, 0.3) 71.03%
  );
  border-radius: 5px;
  margin-right: 5px;
`;

export const SmartAnalysisText = styled.div`
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  background: linear-gradient(90.96deg, #8e84ee 0.82%, #514b88 56.57%);

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
