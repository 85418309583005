import { DocumentTextIcon } from '@heroicons/react/outline';
import React, { FC, useContext, useRef } from 'react';
import { UploadFilesContext } from '../../Context/UploadFilesContext';
import { Icon16, Icon24 } from '../../Icons/Icon';
import { CloudFile } from '../../Models';
import {
  AddFileButton,
  Container,
  DeleteFileButton,
  FileCardItem,
  FileCardItemText,
  FileIcon,
  FileItems,
  FileItemsWrapper,
  Title,
} from './styles';

interface FilesColumnProps {
  currentFiles: File[] | null;
  currentCloudFiles?: CloudFile[];
  updateCurrentFiles: (files: File[] | null) => void;
  onCancelFile: (filename?: string) => void;
  onCancelCloudFile: (filename?: string) => void;
}

const FilesColumn: FC<FilesColumnProps> = ({
  currentFiles,
  currentCloudFiles,
  onCancelFile,
  onCancelCloudFile,
  updateCurrentFiles,
}) => {
  const { addUploadingFile } = useContext(UploadFilesContext);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length) {
      const files = Array.from(e.target.files);
      files.forEach((file) =>
        addUploadingFile({
          file,
          isUploading: false,
          uploadingProgress: 0,
        })
      );

      updateCurrentFiles(currentFiles ? currentFiles.concat(files) : files);
    }
  };

  return (
    <Container>
      <Title>Data</Title>
      <FileItemsWrapper>
        <FileItems>
          {currentFiles?.map((file) => (
            <FileCardItem key={file.name}>
              <FileIcon>
                {file.type?.includes('officedocument') || file.type?.includes('text') ? (
                  <DocumentTextIcon width={22} />
                ) : (
                  <Icon24.Video />
                )}
              </FileIcon>
              <FileCardItemText>{file.name}</FileCardItemText>
              <DeleteFileButton onClick={() => onCancelFile(file.name)}>
                <Icon16.Close />
              </DeleteFileButton>
            </FileCardItem>
          ))}
          {currentCloudFiles &&
            currentCloudFiles.map((file) => (
              <FileCardItem key={file.name}>
                <FileIcon>
                  {file.type?.includes('officedocument') || file.type?.includes('text') ? (
                    <DocumentTextIcon width={22} />
                  ) : (
                    <Icon24.Video />
                  )}
                </FileIcon>
                <FileCardItemText>{file.name}</FileCardItemText>
                <DeleteFileButton onClick={() => onCancelCloudFile(file.name)}>
                  <Icon16.Close />
                </DeleteFileButton>
              </FileCardItem>
            ))}
        </FileItems>
        <AddFileButton onClick={() => inputRef.current?.click()}>
          <Icon24.Plus />
        </AddFileButton>
      </FileItemsWrapper>
      <input
        id={'video_upload'}
        name={'video_upload'}
        accept={
          'video/mp4, video/quicktime, audio/mp3, audio/mp4, audio/x-m4a, .srt, .vtt, .txt, .docx, .pdf'
        }
        type={'file'}
        onChange={handleFileInputChange}
        className={'hidden'}
        ref={inputRef}
        multiple
      />
    </Container>
  );
};

export default FilesColumn;
