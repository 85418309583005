import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import Avatar from '../../Components/Avatar';
import Portal from '../../Components/PortalNew/PortalNew';
import ParticipantLinkingDropdown from '../../Components/ParticipantLinkingDropdown';
import { EditorContext } from '../../Context/EditorContext';
import { SpeakersContext, TSpeakerBlock } from '../../Context/SpeakersContext';
import { useTranscriptLinkedParticipants } from '../../Hooks/useTranscripts';
import { SpeakerDropdownWrapper, UserName } from './styles';

interface ParticipantBlockView {
  transcriptId: string;
  containerElement: HTMLDivElement | null;
}

const ParticipantBlockView: FC<ParticipantBlockView> = ({ transcriptId, containerElement }) => {
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const addButtonRef = useRef<HTMLDivElement | null>(null);
  const { linkParticipant } = useContext(EditorContext);
  const [currentAddButtonRect, setCurrentAddButtonRect] = useState({ top: 0, left: 0 });

  const { speakerBlocks } = useContext(SpeakersContext);

  const [
    ,
    ,
    refetchLinkedParticipants,
    getLinkedParticipant,
    getRowIdByParticipantId,
  ] = useTranscriptLinkedParticipants(transcriptId);

  useEffect(() => {
    const handleScroll = () => {
      if (!addButtonRef.current) return;
      const { top, left, width } = addButtonRef.current.getBoundingClientRect();
      setCurrentAddButtonRect({ top: top, left: left + width + 2 });
    };

    handleScroll();
    containerElement?.addEventListener('scroll', handleScroll, true);
    return () => {
      containerElement?.removeEventListener('scroll', handleScroll, true);
    };
  }, [containerElement]);

  const handleLinkParticipant = async (participant: any, speakerBlock: TSpeakerBlock) => {
    const currentParticipant = getLinkedParticipant(speakerBlock.id);
    const rowId = currentParticipant ? getRowIdByParticipantId(currentParticipant.id) : '';
    await linkParticipant(transcriptId, speakerBlock, rowId, participant);
    refetchLinkedParticipants();
  };

  const handleUnlinkParticipant = async () => {
    refetchLinkedParticipants();
  };

  return (
    <div className="flex items-center flex-wrap">
      {speakerBlocks.map((speakerBlock) => {
        const participant = getLinkedParticipant(speakerBlock.id);
        if (!participant) return null;
        return (
          <div
            key={speakerBlock.id}
            className="m-1 ml-0"
            onClick={(e) => {
              addButtonRef.current = e.currentTarget;
              const { top, left, width } = e.currentTarget.getBoundingClientRect();
              setCurrentAddButtonRect({ top: top, left: left + width + 2 });
              setIsOpenDropdown(true);
            }}
          >
            <div className="flex items-center mr-3 cursor-pointer relative">
              <Avatar user={participant} />
              <UserName>{participant?.name}</UserName>
            </div>
          </div>
        );
      })}
      <div
        className="cursor-pointer text-primary-purple-light-40"
        onClick={(e) => {
          addButtonRef.current = e.currentTarget;
          const { top, left, width } = e.currentTarget.getBoundingClientRect();
          setCurrentAddButtonRect({ top: top, left: left + width + 2 });
          setIsOpenDropdown(true);
        }}
      >
        Add participant
      </div>
      {isOpenDropdown && addButtonRef.current && (
        <Portal wrapperId="speakerNameModalTopBar">
          <SpeakerDropdownWrapper left={currentAddButtonRect?.left} top={currentAddButtonRect?.top}>
            <ParticipantLinkingDropdown
              isOpen={isOpenDropdown}
              onUnlinkParticipant={handleUnlinkParticipant}
              onLinkParticipant={(participant: any, speakerBlock: TSpeakerBlock) =>
                handleLinkParticipant(participant, speakerBlock)
              }
              onClose={() => setIsOpenDropdown(false)}
            />
          </SpeakerDropdownWrapper>
        </Portal>
      )}
    </div>
  );
};

export default ParticipantBlockView;
