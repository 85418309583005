import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
  CSSProperties,
  useCallback,
  useRef,
  lazy,
  Suspense,
} from 'react';
import Badge from './Badge1';
import { Sticky, Tag, Theme, ParticipantBase, DataSourceBase, TagWithDetails } from '../Models';
import useThemes from '../Hooks/useThemes';
import useNotes from '../Hooks/useNotes';
import moment from 'moment';
import { TypeComputedProps, TypeEditInfo } from '@inovua/reactdatagrid-community/types';
const ReactDataGrid = lazy(() => import('@inovua/reactdatagrid-enterprise'));
import '@inovua/reactdatagrid-enterprise/index.css';
import { OptionType } from './MultiSelect';
import { uniqBy } from 'lodash-es';
import { LICENSE_KEY } from '../Consts';
import {
  EmojiHappyIcon,
  EmojiSadIcon,
  PlusIcon,
  DocumentTextIcon,
  VideoCameraIcon,
  DocumentIcon,
} from '@heroicons/react/outline';
import { useAuth0 } from '@auth0/auth0-react';
import EmojiNeutralIcon from '../Icons/EmojiNeutralIcon';
import { SelectableContext } from './Selectable';
import DropDownEditor from './DropDownEditor';
import TextAreaEditor from './TextAreaEditor';
import ReactLoading from 'react-loading';
import { useFetchParticipants, useCreateParticipant } from '../Hooks/useParticipants';
import { useHistory } from 'react-router-dom';

import {
  EmptyStateButton,
  EmptyStatePlusIcon,
  EmptyState,
  EmptyStateContainer,
  EmptyStateTitle,
  EmptyStateSubTitle,
  HeroImage,
  BetaLabel,
} from '../Components/EmptyState2';
import notesEmptyImage from '../assets/empty-states/notes.png';
import usePermissions from '../Hooks/usePermissions';
import { useCreateTag } from '../Hooks/useTags';
import withPortal from './PortalWrapper';
import TagsDropdown from './TagsDropdown';
import PortalWrapper from './PortalWrapper';
import { ta } from 'date-fns/locale';
import useAnalytics from '../Hooks/useAnalytics';
import Loader from './Loader';
import { Icon24 } from '../Icons/Icon';
import { useCSVImport, useMiroImport } from '../Hooks/useDataImport';

type TableProps = {
  stickies: Sticky[];
  dashboardId: string | number;
  width: number;
  sentimentInProgress?: boolean;
  tags: Tag[];
  runSentimentAnalysis: () => void;
};

type CellTag = {
  value: TagWithDetails;
  label: string;
  color: string | undefined;
};

function NotesTable({
  stickies,
  dashboardId,
  width,
  tags,
  sentimentInProgress = false,
  runSentimentAnalysis,
}: TableProps): JSX.Element {
  const { user } = useAuth0();
  const history = useHistory();

  const [selected, setSelected] = useContext(SelectableContext);
  const [gridRef, setGridRef] = useState<TypeComputedProps | null>(null);

  const { createTheme, getFromStickies } = useThemes(dashboardId);
  const { createNote, updateNote } = useNotes(dashboardId);
  const createTag = useCreateTag();

  const [handleImportFromMiro] = useMiroImport(`${dashboardId}`);
  const [handleImportFromCsv] = useCSVImport(`${dashboardId}`);

  // TODO: need to deeply test that
  const stickesWithJustTheme = stickies.map((sticker: Sticky) => ({
    id: sticker.id,
    theme: sticker.theme,
  }));
  const themes = React.useMemo(() => {
    return getFromStickies(stickies);
  }, [JSON.stringify(stickesWithJustTheme)]);

  const [isLastActionEdit, setIsLastActionEdit] = useState(false);
  const [activeCell, setActiveCell] = useState<[number, number] | null>([0, 0]);
  const [createParticipant] = useCreateParticipant();
  const [loading, participants, ,] = useFetchParticipants();
  const { canEditNotes } = usePermissions();

  const rowHeight = 40;

  useEffect(() => {
    if (activeCell && selected) {
      const [type, id] = selected.split('-');

      if (type == 'sticky') {
        if (stickies[activeCell[0]].id != id) {
          setSelected?.(`sticky-${stickies[activeCell[0]].id}`);
        }
      }
    }
  }, [activeCell]);

  useEffect(() => {
    if (selected) {
      const [type, id] = selected.split('-');
      if (type == 'sticky') {
        if (gridRef?.bodyRef?.current) {
          gridRef?.scrollToId(id, { duration: 500, top: true });
        }
      }
    }
  }, [selected]);

  useEffect(() => {
    if (stickies && stickies.length && stickies[stickies.length - 1].id < 0) {
      setActiveCell([stickies.length - 1, 0]);
      gridRef?.scrollToIndex(stickies.length - 1);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      gridRef?.startEdit({
        rowIndex: stickies.length - 1,
        columnId: 0,
      });
    }
  }, [gridRef, stickies]);

  const { analytics } = useAnalytics();

  async function handleCreateNew() {
    setIsLastActionEdit(false);

    analytics.sendEvent('CreateNote', {
      origin: 'NoteTable',
    });

    await createNote(dashboardId, {
      text: '',
    });
  }

  async function handleSentimentClick(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    e.stopPropagation();
    runSentimentAnalysis();
  }

  const RowLoading = (
    <ReactLoading type={'spin'} color={'#382152'} height={'100%'} width={'100%'} />
  );

  function SentimentHeader() {
    return (
      <div>
        <div className={'flex'}>
          <div>Sentiment</div>
          <div className="relative flex flex-col items-center group">
            <div
              className="ml-2 w-2 h-2 m-2 fond rounded-full bg-secondary-red"
              title="You can run sentiment analysis from here anytime."
            />
          </div>
        </div>
      </div>
    );
  }

  const columns = [
    { name: 'id', defaultVisible: false, type: 'number', showInContextMenu: false },
    {
      name: 'text',
      header: 'Note',
      editable: canEditNotes,
      showColumnMenuGroupOptions: false,
      defaultWidth: 350,
      editor: TextAreaEditor,
      // eslint-disable-next-line react/display-name
      render: ({ value }: { value: string }) => (
        <div className={'w-full break-words'} title={value}>
          {value}
        </div>
      ),
    },
    {
      name: 'theme',
      header: 'Theme',
      showColumnMenuGroupOptions: false,
      editable: canEditNotes,
      // eslint-disable-next-line react/display-name
      render: ({ value }: { value: Theme | null }) =>
        value && <Badge name={value.name} color={value.color} />,
      sort: (a: Theme, b: Theme) => {
        const nameSort = a?.name.localeCompare(b?.name);
        if (nameSort != 0) {
          return nameSort;
        }
        return a?.id >= b?.id;
      },
      // eslint-disable-next-line react/display-name
      renderEditor: ({ value, cell, ...props }: { value: Theme; cell: any }) => {
        return (
          <DropDownEditor
            noOptionsMessage={'No themes'}
            placeholder={'Search or create a new theme'}
            cell={cell}
            value={
              value ? { value: value.id as string, label: value.name, color: value.color } : null
            }
            options={themes.map((x: Theme) => ({
              value: x.id,
              label: x.name,
              color: x.color,
            }))}
            {...props}
            isMulti={false}
          />
        );
      },
    },
    {
      name: 'tagsList',
      header: 'Tags',
      editable: canEditNotes,
      showColumnMenuGroupOptions: false,
      // eslint-disable-next-line react/display-name
      render: ({ value }: { value: Tag[] | null }) => {
        const children = (value ?? []).map((x) => (
          <Badge key={x.id} name={x.name} color={x.color || 'rgba(56, 33, 82, 0.2)'} />
        ));
        return <>{children}</>;
      },
      sort: (a: Tag[], b: Tag[]) => {
        return (a ?? [])
          .map((x) => x.name)
          .join(',')
          .localeCompare((b ?? []).map((x) => x.name).join(','));
      },
      // eslint-disable-next-line react/display-name
      renderEditor: ({
        value,
        cell,
        ...props
      }: {
        value: Tag[];
        cell: any;
        onChange(tags: CellTag[]): void;
        onComplete(): void;
      }) => {
        return (
          <PortalWrapper targetElement={cell.domRef.current as HTMLDivElement}>
            <TagsDropdown
              value={value as TagWithDetails[]}
              onChange={async (tags) => {
                props.onChange(
                  tags.map((tag) => ({ value: tag, label: tag.name, color: tag.color }))
                );
              }}
              onBlur={() => props.onComplete()}
            />
          </PortalWrapper>
        );
      },
    },
    {
      name: 'participant',
      header: 'Participant',
      showColumnMenuGroupOptions: false,
      editable: canEditNotes,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line react/display-name
      render: ({ value }: { value: ParticipantBase | null }) => value && value.name,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      sort: (a: ParticipantBase, b: ParticipantBase) => {
        const nameSort = a?.name.localeCompare(b?.name);
        if (nameSort != 0) {
          return nameSort;
        }
        return a?.id >= b?.id;
      },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line react/display-name
      renderEditor: ({ value, cell, ...props }: { value: ParticipantBase; cell: any }) => {
        return (
          <DropDownEditor
            noOptionsMessage={'No participants'}
            placeholder={'Search or create a new participant'}
            autoFocus={false}
            cell={cell}
            value={value ? { value: value.id as string, label: value.name } : null}
            options={participants.map((x: ParticipantBase) => ({
              value: x.id,
              label: x.name,
            }))}
            {...props}
            isMulti={false}
          />
        );
      },
    },
    {
      name: 'sentimentScore',
      header: SentimentHeader,
      editable: canEditNotes,
      showColumnMenuGroupOptions: false,
      // eslint-disable-next-line react/display-name
      renderEditor: ({ value, ...props }: { value: any }) => {
        const calc = value == null ? value : Math.sign(value) * Math.ceil(Math.abs(value));

        const options: OptionType[] = [
          { value: 1, label: 'Positive', color: 'LightGreen' },
          { value: 0, label: 'Neutral', color: 'LightGray' },
          { value: -1, label: 'Negative', color: 'Pink' },
        ];

        const computedValue = options.find((o) => o.value == calc);

        return (
          <DropDownEditor
            isMulti={false}
            {...props}
            value={computedValue ?? null}
            options={options}
          />
        );
      },
      // eslint-disable-next-line react/display-name
      render: ({ value }: { value: number | null }) => {
        if (value == null) {
          if (sentimentInProgress) {
            return (
              <div className={'flex text-gray-400'}>
                <div className={'h-5 w-5 mr-1'}>{RowLoading}</div>
                <div>Analyzing...</div>
              </div>
            );
          }
          return <></>;
        }

        if (value > 0) {
          return (
            <div className={'flex'}>
              <EmojiHappyIcon className={'w-5 h-5 mr-1 text-green-500'} /> Positive
            </div>
          );
        }

        if (value < 0) {
          return (
            <div className={'flex'}>
              <EmojiSadIcon className={'w-5 h-5 mr-1 text-red-500'} /> Negative
            </div>
          );
        }

        return (
          <div className={'flex'}>
            <EmojiNeutralIcon className={'mr-1'} /> Neutral
          </div>
        );
      },
    },
    {
      name: 'data',
      header: 'Source',
      showColumnMenuGroupOptions: false,
      editable: false,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line react/display-name
      render: ({ value }: { value: DataSourceBase | null }) => {
        switch (value?.__typename) {
          case 'Document':
            return (
              <a
                className={'hover:underline'}
                onClick={(e) => {
                  e.preventDefault();
                  history.push(
                    `/projects/${dashboardId}/data/${
                      value.__typename == 'Document' ? 'docs' : 'transcription'
                    }/${value.id}`
                  );
                }}
                href={`/projects/${dashboardId}/data/docs/${value.id}`}
              >
                <DocumentIcon className={'w-6 h-6 mr-2 inline-block'}></DocumentIcon>
                {value.name}
              </a>
            );
          case 'Transcription':
            return (
              <a
                className={'hover:underline'}
                onClick={(e) => {
                  e.preventDefault();
                  history.push(`/projects/${dashboardId}/data/${value.id}`);
                }}
                href={`/projects/${dashboardId}/data/${value.id}`}
              >
                {value.file?.type === 'video' ? (
                  <VideoCameraIcon className={'w-6 h-6 mr-2 inline-block'}></VideoCameraIcon>
                ) : (
                  <DocumentTextIcon className={'w-6 h-6 mr-2 inline-block'}></DocumentTextIcon>
                )}
                {value.name}
              </a>
            );
        }
        if (value != null) {
        }
        return <></>;
      },

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      sort: (a: DataSourceBase, b: DataSourceBase) => {
        const nameSort = a?.name.localeCompare(b?.name);
        if (nameSort != 0) {
          return nameSort;
        }
        return a?.id >= b?.id;
      },
    },
    {
      name: 'userByCreatedBy',
      header: 'Created By',
      showColumnMenuGroupOptions: false,
      // eslint-disable-next-line react/display-name
      render: ({ value }: { value: any }) => <>{value?.name}</>,
      sort: (a: { name: string }, b: { name: string }) => {
        return a?.name.localeCompare(b?.name);
      },
    },
    {
      name: 'createdAt',
      header: 'Created At',
      showColumnMenuGroupOptions: false,
      // eslint-disable-next-line react/display-name
      render: ({ value }: { value: Date }) => value && <>{moment(value).fromNow()}</>,
    },
  ];

  if (user && user['https://notably.ai/claims/org_id'] === 1) {
    columns.splice(
      5,
      0,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore

      {
        name: 'url',
        header: 'Video Snippet',
        editable: canEditNotes,
        showColumnMenuGroupOptions: false,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line react/display-name
        render: ({ value }: { value: string | null }) => {
          return (
            value && (
              <a rel="noreferrer" tabIndex={-1} className="underline" target="_blank" href={value}>
                {value}
              </a>
            )
          );
        },
      }
    );
  }

  const onEditComplete =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    async ({ value, data, columnId }: TypeEditInfo) => {
      if (data.ignoreEdit) {
        return;
      }
      setIsLastActionEdit(true);
      switch (columnId) {
        case 'url':
        case 'sentimentScore':
          await updateNote(data.id, {
            sentimentScore: value?.value,
          });
          break;
        case 'text':
          await updateNote(data.id, {
            [columnId]: value,
          });
          break;
        case 'theme':
          if (value?.action == 'clear') {
            await updateNote(data.id, {
              themeId: null,
            });
            return;
          }
          if (value?.__isNew__) {
            await createTheme(dashboardId, { name: value?.value }, [data]);
          } else {
            await updateNote(data.id, {
              themeId: value?.value,
            });
          }
          break;
        case 'participant':
          if (value?.action == 'clear') {
            await updateNote(data.id, {
              participantId: null,
            });
            return;
          }
          if (value?.__isNew__) {
            const participant = await createParticipant({ name: value?.value });
            await updateNote(data.id, {
              participantId: participant.id,
            });
          } else {
            await updateNote(data.id, {
              participantId: value?.value,
            });
          }
          break;
        case 'tagsList':
          const itemsToAdd = value?.filter((x: OptionType) => x.__isNew__);
          const newTags = [];
          for (const item of itemsToAdd) {
            const newTag = await createTag({
              name: item.value,
              dashboardId: dashboardId as string,
            });
            newTags.push(newTag);
          }
          const updatedTags: { id: string }[] | null = [
            ...value?.filter((x: OptionType) => !x.__isNew__).map((x: OptionType) => x?.value),
            ...newTags,
          ];
          await updateNote(data.id, {
            tagsNotes: {
              deleteOthers: true,
              create: updatedTags?.map((tag) => ({ tagId: tag.id })) || [],
            },
          });
          break;
      }
    };

  const isStartEditKeyPressed = ({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    event,
    handle: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      current: { tryStartEdit },
    },
  }) => {
    if (event.key === 'Enter' && !event.shiftKey && activeCell?.length) {
      if (isLastActionEdit) {
        if (activeCell[0] === stickies.length - 1) {
          (async function () {
            await handleCreateNew();
          })();
          return false;
        } else {
          setActiveCell((prev) => (prev ? [prev[0] + 1, prev[1]] : null));
        }
      } else {
        tryStartEdit({
          rowIndex: activeCell[0],
          columnId: activeCell[1],
          dir: 1,
        });
      }
    }
    setIsLastActionEdit(false);
    return false;
  };

  const renderRow = useCallback(
    (row: { id: any; style: CSSProperties }) => {
      if (selected && row.id == selected.split('-')[1]) row.style.backgroundColor = '#eee';
    },
    [selected]
  );

  const renderColumnContextMenu = useCallback(
    ({ items, ...rest2 }, { cellProps, ...rest }) => {
      items.splice(3, 4);
      if (cellProps.name != 'sentimentScore') {
        return;
      }
      items.splice(0, 0, {
        label: 'Run Sentiment Analysis',
        itemId: 'runSentimentAnalysis',
        disabled: null,
        onClick: handleSentimentClick,
      });
    },
    [sentimentInProgress, stickies]
  );

  const gridStyle = {
    height: `${window.innerHeight - rowHeight * 3 - 34 - 28}px`,
    width: width - 4 + 'px',
  };

  const stikiesWithoutPosition = JSON.stringify(
    stickies.map((stick) => ({ ...stick, x: 0, y: 0 }))
  );

  const Grid = useMemo(() => {
    return (
      <>
        <Suspense fallback={<Loader />}>
          <ReactDataGrid
            emptyText={'No notes created yet'}
            licenseKey={LICENSE_KEY}
            onReady={(ref) => setGridRef(ref.current)}
            rowHeight={rowHeight}
            onEditComplete={onEditComplete}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            isStartEditKeyPressed={isStartEditKeyPressed}
            idProperty="id"
            editStartEvent="click"
            columns={columns}
            enableKeyboardNavigation={true}
            dataSource={stickies}
            activeCell={activeCell}
            onActiveCellChange={setActiveCell}
            showZebraRows={false}
            nativeScroll={true}
            renderRow={renderRow as () => React.ReactNode}
            style={gridStyle}
            renderColumnContextMenu={renderColumnContextMenu as () => React.ReactNode}
          />
          {canEditNotes && (
            <button
              onClick={async () => await handleCreateNew()}
              className="flex text-primary-purple p-3 font-medium"
            >
              <PlusIcon className="w-6 h-6" /> New note
            </button>
          )}
        </Suspense>
      </>
    );
  }, [activeCell, selected, stikiesWithoutPosition, isLastActionEdit, sentimentInProgress, width]);

  if (!stickies?.length) {
    return (
      <EmptyStateContainer>
        <EmptyState>
          <HeroImage src={notesEmptyImage}></HeroImage>
          <EmptyStateTitle>Add Notes to Start Analysis</EmptyStateTitle>
          <EmptyStateSubTitle>
            Analyze all of your highlights from data in this project. Import notes in bulk or create
            notes one at a time.
          </EmptyStateSubTitle>

          {canEditNotes && (
            <>
              <EmptyStateButton onClick={handleImportFromCsv} type="secondary">
                <div className="flex justify-start items-center p-1">
                  <div className="mr-2">
                    <Icon24.Spreadsheet />
                  </div>
                  <div className="text-violet-950 text-base font-medium tracking-tight mr-2">
                    Import Spreadsheet{' '}
                  </div>
                  <div className="text-gray-700 text-opacity-40 text-base font-medium tracking-tight">
                    .csv
                  </div>
                </div>
              </EmptyStateButton>
              <EmptyStateButton onClick={handleImportFromMiro} type="secondary" mt="0">
                <div className="flex justify-start items-center p-1">
                  <div className="mr-2">
                    <Icon24.Miro />
                  </div>
                  <div className="text-violet-950 text-base font-medium tracking-tight mr-2">
                    Import Miro Board
                  </div>
                  <BetaLabel>Beta</BetaLabel>
                </div>
              </EmptyStateButton>
              or
              <EmptyStateButton onClick={handleCreateNew} type="secondary" mt="10">
                <EmptyStatePlusIcon />
                New note
              </EmptyStateButton>
            </>
          )}
        </EmptyState>
      </EmptyStateContainer>
    );
  }

  return Grid;
}

export default NotesTable;
