import styled from 'styled-components';

export const Container = styled.div`
  padding: 10px 15px;
  border-radius: 4px;
  background-color: #ffffff;
`;

export const SliderContainer = styled.div`
  position: relative;
  width: 4px;
  height: 110px;
  background-color: rgba(59, 38, 81, 0.4);
  border-radius: 2px;
`;

export const SliderTrack = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: #3b2651;
  border-radius: 2px;
`;

export const SliderThumb = styled.div`
  position: absolute;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  top: -12px;
  left: calc(50% - 7px);
  background-color: #3b2651;
`;
