import styled, { css } from 'styled-components';
import Button from '../Button';

export const Container = styled.div<{ withoutBorder?: boolean }>`
  position: relative;
  background-color: #ffffff;
  min-width: 354px;
  ${(props) =>
    !props.withoutBorder &&
    css`
      border: 1px solid rgba(215, 211, 220, 1);
      padding: 10px;
      box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.1);
      border-radius: 6px;
    `}
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

export const Title = styled.div`
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: rgba(59, 38, 81, 0.4);
`;

export const TextareaWrapper = styled.div`
  width: 100%;
  position: relative;
  padding: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background: linear-gradient(180deg, #c6c1f6 0%, #b4ace2 100%);
  cursor: pointer;
`;

export const MessageTextArea = styled.textarea`
  outline: none;
  border: none;
  width: 100%;
  resize: none;
  padding: 5px 32px 5px 10px;
  background-color: #ffffff;
  border-radius: 5px;
  max-height: 180px;

  &::placeholder {
    font-weight: 500;
    background: linear-gradient(90.96deg, #8e84ee 0.82%, #514b88 56.57%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

export const SendButton = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 5px;
  bottom: 5px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(215, 211, 220, 1);
  margin: 10px 0;
`;

export const TagControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
`;

export const TagControlsLeftSide = styled.div`
  display: flex;
  align-items: center;
`;

export const TagControlsText = styled.div`
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  margin-left: 10px;
`;

export const TagIconWraper = styled.div`
  color: rgba(177, 168, 185, 1);
`;

export const MessageBlock = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
`;

export const PostySmallIcon = styled.div`
  flex-shrink: 0;
  margin-right: 10px;
  margin-top: 5px;
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

export const CreditInfo = styled.div`
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;

  background: linear-gradient(90deg, rgba(142, 132, 238, 0.6) 0%, rgba(53, 26, 138, 0.6) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 20px;
  display: flex;
  align-items: center;
`;

export const HighlightButton = styled(Button)`
  font-size: 14px;
  font-weight: 500;
  color: #3b2651;
  padding: 12px 10px;
  background: linear-gradient(90deg, #d1deff 0%, #d8c0f8 100%);
  border-radius: 4px;
  background: linear-gradient(96deg, #8e84ee 0.45%, #d2cef8 68.99%);
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.1);
`;

export const HighlightButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
`;

export const PostyIcon = styled.div`
  margin-right: 5px;
`;
