import React, { FC } from 'react';
import Avatar from '../../Components/Avatar';
import LetteredAvatar from '../../Components/LetteredAvatar';
import { TSpeakerBlock } from '../../Context/SpeakersContext';
import QuestionMarkIcon from '../../Icons/QuestionMarkIcon';
import {
  Label,
  LabelWithIcon,
  LegacyParticipantContainer,
  UserIconI,
  ParticipantNameBlock,
  SpeakerNamesBlock,
  SpeakerName,
  SpeakerWrapper,
} from './styles';

interface LegacyParcipantBlockProps {
  participant: {
    id: string;
    name: string;
    picture: string;
  };
  speakers: TSpeakerBlock[];
  onChoose: (speakerId: TSpeakerBlock) => Promise<void>;
}

const LegacyParcipantBlock: FC<LegacyParcipantBlockProps> = ({
  participant,
  speakers,
  onChoose,
}) => {
  return (
    <LegacyParticipantContainer>
      <LabelWithIcon>
        <UserIconI />
        <Label>Participant</Label>
      </LabelWithIcon>
      <ParticipantNameBlock>
        <div className="mr-2">Choose which speaker is</div>
        <Avatar user={participant} />
        <div className="ml-1 mr-2">{participant?.name}</div>
        <div>to link the participant.</div>
      </ParticipantNameBlock>
      <SpeakerNamesBlock>
        {speakers.map((speaker) => (
          <SpeakerWrapper
            key={speaker.id}
            onClick={() => {
              if (!speaker.id) return;
              onChoose(speaker);
            }}
          >
            <LetteredAvatar name={speaker.speakerName} />
            <SpeakerName>{speaker.speakerName}</SpeakerName>
          </SpeakerWrapper>
        ))}
      </SpeakerNamesBlock>
      <div>
        <a
          rel="noreferrer"
          href="https://help.notably.ai/en/articles/9113509-linking-participants-to-speakers-in-transcript-files"
          target="_blank"
          className="flex items-center text-primary-purple-light-40"
        >
          <QuestionMarkIcon color="rgba(59, 38, 81, 0.4)" />
          <div className="ml-1">Learn more about speakers and participants</div>
        </a>
      </div>
    </LegacyParticipantContainer>
  );
};

export default LegacyParcipantBlock;
