import styled from 'styled-components';
import Button from '../Button';

export const Wrapper = styled.div<{ isOpen: boolean }>`
  position: fixed;
  left: 0;
  top: 48px;
  width: 0;
  height: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 999;

  ${({ isOpen }) =>
    isOpen &&
    `
    width: 100%;
    height: calc(100% - 48px);
  `}
`;

export const Overlay = styled.div<{ isOpen: boolean }>`
  position: fixed;
  left: 0;
  top: 48px;
  width: 0;
  height: 0;
  transition: opacity 0.25s ease;
  opacity: 0;
  z-index: -1;

  ${({ isOpen }) =>
    isOpen &&
    `
    width: 100%;
    height: calc(100% - 48px);
    z-index: 999;
  `}
`;

export const Container = styled.div<{ isOpen: boolean }>`
  width: 100%;
  height: 100%;
  max-width: 450px;
  background-color: #ffffff;
  transition: opacity 0.12s ease-in;
  pointer-events: none;
  z-index: 1;
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  border-left: 1px solid rgb(229, 231, 235);
  border-top: 1px solid rgb(229, 231, 235);
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.1);

  ${({ isOpen }) =>
    isOpen &&
    `
    z-index: 9999;
    pointer-events: all;
    opacity: 1;
  `}
`;

export const TopContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
`;

export const CloseButton = styled.div`
  cursor: pointer;
`;

export const Title = styled.div`
  font-size: 18px;
  line-height: 32px;
  font-weight: 600;
  margin-bottom: 10px;
`;

export const Description = styled.div`
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
  margin-bottom: 20px;
`;

export const Content = styled.div`
  padding: 0 20px;
`;

export const SelectTitle = styled.div`
  font-size: 16px;
  line-height: 32px;
  font-weight: 500;
  margin-bottom: 10px;
`;

export const BottomButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px 40px;
  margin-top: 40px;
`;

export const CreditPrice = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: rgba(59, 38, 81, 0.4);
  margin-right: 20px;
`;

export const PostyIcon = styled.div`
  margin-right: 10px;
`;

export const SaveButton = styled(Button)`
  margin-right: 10px;
`;

export const OptionsList = styled.ul`
  list-style: disc;
  padding-left: 20px;
`;
export const Option = styled.li`
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
`;
