import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const CleanConfirmationPanel = styled.div<{ size?: string }>`
  position: absolute;
  width: ${({ size }) => (size === 'small' ? '200px' : '280px')};
  top: calc(100% + 2px);
  left: 0;
  padding: 8px;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  z-index: 999;

  &::before {
    content: ' ';
    top: -22px;
    display: block;
    height: 20px;
    left: 16px;
    position: absolute;
    border-color: transparent transparent #dddddd transparent;
    border-style: solid;
    border-width: 11px;
  }

  &::after {
    content: ' ';
    border-left: solid transparent 10px;
    border-right: solid transparent 10px;
    border-bottom: solid #ffffff 10px;
    top: -10px;
    left: 17px;
    height: 0;
    position: absolute;
    width: 0;
  }
`;

export const CleanConfirmationText = styled.div<{ withoutMargin?: boolean }>`
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  ${({ withoutMargin }) => !withoutMargin && `margin-bottom: 10px`};
`;

export const CleanConfirmationButtons = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
`;

export const CleanConfirmationButton = styled.div`
  margin-right: 5px;

  &:last-child {
    margin-right: 0;
  }
`;

export const HighlightPopupWrapper = styled.div`
  position: absolute;
  top: calc(100% + 5px);
  right: 0;
`;
