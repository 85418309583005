import { ID } from './index';

export interface Sticky {
  id: ID;
  dashboardId: string;
  text: string;
  color: string;
  x: number;
  y: number;
  isGrouped: boolean;
  sentimentScore?: number;
  theme: Theme;
  document?: { id: string; name: string };
  transcription?: { id: string; name: string; file?: { id: string; type: string } };
  tagsList: Tag[];
  participant?: { id: string; name: string; color?: string };
  participantId?: string;
  userByCreatedBy: { name: string; picture: string };
  createdAt: string;
  customDate: string;
  options: string | null;
}

export interface StickyOptions {
  timeOffset: number;
}

export interface Theme {
  id: ID;
  notes: Sticky[];
  x: number;
  y: number;
  name: string;
  color: string;
  userByCreatedBy: any;
  createdAt: Date;
  notesConnection?: { totalCount?: number };
}

export interface TagInput {
  id?: ID;
  name?: string;
  color?: string;
  dashboardId?: string | null;
  isGlobal?: boolean;
  description?: string;
  keywords?: string;
  groupId?: ID | null;
  createdWithAi?: boolean;
}

export interface Tag {
  id: ID;
  name: string;
  color?: string;
  dashboardId?: string | null;
  isGlobal?: boolean;
  groupId: ID | null;
}

export interface TagWithDetails extends Tag {
  description: string;
  keywords: string;
  tagsDocumentsConnection: {
    totalCount: number;
  };
  tagsNotesConnection: {
    totalCount: number;
  };
  tagsTranscriptionsConnection: {
    totalCount: number;
  };
}

export type TTagGroup = {
  id: ID;
  name: string;
  isGlobal: boolean;
};

export type TUpdateTagGroup = {
  name?: string;
  isGlobal?: boolean;
};

export type TTagGroupWithTags = {
  id: ID;
  name: string;
  tags: TagWithDetails[];
};

export type TGroupSort = {
  id: ID;
  dashboardId: ID;
  isGlobal: boolean;
  sorting: ID[];
};

export type TGroupSortRecieved = {
  id: ID;
  dashboardId: ID;
  isGlobal: boolean;
  sorting: string;
};

export type ParticipantBase = {
  id: string;
  name: string;
};

export type DataSourceBase = {
  __typename: string;
  id: string;
  name: string;
  file?: {
    type: string;
  };
};

// Starting width of the sticky
export const STICKY_WIDTH = 200;
// starting width of space between the sticky in a theme/group
export const STICKY_SPACE = 10;
// Starting font of the sticky
export const STICKY_FONT = 14;
// Default spacing step for padding/margin/etc.
export const DEFAULT_STEP = 8;
// Default color for a new sticky
export const DEFAULT_STICKY_COLOR = '#FEF1CE';
// Default scale to show text on stickies
export const DEFAULT_SHOW_TEXT_SCALE = 0.3;
