import { isNil } from 'lodash-es';
import { DEFAULT_STEP, Sticky, STICKY_FONT, STICKY_WIDTH, Theme } from '../Models';

export const calcStartPosition = (
  stickies: Sticky[],
  themes: Theme[]
): {
  x: number;
  y: number;
} => {
  const startPosition = {
    x: 0,
    y: 0,
  };

  if (stickies.length > 0) {
    const stickyPositions = stickies
      .filter((sticky) => !sticky.theme && !isNil(sticky.x) && !isNil(sticky.y))
      .map((sticky) => {
        return {
          x1: sticky.x,
          y1: sticky.y,
          x2: sticky.x + STICKY_WIDTH,
          y2: sticky.y + STICKY_WIDTH,
        };
      });

    const themePositions = themes.map((theme) => {
      const [themeWidth, themeHeight] = calcThemeSize(theme, stickies);
      return {
        x1: theme.x,
        y1: theme.y,
        x2: theme.x + themeWidth,
        y2: theme.y + themeHeight,
      };
    });

    const allPositions = [...stickyPositions, ...themePositions];

    const minX = Math.min(...allPositions.map((pos) => pos.x1));
    const maxY = Math.max(...allPositions.map((pos) => pos.y2));

    startPosition.x = minX;
    startPosition.y = maxY + 32;
  }

  return startPosition;
};

export const calcThemeSize = (
  theme: Theme,
  stickies: Sticky[]
): [width: number, height: number, rowSize: number] => {
  const themeNotes = stickies.filter((sticky) => sticky.theme?.id === theme.id);
  const rowSize = Math.ceil(Math.sqrt(themeNotes.length)) || 1;
  const themeWidth = (DEFAULT_STEP + STICKY_WIDTH) * rowSize + DEFAULT_STEP * 2;
  const themeHeight =
    (DEFAULT_STEP + STICKY_WIDTH) * Math.ceil(themeNotes.length / rowSize) +
    DEFAULT_STEP * 3 +
    STICKY_FONT * 1.25;
  return [themeWidth, themeHeight, rowSize];
};

export const calcThemeSizeFromStickyCount = (
  count: number
): [width: number, height: number, rowSize: number] => {
  const rowSize = Math.ceil(Math.sqrt(count));
  const themeWidth = (DEFAULT_STEP + STICKY_WIDTH) * rowSize + DEFAULT_STEP * 2;
  const themeHeight =
    (DEFAULT_STEP + STICKY_WIDTH) * Math.ceil(count / rowSize) +
    DEFAULT_STEP * 3 +
    STICKY_FONT * 1.25;
  return [themeWidth, themeHeight, rowSize];
};
