import styled from 'styled-components';

export const MenuRow = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  font-size: 14px;
  cursor: pointer;
  color: #382152;
  &:hover {
    background-color: rgba(215, 211, 220, 1);
  }
`;

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  color: #3b2651;
  margin-bottom: 16px;
`;

export const ErrorIcon = styled.div`
  margin-right: 8px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background-color 0.2s ease;
  border-radius: 6px;
  user-select: none;
`;

export const TitleLeftSide = styled.div`
  display: flex;
  align-items: center;
`;

export const TitleRightSide = styled.div`
  display: flex;
  opacity: 0;
  transition: opacity 0.1s ease;
  pointer-events: none;
`;

export const DeleteButton = styled.div`
  cursor: pointer;
  align-items: center;
  display: flex;
  margin-left: 8px;
`;

export const LoadingStateContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const ButtonName = styled.div`
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
  margin: 0 8px;
`;

export const Buttons = styled.div`
  display: inline-flex;
  align-items: right;

  /* overflow: hidden; */
  margin-bottom: 8px;
`;

export const Button = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 8px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  /* border-right: 1px solid #d7d3dc; */
  margin-right: 4px;
  margin-left: 4px;
  cursor: pointer;
  background-color: #ffffff;
  border: 1px solid #d7d3dc;
  box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.05);
  border-radius: 6px;

  &:hover {
    background-color: #f8f6fa;
  }

  /* &:last-child {
    border: none;
  } */
`;

export const NodeWrapper = styled.div<{ isSmart: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ isSmart }) =>
    isSmart ? 'linear-gradient(180deg, #8D83EC 0%, #D7D3DC 100%)' : 'rgba(215, 211, 220, 1)'};
  border-radius: 12px;
  padding: 1px;
  margin-bottom: 16px;
`;

export const NodeContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 12px 20px;
  border-radius: 11px;
  background: #ffffff;

  &:hover ${TitleRightSide} {
    opacity: 1;
    pointer-events: all;
  }
`;

export const InfoText = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: rgba(59, 38, 81, 0.4);
`;

export const ContentWrapper = styled.div<{ collapsed?: boolean }>`
  max-height: ${(props) => (props.collapsed ? '0px' : '4000px')};
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1) -0.1s;
  overflow: scroll;
`;

export const DeleteIconWrapper = styled.div`
  margin-right: 4px;
`;

export const DeleteConfirmation = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #f28904;
`;
export const MenuItemText = styled.span`
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  padding-left: 8px;
`;

export const SelectWrapper = styled.div`
  width: 100%;
  max-width: 260px;
  margin-bottom: 8px;
`;

export const SelectTitle = styled.div`
  color: #3b2651;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 8px;
`;

export const BottomDisclaimer = styled.div`
  color: rgba(59, 38, 81, 0.6);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-top: 8px;
`;

export const SmartAnalysisInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  color: #b2a8b9;
`;

export const SmartAnalysisInfoText = styled.div`
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
  margin-left: 5px;
`;
