import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  position: relative;
  min-width: 232px;
  max-width: 232px;
  border: 1px solid #d7d3dc;
  border-radius: 3px;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
`;

export const ReversableWrapper = styled.div<{ reverse?: boolean }>`
  width: 100%;

  ${({ reverse }) =>
    reverse &&
    `
    display: flex;
    flex-direction: column-reverse;
    padding-top: 10px;
  `}
`;

export const InputField = styled.input`
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  outline: none;
  border-radius: 3px;
`;

export const GlobalSublistButtonWrapper = styled.div`
  position: relative;
  /* padding: 0 10px; */
`;

export const GlobalSublistButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 4px;
  margin: 4px 0;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.1s ease;

  &:hover {
    background-color: rgba(133, 95, 168, 0.1);
  }
`;

export const GlobalSublistButtonText = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #d7d3dc;
`;

export const TagList = styled.div<{ type?: string; reverse?: boolean }>`
  ${({ type, reverse }) =>
    type !== 'global' &&
    `
    ${!reverse && `margin-bottom: 8px`};

    &:last-child {
      ${!reverse && `margin-bottom: 16px`};
    }
  `}
`;

export const TagItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: background-color 0.1s ease;
  border-radius: 3px;
  padding: 5px;

  &:hover {
    background-color: rgba(133, 95, 168, 0.1);
  }
`;

export const TagName = styled.div<{ color?: string }>`
  padding: 5px;
  background-color: ${({ color }) => (color ? color : '#d8c0f880')};
  border-radius: 3px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  max-width: 180px;
  overflow: hidden;
  margin-right: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:last-child {
    margin: 0;
  }
`;

export const TagInstances = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #b1a8b9;
`;

export const ManageTagsButton = styled.div`
  display: flex;
  align-items: center;
  color: #b1a8b9;
  cursor: pointer;
  padding: 10px;
`;

export const ManageTagsText = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  margin-left: 4px;
`;

export const NoOptionsText = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  margin-bottom: 8px;
  text-align: center;
`;

export const ChosenTagsArea = styled.div`
  width: 100%;
  margin-bottom: 10px;
  border: 1px solid #d7d3dc;
  border-radius: 3px;
  padding: 6px;
`;

export const ChosenTagsList = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  flex-wrap: wrap;
`;

export const ChosenTag = styled.div<{ color?: string }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 16px 5px 5px;
  background-color: ${({ color }) => (color ? color : '#d8c0f880')};
  border-radius: 3px;
  margin: 0 4px 4px 0;
`;

export const ChosenTagCloseButton = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
  height: 100%;
  border-radius: 0 3px 3px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.15s ease;

  &:hover {
    color: #424f6a;
  }
`;

export const ChosenTagName = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  max-width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 8px;
`;

export const FolderPanelWrapper = styled.div`
  padding-left: 26px;
  position: absolute;
  bottom: -46px;
  right: -100%;
  width: 100%;
  height: 100%;
  max-width: 258px;
  max-height: 192px;
  z-index: 9999;
`;

export const FolderPanel = styled.div`
  padding: 10px;
  background-color: #ffffff;
  border: 1px solid #d7d3dc;
  border-radius: 3px;
  box-shadow: 0px 0px 0px #d7d3dc, 2px 2px 4px rgba(0, 0, 0, 0.1);
  max-height: 192px;
  overflow: auto;
`;

export const CreateTagButton = styled.div`
  font-size: 14px;
  line-height: 20px;
  padding: 5px;
  border-radius: 3px;
  margin-bottom: 8px;
  cursor: pointer;

  &:hover {
    background-color: rgba(133, 95, 168, 0.1);
  }
`;

export const ContentWrapper = styled.div`
  padding: 0 10px 0;
`;

export const ChosenTagsAreaWrapper = styled.div`
  padding: 10px 10px 0;
`;

export const SidePaddingsWrapper = styled.div`
  padding: 0 10px;
`;

export const TagListTitle = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: rgba(59, 38, 81, 0.4);
  padding: 0 0 0 5px;
`;

export const TagsContainer = styled.div<{ reverse?: boolean }>`
  overflow: auto;
  max-height: 280px;

  ${({ reverse }) =>
    reverse &&
    `
    display: flex;
    flex-direction: column-reverse;
  `}
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
  width: 100%;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${rotate} 0.5s linear infinite;
`;
