import React, { FC } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { TShortPrompt } from '../../Hooks/usePrompts';
import { Icon24 } from '../../Icons/Icon';
import {
  Divider,
  SuggestedPrompt,
  SuggestedPromptIcon,
  SuggestedPromptName,
  SuggestedPrompts,
  SuggestedPromptsOverlay,
  SuggestedPromptsTitle,
  SuggestedPromptsWrapper,
} from './styles';

export const LS_RECENT_PROMPTS_BASE = 'recentlyUsedPrompts';

interface IPopupSuggestedPromptsProps {
  prompts: TShortPrompt[];
  lsKey?: string;
  type?: 'highlight' | 'cluster';
  onChoosePrompt: (prompt: TShortPrompt) => void;
}

const PopupSuggestedPrompts: FC<IPopupSuggestedPromptsProps> = ({
  prompts,
  lsKey,
  type,
  onChoosePrompt,
}) => {
  const [recentlyUsedPrompts] = useLocalStorage<TShortPrompt[]>(
    LS_RECENT_PROMPTS_BASE + '_' + lsKey,
    []
  );
  return (
    <SuggestedPromptsWrapper>
      {!!recentlyUsedPrompts.length && (
        <>
          <SuggestedPromptsTitle>Recently used</SuggestedPromptsTitle>
          <SuggestedPrompts>
            {recentlyUsedPrompts.slice(0, 2).map((prompt) => (
              <SuggestedPrompt key={prompt.name} onClick={() => onChoosePrompt(prompt)}>
                <SuggestedPromptIcon>
                  <Icon24.Clock />
                </SuggestedPromptIcon>
                <SuggestedPromptName>{prompt.name}</SuggestedPromptName>
              </SuggestedPrompt>
            ))}
          </SuggestedPrompts>

          <Divider />
        </>
      )}

      <SuggestedPromptsTitle>Suggested</SuggestedPromptsTitle>
      <SuggestedPrompts>
        {prompts.map((prompt) => (
          <SuggestedPrompt key={prompt.name} onClick={() => onChoosePrompt(prompt)}>
            <SuggestedPromptIcon>
              {type === 'cluster' ? <Icon24.ClusterGray /> : <Icon24.PostyPenGray />}
            </SuggestedPromptIcon>
            <SuggestedPromptName>{prompt.name}</SuggestedPromptName>
          </SuggestedPrompt>
        ))}
      </SuggestedPrompts>
      <SuggestedPromptsOverlay />
    </SuggestedPromptsWrapper>
  );
};

export default PopupSuggestedPrompts;
