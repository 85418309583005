import React, { FC, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TrashIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import differenceInHours from 'date-fns/differenceInHours';
import isBefore from 'date-fns/isBefore';
import { useEffectOnce, useLocalStorage } from 'usehooks-ts';

import SecondaryToolbar from '../../Components/SecondaryToolbar/SecondaryToolbar';
import InsightCoverPhoto from '../../Components/InsightCoverPhoto';
import InsightEditor from '../../Components/InsightEditor';
import Loader from '../../Components/Loader';
import Button from '../../Components/Button';
import {
  useFetchInsightByClientId,
  useUpdateInsightByClientId,
  useDeleteInsight,
} from '../../Hooks/useInsights';
import usePermissions from '../../Hooks/usePermissions';
import Sharing from '../../Components/Sharing';

import { nanoid } from 'nanoid';
import { useOrganization } from '../../Hooks/useOrganization';
import useAnalytics from '../../Hooks/useAnalytics';
import {
  Actions,
  Breadcrumbs,
  Container,
  Delimiter,
  InsightPageWrapper,
  InsightTitle,
} from './styles';

import StatsReactionsComments from '../../Components/StatsReactionsComments';
import CoverPhotoGenerationModal from '../../Components/CoverPhotoGenerationModal';
import { htmlToText } from 'html-to-text';
import { CoverPhotoGenerationContextProvider } from '../../Context/CoverPhotoGenerationContext';

interface InsightProps {
  insight: any;
  loading: boolean;
  canEditInsights: boolean;
  onUpdate(input: any): void;
  onShare(isShared: boolean): void;
  onDelete(): void;
}

const Insight: FC<InsightProps> = ({
  insight,
  loading,
  canEditInsights,
  onUpdate,
  onShare,
  onDelete,
}) => {
  const { dashboardId } = useParams<{ dashboardId: string; clientId: string }>();
  const [, org] = useOrganization();
  const [isGenerationModalOpen, setGenerationModalOpen] = useState(false);
  const [currentInsightContent, setCurrentInsightContent] = useState<string>('');

  if (loading) return <Loader />;

  return (
    <Container>
      <SecondaryToolbar sticky>
        <div className="flex w-full flex-row py-3 px-4 justify-between">
          <Breadcrumbs>
            <Link to={`/projects/${dashboardId}/insights`}>Insights</Link>
            <Delimiter>/</Delimiter>
            <InsightTitle>{insight.title || 'Untitled Insight'}</InsightTitle>
          </Breadcrumbs>
          <Actions>
            {canEditInsights && (
              <>
                <Sharing
                  onChange={onShare}
                  link={
                    insight.shareId
                      ? `${window.location.origin}/s/${org?.id}/insight/${insight.shareId}`
                      : null
                  }
                />
                <Button onClick={() => onDelete()} type={'secondary'}>
                  <TrashIcon className={'w-4 h-4 mr-1'} />
                  Delete
                </Button>
              </>
            )}
          </Actions>
        </div>
      </SecondaryToolbar>
      {!loading && (
        <InsightCoverPhoto
          data={insight}
          isInsightsPanel={false}
          updateInsight={onUpdate}
          onShowGenerationModal={() => setGenerationModalOpen(true)}
        />
      )}
      {!loading && (
        <InsightEditor
          insight={insight}
          onSave={onUpdate}
          isInsightsPanel={false}
          onChangeText={(text) => setCurrentInsightContent(htmlToText(text, { wordwrap: 130 }))}
        />
      )}
      {isGenerationModalOpen && (
        <CoverPhotoGenerationModal
          insightContent={currentInsightContent}
          onClose={() => setGenerationModalOpen(false)}
          onChangeCover={onUpdate}
        />
      )}
    </Container>
  );
};

const wasMoreThanOneHourAgo = (date: Date) => {
  const now = new Date();
  const diffHours = differenceInHours(now, date);

  return isBefore(date, now) && diffHours >= 1;
};

const InsightPage = () => {
  const { dashboardId, clientId } = useParams<{ dashboardId: string; clientId: string }>();
  const { canEditInsights } = usePermissions();

  const { analytics } = useAnalytics();
  const history = useHistory();

  const { loading, data } = useFetchInsightByClientId(clientId);
  const [updateInsightMutation] = useUpdateInsightByClientId();
  const [deleteInsightMutation] = useDeleteInsight();

  // track views
  const [viewLastTracked, setViewLastTracked] = useLocalStorage<string>(
    `insight-${clientId}-view-last-tracked`,
    'Thu Jan 01 1970'
  );
  const hasTrackedView = useRef(false);
  useEffect(() => {
    if (!data?.id || hasTrackedView.current || !wasMoreThanOneHourAgo(new Date(viewLastTracked)))
      return;
    async function addViews(clientId: string, views: number) {
      await updateInsightMutation(clientId, { views });
      setViewLastTracked(new Date().toString());
    }
    hasTrackedView.current = true;
    addViews(data.clientId, (data.views || 0) + 1);
  }, [data]);
  // end track views

  useEffectOnce(() => {
    analytics.sendEvent('pageView.Insight', {
      projectId: dashboardId,
      insightClientId: clientId,
    });
  });

  const handleInsightUpdate = async (input: any) => {
    await updateInsightMutation(clientId, input);
  };

  const handleShare = async (isShared: boolean) => {
    await updateInsightMutation(clientId, { shareId: isShared ? nanoid(16) : null });
  };

  async function handleDelete() {
    if (!confirm(`Are you sure you want to delete the insight? This cannot be undone.`)) {
      return;
    }

    await deleteInsightMutation(dashboardId, clientId);

    analytics.sendEvent('DeleteInsight', {
      projectId: dashboardId,
      insightClientId: clientId,
    });
    history.push(`/projects/${dashboardId}/insights`);
  }

  return (
    <CoverPhotoGenerationContextProvider>
      <InsightPageWrapper>
        <Insight
          key={dashboardId + clientId}
          insight={data}
          loading={loading}
          canEditInsights={canEditInsights}
          onUpdate={handleInsightUpdate}
          onShare={handleShare}
          onDelete={handleDelete}
        />
        <StatsReactionsComments
          insightId={data?.id}
          viewsCount={data?.views}
          evidenceCount={data?.evidencesByParentInsightIdConnection?.totalCount}
        />
      </InsightPageWrapper>
    </CoverPhotoGenerationContextProvider>
  );
};

export default InsightPage;
