/* eslint-disable @typescript-eslint/no-empty-function */
import React, { FC, useContext, useEffect, useState } from 'react';
import { ID, Tag, TagWithDetails } from '../Models';
import {
  useCreateHighlight,
  useDeleteHighlight,
  useFetchHighlights,
  useUpdateHighlight,
} from '../Hooks/useHighlights';
import { HighlightNote, THighlight } from '../Models/Highlight';
import { ProjectChecklistContext } from './ProjectChecklistContext';

export type TInnerHighlight = {
  id?: ID;
  entityId: ID;
  tags: TagWithDetails[];
  texts: string[];
  speakerId?: string;
  createdByAi?: boolean;
  startTime?: number | null;
  endTime?: number | null;
  addedToAnalysis: boolean;
  notes?: HighlightNote[];
  tagsHighlights?: {
    deleteOthers: boolean;
    create: { tagId: ID }[];
  };
};

export type ConnectedTagIds = {
  entityId: ID;
  tagIds: ID[];
  addedToAnalysis: boolean;
};

export type ConnectedTags = {
  entityId: ID;
  tags: TagWithDetails[];
  addedToAnalysis: boolean;
};

interface HighlightsContextProps {
  highlights: TInnerHighlight[];
  selectedEntityId: ID | null;
  highlightForUnset: ID | null;
  refetchHighlights: () => void;
  documentTags: TagWithDetails[];
  rawHighlights: THighlight[];
  addHighlight(highlight: TInnerHighlight, skipRefetch?: boolean): Promise<void>;
  removeHighlight(entityId: ID): void;
  setHighlightsList(highlights: TInnerHighlight[]): void;
  updateHighlightTags(entityId: ID, tags: TagWithDetails[]): void;
  selectEntity(entityId: ID | null): void;
  addHighlightForUnset(entityId: ID | null): void;
  setTags(tags: TagWithDetails[]): void;
  setConnectedTagsFromIds(tags: ConnectedTagIds[]): void;
  setHighlightsAddedToAnalysis(highlights: TInnerHighlight[]): void;
  updateDocumentTags(tags: Tag[]): void;
  removeHighlightsFromDB(highlightIds: ID[]): void;
}

export const HighlightsContext = React.createContext<HighlightsContextProps>({
  highlights: [],
  selectedEntityId: null,
  highlightForUnset: null,
  documentTags: [],
  rawHighlights: [],
  refetchHighlights: () => {},
  addHighlight: async () => {},
  removeHighlight: () => {},
  setHighlightsList: () => {},
  updateHighlightTags: () => {},
  selectEntity: () => {},
  addHighlightForUnset: () => {},
  setTags: () => {},
  setConnectedTagsFromIds: () => {},
  setHighlightsAddedToAnalysis: () => {},
  updateDocumentTags: () => {},
  removeHighlightsFromDB: () => {},
});

interface HighlightsContextProviderProps {
  dashboardId: string;
  documentId?: string;
  transcriptionId?: string;
  children: React.ReactNode;
}

export const HighlightsContextProvider: FC<HighlightsContextProviderProps> = ({
  dashboardId,
  documentId,
  transcriptionId,
  children,
}) => {
  const [, rawHighlights, refetchHighlights] = useFetchHighlights({
    documentId,
    transcriptionId,
  });

  const [createHighlight] = useCreateHighlight();
  const [updateHighlight] = useUpdateHighlight();
  const [deleteHighlight] = useDeleteHighlight();

  const [highlights, setHighlights] = useState<TInnerHighlight[]>([]);
  const [connectedTags, setConnectedTags] = useState<ConnectedTags[]>([]);
  const [selectedEntityId, setSelectedEntityId] = useState<string | null>(null);
  const [highlightForUnset, setHighlightForUnset] = useState<ID | null>(null);
  const [tags, setTags] = useState<TagWithDetails[]>([]);
  const [lastCreatedId, setLastCreatedId] = useState<ID>('');
  const [documentTags, setDocumentTags] = useState<TagWithDetails[]>([]);
  const [deletedHighlightIds, setDeletedHighlightIds] = useState<ID[]>([]);
  const highlightsRef = React.useRef(highlights);
  const rawHighlightsRef = React.useRef(rawHighlights);
  const { highlightAdded, markHighlightAdded } = useContext(ProjectChecklistContext);

  useEffect(() => {
    highlightsRef.current = highlights;
    rawHighlightsRef.current = rawHighlights;
  }, [highlights, rawHighlights]);

  useEffect(() => {
    if (rawHighlights && tags && tags.length) {
      const savedConnectedTags: ConnectedTagIds[] = rawHighlights.map((highlight) => {
        return {
          entityId: highlight.entityId,
          tagIds: highlight.tagsList?.map((tag: { id: any }) => tag.id) || [],
          addedToAnalysis: highlight.addedToAnalysis,
        };
      });
      setConnectedTagsFromIds(savedConnectedTags);
    }
  }, [tags, rawHighlights]);

  useEffect(() => {
    if (rawHighlights)
      setHighlights((currentHighlights) => {
        return currentHighlights.map((highlight) => {
          const currentRaw = rawHighlights.find((item) => item.entityId === highlight.entityId);
          return {
            ...highlight,
            id: currentRaw?.id,
          };
        });
      });
  }, [rawHighlights]);

  useEffect(() => {
    setHighlights((currentHighlights) => {
      return currentHighlights.map((highlight) => {
        const currentConnectedTag = connectedTags.find(
          (item) => item.entityId === highlight.entityId
        );
        if (!currentConnectedTag) return highlight;

        return {
          ...highlight,
          tags: currentConnectedTag.tags,
          addedToAnalysis: currentConnectedTag.addedToAnalysis,
        };
      });
    });
  }, [connectedTags]);

  const setHighlightsList = async (highlights: TInnerHighlight[]) => {
    const highlightsWithTags = highlights.map((highlight) => {
      const newConnectedTags =
        connectedTags.find((tag) => tag.entityId === highlight.entityId)?.tags || [];
      const rawHighlight = rawHighlights.find((item) => item.entityId === highlight.entityId);
      return {
        ...highlight,
        id: rawHighlight?.id,
        notes: rawHighlight?.notesByHiglightId,
        tags: newConnectedTags,
        addedToAnalysis: rawHighlight?.addedToAnalysis || false,
      };
    });
    setHighlights(highlightsWithTags);
  };

  const addHighlight = async (highlight: TInnerHighlight, skipRefetch?: false): Promise<void> => {
    const { entityId, tagsHighlights } = highlight;

    setHighlights((currentHighlights) => {
      const existing = currentHighlights.find((item) => item.entityId === entityId);
      if (existing) {
        return currentHighlights;
      }
      const newHighlights = currentHighlights.concat({ ...highlight, addedToAnalysis: false });
      return newHighlights;
    });
    const idObject = documentId ? { documentId } : { transcriptionId };

    const res = await createHighlight({ dashboardId, ...idObject, entityId, tagsHighlights });
    setLastCreatedId(res.id);

    if (!highlightAdded) {
      markHighlightAdded();
    }

    if (!skipRefetch) {
      refetchHighlights();
    }
  };

  const removeHighlight = async (entityId: string) => {
    setHighlights((currentEntities) =>
      currentEntities.filter((item) => item.entityId !== entityId)
    );

    const highlight = rawHighlights.find((item) => item.entityId === entityId);

    if (!highlight) return;

    setConnectedTags((currentConnectedTags) => {
      const newArr = currentConnectedTags.filter((item) => item.entityId !== entityId);
      return newArr;
    });
  };

  const removeHighlightsFromDB = async (highlightIds: ID[]) => {
    if (!highlightIds.length) return;

    for (const highlightId of highlightIds) {
      try {
        // hack to fix deleting highlights twice when you do it quickly
        if (deletedHighlightIds.includes(highlightId)) return;
        setDeletedHighlightIds((currentIds) => [...currentIds, highlightId]);
        await deleteHighlight(highlightId, documentId, transcriptionId);
      } catch (error) {}
    }
    refetchHighlights();
  };

  const setHighlightsAddedToAnalysis = async (highlights: TInnerHighlight[]) => {
    await Promise.all(
      highlights.map(async (highlight) => {
        const rawHighlight = rawHighlights.find((item) => item.entityId === highlight.entityId);
        if (!rawHighlight) return true;

        await updateHighlight(rawHighlight?.id, {
          addedToAnalysis: true,
        });
      })
    );

    refetchHighlights();
  };

  const updateHighlightTags = async (entityId: string, newTags: TagWithDetails[]) => {
    const highlight = rawHighlights.find((item) => item.entityId === entityId);
    if (!highlight?.id && !lastCreatedId) return;
    await updateHighlight(highlight?.id || lastCreatedId, {
      tagsHighlights: {
        deleteOthers: true,
        create: newTags?.map((tag) => ({ tagId: tag.id })) || [],
      },
    });
    refetchHighlights();
  };

  const selectEntity = (entityId: string | null) => {
    setSelectedEntityId(entityId);
  };

  const setConnectedTagsFromIds = (connectedTagsIds: ConnectedTagIds[]) => {
    if (!tags || !tags.length) return;

    const newConnectedTags: ConnectedTags[] = connectedTagsIds.map((item) => ({
      ...item,
      tags: (tags as TagWithDetails[]).filter((tag) => item.tagIds.includes(tag.id)),
    }));
    setConnectedTags(newConnectedTags);
  };

  const updateDocumentTags = (tags: Tag[]) => {
    const tagsWithDetails = tags.map((tag) => tags.find((item) => item.id === tag.id));
    setDocumentTags(tagsWithDetails as TagWithDetails[]);
  };

  return (
    <HighlightsContext.Provider
      value={{
        highlights,
        selectedEntityId,
        highlightForUnset,
        documentTags,
        rawHighlights: rawHighlights || [],
        updateDocumentTags,
        setHighlightsList,
        addHighlight,
        removeHighlight,
        updateHighlightTags,
        selectEntity,
        addHighlightForUnset: setHighlightForUnset,
        setTags,
        refetchHighlights,
        setConnectedTagsFromIds,
        setHighlightsAddedToAnalysis,
        removeHighlightsFromDB,
      }}
    >
      {children}
    </HighlightsContext.Provider>
  );
};
