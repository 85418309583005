import React, { FC } from 'react';

interface Props {
  children?: React.ReactNode;
}

const Icon48: FC<Props> = ({ children }) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      {children}
    </svg>
  );
};

const Upload: FC = () => {
  return (
    <Icon48>
      <circle cx="24" cy="24" r="20.0156" stroke="currentColor" strokeWidth="1.96875" />
      <path
        d="M31.2727 21.1364L23.6364 13.5M23.6364 13.5L16 21.1364M23.6364 13.5V36.4091"
        stroke="#382152"
        strokeWidth="1.99219"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon48>
  );
};

const PostyMedium: FC = () => {
  return (
    <Icon48>
      <path
        d="M28.0608 43.7437H14.9199C9.4413 43.7437 5 39.3023 5 33.8237V17.1349C5 11.7332 9.3789 7.35432 14.7806 7.35432H35.3625C38.1389 7.35432 40.8159 8.38758 42.8724 10.2529L44.8474 12.0444C45.8693 12.9713 46.4521 14.2868 46.4521 15.6665V25.3523C46.4521 35.5096 38.218 43.7437 28.0608 43.7437Z"
        fill="url(#paint0_linear_43_87)"
      />
      <g clipPath="url(#clip0_43_87)">
        <path
          d="M35.4772 7.35432H10.992C7.6979 7.35432 4.99988 10.0448 4.99988 13.3297V32.6933C4.99988 35.9808 7.6979 38.6687 10.992 38.6687H26.8667C34.9325 38.6687 41.472 32.1476 41.472 24.1044V13.3297C41.472 10.0422 38.7739 7.35432 35.4798 7.35432H35.4772Z"
          fill="#8E84EE"
        />
        <path
          d="M7.82373 34.45C7.82373 35.2928 8.51237 35.984 9.35775 35.984H13.7157C14.3067 35.984 14.7846 35.5061 14.7846 34.9151V32.4791C14.7846 31.8882 14.3067 31.4102 13.7157 31.4102H8.89266C8.30167 31.4102 7.82373 31.8882 7.82373 32.4791V34.45Z"
          fill="#736DE4"
        />
        <path
          d="M13.6951 24.3002H8.91322C8.31151 24.3002 7.82373 24.788 7.82373 25.3897V27.8333C7.82373 28.4351 8.31151 28.9228 8.91322 28.9228H13.6951C14.2969 28.9228 14.7846 28.4351 14.7846 27.8333V25.3897C14.7846 24.788 14.2969 24.3002 13.6951 24.3002Z"
          fill="#736DE4"
        />
        <path
          d="M13.7054 17.2394H8.90294C8.30691 17.2394 7.82373 17.7226 7.82373 18.3186V20.734C7.82373 21.33 8.30691 21.8132 8.90294 21.8132H13.7054C14.3015 21.8132 14.7846 21.33 14.7846 20.734V18.3186C14.7846 17.7226 14.3015 17.2394 13.7054 17.2394Z"
          fill="#736DE4"
        />
        <path
          d="M7.82373 11.6736V13.5597C7.82373 14.2201 8.3582 14.7545 9.01857 14.7545H14.2861C14.5611 14.7545 14.7846 14.531 14.7846 14.256V10.6278C14.7846 10.3529 14.5611 10.1293 14.2861 10.1293H9.36546C8.51751 10.1293 7.82373 10.8231 7.82373 11.6711V11.6736Z"
          fill="#736DE4"
        />
        <path
          d="M37.5659 17.2394H18.3483C17.7522 17.2394 17.269 17.7226 17.269 18.3186V20.734C17.269 21.33 17.7522 21.8132 18.3483 21.8132H37.5659C38.1619 21.8132 38.6451 21.33 38.6451 20.734V18.3186C38.6451 17.7226 38.1619 17.2394 37.5659 17.2394Z"
          fill="#736DE4"
        />
        <path
          d="M37.0931 10.1318H18.4125C17.7804 10.1318 17.269 10.6432 17.269 11.2753V13.611C17.269 14.2431 17.7804 14.7544 18.4125 14.7544H37.5042C38.1363 14.7544 38.6477 14.2431 38.6477 13.611V11.6838C38.6477 10.8282 37.9488 10.1293 37.0931 10.1293V10.1318Z"
          fill="#736DE4"
        />
        <path
          d="M18.413 35.9816H26.8874C27.5195 35.9816 28.0308 35.4702 28.0308 34.8381V31.4823C28.0308 31.4566 28.0334 31.4335 28.0334 31.4078H18.4104C17.7783 31.4078 17.267 31.9191 17.267 32.5512V34.8381C17.267 35.4702 17.7783 35.9816 18.4104 35.9816H18.413Z"
          fill="#736DE4"
        />
        <path
          d="M38.6477 25.328C38.6477 24.7602 38.1877 24.3002 37.6199 24.3002H18.3765C17.765 24.3002 17.269 24.7961 17.269 25.4077V27.8154C17.269 28.4269 17.765 28.9228 18.3765 28.9228H28.1896C28.5314 28.9228 28.8449 28.7532 29.053 28.4834C29.9189 27.3605 31.2525 26.6359 32.7531 26.6359H38.6451"
          fill="#736DE4"
        />
        <path
          d="M30.5181 31.4823V35.8865C34.2336 35.3649 37.2734 32.6694 38.2832 29.1209H32.7536C31.5202 29.1209 30.5155 30.1795 30.5155 31.4823H30.5181Z"
          fill="#736DE4"
        />
        <path
          d="M15 25C15 25 16.8598 29 22.9199 29C28.98 29 31 25 31 25"
          stroke="#351A8A"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </g>
      <path
        d="M12.7192 2L13.4961 4.6637L15.4384 5.49611L13.4961 6.32853L12.7192 8.99221L11.9423 6.32853L10 5.49611L11.9423 4.6637L12.7192 2Z"
        fill="#D2CEF8"
        stroke="#D2CEF8"
        strokeWidth="1.24408"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 7L7.42856 11.5714L11 13L7.42856 14.4286L6 19L4.57144 14.4286L1 13L4.57144 11.5714L6 7Z"
        fill="#351A8A"
        stroke="#351A8A"
        strokeWidth="1.24408"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="15.1539" cy="19.1539" r="3.15393" fill="#351A8A" />
      <path
        d="M14.2849 19.8028C14.8876 19.8028 15.3761 19.3143 15.3761 18.7117C15.3761 18.109 14.8876 17.6205 14.2849 17.6205C13.6823 17.6205 13.1937 18.109 13.1937 18.7117C13.1937 19.3143 13.6823 19.8028 14.2849 19.8028Z"
        fill="white"
      />
      <circle cx="31.1879" cy="19.1539" r="3.15393" fill="#351A8A" />
      <path
        d="M30.3191 19.8028C30.9217 19.8028 31.4103 19.3143 31.4103 18.7117C31.4103 18.109 30.9217 17.6205 30.3191 17.6205C29.7164 17.6205 29.2279 18.109 29.2279 18.7117C29.2279 19.3143 29.7164 19.8028 30.3191 19.8028Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_43_87"
          x1="43.615"
          y1="11.2492"
          x2="5.77898"
          y2="42.5196"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8E84EE" />
          <stop offset="1" stopColor="#351A8A" />
        </linearGradient>
        <clipPath id="clip0_43_87">
          <rect width="40.6763" height="34.8198" fill="white" transform="translate(5 7.35432)" />
        </clipPath>
      </defs>
    </Icon48>
  );
};

const PostyMediumGray: FC = () => {
  return (
    <Icon48>
      <path
        d="M28.0608 43.7437H14.9199C9.4413 43.7437 5 39.3023 5 33.8237V17.1349C5 11.7332 9.3789 7.35432 14.7806 7.35432H35.3625C38.1389 7.35432 40.8159 8.38758 42.8724 10.2529L44.8474 12.0444C45.8693 12.9713 46.4521 14.2868 46.4521 15.6665V25.3523C46.4521 35.5096 38.218 43.7437 28.0608 43.7437Z"
        fill="url(#paint0_linear_43_137)"
      />
      <g clipPath="url(#clip0_43_137)">
        <path
          d="M35.4772 7.35432H10.992C7.6979 7.35432 4.99988 10.0448 4.99988 13.3297V32.6933C4.99988 35.9808 7.6979 38.6687 10.992 38.6687H26.8667C34.9325 38.6687 41.472 32.1476 41.472 24.1044V13.3297C41.472 10.0422 38.7739 7.35432 35.4798 7.35432H35.4772Z"
          fill="#D7D3DC"
        />
        <path
          d="M7.82373 34.45C7.82373 35.2928 8.51237 35.984 9.35775 35.984H13.7157C14.3067 35.984 14.7846 35.5061 14.7846 34.9151V32.4791C14.7846 31.8881 14.3067 31.4102 13.7157 31.4102H8.89266C8.30167 31.4102 7.82373 31.8881 7.82373 32.4791V34.45Z"
          fill="#F3F2F5"
        />
        <path
          d="M13.6951 24.3002H8.91322C8.31151 24.3002 7.82373 24.788 7.82373 25.3897V27.8333C7.82373 28.4351 8.31151 28.9228 8.91322 28.9228H13.6951C14.2969 28.9228 14.7846 28.4351 14.7846 27.8333V25.3897C14.7846 24.788 14.2969 24.3002 13.6951 24.3002Z"
          fill="#F3F2F5"
        />
        <path
          d="M13.7054 17.2394H8.90294C8.30691 17.2394 7.82373 17.7226 7.82373 18.3186V20.734C7.82373 21.33 8.30691 21.8132 8.90294 21.8132H13.7054C14.3015 21.8132 14.7846 21.33 14.7846 20.734V18.3186C14.7846 17.7226 14.3015 17.2394 13.7054 17.2394Z"
          fill="#F3F2F5"
        />
        <path
          d="M7.82373 11.6736V13.5597C7.82373 14.2201 8.3582 14.7545 9.01857 14.7545H14.2861C14.5611 14.7545 14.7846 14.531 14.7846 14.256V10.6278C14.7846 10.3529 14.5611 10.1293 14.2861 10.1293H9.36546C8.51751 10.1293 7.82373 10.8231 7.82373 11.6711V11.6736Z"
          fill="#F3F2F5"
        />
        <path
          d="M37.5659 17.2394H18.3483C17.7522 17.2394 17.269 17.7226 17.269 18.3186V20.734C17.269 21.33 17.7522 21.8132 18.3483 21.8132H37.5659C38.1619 21.8132 38.6451 21.33 38.6451 20.734V18.3186C38.6451 17.7226 38.1619 17.2394 37.5659 17.2394Z"
          fill="#F3F2F5"
        />
        <path
          d="M37.0931 10.1318H18.4125C17.7804 10.1318 17.269 10.6432 17.269 11.2753V13.611C17.269 14.2431 17.7804 14.7544 18.4125 14.7544H37.5042C38.1363 14.7544 38.6477 14.2431 38.6477 13.611V11.6838C38.6477 10.8282 37.9488 10.1293 37.0931 10.1293V10.1318Z"
          fill="#F3F2F5"
        />
        <path
          d="M18.413 35.9816H26.8874C27.5195 35.9816 28.0308 35.4702 28.0308 34.8381V31.4823C28.0308 31.4566 28.0334 31.4335 28.0334 31.4078H18.4104C17.7783 31.4078 17.267 31.9191 17.267 32.5512V34.8381C17.267 35.4702 17.7783 35.9816 18.4104 35.9816H18.413Z"
          fill="#F3F2F5"
        />
        <path
          d="M38.6477 25.328C38.6477 24.7602 38.1877 24.3002 37.6199 24.3002H18.3765C17.765 24.3002 17.269 24.7961 17.269 25.4077V27.8154C17.269 28.4269 17.765 28.9228 18.3765 28.9228H28.1896C28.5314 28.9228 28.8449 28.7532 29.053 28.4834C29.9189 27.3605 31.2525 26.6359 32.7531 26.6359H38.6451"
          fill="#F3F2F5"
        />
        <path
          d="M30.5181 31.4823V35.8865C34.2336 35.3649 37.2734 32.6694 38.2832 29.1209H32.7536C31.5202 29.1209 30.5155 30.1795 30.5155 31.4823H30.5181Z"
          fill="#F3F2F5"
        />
        <path
          d="M13.8184 19.285C13.8184 19.285 14.4816 20.8411 16.6428 20.8411C18.8039 20.8411 19.5243 19.285 19.5243 19.285"
          stroke="#A9A0B3"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M25.2302 19.285C25.2302 19.285 25.8935 20.8411 28.0546 20.8411C30.2158 20.8411 30.9362 19.285 30.9362 19.285"
          stroke="#A9A0B3"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M15 25C15 25 16.8598 29 22.9199 29C28.98 29 31 25 31 25"
          stroke="#A9A0B3"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </g>
      <path
        d="M12.7192 2L13.4961 4.6637L15.4384 5.49611L13.4961 6.32853L12.7192 8.99221L11.9423 6.32853L10 5.49611L11.9423 4.6637L12.7192 2Z"
        fill="#A9A0B3"
        stroke="#A9A0B3"
        strokeWidth="1.24408"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 7L7.42856 11.5714L11 13L7.42856 14.4286L6 19L4.57144 14.4286L1 13L4.57144 11.5714L6 7Z"
        fill="#C9C3CF"
        stroke="#C9C3CF"
        strokeWidth="1.24408"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_43_137"
          x1="43.615"
          y1="11.2492"
          x2="5.77898"
          y2="42.5196"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D7D3DC" />
          <stop offset="1" stopColor="#B1A8B9" />
        </linearGradient>
        <clipPath id="clip0_43_137">
          <rect width="40.6763" height="34.8198" fill="white" transform="translate(5 7.35432)" />
        </clipPath>
      </defs>
    </Icon48>
  );
};

export default { Upload, PostyMedium, PostyMediumGray };
