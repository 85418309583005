import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const TableBody = styled.div``;

export const TableRow = styled.div`
  display: flex;
  align-items: flex-start;
  border-top: 1px solid #d7d3dc;
  padding: 8px 16px;
  cursor: pointer;
`;

export const TableItem = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1 0;
  font-size: 14px;
  font-weight: 500;
  padding-left: 20px;

  &:first-child {
    overflow: hidden;
    padding: 0;
  }

  &:last-child {
    flex: 2 1 0;
    margin-top: 5px;
  }
`;

export const TextAreaField = styled.textarea`
  width: 100%;
  height: 31px;
  padding: 5px 0;
  outline: none;
  font-weight: 500;
  background: transparent;
  border-radius: 3px;
  resize: none;
  cursor: pointer;
`;

export const TagName = styled.div<{ color?: string; withoutCheckbox?: boolean }>`
  max-width: 240px;
  margin: 0 12px 0 0px;
  margin-right: 12px;
  margin-left: ${({ withoutCheckbox }) => (withoutCheckbox ? '10px' : 0)};
  border-radius: 3px;
  padding: 5px;
  background-color: ${({ color }) => (color ? color : '#d8c0f880')};
  word-wrap: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const Placeholder = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: #3b265166;
  opacity: 0.7;
`;

export const CheckBoxWrapper = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
`;

export const ColorCircle = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: ${({ color }) => color};
  margin-left: 8px;
`;

export const TagNumber = styled.div`
  font-size: 14px;
  line-height: 24px;
  color: rgba(59, 38, 81, 0.4);
`;
