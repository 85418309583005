import styled from 'styled-components';

type PortalCoordinates = {
  left: number;
  top: number;
  bottom: number | null;
};

export const Wrapper = styled.div<{ coordinates: PortalCoordinates; visible?: boolean }>`
  position: absolute;
  z-index: 9999;
  left: ${({ coordinates }) => coordinates.left}px;
  ${({ coordinates }) =>
    coordinates.bottom ? `bottom: ${coordinates.bottom}px;` : `top: ${coordinates.top}px;`}

  ${({ visible }) =>
    !visible &&
    `
    opacity: 0;
  `}
`;
