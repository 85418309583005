import { DEFAULT_STEP, ID, Sticky, STICKY_WIDTH, Tag } from '../Models';
import { Reference, useApolloClient, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  CREATE_NOTE,
  DELETE_NOTE,
  RUN_SENTIMENT_ANALYSIS,
  UPDATE_NOTE,
} from '../GraphQL/mutations';
import { NoteInput } from '../GraphQL/__generated__/globalTypes';
import {
  FETCH_NOTES,
  FETCH_NOTE,
  FETCH_THEMES,
  FETCH_NOTES_WITH_FILTERS,
  FETCH_HIGHLIGHTS,
} from '../GraphQL/queries';
import useThemes from './useThemes';
import { TInnerHighlight } from '../Context/HighlightsContext';
import { calcStartPosition } from '../Utils/canvas';
import { NoteForCard } from '../Components/Notes/NoteCard';
import { useContext } from 'react';
import { ProjectChecklistContext } from '../Context/ProjectChecklistContext';
import { uniq, xor } from 'lodash-es';
import useAnalytics from './useAnalytics';

export default function useNotes(dashboardId: ID = 0) {
  const { analytics } = useAnalytics();
  const [createNoteMutation] = useMutation(CREATE_NOTE);
  const [updateNoteMutation] = useMutation(UPDATE_NOTE);
  const [deleteNoteMutation] = useMutation(DELETE_NOTE);
  const [fetch, { loading, data }] = useLazyQuery(FETCH_NOTES);
  const client = useApolloClient();
  const [runSentimentAnalysisMutation] = useMutation(RUN_SENTIMENT_ANALYSIS);
  const { searchThemeByText } = useThemes(dashboardId ?? 1);
  const { notesAdded, markNotesAdded } = useContext(ProjectChecklistContext);

  function mapToId(stickies: Sticky[]): { [key: string]: Sticky } {
    return stickies.reduce((a, x: Sticky) => ({ ...a, [x.id]: x }), {});
  }

  async function runSentimentAnalysis(noteId: ID, refetch = true) {
    const result = await runSentimentAnalysisMutation({
      variables: {
        noteId,
      },
      refetchQueries: refetch
        ? [
            {
              query: FETCH_NOTES,
              variables: {
                condition: {
                  dashboardId,
                },
              },
            },
          ]
        : [],
    });
    return result?.data?.score;
  }

  function fetchNotes(dashboardId: ID) {
    fetch({
      variables: {
        condition: {
          dashboardId,
        },
      },
    });
    return [loading, data];
  }

  async function deleteNote(id: ID) {
    await deleteNoteMutation({
      variables: { id },
      update(cache) {
        cache.modify({
          fields: {
            notes(existingRefs, { readField }) {
              return existingRefs.filter((ref: Reference) => id !== readField('id', ref));
            },
          },
        });
      },
    });
  }

  async function updateNote(stickyId: ID, input: NoteInput, refetch?: boolean): Promise<Sticky> {
    const record = await updateNoteMutation({
      variables: { id: stickyId, input },
      refetchQueries: refetch
        ? [
            {
              query: FETCH_NOTES,
              variables: {
                condition: {
                  dashboardId,
                },
              },
            },
          ]
        : [],
      update(
        cache,
        {
          data: {
            updateNote: { note },
          },
        }
      ) {
        cache.modify({
          id: cache.identify({
            __typename: 'Note',
            id: stickyId,
          }),
          fields: {
            theme(existing, { toReference }) {
              if (note.themeId) {
                return toReference({
                  __typename: 'Theme',
                  id: note.themeId,
                });
              }
              if (note.themeId === note) {
                return null;
              }
              if (note.theme) {
                return toReference(note.theme);
              }
              return existing;
            },
            participant(existing, { toReference }) {
              if (note.participantId) {
                return toReference({
                  __typename: 'Participant',
                  id: note.participantId,
                });
              }
              if (note.participantId === null) {
                return null;
              }
              return existing;
            },
          },
        });
      },
    });

    return record.data?.updateNote?.note;
  }

  async function createNote(dashboardId: ID, note: NoteInput): Promise<Sticky> {
    note = {
      x: Math.ceil(Math.random() * 1000) + 300,
      y: Math.ceil(Math.random() * 600),
      ...note,
    };

    const record = await createNoteMutation({
      variables: {
        input: {
          dashboardId,
          ...note,
        },
      },
      // optimisticResponse: { // commenting this out, causes re-rendering of the grid and canvas
      //   createNote: {
      //     note: {
      //       __typename: 'Note',
      //       id: -Math.random() * 1000,
      //       text: '',
      //       tagsByItemId: [],
      //       theme: null,
      //       color: null,
      //       dashboardId,
      //       userByCreatedBy: null,
      //       sentimentScore: null,
      //       createdAt: null,
      //       url: null,
      //       ...note,
      //     },
      //   },
      // },
      update(
        cache,
        {
          data: {
            createNote: { note },
          },
        }
      ) {
        cache.modify({
          fields: {
            notes(notes, { toReference }) {
              cache.writeQuery({
                query: FETCH_NOTES,
                variables: { condition: { dashboardId } },
                data: {
                  notes: [...notes, toReference(note)],
                },
              });
              return [...notes, toReference(note)];
            },
          },
        });
      },
    });
    analytics.sendEvent('Hooks_NoteCreated', { dashboardId });
    return record.data?.createNote?.note;
  }

  function searchStickyByText(
    items: Sticky[],
    query: string,
    tags?: Tag[],
    themeIds?: number[],
    participantIds?: string[]
  ): Sticky[] {
    let filteredStickies = items;

    if (tags && tags.length) {
      filteredStickies = filteredStickies.filter((sticky) =>
        sticky.tagsList
          ?.map((el: Tag) => el.id)
          .some((r) => tags.map((el: Tag) => el.id).indexOf(r) >= 0)
      );
    }

    if (themeIds && themeIds.length) {
      filteredStickies = filteredStickies.filter(
        (x) => x.theme && searchThemeByText([x.theme], query, themeIds).length > 0
      );
    }

    if (participantIds && participantIds.length) {
      filteredStickies = filteredStickies.filter(
        (x) => x.participant && participantIds.includes(x.participant.id)
      );
    }

    if (!query) {
      return filteredStickies;
    }

    const lcQuery = query.toLowerCase();
    return filteredStickies.filter(
      (x) =>
        x.text?.toLowerCase().includes(lcQuery) ||
        x.tagsList.filter((x: Tag) => x.name?.toLowerCase().includes(lcQuery)).length
    );
  }

  async function fetchNotesByTranscriptId(dashboardId: ID, transcriptionId: ID) {
    const {
      data: { notes },
    } = await client.query({
      query: FETCH_NOTES,
      variables: {
        condition: {
          dashboardId,
          transcriptionId,
        },
      },
    });

    return notes;
  }

  const createNotesFromEntitiesWithTags = async (
    highlights: TInnerHighlight[],
    options: {
      transcriptId?: ID;
      documentId?: ID;
      participantId?: ID;
      tags?: Tag[];
      force?: boolean;
      getLinkedParticipant?: (speakerId: string) => { id: string; tagsList: any[] } | null;
    }
  ) => {
    const getParticipantId = (highlight: TInnerHighlight) => {
      if (highlight.speakerId && !options?.documentId) {
        const linkedParticipant = options?.getLinkedParticipant
          ? options.getLinkedParticipant(highlight.speakerId)
          : null;

        return linkedParticipant?.id;
      }
      return options.participantId || null;
    };
    const getParticipantTags = (highlight: TInnerHighlight) => {
      if (highlight.speakerId) {
        const linkedParticipant = options?.getLinkedParticipant
          ? options.getLinkedParticipant(highlight.speakerId)
          : null;
        return linkedParticipant?.tagsList || [];
      }
      return [];
    };

    const {
      data: { highlights: existingHighlights },
    } = await client.query({
      query: FETCH_HIGHLIGHTS,
      variables: {
        condition: {
          documentId: options.documentId,
          transcriptionId: options.transcriptId,
          dashboardId,
        },
      },
      fetchPolicy: 'network-only',
    });

    const patchedHighlights = highlights.map((highlight) => {
      const existingHighlight = existingHighlights.find((h: any) => h.id === highlight.id);
      return {
        ...highlight,
        notes: existingHighlight?.notesByHiglightId,
      };
    });

    const {
      data: { notes: existingNotes },
    } = await client.query({
      query: FETCH_NOTES,
      variables: {
        condition: {
          dashboardId,
        },
      },
    });
    const {
      data: { themes },
    } = await client.query({
      query: FETCH_THEMES,
      variables: {
        dashboardId,
      },
    });

    if (!options.transcriptId && !options.documentId) return;
    const highlightsToAdd = options.force
      ? highlights
      : patchedHighlights.filter(
          (highlight) => !highlight.addedToAnalysis || highlight?.notes?.length === 0
        );

    const highlightsToAddIds = highlightsToAdd.map((highlight: any) => highlight.id);
    const highlightsToUpdate = patchedHighlights.filter(
      (highlight) => !highlightsToAddIds.includes(highlight.id)
    );

    const notesToUpdate = [];

    for (const key in highlightsToUpdate) {
      if (!highlightsToUpdate[key].notes) continue;
      const participantId = getParticipantId(highlightsToUpdate[key]);

      const participantTags = getParticipantTags(highlightsToUpdate[key]);

      const allTagIds = uniq(
        [...(highlightsToUpdate[key].tags || []), ...participantTags, ...(options.tags || [])].map(
          (tag) => tag.id
        )
      );

      for (const note of highlightsToUpdate[key].notes || []) {
        if (note.participantId !== participantId) {
          notesToUpdate.push({
            id: note.id,
            input: { participantId },
          });
        }
        const noteTagIds = note.tagsList.map((tag: any) => tag.id);
        if (xor(noteTagIds, allTagIds).length > 0) {
          // const tagIdsToAdd = union(noteTagIds, allTagIds);
          notesToUpdate.push({
            id: note.id,
            input: {
              tagsNotes: {
                deleteOthers: true,
                create: allTagIds?.map((tagId) => ({ tagId: tagId })) || [],
              },
            },
          });
        }
      }
    }

    const notes = [];

    for (const key in highlightsToAdd) {
      const allTagIds = [...(highlightsToAdd[key].tags || []), ...(options.tags || [])].map(
        (tag) => tag.id
      );

      const startTime = highlightsToAdd[key].startTime || 0;
      const timeOffset = Math.floor(startTime / 1000);
      const endTime = (highlightsToAdd[key].endTime || 0) / 1000;

      notes.push({
        transcriptionId: options.transcriptId,
        documentId: options.documentId,
        participantId: getParticipantId(highlightsToAdd[key]),
        higlightId: highlightsToAdd[key].id,
        text: highlightsToAdd[key].texts.join(' '),
        tagsNotes: {
          create: allTagIds?.map((tagId) => ({ tagId: tagId })) || [],
        },
        options: JSON.stringify({
          timeOffset,
          endTime,
        }),
      });
    }

    if (notes.length === 0 && notesToUpdate.length === 0) return;

    const startPosition = calcStartPosition(existingNotes, themes);

    const notesWithPositions = notes.map((note, index) => {
      return {
        ...note,
        x: startPosition.x + (index % 5) * (STICKY_WIDTH + 32),
        y: startPosition.y + Math.floor(index / 5) * (STICKY_WIDTH + 32),
      };
    });

    await Promise.all(notesWithPositions.map((note) => createNote(dashboardId, note)));
    await Promise.all(notesToUpdate.map((item) => updateNote(item.id, item.input)));
    if (!notesAdded) {
      markNotesAdded();
    }
  };

  return {
    fetchNotes,
    deleteNote,
    updateNote,
    createNote,
    mapToId,
    searchStickyByText,
    runSentimentAnalysis,
    createNotesFromEntitiesWithTags,
    fetchNotesByTranscriptId,
  };
}

export function useFetchNote(id: ID): [boolean, any] {
  const { data, loading } = useQuery(FETCH_NOTE, {
    variables: { id },
  });
  return [loading, data?.note];
}

export const useCopyNote = (): [(noteId: string, targetDashboardId?: string) => Promise<any>] => {
  const client = useApolloClient();
  const [createNoteMutation] = useMutation(CREATE_NOTE);

  return [
    async (noteId: string, targetDashboardId?: string) => {
      const {
        data: { note },
      } = await client.query({
        query: FETCH_NOTE,
        variables: { id: noteId },
      });

      const newNote = {
        x: note.x + STICKY_WIDTH + DEFAULT_STEP,
        y: note.y,
        color: note.color,
        text: note.text,
        dashboardId: targetDashboardId || note.dashboardId,
        tagsNotes: {
          create: note.tagsList?.map((tag: Tag) => ({ tagId: tag.id })) || [],
        },
        customDate: note.customDate,
        documentId: note.documentId,
        participantId: note.participantId,
        sentimentScore: note.sentimentScore,
        themeId: note.themeId,
        transcriptionId: note.transcriptionId,
        url: note.url,
        options: note.options,
      };

      const record = await createNoteMutation({
        variables: {
          input: {
            ...newNote,
          },
        },
        update(
          cache,
          {
            data: {
              createNote: { note },
            },
          }
        ) {
          cache.modify({
            fields: {
              notes(notes, { toReference }) {
                cache.writeQuery({
                  query: FETCH_NOTES,
                  variables: { condition: { dashboardId: newNote.dashboardId } },
                  data: {
                    notes: [...notes, toReference(note)],
                  },
                });
                return [...notes, toReference(note)];
              },
            },
          });
        },
      });
      return record.data?.createNote?.note;
    },
  ];
};

export const useFetchNotes = (filter: any): [boolean, NoteForCard[], () => void] => {
  const { loading, data, refetch } = useQuery(FETCH_NOTES_WITH_FILTERS, {
    variables: { filter },
  });
  return [loading, data?.notes || [], refetch];
};
