import { useMutation } from '@apollo/client';
import { OIDC_DEFAULT_SCOPES } from '@azure/msal-browser';
import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import { AUTHORIZE_ONEDRIVE } from '../../GraphQL/mutations';
import { Icon24 } from '../../Icons/Icon';
import { CloudFile } from '../../Models';
import {
  consumerMSALInstance,
  defaultMSALInstance,
  defaultRedirectURI,
  getToken,
} from '../Integrations/utils/msal';
import Loader from '../Loader';
import Modal from '../Modal';
import {
  IFilePickerOptions,
  IPickData,
  ODSPInit,
  OneDriveConsumerInit,
  Picker,
  SPItem,
} from './sdk-domevents';

// User completed picking items. If pickedItems is undefined, the user clicked cancel.
export type OnPickerCompletedFn = (pickedItems?: SPItem[]) => void;

export type OneDrivePickerProps = {
  onFileSelected?: (files: CloudFile[]) => void;
};

function OneDrivePickerButton(props: OneDrivePickerProps): JSX.Element {
  const [loaded, setLoaded] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [authorizeOneDriveMutation] = useMutation(AUTHORIZE_ONEDRIVE);
  const [iframeRef] = useHookWithRefCallback(
    (pickedItems) => {
      const selectedFiles = pickedItems?.map((item) => {
        let id = item.id;
        if (item.sharepointIds?.siteId?.length) {
          id = `sites/${item.sharepointIds.siteId}/drive/items/${item.id}`;
        }
        return {
          name: item.name,
          size: item.size,
          source: 'onedrive',
          url: item.webDavUrl,
          // TODO map this from the file extension
          mimeType: '',
          sourceId: id,
        } as CloudFile;
      });
      defaultMSALInstance
        .acquireTokenSilent({
          prompt: 'consent',
          scopes: ['api://56a6d9c9-56d4-4f41-a578-c16231016d31/.default', ...OIDC_DEFAULT_SCOPES],
        })
        .then(async (tokenResponse) => {
          await authorizeOneDriveMutation({
            variables: { code: tokenResponse.accessToken, redirectUri: defaultRedirectURI },
          });
          props.onFileSelected?.(selectedFiles || []);
          setPicked(false);
          setModalOpen(false);
        })
        .catch((error) => {
          throw error;
        });
    },
    loaded,
    () => setLoaded(true)
  );

  function handleButtonClick() {
    setModalOpen(true);
  }

  const iframe = <iframe width="100%" height="600" ref={iframeRef} title="OneDrive Picker" />;
  return (
    <>
      <StyledButton onClick={handleButtonClick}>
        <StyledButtonImage>
          <Icon24.OneDriveIcon viewBox="0 0 34 30" />
        </StyledButtonImage>
        <StyledButtonText>Import from OneDrive</StyledButtonText>
        <BetaLabel>Beta</BetaLabel>
      </StyledButton>
      <Modal
        title=""
        isOpen={modalOpen}
        onClose={() => {
          setModalOpen(false);
          setLoaded(false);
        }}
        style={{ content: { width: '99%', maxWidth: '99%' }, overlay: { zIndex: 10000 } }}
      >
        {!loaded && (
          <div>
            <Loader />
          </div>
        )}
        {iframe}
      </Modal>
    </>
  );
}

function useHookWithRefCallback(
  onCompleted: OnPickerCompletedFn,
  loaded: boolean,
  onLoaded: () => void
) {
  const ref = useRef(null);
  const setRef = useCallback((node) => {
    if (ref.current) {
      // TODO cleanup any events/references added to the last instance
    }

    if (node) {
      initPicker(onCompleted, loaded, onLoaded, node.contentWindow);
    }

    // Save a reference to the node
    ref.current = node;
  }, []);

  return [setRef];
}

async function initPicker(
  onCompleted: OnPickerCompletedFn,
  loaded: boolean,
  onLoaded: () => void,
  contentWindow: Window
) {
  const token = await getToken({
    command: 'authenticate',
    type: 'Graph',
    resource: 'https://graph.microsoft.com',
  });

  const { driveType } = await fetchJSON<{ driveType: string }>(
    token,
    'https://graph.microsoft.com/v1.0/drive'
  );

  let pickerParams: OneDriveConsumerInit | ODSPInit;

  const options: IFilePickerOptions = {
    sdk: '8.0',
    entry: {
      oneDrive: {},
    },
    authentication: {},
    messaging: {
      origin: location.origin,
      channelId: '27',
    },
    selection: {
      mode: 'single',
    },
    typesAndSources: {
      filters: ['.mp4', '.mov', '.m4a', '.mp3', '.srt', '.otf', '.txt', '.docx', '.pdf'],
      mode: 'files',
      pivots: {
        oneDrive: true,
        recent: true,
        sharedLibraries: true,
        shared: true,
        search: true,
      },
    },
  };

  if (driveType === 'documentLibrary') {
    console.info('Using SharePoint picker');
    const res = await fetchJSON<{ webUrl: string }>(
      token,
      'https://graph.microsoft.com/v1.0/sites/root'
    );
    pickerParams = {
      type: 'ODSP',
      baseUrl: res.webUrl,
      tokenFactory: getToken,
      options,
    };
  } else {
    console.info('Using OneDrive Consumer picker');
    pickerParams = {
      type: 'Consumer',
      tokenFactory: (cmd) =>
        getToken({ ...cmd, scopes: ['OneDrive.ReadOnly'], msalInstance: consumerMSALInstance }),
      options,
    };
  }

  const picker = await Picker(contentWindow, pickerParams);

  picker.addEventListener('pickernotification', (e) => {
    console.info('[OneDrive Picker: pickernotification]', e.detail);
    // sdk-domevents is typed incorrectly
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((e as any).detail?.data?.notification === 'page-loaded') {
      onLoaded();
    }
  });
  picker.addEventListener('pickerchange', (e) => {
    console.info('[OneDrive Picker: pickerchange]', e.detail);
    // sdk-domevents is typed incorrectly
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data = (e as any).detail?.data as IPickData;
    if (!getPicked()) {
      setPicked(true);
      onCompleted(data.items);
    }
  });

  window.addEventListener('close', () => {
    onCompleted();
  });
}

// Home-grown state because the iframe communication is outside of a React component
let picked = false;
function setPicked(p: boolean) {
  picked = p;
}
function getPicked() {
  return picked;
}

async function fetchJSON<T>(token: string, url: string) {
  const res = await fetch(url, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return res.json() as Promise<T>;
}

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  padding: 8px 10px;
  border-radius: 3px;
  border: 1px solid #d7d3dc;
  box-shadow: 0px 1px 0px #d7d3dc, 2px 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #f3f4f6;
  }
`;
export const StyledButtonImage = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
`;

export const StyledButtonText = styled.div`
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
`;

export const BetaLabel = styled.span`
  margin-left: 4px;
  margin-right: 2px;
  padding: 0px 4px;
  gap: 5px;
  border-radius: 3px;
  background: rgba(56, 33, 82, 0.2);
  color: #382152;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
`;

export default OneDrivePickerButton;
