import styled from 'styled-components';

export const SmartIconTooltip = styled.div`
  position: absolute;
  padding: 8px 6px;
  top: -40px;
  left: -202px;
  background: rgba(59, 38, 81, 1);
  color: #ffffff;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  z-index: 999;
  width: 228px;
  display: none;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  flex-shrink: 0;
`;

export const SmartIcon = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  color: #b1a8b9;
  transition: color 0.3s ease;

  &:hover {
    color: #8d83ec;
  }

  &:hover ${SmartIconTooltip} {
    display: flex;
  }
`;

export const TooltipIcon = styled.div`
  margin-right: 5px;
`;
