import styled, { css } from 'styled-components';

export const Container = styled.div<{ sticky?: boolean }>`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  background-color: #ffffff;

  ${({ sticky }) =>
    sticky &&
    css`
      position: sticky;
      top: 49px;
      z-index: 5;
    `}
`;
