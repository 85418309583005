/* eslint-disable @typescript-eslint/no-empty-function */
import React, { FC, useEffect, useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { useCreateProjectChecklist, useUpdateProjectChecklist } from '../Hooks/useProjectChecklist';
import { useApolloClient } from '@apollo/client';
import { CURRENT_USER, FETCH_PROJECT_CHECKLIST } from '../GraphQL/queries';

interface ProjectChecklistContextProps {
  dataAdded: boolean;
  highlightAdded: boolean;
  summaryAdded: boolean;
  insightAdded: boolean;
  notesAdded: boolean;
  customTemplateAdded: boolean;
  teammateAdded: boolean;
  loading: boolean;
  markDataAdded(): void;
  markSummaryAdded(): void;
  markHighlightAdded(): void;
  markNotesAdded(): void;
  markInsightAdded(): void;
  markCustomTemplateAdded(): void;
  markTeammateAdded(): void;
}

type Checklist = {
  id: string;
  dataAdded: boolean;
  highlightAdded: boolean;
  summaryAdded: boolean;
  insightAdded: boolean;
  notesAdded: boolean;
  customTemplateAdded: boolean;
  teammateAdded: boolean;
};

type ChecklistKey =
  | 'dataAdded'
  | 'highlightAdded'
  | 'summaryAdded'
  | 'insightAdded'
  | 'notesAdded'
  | 'customTemplateAdded'
  | 'teammateAdded';

export const ProjectChecklistContext = React.createContext<ProjectChecklistContextProps>({
  dataAdded: false,
  highlightAdded: false,
  insightAdded: false,
  summaryAdded: false,
  notesAdded: false,
  customTemplateAdded: false,
  teammateAdded: false,
  loading: false,
  markDataAdded: () => {},
  markHighlightAdded: () => {},
  markInsightAdded: () => {},
  markSummaryAdded: () => {},
  markNotesAdded: () => {},
  markCustomTemplateAdded: () => {},
  markTeammateAdded: () => {},
});

export const ProjectChecklistContextProvider: FC = ({ children }) => {
  const client = useApolloClient();
  const [checklist, setChecklist] = useState<Checklist | null>(null);
  const createChecklist = useCreateProjectChecklist();
  const updateChecklist = useUpdateProjectChecklist();
  const [userId, setUserId] = useState('');

  const [isHighlightCompleted, setIsHighlightCompleted] = useLocalStorage(
    'ProjectCheckList_highlight-completed',
    false
  );

  const markChecklistValue = async (key: ChecklistKey) => {
    if (!checklist || checklist?.[key]) return;
    await updateChecklist(checklist.id, { [key]: true });
    fetchChecklist(userId);
  };

  const fetchChecklist = async (userId: string) => {
    const {
      data: { projectChecklists },
    } = await client.query({
      query: FETCH_PROJECT_CHECKLIST,
      variables: { createdBy: userId },
    });

    if (projectChecklists.length === 0) {
      if (!userId) return;
      const { projectChecklist: newList } = await createChecklist(userId);
      setChecklist(newList);
    } else {
      setChecklist(projectChecklists[0]);
    }
  };

  const fetchUser = async () => {
    const {
      data: { currentUsr },
    } = await client.query({ query: CURRENT_USER });
    if (!currentUsr) return;
    setUserId(currentUsr.id);
    const isViewer = currentUsr?.role === 'viewer';
    if (isViewer) return;
    fetchChecklist(currentUsr.id);
  };

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    if (isHighlightCompleted && checklist) {
      markChecklistValue('highlightAdded');
      setIsHighlightCompleted(false);
    }
  }, [isHighlightCompleted, checklist]);

  return (
    <ProjectChecklistContext.Provider
      value={{
        dataAdded: !!checklist?.dataAdded,
        highlightAdded: !!checklist?.highlightAdded,
        insightAdded: !!checklist?.insightAdded,
        summaryAdded: !!checklist?.summaryAdded,
        notesAdded: !!checklist?.notesAdded,
        customTemplateAdded: !!checklist?.customTemplateAdded,
        teammateAdded: !!checklist?.teammateAdded,
        loading: !checklist,
        markDataAdded: () => {
          markChecklistValue('dataAdded');
        },
        markHighlightAdded: () => {
          markChecklistValue('highlightAdded');
        },
        markInsightAdded: () => {
          markChecklistValue('insightAdded');
        },
        markSummaryAdded: () => {
          markChecklistValue('summaryAdded');
        },
        markNotesAdded: () => {
          markChecklistValue('notesAdded');
        },
        markCustomTemplateAdded: () => {
          markChecklistValue('customTemplateAdded');
        },
        markTeammateAdded: () => {
          markChecklistValue('teammateAdded');
        },
      }}
    >
      {children}
    </ProjectChecklistContext.Provider>
  );
};
