import { Extension, Range, Dispatch } from '@tiptap/core';
import { Decoration, DecorationSet } from '@tiptap/pm/view';
import { Plugin, PluginKey, Transaction } from '@tiptap/pm/state';
import { Node as PMNode } from '@tiptap/pm/model';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    cleaning: {
      setFillerWords: (fillerWords: string[] | null) => ReturnType;
      setCleaningReplaceTerm: (replaceTerm: string) => ReturnType;
      cleanAll: () => ReturnType;
    };
  }
}

interface TextNodesWithPosition {
  text: string;
  pos: number;
}

interface ProcessedSearches {
  decorationsToReturn: DecorationSet;
  results: Range[];
}

function processSearches(
  doc: PMNode,
  fillerWords: string[],
  searchResultClass: string
): ProcessedSearches {
  const decorations: Decoration[] = [];
  const results: Range[] = [];

  let textNodesWithPosition: TextNodesWithPosition[] = [];
  let index = 0;

  if (!fillerWords || !fillerWords.length) {
    return {
      decorationsToReturn: DecorationSet.empty,
      results: [],
    };
  }

  doc?.descendants((node, pos) => {
    if (node.isText) {
      if (textNodesWithPosition[index]) {
        textNodesWithPosition[index] = {
          text: textNodesWithPosition[index].text + node.text,
          pos: textNodesWithPosition[index].pos,
        };
      } else {
        textNodesWithPosition[index] = {
          text: `${node.text}`,
          pos,
        };
      }
      index += 1;
    } else {
      index += 1;
    }
  });

  textNodesWithPosition = textNodesWithPosition.filter(Boolean);

  for (const element of textNodesWithPosition) {
    const { text, pos } = element;
    const matchesArr = fillerWords.map((fillerWord) => {
      const regex = new RegExp(`(?:^|\\W)${fillerWord}(?:$|\\W)`, 'gui');
      return text.matchAll(regex);
    });

    const duplicateRegex = new RegExp(/\b(\w+)\s+\1\b/, 'gui');
    const duplicateMatches = Array.from(text.matchAll(duplicateRegex)).filter(([matchText]) =>
      matchText.trim()
    );

    const matches = matchesArr
      .map((match) => Array.from(match).filter(([matchText]) => matchText.trim()))
      .flat();

    for (const m of matches) {
      if (m[0] === '') break;

      if (m.index !== undefined) {
        const addedIndex = m[0][m[0].length - 1] === ' ' ? 1 : 0;
        results.push({
          from: pos + m.index,
          to: pos + m.index + m[0].length - addedIndex,
        });
      }
    }

    for (const m of duplicateMatches) {
      if (m[0] === '') break;

      if (m.index !== undefined) {
        results.push({
          from: pos + m.index + m[1].length,
          to: pos + m.index + m[1].length * 2 + 1,
        });
      }
    }
  }

  results.sort((a, b) => a.from - b.from);

  for (let i = 0; i < results.length; i += 1) {
    const r = results[i];
    const decoration: Decoration = Decoration.inline(r.from, r.to, {
      class: searchResultClass,
    });

    decorations.push(decoration);
  }

  return {
    decorationsToReturn: DecorationSet.create(doc, decorations),
    results,
  };
}

const rebaseNextResult = (
  replaceTerm: string,
  index: number,
  lastOffset: number,
  results: Range[]
): [number, Range[]] | null => {
  const nextIndex = index + 1;

  if (!results[nextIndex]) return null;

  const { from: currentFrom, to: currentTo } = results[index];
  const offset = currentTo - currentFrom - replaceTerm.length + lastOffset;
  const { from, to } = results[nextIndex];

  results[nextIndex] = {
    to: to - offset,
    from: from - offset,
  };

  return [offset, results];
};

const cleanAll = (
  replaceTerm: string,
  results: Range[],
  { tr, dispatch }: { tr: Transaction; dispatch: Dispatch }
) => {
  let offset = 0;
  let resultsCopy = results.slice();

  if (!resultsCopy.length) return;

  for (let i = 0; i < resultsCopy.length; i += 1) {
    const { from, to } = resultsCopy[i];

    tr.insertText(replaceTerm, from, to);
    const rebaseNextResultResponse = rebaseNextResult(replaceTerm, i, offset, resultsCopy);

    if (!rebaseNextResultResponse) continue;

    offset = rebaseNextResultResponse[0];
    resultsCopy = rebaseNextResultResponse[1];
  }

  dispatch?.(tr);
};

export const documentCleaningPluginKey = new PluginKey('documentCleaningPlugin');

export interface DocumentCleaningOptions {
  searchResultClass: string;
  disableRegex: boolean;
}

export interface DcoumentCleaningStorage {
  fillerWords: string[] | null;
  replaceTerm: string;
  results: Range[];
  lastFillerWords: string[] | null;
}

export const DocumentCleaning = Extension.create<DocumentCleaningOptions, DcoumentCleaningStorage>({
  name: 'documentCleaning',

  addOptions() {
    return {
      searchResultClass: 'clean-result',
      disableRegex: true,
    };
  },

  addStorage() {
    return {
      fillerWords: null,
      replaceTerm: '',
      results: [],
      lastFillerWords: null,
    };
  },

  addCommands() {
    return {
      setFillerWords: (fillerWords: string[] | null) => ({ editor }) => {
        editor.storage.documentCleaning.fillerWords = fillerWords;

        return false;
      },
      setCleaningReplaceTerm: (replaceTerm: string) => ({ editor }) => {
        editor.storage.documentCleaning.replaceTerm = replaceTerm;

        return false;
      },
      cleanAll: () => ({ editor, tr, dispatch }) => {
        const { replaceTerm, results } = editor.storage.documentCleaning;

        cleanAll(replaceTerm, results, { tr, dispatch });

        return false;
      },
    };
  },

  addProseMirrorPlugins() {
    const editor = this.editor;
    const { searchResultClass } = this.options;

    const setLastFillerWords = (t: string) => (editor.storage.documentCleaning.lastFillerWords = t);

    return [
      new Plugin({
        key: documentCleaningPluginKey,
        state: {
          init: () => DecorationSet.empty,
          apply({ doc, docChanged }, oldState) {
            const { fillerWords, lastFillerWords } = editor.storage.documentCleaning;

            if (!docChanged && lastFillerWords?.toString() === fillerWords?.toString())
              return oldState;

            setLastFillerWords(fillerWords);

            if (!fillerWords || !fillerWords.length) {
              editor.storage.documentCleaning.results = [];
              return DecorationSet.empty;
            }

            const { decorationsToReturn, results } = processSearches(
              doc,
              fillerWords,
              searchResultClass
            );

            if (!results.length) {
              editor.storage.documentCleaning.results = [{ type: 'empty' }];
              return DecorationSet.empty;
              // editor.storage.documentCleaning.fillerWords = null;
            }

            editor.storage.documentCleaning.results = results;
            return decorationsToReturn;
          },
        },
        props: {
          decorations(state) {
            return this.getState(state);
          },
        },
      }),
    ];
  },
});

export default DocumentCleaning;
