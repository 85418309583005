import React, { useMemo, useState } from 'react';
import Modal from '../Modal';
import VideoClip from '../VideoClip';
import styled from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';
import {
  CalendarIcon,
  UserIcon,
  TagIcon,
  EmojiHappyIcon,
  ClockIcon,
  XIcon,
} from '@heroicons/react/outline';
import moment from 'moment';
import Avatar from '../Avatar';
import Loader from '../Loader';
import { Sticky, ID, Theme, Tag } from '../../Models';
import { NoteInput } from '../../GraphQL/__generated__/globalTypes';
import { useFetchFile } from '../../Hooks/useFiles';
import ParticipantPicker from '../ParticipantPicker';
import TagsPicker from '../TagsPicker';
import { ThemeIcon } from '../../Icons';
import SentimentPicker from '../SentimentPicker';
import ThemePicker from '../ThemePicker';
import usePermissions from '../../Hooks/usePermissions';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Button from '../Button';
import InternalSharing from '../InternalSharing';
import { Link } from 'react-router-dom';
import { Icon24 } from '../../Icons/Icon';

const fmtSS = (s: number): string => {
  const date = new Date(0);
  date.setSeconds(s);
  const timeString = date.toISOString().substring(11, 19);
  if (timeString.substring(0, 2) === '00') return timeString.substring(3);
  return timeString;
};

const EditorDetailsRow = ({
  IconComponent,
  label,
  children,
}: React.PropsWithChildren<{
  IconComponent: React.ReactNode;
  label: string;
}>): JSX.Element => {
  return (
    <DetailsRow>
      <LabelWithIcon>
        <IconContainer>{IconComponent}</IconContainer>
        <Label>{label}</Label>
      </LabelWithIcon>
      {children}
    </DetailsRow>
  );
};

export const FileViewer = ({
  fileId,
  offset,
  endTime,
  editable,
  onSeek,
  onUpdateNote,
}: {
  fileId: string;
  offset: number;
  endTime?: number | null;
  editable?: boolean;
  onSeek?: (s: number) => void | Promise<void>;
  onUpdateNote?: (input: NoteInput) => Promise<Sticky>;
}): JSX.Element => {
  const [loading, file] = useFetchFile(fileId);
  if (loading) return <Loader />;
  return (
    <VideoClip
      src={file?.signedVideoUrl}
      offset={offset}
      endTime={endTime}
      onSeek={onSeek}
      onUpdateNote={onUpdateNote}
      editable={editable}
      disableMinimization
    />
  );
};

export default function StickyModal({
  sticky,
  onClose,
  updateNote,
  updateTags,
  themes,
}: {
  sticky: Sticky;
  onClose: () => void;
  updateNote: (stickyId: ID, input: NoteInput, refetch?: boolean) => Promise<Sticky>;
  updateTags: (stickyId: ID, tags: Tag[] | null) => Promise<void>;
  themes: Theme[];
}): JSX.Element {
  const options = sticky.options ? JSON.parse(sticky.options) : {};
  const { canEditNotes } = usePermissions();
  const [showVideoEditor, setShowVideoEditor] = useState<boolean>(false);
  const [isSavingClip, setIsSavingClip] = useState<boolean>(false);
  const [currentSeek, setCurrentSeek] = useState<number>(options?.timeOffset || 0);

  const hasVideo = !!sticky.transcription?.file?.id;

  const getSource = useMemo(() => {
    if (sticky.transcription) {
      return {
        href: `/projects/${sticky.dashboardId}/data/${sticky.transcription.id}`,
        name: sticky.transcription.name,
      };
    } else if (sticky.document) {
      return {
        href: `/projects/${sticky.dashboardId}/data/docs/${sticky.document.id}`,
        name: sticky.document.name,
      };
    }
    return null;
  }, [sticky]);

  return (
    <Modal
      title={''}
      showTitle={false}
      isOpen={true}
      style={{ overlay: { zIndex: 1000 }, content: { maxWidth: '700px' } }}
      onClose={onClose}
    >
      <div className="flex justify-end items-center mb-3">
        <div className="mr-3">
          <InternalSharing
            type="secondary"
            link={`${window.location.origin}/projects/${sticky.dashboardId}/notes/${sticky.id}`}
          />
        </div>
        <button onClick={() => onClose?.()}>
          <XIcon className="w-6 h-6" />
        </button>
      </div>
      <div className="text-primary-purple">
        {sticky.transcription?.file?.id && (
          <div className="mb-4">
            <FileViewer
              fileId={sticky.transcription?.file?.id}
              offset={options?.timeOffset || 0}
              endTime={options?.endTime || null}
              onSeek={setCurrentSeek}
              onUpdateNote={async (input: NoteInput) => await updateNote(sticky.id, input, true)}
              editable={canEditNotes}
            />
          </div>
        )}
        <Details>
          <TitleInput
            defaultValue={sticky.text}
            onBlur={async (e) => await updateNote(sticky.id, { text: e.target.value }, true)}
            onKeyDown={(e) => e.code === 'Enter' && e.currentTarget.blur()}
            placeholder="Add text"
            className={'text-3xl mb-2 w-full font-medium'}
            autoFocus={false}
            readOnly={!canEditNotes}
          />
          {!showVideoEditor && (
            <>
              {hasVideo && (
                <>
                  <EditorDetailsRow
                    label="Video start"
                    IconComponent={<ClockIcon height="24" width="24" />}
                  >
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        setCurrentSeek(options?.timeOffset || 0);
                        setShowVideoEditor(true);
                      }}
                    >
                      {fmtSS(options?.timeOffset || 0)}
                    </div>
                  </EditorDetailsRow>

                  {options?.endTime && (
                    <EditorDetailsRow
                      label="Video end"
                      IconComponent={<ClockIcon height="24" width="24" />}
                    >
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          setShowVideoEditor(true);
                        }}
                      >
                        {fmtSS(options.endTime || 0)}
                      </div>
                    </EditorDetailsRow>
                  )}
                </>
              )}

              <EditorDetailsRow label="Theme" IconComponent={<ThemeIcon height="24" width="24" />}>
                <ThemePicker
                  themes={themes}
                  value={sticky.theme}
                  onChange={async (value) => {
                    await updateNote(sticky.id, { themeId: value }, true);
                  }}
                  sticky={sticky}
                  readOnly={!canEditNotes}
                />
              </EditorDetailsRow>
              <EditorDetailsRow label="Tags" IconComponent={<TagIcon />}>
                <Placeholder>
                  <TagsPicker
                    tags={sticky.tagsList}
                    onChange={async (tags) => {
                      await updateTags(sticky.id, tags);
                    }}
                    dashboardId={sticky.dashboardId}
                    readOnly={!canEditNotes}
                    selectedText={sticky.text}
                  />
                </Placeholder>
              </EditorDetailsRow>
              <EditorDetailsRow label="Participant" IconComponent={<UserIcon />}>
                <ParticipantPicker
                  participant={sticky.participant}
                  onChange={async (participantId: string | null) =>
                    await updateNote(sticky.id, { participantId }, true)
                  }
                  readOnly={!canEditNotes}
                />
              </EditorDetailsRow>
              <EditorDetailsRow label="Sentiment" IconComponent={<EmojiHappyIcon />}>
                <SentimentPicker
                  sentimentScore={sticky.sentimentScore}
                  onChange={async (score) => {
                    await updateNote(sticky.id, { sentimentScore: score }, true);
                  }}
                  readOnly={!canEditNotes}
                />
              </EditorDetailsRow>
              <EditorDetailsRow label="Date" IconComponent={<CalendarIcon />}>
                <div className="cursor-pointer">
                  <DatePicker
                    onChange={async (date) => {
                      await updateNote(sticky.id, { customDate: date }, true);
                    }}
                    customInput={
                      <div>
                        {moment(sticky?.customDate || sticky?.createdAt).format('MM/DD/YYYY')}
                      </div>
                    }
                    selected={new Date(sticky?.customDate || sticky?.createdAt)}
                    popperPlacement="bottom"
                  />
                </div>
              </EditorDetailsRow>
              <EditorDetailsRow label="Created by" IconComponent={<UserIcon />}>
                <Avatar user={sticky.userByCreatedBy} />
                <UserName>{sticky.userByCreatedBy?.name}</UserName>
              </EditorDetailsRow>
              {getSource && (
                <EditorDetailsRow label="Source" IconComponent={<Icon24.Link />}>
                  <Link to={getSource.href}>{getSource.name}</Link>
                </EditorDetailsRow>
              )}
            </>
          )}
          {showVideoEditor && (
            <div className="flex flex-1 items-center text-primary-purple flex-col font-medium">
              <div className="flex border border-primary-purple-light rounded-md px-3 py-1">
                <ClockIcon height="24" width="24" />
                <span className="ml-2">Video start:</span>
                <span className="ml-2">{fmtSS(currentSeek)}</span>
              </div>
              <div className="font-medium mt-4">
                Adjust your video clip by seeking to the time you want it to start.
              </div>
              <div className="flex mt-4 mb-10 justify-center">
                <Button
                  type={'secondary'}
                  onClick={() => {
                    setShowVideoEditor(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type={'primary'}
                  className="ml-2 min-w-[124px]"
                  disabled={isSavingClip}
                  onClick={async () => {
                    setIsSavingClip(true);
                    await updateNote(
                      sticky.id,
                      {
                        options: JSON.stringify({
                          ...options,
                          timeOffset: currentSeek,
                        }),
                      },
                      true
                    );
                    setShowVideoEditor(false);
                    setIsSavingClip(false);
                  }}
                >
                  {isSavingClip ? 'Saving...' : 'Save video clip'}
                </Button>
              </div>
            </div>
          )}
        </Details>
      </div>
    </Modal>
  );
}

const TitleInput = styled(TextareaAutosize)`
  font-size: 24px;
  outline: none;
  transition: 0.3s ease 0s;
`;
interface DetailsProps {
  readonly maxWidth?: number;
}
const Details = styled.div<DetailsProps>`
  padding-bottom: 16px;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth + 'px' : '800px')};
  margin: auto;
`;

const DetailsRow = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  align-items: center;

  & a {
    text-decoration: underline;
  }
`;

const LabelWithIcon = styled.div`
  display: flex;
  width: 144px;
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
  color: ${(props) => props.theme.colors.primary.purple};
`;

const IconContainer = styled.div`
  height: 24px;
  width: 24px;
  margin: 4px 8px 4px 0;
`;

const UserName = styled.span`
  margin-left: 8px;
`;

const Placeholder = styled.div`
  color: ${(props) => props.theme.colors.text.placeholder2};
  cursor: pointer;
`;
