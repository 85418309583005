import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

export const TemplateIcon = styled.div<{ src: string }>`
  position: relative;
  width: 68px;
  height: 68px;
  margin-right: 10px;
  background-image: url(${({ src }) => src});
  background-size: contain;
  background-repeat: no-repeat;
  flex-shrink: 0;
  cursor: pointer;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px 30px;
  border-bottom: 1px solid #d7d3dc;
`;

export const LeftSide = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TemplateName = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
`;

export const TemplateDescription = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;

export const RightSide = styled.div`
  flex-shrink: 0;
  margin-left: 20px;
`;

export const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px 0;
  margin-bottom: 24px;
`;

export const CreatedBy = styled.div`
  display: flex;
  align-items: center;
`;

export const IconWrapper = styled.div`
  color: rgba(59, 38, 81, 0.6);
  margin-right: 5px;
`;

export const CreatedByText = styled.div`
  color: rgba(59, 38, 81, 0.6);
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  margin-right: 20px;
`;

export const InputContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 0 20px 20px;
  flex: 1;
`;

export const OutputResult = styled.div`
  display: flex;
  flex: 1;
  border: 1px solid #d7d3dc;
  border-radius: 6px;
  height: 100%;
  overflow-y: auto;
`;

export const CtaOverlay = styled.div`
  position: absolute;
  bottom: 27px;
  left: 22px;
  right: 22px;
  height: 60%;
  border-radius: 0 0 4px 4px;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px -40px 30px 0px #fafafa;
`;

export const CtaButton = styled.div``;

export const CtaLabel = styled.div`
  display: flex;
  align-items: center;
  color: rgba(59, 38, 81, 0.6);
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 16px;
`;

export const CtaInfo = styled.div`
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
  max-width: 400px;
  text-align: center;
  margin-bottom: 16px;
`;

export const ChevronLabelText = styled.div`
  margin-left: 5px;
`;
