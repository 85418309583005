import styled from 'styled-components';

export const GrabberTimeContainer = styled.div`
  display: none;
  position: absolute;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  justify-content: center;
  width: 80px;
  left: calc(50% - 40px);
  top: -20px;
  color: #ffffff;
`;

export const Grabber = styled.div`
  position: absolute;
  top: calc(50% - 14px);
  width: 8px;
  height: 28px;
  background-color: #ffffff;
  cursor: grab;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  border: 1px solid #e2e2e2;

  &::after {
    content: '';
    position: absolute;
    width: 2px;
    height: 8px;
    left: calc(50% - 1px);
    top: calc(50% - 4px);
    background-color: #382152;
  }

  &:active {
    opacity: 1;
    cursor: grabbing;
  }

  &:active ${GrabberTimeContainer} {
    display: flex;
  }
`;

export const GrabberStart = styled(Grabber)`
  border-radius: 2px 0 0 2px;
  transform-origin: 100%;
`;

export const GrabberEnd = styled(Grabber)`
  border-radius: 0 2px 2px 0;
`;

export const ControlPanel = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(56, 33, 82, 0.6);
  padding: 5px 10px;
`;

export const ControlButton = styled.div<{ active?: boolean; isText?: boolean }>`
  position: relative;
  color: white;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  ${({ isText }) => isText && `min-width: 42px`};
  text-align: center;
  transition: color 0.2s;
  padding: 5px;
  margin-right: 5px;
  ${({ active }) => active && 'background-color: #3B2651;'}
  border-radius: 6px;
  user-select: none;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    color: #f0f0f0;
  }
`;

export const ProgressBarLine = styled.div`
  position: absolute;
  height: 2px;
  width: 100%;
  background-color: #d2cef8;
  top: 0;
  left: 0;
  z-index: 1;
`;

export const ProgressBarWrapper = styled.div`
  position: absolute;
  left: 0;
  top: -4px;
  width: 100%;
  height: 4px;
  cursor: pointer;
`;

export const ProgressBarContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  opacity: 0.5;
`;

export const ProgressSegment = styled.div<{ editModeOn: boolean }>`
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: ${({ editModeOn }) => (editModeOn ? '#3B2651' : 'transparent')};
  z-index: 1;
  cursor: pointer;
`;

export const ProgressBar = styled.div`
  position: absolute;
  background-color: #ffffff;
  left: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
  pointer-events: none;
  z-index: 1;
`;

export const Time = styled.div`
  position: absolute;
  width: 100%;
  max-width: 128px;
  top: calc(50% - 9px);
  left: calc(50% - 64px);
  color: white;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  text-align: center;
`;

export const ControlRightButtons = styled.div`
  display: flex;
  align-items: center;
`;

export const VolumePanel = styled.div<{ show: boolean }>`
  position: absolute;
  top: -145px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  display: ${({ show }) => (show ? 'block' : 'none')};
`;

export const VolumeRange = styled.input`
  height: 100px;
  cursor: pointer;
  writing-mode: vertical-lr;
  direction: rtl;
  appearance: slider-vertical;
  width: 5px;
  background-color: #ffffff;
`;
