import { Extension, Range } from '@tiptap/core';
import { DecorationSet } from '@tiptap/pm/view';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { Node as PMNode } from '@tiptap/pm/model';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    autoHighlights: {
      findNextOccurance: (
        text: string
      ) => ({
        editor,
      }: {
        editor: any;
      }) => {
        result: Range[];
      };
    };
  }
}

interface TextNodesWithPosition {
  text: string;
  pos: number;
}

const getRegex = (s: string, disableRegex: boolean, caseSensitive: boolean): RegExp => {
  return RegExp(
    disableRegex ? s.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') : s,
    caseSensitive ? 'gu' : 'gui'
  );
};

interface ProcessedSearches {
  result: Range[];
}

const problemText = `So my question to start this off is you're doing a lot of other things on sustainability, but what are you actually trying to achieve with your electric vehicle strategy and not just here, but you know, I was on ABC radio two weeks ago talking about what you're doing in India with, with uh electric vehicles.`;

function searchInDifferentNodes(
  textNodesWithPosition: TextNodesWithPosition[],
  searchTerm: string
): Range[] {
  if (!searchTerm) return [];

  let found = false;
  const searchTermArr = searchTerm.split(' ');
  let index = 0;
  const result = [];
  let nodeIndex = 0;
  let lessThanThreeWords = false;

  while (!found && index !== searchTermArr.length) {
    const currentArr = searchTermArr.slice(0, searchTermArr.length - index);

    if (currentArr.length < 3) {
      lessThanThreeWords = true;
      break;
    }

    const searchStr = currentArr.join(' ');
    const searchRegex = getRegex(searchStr, true, false);

    let i = 0;
    for (const element of textNodesWithPosition) {
      if (found) break;
      const { text, pos } = element;
      const matches = Array.from(text.matchAll(searchRegex)).filter(([matchText]) =>
        matchText.trim()
      );
      for (const m of matches) {
        if (m[0] === '') break;

        if (m.index !== undefined) {
          result.push({
            from: pos + m.index,
            to: pos + m.index + m[0].length,
          });
          found = true;
          nodeIndex = i;
          break;
        }
      }
      ++i;
    }

    if (!found) {
      index += 1;
    }
  }

  if (index === searchTermArr.length || lessThanThreeWords) {
    return [];
  }

  const tail = searchTermArr.slice(searchTermArr.length - index).join(' ');
  if (tail.length) {
    const tailResult = searchInDifferentNodes(
      textNodesWithPosition.slice(nodeIndex, nodeIndex + 5),
      tail
    );
    result.push(...tailResult);
  }

  return result;
}

function findNextOccurance(doc: PMNode, searchTerm: string): ProcessedSearches {
  let result: Range[] = [];
  let textNodesWithPosition: TextNodesWithPosition[] = [];
  let index = 0;

  if (!searchTerm) {
    return {
      result: [],
    };
  }

  doc?.descendants((node, pos) => {
    if (node.isText) {
      if (textNodesWithPosition[index]) {
        textNodesWithPosition[index] = {
          text: textNodesWithPosition[index].text + node.text,
          pos: textNodesWithPosition[index].pos,
        };
      } else {
        textNodesWithPosition[index] = {
          text: `${node.text}`,
          pos,
        };
      }
      index += 1;
    } else {
      index += 1;
    }
  });

  textNodesWithPosition = textNodesWithPosition.filter(Boolean);
  result = searchInDifferentNodes(textNodesWithPosition, searchTerm);

  return {
    result,
  };
}

export const autoHighlightsPluginKey = new PluginKey('autoHighlightsPlugin');

export interface AutoHighlightsOptions {
  autoHighlightResultClass: string;
}

export interface AutoHighlightsStorage {
  results: Range[];
}

export const AutoHighlights = Extension.create<AutoHighlightsOptions, AutoHighlightsStorage>({
  name: 'autoHighlights',

  addOptions() {
    return {
      autoHighlightResultClass: 'search-result',
    };
  },

  addStorage() {
    return {
      results: [],
    };
  },

  addCommands() {
    return {
      findNextOccurance: (text: string) => ({ editor }) => {
        const { result } = findNextOccurance(editor.state.doc, text);
        return {
          result,
        };
      },
    };
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: autoHighlightsPluginKey,
        state: {
          init: () => DecorationSet.empty,
          apply({ doc, docChanged }, oldState) {
            return DecorationSet.empty;
          },
        },
        props: {
          decorations(state) {
            return this.getState(state);
          },
        },
      }),
    ];
  },
});

export default AutoHighlights;
