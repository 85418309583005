import React, { FC } from 'react';

interface Props {
  children?: React.ReactNode;
  className?: string;
}

const Icon: FC<Props> = ({ children, className }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {children}
    </svg>
  );
};

const Tags: FC<{ className: string }> = ({ className }) => {
  return (
    <Icon className={className}>
      <path
        d="M5.55555 5.55555H5.56443M5.55555 2H9.99998C10.455 1.99999 10.9099 2.17355 11.257 2.5207L17.4793 8.74291C18.1736 9.43713 18.1736 10.5628 17.4793 11.257L11.257 17.4792C10.5628 18.1736 9.43713 18.1736 8.74291 17.4792L2.5207 11.257C2.17356 10.9099 2 10.4549 2 9.99998V5.55555C2 3.59187 3.59187 2 5.55555 2Z"
        stroke="#3B2651"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

const Chevron: FC = () => {
  return (
    <Icon>
      <path
        d="M4 11.1429L7.42857 14.5714L16 6"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

const GotoArrow: FC = () => {
  return (
    <Icon>
      <path
        d="M10.8995 3.82844H16.5563M16.5563 3.82844V9.4853M16.5563 3.82844L3.82843 16.5564"
        stroke="#3B2651"
        strokeOpacity="0.4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

const Analysis: FC = () => {
  return (
    <Icon>
      <path
        d="M1 5C1 4.448 1.448 4 2 4H7.33333C7.88533 4 8.33333 4.448 8.33333 5V8.33333C8.33333 8.88533 7.88533 9.33333 7.33333 9.33333H2C1.73478 9.33333 1.48043 9.228 1.29289 9.04044C1.10536 8.85289 1 8.59858 1 8.33333V5ZM11.6667 6.33333C11.6667 5.78133 12.1147 5.33333 12.6667 5.33333H17.3333C17.8853 5.33333 18.3333 5.78133 18.3333 6.33333V13.6667C18.3333 14.2187 17.8853 14.6667 17.3333 14.6667H12.6667C12.4014 14.6667 12.1471 14.5613 11.9596 14.3738C11.772 14.1862 11.6667 13.9319 11.6667 13.6667V6.33333ZM2.33333 13C2.33333 12.448 2.78133 12 3.33333 12H8C8.552 12 9 12.448 9 13V15C9 15.552 8.552 16 8 16H3.33333C3.06812 16 2.81376 15.8947 2.62622 15.7071C2.43869 15.5196 2.33333 15.2652 2.33333 15V13Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

export default { Tags, Chevron, GotoArrow, Analysis };
