import moment from 'moment';

class VideoHelper {
  static currentVideoProgress = (
    videoDuration: number,
    currentTime: number,
    startTime: number
  ): number => {
    if (!videoDuration) return 0;
    return ((currentTime - startTime) / videoDuration) * 100;
  };

  static timeToOffset = (containerWidth: number, videoDuration: number, time: number): number => {
    if (!videoDuration) return 0;
    return (time * containerWidth) / videoDuration;
  };

  static timeFromCursorPosition = (
    cursorPositionX: number,
    containerOffset: number,
    containerWidth: number,
    videoDuration: number
  ): number => {
    if (!containerWidth) return 0;

    const percentOfContainerWidth = (cursorPositionX - containerOffset) / containerWidth;
    return videoDuration * percentOfContainerWidth;
  };

  static timeFromCursorPositionInMode = (
    cursorPositionX: number,
    containerOffset: number,
    containerWidth: number,
    startTime: number,
    endTime: number,
    videoDuration: number,
    editModeOn: boolean
  ): number => {
    if (!containerWidth) return 0;
    const percentOfContainerWidth = (cursorPositionX - containerOffset) / containerWidth;
    if (editModeOn) {
      return videoDuration * percentOfContainerWidth;
    }
    return startTime + (endTime - startTime) * percentOfContainerWidth;
  };

  static formatTime = (time: moment.MomentInput): string => {
    const formatedString = moment.utc(time).format('HH:mm:ss');
    let newString = '';
    formatedString.split(':').forEach((element, index) => {
      if (element === '00' && index === 0) return;
      newString += `${element}${index === 2 ? '' : ':'}`;
    });
    return newString;
  };
}

export default VideoHelper;
