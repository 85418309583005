import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: calc(100% + 5px);
  right: 0;
  z-index: 9999;
  padding: 10px;
  border: 1px solid #d7d3dc;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

export const SearchRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  &:last-child {
    margin: 0;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex: 1;
  padding: 5px 8px;
  border: 1px solid #d7d3dc;
  border-radius: 4px;
`;

export const ReplaceInputContainer = styled.div`
  max-width: 220px;
  width: 100%;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin: 0 10px;
`;

export const SearchButton = styled.div`
  padding: 5px;
  cursor: pointer;
  border-radius: 3px;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #f5f5f5;
  }
`;

export const CloseButton = styled.div`
  padding: 5px;
  cursor: pointer;
`;

const ChevronButton = styled.div<{ enabled?: boolean }>`
  cursor: pointer;
  border-radius: 3px;
  transition: background-color 0.2s ease;
  ${({ enabled }) =>
    enabled
      ? `
    &:hover {
      background-color: #f5f5f5;
    }
  `
      : `
    color: rgba(59, 38, 81, 0.4);
  `}
`;

export const ChevronButtonTop = styled(ChevronButton)`
  transform: rotate(180deg);
  margin-right: 5px;
`;

export const ChevronButtonBottom = styled(ChevronButton)``;

export const Divier = styled.div`
  width: 1px;
  height: 24px;
  background-color: #d7d3dc;
`;

export const InputStats = styled.div<{ show: boolean }>`
  opacity: ${({ show }) => (show ? 1 : 0)};
  display: flex;
  margin-left: 4px;
  color: rgba(59, 38, 81, 0.4);
  flex-shrink: 0;
`;

export const ReplaceButtonWrapper = styled.div`
  margin-right: 5px;
`;

export const ReplaceButton = styled.div`
  padding: 8px 10px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  cursor: pointer;
  user-select: none;
  border-radius: 4px;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #f5f5f5;
  }
`;
