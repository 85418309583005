import React, { SyntheticEvent, useRef, useEffect, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { NoteInput } from '../../GraphQL/__generated__/globalTypes';
import useMinimizeOnScroll from '../../Hooks/useMinimizeOnScroll';
import { ID, Sticky } from '../../Models';
import VideoPlayerControls from '../VideoPlayerControls';
import VideoHelper from '../VideoPlayerControls/VideoHelper';
import { VideoContainer, VideoWrapper } from './styles';

export default function VideoClip({
  src,
  offset,
  endTime,
  editable,
  playOnOffsetChange,
  disableMinimization,
  scrollingContainer,
  onSeek,
  onUpdateNote,
}: {
  src: string;
  offset?: number | null;
  endTime?: number | null;
  editable?: boolean;
  playOnOffsetChange?: boolean;
  disableMinimization?: boolean;
  scrollingContainer?: HTMLDivElement | null;
  onSeek?: (s: number) => void | Promise<void>;
  onUpdateNote?: (input: NoteInput) => Promise<Sticky>;
}): JSX.Element {
  const [stopOnce, setStopOnce] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const playerRef = useRef<HTMLVideoElement>(null);
  const stopOnceRef = useRef(stopOnce);
  stopOnceRef.current = stopOnce;

  const [newStartTime, setNewStartTime] = useState(offset || 0);
  const [newEndTime, setNewEndTime] = useState(endTime || 0);
  const [videoProgress, setVideoProgress] = useState(0);
  const [videoDuration, setVideoDuration] = useState(0);
  const [isMinimized, playerRect, handleOnLoad] = useMinimizeOnScroll(
    playerRef,
    scrollingContainer
  );

  useEffect(() => {
    const videoElt = playerRef.current;

    if (!videoElt) return;
    videoElt.onloadedmetadata = () => {
      setNewStartTime(offset || 0);

      setNewEndTime(endTime || videoElt.duration);
      setVideoDuration(videoElt.duration);
    };

    setVideoProgress(
      VideoHelper.currentVideoProgress(videoElt.duration, videoElt.currentTime, newStartTime)
    );
  }, []);

  useEffect(() => {
    const videoElt = playerRef.current;

    const handleTimeUpdate = (): void => {
      if (!videoElt || !isPlaying) return;
      setVideoProgress(
        VideoHelper.currentVideoProgress(videoElt.duration, videoElt.currentTime, newStartTime)
      );

      if (videoElt.currentTime >= newEndTime) {
        videoElt.pause();
        videoElt.currentTime = newEndTime;
        setIsPlaying(false);
      }
    };

    videoElt?.addEventListener('timeupdate', handleTimeUpdate);
    return () => {
      videoElt?.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [newStartTime, newEndTime, isPlaying]);

  useEffect(() => {
    if (playerRef.current && offset) playerRef.current.currentTime = offset;
  }, [offset]);

  useEffect(() => {
    if (playerRef.current && onSeek) {
      playerRef.current.addEventListener('seeking', (event) => {
        if (playerRef.current) onSeek(getPlayerCurrentTime());
      });
    }
  });

  useEffect(() => {
    if (playerRef.current && offset) {
      playerRef.current.currentTime = offset;
      if (playOnOffsetChange) {
        playerRef.current.play();
        setIsPlaying(true);
      }
    }
  }, [offset, playOnOffsetChange]);

  const getPlayerCurrentTime = () => Math.floor(playerRef?.current?.currentTime || 0);
  const getPlayerCurrentTimeAccurate = () => playerRef?.current?.currentTime || 0;

  const onVideoLoad = (e: SyntheticEvent<HTMLVideoElement>) => {
    handleOnLoad();
    if (offset) e.currentTarget.currentTime = offset;
  };

  const handleTimeUpdate = () => {
    if (
      !stopOnceRef.current &&
      playerRef.current &&
      endTime &&
      playerRef.current.currentTime >= endTime
    ) {
      playerRef.current.pause();
      setStopOnce(true);
    }
  };

  const handleVolumeChange = (volume: number) => {
    const videoElt = playerRef.current;
    if (!videoElt) return;
    videoElt.volume = volume;
  };

  const handleSpeedChange = (currentSpeed: number) => {
    const videoElt = playerRef.current;
    if (!videoElt) return;
    videoElt.playbackRate = currentSpeed;
  };

  const handlePlayPauseClick = (): void => {
    const videoElt = playerRef.current;
    if (!videoElt) return;

    if (isPlaying) {
      videoElt.pause();
      setIsPlaying(false);
    } else {
      if (Math.abs(videoElt.currentTime - newEndTime) < 0.01) {
        videoElt.currentTime = newStartTime;
      }

      void videoElt.play();
      setIsPlaying(true);
    }
  };

  const handleMoveGrabber = (currentTime: number): void => {
    const videoElt = playerRef.current;
    if (!videoElt) return;

    videoElt.pause();
    videoElt.currentTime = currentTime;

    setIsPlaying(false);
    setVideoProgress(0);
  };

  const handleUpdateNote = useDebouncedCallback((input: NoteInput) => {
    onUpdateNote && onUpdateNote(input);
  }, 200);

  const handleStartTimeChange = (newStartTime: number): void => {
    setNewStartTime(newStartTime);
    handleUpdateNote({
      options: JSON.stringify({ timeOffset: newStartTime, endTime: newEndTime }),
    });
  };

  const handleEndTimeChange = (newEndTime: number): void => {
    setNewEndTime(newEndTime);
    handleUpdateNote({
      options: JSON.stringify({ timeOffset: newStartTime, endTime: newEndTime }),
    });
  };

  const handleCurrentTimeChange = (newCurrentTime: number): void => {
    const videoElt = playerRef.current;
    if (!videoElt) return;

    videoElt.currentTime = newCurrentTime;
    setVideoProgress(
      VideoHelper.currentVideoProgress(videoElt.duration, videoElt.currentTime, newStartTime)
    );
  };

  const memoizedVideo = useMemo(() => {
    return (
      <video
        onClick={handlePlayPauseClick}
        className="my-0"
        onLoadedData={onVideoLoad}
        ref={playerRef}
        style={{
          width: '100%',
          zIndex: 3,
        }}
        onTimeUpdate={() => handleTimeUpdate()}
      >
        <source src={src} type="video/mp4" />
      </video>
    );
  }, [src, isPlaying]);

  return (
    <VideoWrapper>
      {isMinimized && !disableMinimization && (
        <div style={{ height: playerRect.height, width: '100%' }}></div>
      )}

      <VideoContainer isMinimized={!disableMinimization && isMinimized}>
        {memoizedVideo}
        <VideoPlayerControls
          onPlayPause={handlePlayPauseClick}
          isPlaying={isPlaying}
          startTime={newStartTime}
          endTime={newEndTime || playerRef.current?.duration || 0}
          videoDuration={videoDuration}
          videoProgress={videoProgress}
          currentTime={getPlayerCurrentTimeAccurate()}
          onMoveGrabber={handleMoveGrabber}
          onStartTimeChange={handleStartTimeChange}
          onEndTimeChange={handleEndTimeChange}
          onCurrentTimeChange={handleCurrentTimeChange}
          onVolumeChange={handleVolumeChange}
          onSpeedChange={handleSpeedChange}
          editable={editable}
        />
      </VideoContainer>
    </VideoWrapper>
  );
}
