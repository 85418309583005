import { useApolloClient } from '@apollo/client/react';
import React, { FC, useEffect, useRef, useState } from 'react';
import { FETCH_PARTICIPANTS, FETCH_TAGS } from '../../GraphQL/queries';
import { uniq } from 'lodash-es';
import { captureException } from '@sentry/react';
import { parse } from 'csv-parse/browser/esm/sync';
import { toast } from 'react-toastify';
import useAnalytics from '../../Hooks/useAnalytics';
import { Icon24 } from '../../Icons/Icon';
import QuestionMarkIcon from '../../Icons/QuestionMarkIcon';
import PortalNew from '../PortalNew';
import { useCreateTag } from '../../Hooks/useTags';

import {
  Button,
  ButtonText,
  Container,
  DROPDOWN_WIDTH,
  NewDocDropdown,
  NewDocDropDownDivider,
  NewDocDropdownItem,
  NewDocDropdownItemWrapper,
} from './styles';
import { useCreateParticipant } from '../../Hooks/useParticipants';

type ImportFromCSVRecord = {
  name?: string;
  company?: string;
  role?: string;
  email?: string;
  phone?: string;
  description?: string;
  color?: string;
  tags?: string;
};

const ParticipantImportMenu: FC = () => {
  const { analytics } = useAnalytics();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const buttonRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const createTag = useCreateTag();

  const [createParticipant] = useCreateParticipant();

  const client = useApolloClient();

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (buttonRef.current && buttonRef.current.contains(e.target as Node)) return;
      if (dropdownRef.current && !dropdownRef.current.contains(e.target as Node)) {
        e.stopPropagation();
        setIsDropdownOpen(false);
      }
    };
    window.addEventListener('mousedown', handleClick);

    return () => {
      window.removeEventListener('mousedown', handleClick);
    };
  }, [dropdownRef]);

  const dropdownCoordinates = () => {
    if (!buttonRef.current) return { top: 0, left: 0 };

    const { top, left, width, height } = buttonRef.current.getBoundingClientRect();
    const widthDiff = DROPDOWN_WIDTH - width;

    return {
      top: top + height + 5,
      left: left - widthDiff,
    };
  };

  const handleCSVImport = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.csv';
    input.onchange = (e) => {
      const target = e.target as HTMLInputElement;
      if (!target.files || !target.files.length) return;

      const file = target.files[0];

      const reader = new FileReader();
      reader.readAsText(file, 'UTF-8');

      reader.onload = async (readerEvent) => {
        setIsDropdownOpen(false);
        const content = readerEvent?.target?.result as string;
        if (!content) return;
        const toastId = toast.loading('Importing participants...');

        try {
          const additionalRecord = null;

          const records: any[] = parse(content, {
            columns: (header) => {
              const columns: string[] = header.map((column: string) => column.toLowerCase());
              // const hasHeader = ['note', 'content', 'text'].some((r) => columns.includes(r));
              // if (!hasHeader) {
              //   additionalRecord = { note: columns[0] };
              //   return ['note', ...columns];
              // }
              return columns;
            },
            skip_empty_lines: true,
            relax_column_count: true,
            delimiter: [',', ';'],
          });

          // handle issue with columns option removing header row
          if (additionalRecord) records.push(additionalRecord);

          toast.update(toastId, {
            render: `Importing ${records.length} participants from .csv...`,
          });

          const tagNames: string[] = uniq(
            records
              .filter((record: ImportFromCSVRecord) => record.name && record.tags)
              .map((record: ImportFromCSVRecord) => {
                return record?.tags?.split(',') || [];
              })
              .flat()
          );

          let existingTags: any[] = [];

          if (tagNames.length > 0) {
            existingTags =
              (
                await client.query({
                  query: FETCH_TAGS,
                  variables: {
                    filter: { isGlobal: { equalTo: true } },
                    withDetails: false,
                  },
                  fetchPolicy: 'network-only',
                })
              )?.data?.tags || [];
          }

          const newTagNames = tagNames.filter(
            (name) =>
              !existingTags.some(
                (tag: { name: string }) => tag.name?.toLowerCase() === name?.toLowerCase()
              )
          );

          const newTags = await Promise.all(
            newTagNames.map((name) => createTag({ name, isGlobal: true }))
          );

          const tags = [...existingTags, ...newTags];

          const participantNames = uniq(
            records
              .filter((record: ImportFromCSVRecord) => record.name)
              .map((record: ImportFromCSVRecord) => {
                return record.name;
              })
          );

          if (participantNames?.length > 0) {
            const {
              data: { participants: existingParticipants = [] },
            } = await client.query({
              query: FETCH_PARTICIPANTS,
              fetchPolicy: 'network-only',
            });

            const newParticipantNames = participantNames.filter(
              (name) =>
                !existingParticipants.some(
                  (participant: { name: string }) =>
                    participant.name?.toLowerCase() === name?.toLowerCase()
                )
            );

            const participantsToAdd = records
              .filter((record: ImportFromCSVRecord) => newParticipantNames.includes(record.name))
              .map((record: ImportFromCSVRecord) => {
                const allTags = record.tags
                  ?.split(',')
                  ?.filter((x) => x)
                  .map((tagName) => ({
                    tagId: tags?.find((tag) => tag.name?.toLowerCase() === tagName?.toLowerCase())
                      ?.id,
                  }));

                const participantData: {
                  name: string | undefined;
                  company: string | undefined;
                  role: string | undefined;
                  email: string | undefined;
                  phone: string | undefined;
                  description: string | undefined;
                  tagsParticipants?: any;
                } = {
                  name: record.name,
                  company: record.company,
                  role: record.role,
                  email: record.email,
                  phone: record.phone,
                  description: record.description,
                };

                if (allTags) {
                  participantData.tagsParticipants = {
                    create: allTags,
                  };
                }

                return participantData;
              });

            const newParticipants = await Promise.all(
              participantsToAdd.map((participant) => createParticipant(participant))
            );

            toast.update(toastId, {
              render: `Successfully imported ${participantsToAdd.length} participants.`,
              type: 'success',
              isLoading: false,
              autoClose: 1000,
            });
          }
        } catch (error) {
          captureException(error);
          toast.update(toastId, {
            render: 'Unable to parse the file.',
            type: 'error',
            isLoading: false,
            autoClose: 5000,
          });
        }
      };
    };
    input.click();
  };

  return (
    <Container>
      <Button ref={buttonRef} onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
        <ButtonText>Import</ButtonText>
        <Icon24.ChevronDown />
      </Button>

      {isDropdownOpen && (
        <PortalNew wrapperId="NewDocDropdownPortal">
          <NewDocDropdown
            top={dropdownCoordinates().top}
            left={dropdownCoordinates().left}
            ref={dropdownRef}
          >
            <div className="m-2 ml-3 mb-1 font-medium text-primary-purple-light-40 text-sm">
              Import
            </div>

            <NewDocDropdownItemWrapper>
              <NewDocDropdownItem onClick={handleCSVImport}>
                <div className="flex justify-start items-center">
                  <div className="mr-2">
                    <Icon24.Spreadsheet />
                  </div>
                  <div className="text-violet-950 text-base font-medium tracking-tight mr-2">
                    Spreadsheet
                  </div>
                  <div className="text-gray-700 text-opacity-40 text-base font-medium tracking-tight">
                    .csv
                  </div>
                </div>
              </NewDocDropdownItem>
              {/* <NewDocDropDownDivider /> */}
            </NewDocDropdownItemWrapper>

            <div>
              <a
                rel="noreferrer"
                href="http://help.notably.ai/en/articles/9204088-how-to-create-new-participants"
                target="_blank"
                className="group flex items-center px-2 py-2 text-sm text-primary-purple-light-40"
              >
                <QuestionMarkIcon />
                <div className="ml-1 font-medium">Help Importing Participants</div>
              </a>
            </div>
          </NewDocDropdown>
        </PortalNew>
      )}
    </Container>
  );
};

export default ParticipantImportMenu;
