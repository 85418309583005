import styled from 'styled-components';

export const PopupContainer = styled.div<{ show: boolean }>`
  position: fixed;
  width: 100%;
  max-width: 560px;
  max-height: calc(100vh - 48px);
  overflow: hidden;
  top: 52px;
  left: calc(50% - 280px);
  display: ${({ show }) => (show ? 'block' : 'none')};
  z-index: 9999;
  background: transparent;
  padding-bottom: 32px;
`;
