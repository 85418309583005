import styled from 'styled-components';
import { PlusIcon } from '@heroicons/react/outline';
import Button from './Button';
import { breakpoint } from '../Shared';

export const EmptyStateContainer = styled.div`
  display: flex;
  height: 80%;
`;
export const EmptyState = styled.div`
  display: flex;
  margin: auto;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  text-align: center;
`;
export const EmptyStateTitle = styled.div`
  font-weight: 500;
  font-size: 20px;
  color: ${(props) => props.theme.colors.primary.purple};
  margin-top: 20px;
  margin-bottom: 10px;
`;
export const EmptyStateSubTitle = styled.div`
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  color: ${(props) => props.theme.colors.primary.purple};
`;
export const EmptyStateText = styled.div`
  font-weight: normal;
  font-size: 14px;
  color: ${(props) => props.theme.colors.text.placeholder};
`;
export const EmptyStateSecondaryText = styled.div`
  font-weight: normal;
  font-size: 14px;
  color: ${(props) => props.theme.colors.text.placeholder};
  margin-top: 10px;
`;
export const HeroImage = styled.div<{ src: string; wide?: boolean }>`
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 200px;
  width: ${(props) => (props.wide ? 400 : 200)}px;
  ${breakpoint.Mobile`
    width: 100%;
  `}
`;

export const EmptyStatePlusIcon = styled(PlusIcon)`
  height: 24px;
  width: 24px;
  padding-right: 4px;
`;
export const EmptyStateButton = styled(Button)<{ mt?: string }>`
  margin-top: ${(props) => (props.mt ? props.mt : 20)}px;
  margin-bottom: 10px;
  font-weight: 500;
  border-radius: 3px;
  font-size: 14px;
  padding: 4px 8px;
`;

export const BetaLabel = styled.span`
  margin-left: 2px;
  margin-right: 2px;
  padding: 2px 4px;
  gap: 5px;
  border-radius: 3px;
  background: rgba(56, 33, 82, 0.2);
  color: #382152;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
`;
