import React, { useCallback, useEffect, useState } from 'react';

type PlayerRect = {
  height: number;
  width: number;
  top: number;
};
export default function useMinimizeOnScroll(
  playerRef: React.RefObject<HTMLVideoElement | HTMLAudioElement>,
  scrollingContainer?: HTMLElement | null
): [boolean, PlayerRect, () => void] {
  const [isMinimized, setIsMinimized] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [playerRect, setPlayerRect] = useState<PlayerRect>({
    height: 360,
    width: 800,
    top: 108,
  });
  const [isRectInitialized, setRectInitialized] = useState(false);

  useEffect(() => {
    if (scrollingContainer && isLoaded && !isRectInitialized) {
      setPlayerRect({
        height: playerRect.height,
        width: playerRect.width,
        top: playerRect.top + scrollingContainer.scrollTop,
      });
      setRectInitialized(true);
    }
  }, [scrollingContainer, isLoaded, playerRect, isRectInitialized]);

  const checkIfVisible = useCallback((rect: PlayerRect, scrollOffset: number) => {
    const currentIsVisible = rect.top + rect.height - scrollOffset > 116;
    setIsMinimized(!currentIsVisible);
  }, []);

  useEffect(() => {
    if (!isLoaded) return;

    const handleScroll = () => {
      const scrollOffset = scrollingContainer ? scrollingContainer.scrollTop : window.pageYOffset;
      checkIfVisible(playerRect, scrollOffset);
    };

    const scrollingElement = scrollingContainer ? scrollingContainer : window;
    scrollingElement.addEventListener('scroll', handleScroll);
    return () => {
      scrollingElement.removeEventListener('scroll', handleScroll);
    };
  }, [playerRect, isLoaded, scrollingContainer, checkIfVisible]);

  const handleOnLoad = () => {
    const rect = playerRef.current?.getBoundingClientRect();
    if (!rect) return;
    const scrollOffset = scrollingContainer ? scrollingContainer.scrollTop : window.pageYOffset;
    checkIfVisible(rect, scrollOffset);
    setPlayerRect({
      height: rect?.height,
      width: rect?.width,
      top: rect?.top + scrollOffset,
    });
    setIsLoaded(true);
  };
  return [isMinimized, playerRect, handleOnLoad];
}
