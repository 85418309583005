import React, { FC, forwardRef, useEffect, useRef } from 'react';
import { Checkbox } from '@chakra-ui/react';

import {
  Container,
  TableBody,
  TableRow,
  TableItem,
  TextAreaField,
  TagName,
  Placeholder,
  CheckBoxWrapper,
  TagNumber,
} from './styles';
import { ID, TagWithDetails } from '../../Models';
import { DragItem } from '../DragAndDrop';

interface TagsTableProps {
  tags: TagWithDetails[];
  checkedTags: TagWithDetails[];
  disabled?: boolean;
  onCheckedTagsChange(tags: TagWithDetails[]): void;
  onTagClick?(tagId: string): void;
}

const TagsTable = forwardRef<HTMLDivElement, TagsTableProps>(
  ({ tags, checkedTags, disabled, onCheckedTagsChange, onTagClick }, ref) => {
    const textAreaRefs = useRef<HTMLTextAreaElement[]>([]);
    const checkBoxRef = useRef<HTMLInputElement>(null);

    const handleCheckboxCheckedChange = (tagId: ID, isChecked: boolean) => {
      const tag = tags.find((tag) => tag.id === tagId);
      if (!tag) return;

      isChecked
        ? onCheckedTagsChange([...checkedTags, tag])
        : onCheckedTagsChange(checkedTags.filter((currentTag) => currentTag.id !== tag.id));
    };

    const handleRowClick = (e: React.MouseEvent<HTMLElement>, tagId: ID) => {
      if (checkBoxRef.current?.parentElement?.contains(e.target as Node)) return;
      onTagClick && onTagClick(tagId as string);
    };

    useEffect(() => {
      textAreaRefs.current.forEach((el) => {
        el.style.height = `1px`;
        el.style.height = `${el.scrollHeight}px`;
      });
    }, [tags]);

    return (
      <Container ref={ref}>
        <TableBody>
          {tags.map((tag) => (
            <DragItem<TagWithDetails> key={tag.id} item={tag} type="tagGroup">
              <TableRow key={tag.id} onClick={(e) => handleRowClick(e, tag.id)}>
                <TableItem>
                  {!disabled && (
                    <CheckBoxWrapper>
                      <Checkbox
                        isChecked={
                          checkedTags.findIndex((currentTag) => tag.id === currentTag.id) !== -1
                        }
                        className="flex"
                        colorScheme="purple"
                        onChange={(e) => handleCheckboxCheckedChange(tag.id, e.target.checked)}
                        style={{ padding: '12px' }}
                        ref={checkBoxRef}
                        disabled={disabled}
                      />
                    </CheckBoxWrapper>
                  )}
                  <TagName color={tag.color} withoutCheckbox={disabled}>
                    {tag.name ? tag.name : <Placeholder>New tag</Placeholder>}
                  </TagName>

                  <TagNumber>
                    {tag.tagsDocumentsConnection.totalCount +
                      tag.tagsNotesConnection.totalCount +
                      tag.tagsTranscriptionsConnection.totalCount}
                  </TagNumber>
                </TableItem>

                <TableItem>
                  <TextAreaField
                    defaultValue={tag.description}
                    ref={(el) => el && textAreaRefs.current.push(el)}
                    readOnly
                  />
                </TableItem>
              </TableRow>
            </DragItem>
          ))}
        </TableBody>
      </Container>
    );
  }
);

TagsTable.displayName = 'TagsTable';
export default TagsTable;
