import styled from 'styled-components';

export const DotsButton = styled.div`
  position: relative;
  padding: 5px;
  margin-left: 10px;
  cursor: pointer;
`;

export const MenuPanel = styled.div<{ show: boolean }>`
  position: absolute;
  top: 36px;
  right: 0;
  white-space: nowrap;
  display: none;
  ${({ show }) => show && `display: block;`}

  border-radius: 3px;
  border: 1px solid var(--Light-Purple-Gray, #d7d3dc);
  background-color: #ffffff;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.1);
  z-index: 2;
`;

export const MenuPanelItem = styled.div<{ itemColor?: string }>`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 6px 40px 6px 10px;
  cursor: pointer;
  user-select: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;

  ${({ itemColor }) => itemColor === 'red' && `color: #EA4335;`}

  &:hover {
    background: #f8f8f8;
  }
`;

export const MenuPanelItemIcon = styled.div`
  margin-right: 5px;
`;
