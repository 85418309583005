import styled from 'styled-components';

export const Container = styled.div<{ isOpen: boolean }>`
  min-width: 190px;
  background-color: #ffffff;
  border-radius: 6px;
  border: 1px solid rgba(215, 211, 220, 1);
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.1);
  padding: 5px 0;
  z-index: 99999;
`;

export const DropdownTitle = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  color: rgba(59, 38, 81, 0.4);
`;

export const InputWrapper = styled.div`
  margin: 10px 0;
`;

export const NestedDropdownList = styled.div`
  position: absolute;
  width: 190px;
  top: 0;
  left: calc(100% - 5px);
  width: 220px;
  max-height: 250px;
  padding: 5px 0 0;
  display: none;
  background-color: #ffffff;
  border-radius: 6px;
  border: 1px solid rgba(215, 211, 220, 1);
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.1);
  z-index: 99999;
  overflow: hidden;
`;

export const NestedDropdown = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 5px 10px;

  &:hover {
    background-color: #f5f5f5;
  }

  &:hover ${NestedDropdownList} {
    display: block;
  }
`;

export const NestedDropdownTitle = styled.div`
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
`;

export const NestedDropdownIcon = styled.div``;

export const NestedDropdownItem = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background-color: #f5f5f5;
  }
`;

export const SpeakerName = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin-left: 10px;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(215, 211, 220, 1);
  margin: 10px 0;
`;

export const PaddingWrapper = styled.div`
  padding: 0 10px;
`;

export const SearchInputWrapper = styled.div`
  padding: 0 10px;
  margin: 10px 0;
`;

export const CreateParticipantButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-bottom: 10px;
`;

export const CreateParticipantText = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin-left: 4px;
  margin-right: 10px;

  &:first-child {
    flex-shrink: 0;
  }

  &:last-child {
    margin-right: 0;
    max-width: 110px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const NestedDropdownContainer = styled.div`
  width: 100%;
  overflow-y: auto;
  max-height: 165px;
`;

export const UnlinkParticipantButton = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0 5px;
`;

export const UnlinkIconWrapper = styled.div`
  margin-left: 5px;
  flex-shrink: 0;
  color: rgba(59, 38, 81, 0.4);
  margin-top: 2px;
  cursor: pointer;
`;

export const UnlinkParticipantText = styled.div`
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const SpinnerWrapper = styled.div`
  margin-right: 5px;
`;
