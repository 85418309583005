import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { NodeViewWrapper, NodeViewContent } from '@tiptap/react';

import { ContentWrapper, MenuContainer } from './styles';
import { TagWithDetails } from '../../../../Models';
import HighlightMenu from '../HighlightMenu';
import TagsDropdown from '../../../TagsDropdown';
import { HighlightsContext } from '../../../../Context/HighlightsContext';
import PortalNew from '../../../PortalNew';

const topBarHeight = 48;
const toolbarHeight = 59;

const HighlightTagComponent = (props: any) => {
  const [showButtons, setShowButtons] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const containerRef = useRef<HTMLSpanElement>(null);
  const [menuRect, setMenuRect] = useState<DOMRect>();
  const [highlightBoundingRect, setHighlightBoundingRect] = useState<DOMRect>();
  const parentContainer: HTMLDivElement = props.extension.options.parentContainer;
  const editorContainer: HTMLDivElement = props.extension.options.editorContainerRef.current;
  const isEditable = props.extension.options.editable;

  const {
    highlights,
    selectedEntityId,
    removeHighlight,
    updateHighlightTags,
    selectEntity,
    highlightForUnset,
    addHighlightForUnset,
  } = useContext(HighlightsContext);
  const isNewTag = !!props.node.attrs.newTag;
  useEffect(() => {
    const { selection } = props.editor.state;
    const { $from } = selection;

    if ($from.parent.type.name !== 'highlightTag') {
      selectEntity(null);
      return;
    }
    const { id } = props.editor.getAttributes('highlightTag');

    if (id === props.node.attrs.id) {
      selectEntity(id);
    }
  }, [props.editor.view.state.selection, props.node.attrs.id]);

  useEffect(() => {
    if (isNewTag && props.node.attrs.serialNumber === 0) {
      setShowButtons(false);
      setTimeout(() => props.updateAttributes({ newTag: false }), 0);
      setShowDropdown(true);
    }
  }, [props, isNewTag, props.node.attrs.serialNumber]);

  const handleScrollFinished = (onFinish: () => void) => {
    let position: number | null = null;
    const checkIfScrollIsStatic = setInterval(() => {
      if (position === parentContainer.scrollTop) {
        clearInterval(checkIfScrollIsStatic);
        onFinish();
      }
      position = parentContainer.scrollTop;
    }, 50);
  };

  useEffect(() => {
    if (showDropdown) return;
    if (selectedEntityId === props.node.attrs.id && props.node.attrs.serialNumber === 0) {
      const { selection } = props.editor.state;
      const { $from } = selection;

      if ($from.parent.attrs.id !== props.node.attrs.id && highlightBoundingRect) {
        const highlightOffsetTop = containerRef?.current?.offsetTop || 0;
        const highlightHeight = containerRef?.current?.offsetHeight || 0;
        const isVisible =
          highlightOffsetTop > parentContainer.scrollTop &&
          highlightOffsetTop + highlightHeight <
            parentContainer.scrollTop + parentContainer.offsetHeight;

        if (!isVisible) {
          parentContainer.scrollTo({
            top: highlightOffsetTop,
            behavior: 'smooth',
          });

          handleScrollFinished(() => {
            props.editor
              .chain()
              .focus()
              .setTextSelection(props.getPos() + 1)
              .run();
          });
        } else {
          props.editor
            .chain()
            .focus()
            .setTextSelection(props.getPos() + 1)
            .run();
        }
      }

      if (isEditable) {
        setShowButtons(true);
      }
    } else {
      setShowButtons(false);
    }
  }, [selectedEntityId, props.node.attrs.id, props.node.attrs.serialNumber]);

  useEffect(() => {
    if (highlightForUnset === props.node.attrs.id) {
      props.editor
        .chain()
        .focus()
        .setTextSelection(props.getPos() + 1)
        .run();

      setTimeout(() => {
        props.editor.chain().focus().unsetHighlightTag().run();
        addHighlightForUnset(null);
        removeHighlight(props.node.attrs.id);
      }, 0);
    }
  }, [highlightForUnset, props.node.attrs.id, props.editor]);

  useEffect(() => {
    const handleScroll = () => {
      setHighlightBoundingRect(containerRef?.current?.getBoundingClientRect());
    };

    const observer = new ResizeObserver(() => {
      setHighlightBoundingRect(containerRef?.current?.getBoundingClientRect());
    });

    observer.observe(parentContainer);
    setHighlightBoundingRect(containerRef?.current?.getBoundingClientRect());
    document.addEventListener('scroll', handleScroll, true);
    return () => {
      document.removeEventListener('scroll', handleScroll, true);
      observer.disconnect();
    };
  }, []);

  const handleMenuRender = useCallback(
    (node) => {
      if (showButtons || showDropdown) {
        setMenuRect(node?.getBoundingClientRect());
      }
    },
    [showButtons, showDropdown]
  );

  let left = null;
  let top = null;

  if (highlightBoundingRect && menuRect) {
    left = highlightBoundingRect.left + highlightBoundingRect.width / 2 - menuRect.width / 2;
    top = highlightBoundingRect.top - menuRect.height;

    if (
      top < editorContainer.offsetTop + topBarHeight + toolbarHeight - parentContainer.scrollTop ||
      (parentContainer.scrollTop >= 232 && top < 178)
    ) {
      top = top + menuRect.height + highlightBoundingRect.height + 16;
    }
  }

  return (
    <NodeViewWrapper as="span">
      <ContentWrapper
        color={
          highlights.find((highlight) => highlight.entityId === props.node.attrs.id)?.tags[0]?.color
        }
        isActive={selectedEntityId === props.node.attrs.id}
        ref={containerRef}
      >
        {(showButtons || showDropdown) && (
          <PortalNew wrapperId="highlightMenu">
            <MenuContainer top={top || 0} left={left || 0} ref={handleMenuRender}>
              {showButtons && (
                <HighlightMenu
                  isTagActive={props.editor.isActive('highlightTag')}
                  onHighlight={() => {
                    removeHighlight(props.node.attrs.id);
                    props.editor.chain().focus().unsetHighlightTag().run();
                  }}
                  onSetTag={() => {
                    setShowButtons(false);
                    setShowDropdown(true);
                  }}
                  tagsCount={
                    highlights.find((highlight) => highlight.entityId === props.node.attrs.id)?.tags
                      ?.length || 0
                  }
                />
              )}

              {showDropdown && (
                <TagsDropdown
                  value={
                    highlights.find((highlight) => highlight.entityId === props.node.attrs.id)
                      ?.tags as TagWithDetails[]
                  }
                  onChange={(selectedTags: TagWithDetails[]) => {
                    updateHighlightTags(props.node.attrs.id, selectedTags);
                  }}
                  onBlur={() => setShowDropdown(false)}
                  selectedText={props.node.textContent}
                />
              )}
            </MenuContainer>
          </PortalNew>
        )}

        <NodeViewContent as="span" />
      </ContentWrapper>
    </NodeViewWrapper>
  );
};

export default HighlightTagComponent;
