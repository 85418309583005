import React, { useContext } from 'react';
import { BubbleMenu as Menu, Editor } from '@tiptap/react';

import HighlightMenu from '../HighlightMenu';
import { NodeSelection } from '@tiptap/pm/state';
import { HighlightsContext } from '../../../../Context/HighlightsContext';
import { EditorContext } from '../../../../Context/EditorContext';

interface BubbleMenuProps {
  editor: Editor;
}

const BubbleMenu: React.FC<BubbleMenuProps> = ({ editor }) => {
  const { addHighlight } = useContext(HighlightsContext);
  const { documentParticipantId } = useContext(EditorContext);

  const handleHighlightTag = (type: 'tag' | 'highlight') => {
    const { from, to } = editor.view.state.selection;
    const currentTexts: string[] = editor.state.doc.textBetween(from, to, '#').split('#');

    const generatedId = Math.floor(Math.random() * Date.now()).toString(16);

    let speakerNameId = '';
    const selectedTextAnchor = editor.view.state.selection.anchor;
    editor.state.doc.content.nodesBetween(0, editor.state.doc.content.size, (node, pos) => {
      if (
        node.type.name === 'speaker' &&
        selectedTextAnchor >= pos &&
        selectedTextAnchor <= pos + node.nodeSize
      ) {
        speakerNameId = node.attrs.id;
      }
    });

    addHighlight({
      entityId: generatedId,
      speakerId: speakerNameId || documentParticipantId || '',
      texts: currentTexts,
      tags: [],
      addedToAnalysis: false,
    });

    editor
      .chain()
      .focus()
      .setHighlightTag({
        id: generatedId,
        type,
        speakerId: speakerNameId || documentParticipantId || '',
      })
      .run();
  };

  return (
    <Menu
      editor={editor}
      tippyOptions={{ duration: 100 }}
      shouldShow={({ editor }) => {
        const forbiddenTypes = ['highlightTag', 'ResizableImage', 'transcriptLabel', 'image'];
        const { state } = editor;
        const { selection } = state;
        const { $from } = selection;

        if (selection.empty) {
          return false;
        }

        if (
          selection instanceof NodeSelection &&
          forbiddenTypes.includes(selection.node.type.name)
        ) {
          return false;
        }

        if (forbiddenTypes.includes($from.parent.type.name)) {
          return false;
        }
        return true;
      }}
    >
      <HighlightMenu
        isTagActive={editor.isActive('highlightTag')}
        onHighlight={() => handleHighlightTag('highlight')}
        onSetTag={() => handleHighlightTag('tag')}
      />
    </Menu>
  );
};

export default BubbleMenu;
