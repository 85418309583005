import React from 'react';
import { Loader } from '../../Components';
import { useHistory } from 'react-router-dom';
import { DocumentTextIcon, TrashIcon, VideoCameraIcon } from '@heroicons/react/outline';
import { Table, TableCell, TableRow } from '../../Components/TableWithBorders';
import styled from 'styled-components';
import ParticipantEditor from '../../Components/ParticipantEditor';
import EmptyState from '../../Components/EmptyState';
import moment from 'moment';
import { useDeleteParticipant, useFetchParticipant } from '../../Hooks/useParticipants';
import Button from '../../Components/Button';
import { defaultProjectIcons } from '../../Consts/projectIcons';

const TabHeader = styled.h2`
  display: inline-block;
  font-weight: 600;
  font-size: 14px;
  margin-top: 24px;
`;

const TableContainer = styled.div`
  margin-top: 24px;
`;

const ItemName = styled.div`
  display: flex;
  align-items: center;
`;

const IconContainer = styled.div`
  margin-right: 8px;
`;

interface Props {
  participantId: string;
}

const ParticipantDetail = ({ participantId }: Props): JSX.Element => {
  const history = useHistory();
  const [loading, participant] = useFetchParticipant(participantId);
  const [deleteParticipant] = useDeleteParticipant();

  if (loading || !participant) {
    return (
      <div className="w-full">
        <Loader />
      </div>
    );
  }

  async function handleDelete() {
    if (!confirm(`Are you sure you want to delete "${participant.name}"? This cannot be undone.`)) {
      return;
    }
    await deleteParticipant(participantId);

    history.push('/participants');
  }

  const transcriptItems =
    participant.transcriptions?.map((x: any) => ({
      key: 'transcript' + x.id,
      icon: <VideoCameraIcon className={'w-6 h-6'} />,
      name: x.name,
      path: `/projects/${x.dashboardId}/data/${x.id}`,
      createdBy: x.userByCreatedBy,
      updatedAt: x.updatedAt,
      dashboard: x.dashboard,
    })) || [];

  const transcriptMItems =
    participant.transcriptionsByNoteParticipantIdAndTranscriptionIdList?.map((x: any) => ({
      key: 'transcript' + x.id,
      icon: <VideoCameraIcon className={'w-6 h-6'} />,
      name: x.name,
      path: `/projects/${x.dashboardId}/data/${x.id}`,
      createdBy: x.userByCreatedBy,
      updatedAt: x.updatedAt,
      dashboard: x.dashboard,
    })) || [];

  const documentItems =
    participant.documents?.map((x: any) => ({
      key: 'document' + x.id,
      icon: <DocumentTextIcon className={'w-6 h-6'} />,
      name: x.name,
      path: `/projects/${x.dashboardId}/data/docs/${x.id}`,
      createdBy: x.userByCreatedBy,
      updatedAt: x.updatedAt,
      dashboard: x.dashboard,
    })) || [];

  const allItems = [...transcriptItems, ...transcriptMItems, ...documentItems].sort((a, b) =>
    a.updatedAt < b.updatedAt ? 1 : -1
  );

  return (
    <div className="flex-col overflow-hidden flex w-full h-auto">
      <div className="flex w-full items-center justify-between border-b border-b-primary-purple-gray px-5 py-1">
        <p className="text-md font-medium">Participants / {participant.name}</p>
        <Button
          className={'mr-2 flex-inline text-red-500'}
          onClick={() => handleDelete()}
          type={'secondary'}
        >
          <TrashIcon className={'w-4 h-4 mr-1'} />
          Delete
        </Button>
      </div>
      <div className="px-16 py-12 overflow-y-scroll">
        <ParticipantEditor participant={participant} />

        <TabHeader>Data</TabHeader>

        {!allItems.length && (
          <EmptyState>
            <div className={'text-center'}>This participant has no data, yet.</div>
          </EmptyState>
        )}

        {!!allItems.length && (
          <TableContainer className="border border-primary-purple-light rounded overflow-x-scroll shadow-base">
            <Table className="min-w-full divide-y divide-gray-200">
              <tbody className="bg-white divide-y divide-gray-200">
                {allItems.map((item: any) => {
                  const icon =
                    defaultProjectIcons.find((icon) => icon.name === item.dashboard?.cover) ||
                    defaultProjectIcons[0];
                  return (
                    <TableRow
                      key={item.key}
                      onClick={() => {
                        history.push(item.path);
                      }}
                    >
                      <TableCell className="p-3 whitespace-nowrap font-medium">
                        <ItemName>
                          <IconContainer>{item.icon}</IconContainer>
                          <div className="text-ellipsis whitespace-nowrap overflow-hidden text-sm max-w-[220px]">
                            {item.name || 'Untitled'}
                          </div>
                        </ItemName>
                      </TableCell>
                      <TableCell className="p-3 whitespace-nowrap">
                        <ItemName>
                          <IconContainer>
                            <img
                              className="rounded-md"
                              src={icon?.url}
                              alt="Project cover"
                              style={{
                                width: 24,
                                height: 24,
                                objectFit: 'cover',
                              }}
                            />
                          </IconContainer>
                          <div className="text-ellipsis whitespace-nowrap overflow-hidden text-sm max-w-[220px]">
                            {item.dashboard.name || 'Untitled'}
                          </div>
                        </ItemName>
                      </TableCell>
                      <TableCell className="p-3 whitespace-nowrap text-right text-primary-purple-light-40">
                        {item.updatedAt ? moment(item.updatedAt).format('MMM D, YYYY') : ''}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </tbody>
            </Table>
          </TableContainer>
        )}
      </div>
    </div>
  );
};

export default ParticipantDetail;
