// import * as FullStory from '@fullstory/browser';
import TagManager from 'react-gtm-module';

declare global {
  interface Window {
    dataLayer?: any;
    amplitude?: any;
  }
}

const useAnalytics = () => {
  window.dataLayer = window.dataLayer || [];

  const analytics = {
    sendEvent: (event: string, data?: Record<string, any>) => {
      if (window.amplitude) window.amplitude.track(event, data);
      if (window.dataLayer) {
        // window.dataLayer.push({
        //   event,
        //   ...data,
        // });
        const tagManagerArgs = {
          dataLayer: {
            event: event,
            pagePath: window.location.href,
            name: event,
            ...data,
          },
          // dataLayerName: 'APPDataLayer',
        };
        TagManager.dataLayer(tagManagerArgs);
      } else {
        console.log('GTM not initialized ', 'event: ', event, 'data: ', data);
      }
      // FullStory.event(event, data || {});
    },
  };

  return { analytics };
};

export default useAnalytics;
