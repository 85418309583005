import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{ minimized?: boolean; left?: number; top?: number }>`
  ${({ minimized, left, top }) =>
    minimized
      ? css`
          position: absolute;
          left: ${left}px;
          top: ${top}px;
          z-index: 3;
        `
      : css`
          position: fixed;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 20px;
          z-index: 999;
        `}
`;

export const Overlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  background-color: #a39ea9;
  transition: opacity 0.25s ease;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  z-index: 999;
`;

export const Container = styled.div<{ minimized?: boolean }>`
  width: 100%;
  max-width: ${({ minimized }) => (minimized ? '290px' : '490px')};
  background-color: #ffffff;
  border-radius: 12px;
  filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.1));
  transition: opacity 0.12s ease-in;
  z-index: 9999;
  opacity: 1;
  display: flex;
  flex-direction: column;
  max-height: ${({ minimized }) => (minimized ? '360px' : '500px')};
  overflow: hidden;
  ${({ minimized }) =>
    minimized &&
    css`
      border: 1px solid #d7d3dc;
      padding-top: 10px;
      z-index: 3;
    `}
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 20px 0;
  margin-bottom: 10px;
`;

export const Title = styled.div`
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
`;

export const CloseButton = styled.div`
  cursor: pointer;
`;

export const Subtitle = styled.div`
  padding: 0 20px;
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  margin-bottom: 10px;
`;

export const SearchInputWrapper = styled.div<{ minimized?: boolean }>`
  padding: ${({ minimized }) => (minimized ? '0 10px' : '0 20px')};
  margin-bottom: 20px;
`;

export const Label = styled.div<{ minimized?: boolean }>`
  padding: ${({ minimized }) => (minimized ? '0 10px' : '0 20px')};
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  margin-bottom: 10px;
`;

export const ItemsList = styled.div<{ minimized?: boolean }>`
  padding: ${({ minimized }) => (minimized ? '0 10px 10px' : '0 10px 20px 10px')};
  width: 100%;
  overflow-y: auto;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 10px 10px 10px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }
`;

export const IconContainer = styled.div`
  flex-shrink: 0;
  margin-right: 10px;
`;

export const ItemIcon = styled.div<{ src: string }>`
  width: 24px;
  height: 24px;
  margin-right: 10px;
  background-image: url(${({ src }) => src});
  background-size: contain;
  background-repeat: no-repeat;
  flex-shrink: 0;
`;

export const ItemName = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
`;

export const LoadingState = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EmptyStateText = styled.div`
  color: rgba(59, 38, 81, 0.4);
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
`;

export const NewProjectContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const NewProjectButtonWrapper = styled.div`
  margin-top: 20px;
`;

export const NewProjectNameWrapper = styled.div`
  padding: 10px;
  margin-bottom: 15px;
`;

export const NewProjectNameContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  border-bottom: 1px solid #d7d3dc;
`;

export const NewProjectIcon = styled.div<{ src: string }>`
  width: 28px;
  height: 26px;
  margin-right: 10px;
  background-image: url(${({ src }) => src});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;
