import React, { FC, useMemo, useState } from 'react';
import { useOrganization } from '../../Hooks/useOrganization';
import { useCreatePrompt } from '../../Hooks/usePrompts';
import { Icon24 } from '../../Icons/Icon';
import Button from '../Button';
import Spinner from '../Spinner';
import { tiersArray as tiers } from '../../Consts/tiers-08-23';

import {
  TemplatePlaceholderContainer,
  TemplatePlaceholderImage,
  TemplatePlaceholderTitle,
  TemplatePlaceholderDescription,
  CustomTemplateButtonText,
  SpinnerWrapper,
  TemplatePlaceholderBadge,
  TemplatePlaceholderBadgeIcon,
  TemplatePlaceholderLearnMore,
} from './styles';

interface TemplatePlaceholderProps {
  type?: 'custom' | 'favorite' | 'search';
  onClickExplore?(): void;
  onClickNewTemplate?(templateId: string): void;
  onViewPlans?(): void;
}

const TemplatePlaceholder: FC<TemplatePlaceholderProps> = ({
  type = 'custom',
  onClickExplore,
  onClickNewTemplate,
  onViewPlans,
}) => {
  const [isCreating, setIsCreating] = useState(false);
  const [createPrompt] = useCreatePrompt();
  const [, org] = useOrganization();
  const currentTier = tiers.find((x) => x.id === org.plan);
  const isEnterpriseOrTeam = currentTier?.id === 'enterprise' || currentTier?.id === 'team';

  const handleCreatePrompt = async () => {
    setIsCreating(true);
    const newPrompt = await createPrompt({
      title: 'New template',
      description: '',
      isEnabled: false,
      mainPrompt: '',
      type: 'insight',
      outputLength: 'short',
    });
    setIsCreating(false);
    onClickNewTemplate
      ? onClickNewTemplate(newPrompt.id)
      : window.open(`/templates/${newPrompt.id}`, '_blank');
  };

  const handleViewPlans = () => {
    onViewPlans ? onViewPlans : window.open('/settings/plans', '_blank');
  };

  const handleCtaClick = () => {
    if (type === 'favorite') {
      onClickExplore ? onClickExplore() : window.open('/templates', '_blank');
      return;
    }

    if (isEnterpriseOrTeam) {
      handleCreatePrompt();
    } else {
      handleViewPlans();
    }
  };

  const currentContent = useMemo(() => {
    switch (type) {
      case 'custom':
        return {
          title: 'Custom Templates',
          description:
            'Ask anything about your research with AI. Create a custom AI template and publish it to your workspace.',
        };
      case 'favorite':
        return {
          title: 'Nothing favorited yet',
          description:
            'Click the heart on templates you like to quickly use them on your research.',
        };
      case 'search':
        return {
          title: 'We couldn’t find a matching template',
          description:
            'Custom AI templates let you ask anything about your research. Create one for your workspace during your 7-day trial.',
        };
    }
  }, [type]);

  const ctaText = useMemo(() => {
    if (type === 'favorite') {
      return 'Explore all templates';
    }
    return isEnterpriseOrTeam ? 'New template' : 'View plans';
  }, [isEnterpriseOrTeam, type]);

  return (
    <TemplatePlaceholderContainer>
      <TemplatePlaceholderImage />
      {!isEnterpriseOrTeam && (
        <TemplatePlaceholderBadge>
          <TemplatePlaceholderBadgeIcon>
            <Icon24.Badge />
          </TemplatePlaceholderBadgeIcon>
          Teams and Enterprise
        </TemplatePlaceholderBadge>
      )}
      <TemplatePlaceholderTitle>{currentContent.title}</TemplatePlaceholderTitle>
      <TemplatePlaceholderDescription>{currentContent.description}</TemplatePlaceholderDescription>

      {isCreating ? (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      ) : (
        <>
          <Button type="secondary" onClick={handleCtaClick}>
            {isEnterpriseOrTeam && <Icon24.Plus />}
            <CustomTemplateButtonText>{ctaText}</CustomTemplateButtonText>
          </Button>

          {!isEnterpriseOrTeam && (
            <TemplatePlaceholderLearnMore
              onClick={() =>
                window.open(
                  'https://help.notably.ai/en/articles/7263946-what-are-ai-summary-templates',
                  '_blank'
                )
              }
            >
              Learn more about AI Templates
            </TemplatePlaceholderLearnMore>
          )}
        </>
      )}
    </TemplatePlaceholderContainer>
  );
};

export default TemplatePlaceholder;
