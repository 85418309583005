import React, { FC, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import usePermissions from '../../Hooks/usePermissions';
import { useOrganization } from '../../Hooks/useOrganization';
import useAnalytics from '../../Hooks/useAnalytics';
import { Container } from './styles';
import { TrashIcon, MicrophoneIcon, ArrowUpOnSquareIcon } from '@heroicons-v2/react/24/outline';
import { StopIcon } from '@heroicons-v2/react/24/solid';
import { saveRecordingToDB, getRecordingsFromDB, deleteRecordingFromDB } from '../../Utils/audioDB';
import { nanoid } from 'nanoid';

function getCurrentDateTime() {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

declare global {
  type MediaRecorder = {
    new (stream: MediaStream, options?: MediaRecorderOptions): MediaRecorder;
    start(timeslice?: number): void;
    stop(): void;
    pause(): void;
    resume(): void;
    ondataavailable: (event: BlobEvent) => void;
    onstop?: (event: Event) => void;
    onerror?: (event: Event) => void;
    mimeType: string;
    state: 'inactive' | 'recording' | 'paused';
    stream: MediaStream;
  };

  interface BlobEvent extends Event {
    data: Blob;
  }

  interface MediaRecorderOptions {
    mimeType?: string;
    audioBitsPerSecond?: number;
    videoBitsPerSecond?: number;
    bitsPerSecond?: number;
  }
}

const Recorder: FC = () => {
  const history = useHistory();
  // const [, org] = useOrganization();
  // const { analytics } = useAnalytics();

  const [isRecording, setIsRecording] = useState(false);
  const [recordings, setRecordings] = useState<{ name: string; id: string; blob: Blob }[]>([]);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);
  const audioContextRef = useRef<AudioContext | null>(null);
  const analyserRef = useRef<AnalyserNode | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const animationRef = useRef<number | null>(null);

  useEffect(() => {
    const loadRecordings = async () => {
      const savedRecordings = await getRecordingsFromDB();
      setRecordings(savedRecordings);
    };

    loadRecordings();
    drawWaveform();
  }, []);

  const startRecording = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const mediaRecorder = new (window as any).MediaRecorder(stream);
    mediaRecorderRef.current = mediaRecorder;

    const audioContext = new AudioContext();
    audioContextRef.current = audioContext;
    const source = audioContext.createMediaStreamSource(stream);
    const analyser = audioContext.createAnalyser();
    analyser.fftSize = 2048;
    analyserRef.current = analyser;
    source.connect(analyser);

    drawWaveform();

    mediaRecorder.ondataavailable = (event: any) => {
      audioChunksRef.current.push(event.data);
      // TODO: save 10 sec chunks to indexedDB
    };

    mediaRecorder.onstop = async () => {
      const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/mp3' });
      const url = URL.createObjectURL(audioBlob);
      await saveRecordingToDB(nanoid(10), { blob: audioBlob, name: getCurrentDateTime() });
      audioChunksRef.current = [];
      const updatedRecordings = await getRecordingsFromDB();
      setRecordings(updatedRecordings);

      audioContext.close();
      if (animationRef.current) cancelAnimationFrame(animationRef.current);
    };

    mediaRecorder.start(10000);
    setIsRecording(true);
  };

  const stopRecording = () => {
    mediaRecorderRef.current?.stop();
    setIsRecording(false);
  };

  const deleteRecording = async (id: string) => {
    if (!confirm('Are you sure you want to delete this recording?')) return;
    await deleteRecordingFromDB(id);
    const updatedRecordings = await getRecordingsFromDB();
    setRecordings(updatedRecordings);
  };

  const drawWaveform = () => {
    if (!canvasRef.current || !analyserRef.current) return;

    const canvas = canvasRef.current;
    const canvasContext = canvas.getContext('2d');
    const analyser = analyserRef.current;
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    const draw = () => {
      if (!canvasContext) return;
      analyser.getByteTimeDomainData(dataArray);

      canvasContext.fillStyle = '#eee';
      canvasContext.fillRect(0, 0, canvas.width, canvas.height);

      canvasContext.lineWidth = 2;
      canvasContext.strokeStyle = '#3B2651';
      canvasContext.beginPath();

      const sliceWidth = canvas.width / bufferLength;
      let x = 0;

      for (let i = 0; i < bufferLength; i++) {
        const v = dataArray[i] / 128.0;
        const y = (v * canvas.height) / 2;

        if (i === 0) {
          canvasContext.moveTo(x, y);
        } else {
          canvasContext.lineTo(x, y);
        }

        x += sliceWidth;
      }

      canvasContext.lineTo(canvas.width, canvas.height / 2);
      canvasContext.stroke();

      animationRef.current = requestAnimationFrame(draw);
    };

    draw();
  };

  return (
    <Container>
      <h2 className="font-semibold text-xl py-2 mb-2">New recording</h2>
      <div className="flex items-center">
        <div className="rounded-xl" style={{ backgroundColor: '#eee' }}>
          <canvas ref={canvasRef} width={750} height={60} className="rounded-xl"></canvas>
        </div>

        {isRecording ? (
          <button
            onClick={stopRecording}
            className="rounded-full w-10 h-10 flex items-center justify-center ml-2 border border-1 border-primary-purple"
            // style={{ backgroundColor: '#fff' }}
          >
            <StopIcon stroke="#3B2651" className={'h-5 w-5 cursor-pointer'} />
          </button>
        ) : (
          <button
            onClick={startRecording}
            className="rounded-full w-10 h-10 flex items-center justify-center ml-2"
            style={{ backgroundColor: 'red' }}
          >
            <MicrophoneIcon stroke="#fff" className={'h-5 w-5 cursor-pointer'} />
          </button>
        )}
      </div>

      <h3 className="font-semibold text-lg mt-5">Saved Recordings</h3>
      {recordings.map((recording, index) => (
        <div className="my-5" key={recording.id}>
          <div className="font-medium text-sm">{recording.name}</div>
          <div className="flex  items-center mt-2">
            <audio
              controls
              src={URL.createObjectURL(recording.blob)}
              className="flex-1 mr-2"
            ></audio>
            <ArrowUpOnSquareIcon
              stroke="#3B2651"
              className={'h-5 w-5 cursor-pointer mr-2'}
              onClick={() => history.push(`/projects/upload?recordingId=${recording.id}`)}
            />
            <TrashIcon
              stroke="#3B2651"
              className={'h-5 w-5 cursor-pointer'}
              onClick={() => deleteRecording(recording.id)}
            />
          </div>
        </div>
      ))}
    </Container>
  );
};

export default Recorder;
