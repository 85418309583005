import styled, { css } from 'styled-components';

export const VideoWrapper = styled.div`
  width: 100%;
`;

export const VideoContainer = styled.div<{ isMinimized?: boolean }>`
  position: relative;
  ${({ isMinimized }) =>
    isMinimized &&
    css`
      position: fixed;
      left: 12px;
      bottom: 72px;
      width: 22vw;
      z-index: 3;
    `}
`;

export const ControlPanel = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(56, 33, 82, 0.8);
  padding: 5px 10px;
`;

export const ProgressBar = styled.div`
  position: absolute;
  left: 0;
  top: -4px;
  width: 100%;
  height: 4px;
  background-color: #ffffff;
  cursor: pointer;
`;

export const ControlButton = styled.div`
  color: white;
  cursor: pointer;
  transition: color 0.2s;
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    color: #f0f0f0;
  }
`;

export const Time = styled.div`
  position: absolute;
  width: 100%;
  max-width: 100px;
  top: calc(50% - 9px);
  left: calc(50% - 50px);
  color: white;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  text-align: center;
`;

export const ControlRightButtons = styled.div`
  display: flex;
  align-items: center;
`;

export const ProgressLine = styled.div`
  position: relative;
  width: 20%;
  height: 100%;
  background-color: #5d00be;
`;

export const ProgressLineCircle = styled.div`
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: #5d00be;
  border-radius: 50%;
  top: -2px;
  right: -4px;
`;
