import { toast } from 'react-toastify';
import useTranscripts from './useTranscripts';
import { useHistory } from 'react-router';
import React, { useContext } from 'react';
import useFiles from './useFiles';
import axios from 'axios';
import { getFileType } from '../Utils/fileTypes';
import { UploadFilesContext } from '../Context/UploadFilesContext';
import { useIntercom } from 'react-use-intercom';
import { TPrompt } from './usePrompts';
import { CloudFile } from '../Models';
import { useMutation } from '@apollo/client';
import {
  UPLOAD_FROM_GOOGLE_TO_S3,
  UPLOAD_FROM_ONEDRIVE_TO_S3,
  IMPORT_PDF,
} from '../GraphQL/mutations';
import { useCreateDocument } from './useDocuments';
import StarterKit from '@tiptap/starter-kit';
import Image from '@tiptap/extension-image';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import { generateJSON } from '@tiptap/react';
import { PropmptTemplate } from '../Consts/promptTemplates';

export const useUploadFromGoogleToS3 = (): ((
  cloudFileId: string,
  fileId: string,
  fileName: string
) => Promise<{ success: boolean }>) => {
  const [uploadFromGoogleToS3Mutation] = useMutation(UPLOAD_FROM_GOOGLE_TO_S3);

  return async (cloudFileId: string, fileId: string, fileName: string) => {
    const res = await uploadFromGoogleToS3Mutation({
      variables: {
        cloudFileId,
        fileId,
        fileName,
      },
    });
    return res?.data?.uploadFromGoogleToS3;
  };
};

export const useUploadFromOneDriveToS3 = (): ((
  cloudFileId: string,
  fileId: string,
  fileName: string
) => Promise<{ success: boolean }>) => {
  const [uploadFromOneDriveToS3Mutation] = useMutation(UPLOAD_FROM_ONEDRIVE_TO_S3);

  return async (cloudFileId: string, fileId: string, fileName: string) => {
    const res = await uploadFromOneDriveToS3Mutation({
      variables: {
        cloudFileId,
        fileId,
        fileName,
      },
    });
    return res?.data?.uploadFromOneDriveToS3;
  };
};

export default function useUpload() {
  const { srtToTiptap, createTranscript } = useTranscripts();
  const [createDocument] = useCreateDocument();
  const { createFile, uploadFileToS3, importFile, updateFile } = useFiles();
  const { setRequestCancelSource } = useContext(UploadFilesContext);
  const uploadFromGoogleToS3 = useUploadFromGoogleToS3();
  const uploadFromOneDriveToS3 = useUploadFromOneDriveToS3();

  const [importPDFMutation] = useMutation(IMPORT_PDF);

  const intercom = useIntercom();

  const history = useHistory();

  function handleTranscriptUpload(
    dashboardId: string,
    file: File,
    options?: {
      redirectAfterUpload: boolean;
      template?: PropmptTemplate | null;
      callback?: (fileId: string) => void;
    }
  ) {
    const toastId = toast.loading(<>Uploading transcript please wait...</>);

    if (!file.name.endsWith('.srt') && !file.name.endsWith('.vtt')) {
      toast.update(toastId, {
        render: <>Only .srt and .vtt files are supported!</>,
        type: 'error',
        isLoading: false,
        autoClose: 1000,
      });
      return;
    }

    const reader = new FileReader();
    reader.onload = function (event) {
      if (!event.target?.result) {
        return;
      }

      const srt = event.target.result as string;
      try {
        createTranscript(dashboardId, {
          name: file.name.split('/').pop(),
          text: JSON.stringify(srtToTiptap(srt, options?.template)),
        }).then((transcript) => {
          toast.update(toastId, {
            render: <>Successfully uploaded transcript!</>,
            type: 'success',
            isLoading: false,
            autoClose: 1000,
          });

          options?.callback?.(transcript.id);
          if (options?.redirectAfterUpload) {
            history.push(`/projects/${dashboardId}/data/${transcript.id}`);
          }
        });
      } catch (e) {
        intercom.showNewMessage(
          `Hi, I'm having trouble uploading a transcript. Please help me out.`
        );
        throw e;
      }
    };

    reader.readAsText(file);
  }

  async function handlePdfUpload(
    dashboardId: string,
    file: File,
    options?: {
      redirectAfterUpload: boolean;
      template?: PropmptTemplate | null;
      callback?: (fileId: string) => void;
    }
  ) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onerror = (error) => reject(error);

      reader.onload = async (event) => {
        if (!event.target?.result) {
          reject(new Error('No result from file reader'));
          return;
        }
        const toastId = toast.loading(`Processing ${file.name}, please wait...`);
        try {
          const base64content = event.target?.result as string;

          const res = await importPDFMutation({
            variables: {
              dashboardId,
              content: base64content.split(',')[1],
            },
          });

          if (res?.data?.importPDF.success) {
            const jsonContent = generateJSON(res?.data?.importPDF.html, [
              StarterKit,
              Table,
              TableCell,
              TableRow,
              TableHeader,
              Image,
            ]).content;

            if (options?.template) {
              jsonContent.unshift({
                type: 'summary',
                content: [],
                attrs: {
                  id: Math.floor(Math.random() * Date.now()).toString(16),
                  templateTitle: options.template.title,
                  templateId: options.template.id,
                  status: 'readyToStartGeneration',
                },
              });
            }

            const document = await createDocument(dashboardId, {
              content: JSON.stringify({ type: 'doc', content: jsonContent }),
              name: file.name,
              folderId: null,
            });

            options?.callback?.(document.id);
            if (options?.redirectAfterUpload) {
              history.push(`/projects/${dashboardId}/data/docs/${document.id}`);
            }
          }
          toast.update(toastId, {
            render: `Successfully uploaded ${file.name}!`,
            type: 'success',
            isLoading: false,
            autoClose: 2000,
          });
          resolve(res?.data?.importPDF.success);
        } catch (error) {
          toast.update(toastId, {
            render: `Failed to process ${file.name}!`,
            type: 'error',
            isLoading: false,
            autoClose: 3000,
          });
          reject(error);
        }
      };
      reader.readAsDataURL(file);
    });
  }

  async function handleDocxUpload(
    dashboardId: string,
    file: File,
    options?: {
      redirectAfterUpload: boolean;
      template?: PropmptTemplate | null;
      callback?: (fileId: string) => void;
    }
  ) {
    const reader = new FileReader();
    reader.onload = async (event) => {
      if (!event.target?.result) {
        return;
      }
      const mammoth = await import('mammoth');
      const result = await mammoth.convertToHtml({
        arrayBuffer: event.target.result as ArrayBuffer,
      });
      const jsonContent = generateJSON(result.value, [
        StarterKit,
        Table,
        TableCell,
        TableRow,
        TableHeader,
        Image,
      ]).content;

      if (options?.template) {
        jsonContent.unshift({
          type: 'summary',
          content: [],
          attrs: {
            id: Math.floor(Math.random() * Date.now()).toString(16),
            templateTitle: options.template.title,
            templateId: options.template.id,
            status: 'readyToStartGeneration',
          },
        });
      }

      const document = await createDocument(dashboardId, {
        content: JSON.stringify({ type: 'doc', content: jsonContent }),
        name: file.name,
        folderId: null,
      });

      options?.callback?.(document.id);
      if (options?.redirectAfterUpload) {
        history.push(`/projects/${dashboardId}/data/docs/${document.id}`);
      }
    };
    reader.readAsArrayBuffer(file);
  }

  async function handleTxtUpload(
    dashboardId: string,
    file: File,
    options?: {
      redirectAfterUpload: boolean;
      template?: PropmptTemplate | null;
      callback?: (fileId: string) => void;
    }
  ) {
    const reader = new FileReader();
    reader.onload = async (event) => {
      if (!event.target?.result) {
        return;
      }

      const text = event.target.result as string;
      const content: any = [
        {
          type: 'paragraph',
          content: [
            {
              type: 'text',
              text: text,
            },
          ],
        },
      ];

      if (options?.template) {
        content.unshift({
          type: 'summary',
          content: [],
          attrs: {
            id: Math.floor(Math.random() * Date.now()).toString(16),
            templateTitle: options.template.title,
            templateId: options.template.id,
            status: 'readyToStartGeneration',
          },
        });
      }

      const document = await createDocument(dashboardId, {
        content: JSON.stringify({ type: 'doc', content }),
        name: file.name,
        folderId: null,
      });

      options?.callback?.(document.id);
      if (options?.redirectAfterUpload) {
        history.push(`/projects/${dashboardId}/data/docs/${document.id}`);
      }
    };

    reader.readAsText(file);
  }

  async function handleFileUpload(
    dashboardId: string,
    file: File,
    options?: {
      onProgressChange?(progress: number): void;
      language?: string;
      speakerCount?: number;
      aiTemplate?: string;
      callback?: (fileId: string) => void;
    }
  ) {
    const cancel = axios.CancelToken.source();
    setRequestCancelSource(file.name, cancel);

    function updateProgress(event: ProgressEvent) {
      options && options.onProgressChange && options.onProgressChange(event.loaded / event.total);
    }

    try {
      const result = await uploadFileToS3(file, {
        cancelToken: cancel.token,
        onProgress: updateProgress,
      });

      if (!result.ok) {
        toast.error('Error uploading file');
        window.Intercom('showNewMessage', 'Hi, I am having trouble uploading a file.');
        throw new Error('Error uploading file');
      }

      const res = await createFile(dashboardId, {
        name: file.name,
        type: getFileType(file.name),
        mimeType: file.type,
        status: 'PROCESSING',
        s3VideoPath: result.path,
        language: options?.language,
        aiTemplate: options?.aiTemplate,
        speakers: options?.speakerCount || 4,
      });

      if (res?.id) {
        const transcript = await createTranscript(dashboardId, {
          name: file.name.split('/').pop(),
          fileId: res?.id,
        });
        options?.callback?.(transcript.id);
      }
    } catch (e) {
      if (axios.isCancel(e)) {
        return;
      }
      return;
    }
  }

  async function handleCloudFileUpload(
    dashboardId: string,
    file: CloudFile,
    options?: {
      language?: string;
      speakerCount?: number;
      aiTemplate?: PropmptTemplate | null;
      callback?: (fileId: string) => void;
    }
  ) {
    try {
      if (file.source === 'google') {
        const res = await createFile(dashboardId, {
          name: file.name,
          type: 'video',
          mimeType: file.mimeType,
          status: 'PROCESSING',
          language: options?.language,
          speakers: options?.speakerCount || 4,
          aiTemplate: options?.aiTemplate?.id,
        });

        if (res?.id) {
          const transcript = await createTranscript(dashboardId, {
            name: file.name.split('/').pop(),
            fileId: res?.id,
          });
          options?.callback?.(transcript.id);
          const result = await uploadFromGoogleToS3(file.sourceId, res.id, file.name);

          if (!result.success) {
            throw new Error('Error uploading file');
          }
        }
      }
      if (file.source === 'onedrive') {
        const res = await createFile(dashboardId, {
          name: file.name,
          type: 'video',
          mimeType: file.mimeType,
          status: 'PROCESSING',
          language: options?.language,
          aiTemplate: options?.aiTemplate?.id,
        });

        if (res?.id) {
          const transcript = await createTranscript(dashboardId, {
            name: file.name.split('/').pop(),
            fileId: res?.id,
          });

          options?.callback?.(transcript.id);
          const result = await uploadFromOneDriveToS3(file.sourceId, res.id, file.name);

          if (!result.success) {
            throw new Error('Error uploading file');
          }
        }
      }
      if (file.source === 'zoom') {
        if (!file.sourceUrl || !file.status) return;
        const result = await importFile(dashboardId, file.name, file.sourceUrl, file.status);
        if (result.created) {
          await updateFile(result.file.id, {
            language: options?.language,
            aiTemplate: options?.aiTemplate?.id,
          });
          const transcript = await createTranscript(dashboardId, {
            name: file.name,
            fileId: result.file.id,
            text: file.transcript
              ? JSON.stringify(srtToTiptap(file.transcript, options?.aiTemplate))
              : null,
          });
          options?.callback?.(transcript.id);
        }
      }
    } catch (e) {
      console.log(e);
      return;
    }
  }

  return {
    handleTranscriptUpload,
    handleFileUpload,
    handleCloudFileUpload,
    handleTxtUpload,
    handleDocxUpload,
    handlePdfUpload,
  };
}
