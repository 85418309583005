import React, { useMemo } from 'react';
import { TTagGroupWithTags, TagWithDetails } from '../../Models';
import { withGlobalAndProjectTagManagement, withGlobalTagManagement } from '.';

interface WithTagManagementOptons {
  value?: TagWithDetails[];
  selectedText?: string;
  hideManageButton?: boolean;
  onlyGlobalTags?: boolean;
  reverse?: boolean;
  onChange?(tags: TagWithDetails[]): void;
  onCreate?(tag: TagWithDetails): void;
  onDelete?(tag: TagWithDetails): void;
  onBlur?(): void;
}

export interface PassedComponentProps {
  groups: TTagGroupWithTags[];
  tagsWithoutGroup: TagWithDetails[];
  value?: TagWithDetails[];
  dashboardId?: string;
  suggestedTags?: TagWithDetails[];
  isLoading?: boolean;
  hideManageButton?: boolean;
  onRefetchTags(): void;
  onChange?(tags: TagWithDetails[]): void;
  onCreate?(tagName: string): Promise<TagWithDetails>;
  onBlur?(): void;
  onUpdate?(): void;
}

const withTagManagement = (
  PassedComponent: React.ComponentType<PassedComponentProps>
): React.FC<WithTagManagementOptons> => {
  const WrappedComponent: React.FC<WithTagManagementOptons> = ({ onlyGlobalTags, ...props }) => {
    const Component = useMemo(
      () =>
        onlyGlobalTags
          ? withGlobalTagManagement(PassedComponent)
          : withGlobalAndProjectTagManagement(PassedComponent),
      [onlyGlobalTags]
    );
    return <Component {...props} />;
  };

  return WrappedComponent;
};

export default withTagManagement;
