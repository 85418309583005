import React, { FC, useRef, useState } from 'react';
import { Container, SliderContainer, SliderThumb, SliderTrack } from './styles';

const THUMB_HEIGHT = 14;

interface SliderProps {
  onChange: (value: number) => void;
}

const Slider: FC<SliderProps> = ({ onChange }) => {
  const [currentTrackHeight, setCurrentTrackHeight] = useState(96);

  const trackRef = useRef<HTMLDivElement>(null);
  const sliderContainerRef = useRef<HTMLDivElement>(null);

  const addThumbMoveHandler = (handleMove: (event: MouseEvent) => void): void => {
    const handleMouseUp = (): void => {
      window.removeEventListener('mousemove', handleMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };

    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('mousemove', handleMove);
  };

  const handleThumbMove = (clientY: number): void => {
    const containerElt = sliderContainerRef.current;
    if (!containerElt) return;

    const { top, height } = containerElt.getBoundingClientRect();
    let thumbPosition = height - clientY + top;

    if (thumbPosition > height - THUMB_HEIGHT) thumbPosition = height - THUMB_HEIGHT;
    if (thumbPosition < 0) thumbPosition = 0;

    setCurrentTrackHeight(thumbPosition);
    onChange((thumbPosition / (height - THUMB_HEIGHT)) * 100);
  };

  return (
    <Container>
      <SliderContainer ref={sliderContainerRef}>
        <SliderTrack
          ref={trackRef}
          style={{
            height: `${currentTrackHeight + 2}px`,
          }}
        >
          <SliderThumb
            onMouseDown={(event) => {
              event.preventDefault();
              addThumbMoveHandler((event) => handleThumbMove(event.clientY));
            }}
            onTouchMove={(event) => {
              event.preventDefault();
              handleThumbMove(event.touches[0].clientY);
            }}
          />
        </SliderTrack>
      </SliderContainer>
    </Container>
  );
};

export default Slider;
