import React, { ReactNode, useEffect, useState } from 'react';
import { Menu } from '@headlessui/react';
import {
  BellIcon,
  ChevronDownIcon,
  CogIcon,
  LogoutIcon,
  MenuIcon,
  QuestionMarkCircleIcon,
  ChatIcon,
  XIcon,
} from '@heroicons/react/outline';
import { Link, useRouteMatch } from 'react-router-dom';
import { DropDownMenu } from '../DropDownMenu';
import { useAuth0 } from '@auth0/auth0-react';
import styled from 'styled-components';
import useUsers from '../../Hooks/useUsers';
import { useOrganization } from '../../Hooks/useOrganization';
import { defaultUserImages } from '../../Consts/userImages';
import usePermissions from '../../Hooks/usePermissions';
import GlobalSearch from '../GlobalSearch/GlobalSearch';
import OrgLogo from '../OrgLogo';
import { Icon24 } from '../../Icons/Icon';
import FullLogo from '../../assets/logo-full.svg';

import {
  TrialUpgradeLink,
  MobileMenuContainer,
  MobileMenuLink,
  MobileLogoWrapper,
  CloseButton,
  FullLogoContainer,
  NewButtonWrapper,
} from './styles';
import moment from 'moment';
import useMediaQuery from '../../Hooks/useMediaQuery';
import Button from '../Button';
import NewDocButton from '../NewDocButton/NewDocButton';

let navigation = [] as { name: string; href: string; icon?: ReactNode; current: boolean }[];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const Header2 = ({
  showUpgradeBtn,
  showTrialUpgradeButton,
}: {
  showUpgradeBtn: boolean;
  showTrialUpgradeButton?: boolean;
}): JSX.Element | null => {
  const { logout, user: auth0User } = useAuth0();
  const { currentUser } = useUsers();
  const { canChangePlan, canEditTeam, canEditData } = usePermissions();
  const [, user] = currentUser();
  const [, org] = useOrganization();
  const { isPhone } = useMediaQuery();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const isOnFreePlan = org?.plan === 'free';
  const currImage =
    defaultUserImages.find((image) => image.name === user?.picture) || defaultUserImages[0];
  const match = useRouteMatch('/projects/:id/notes*');

  const isParticipantPage = useRouteMatch('/participants/');
  const isTemplatesPage = useRouteMatch('/templates');
  const isMainPage = useRouteMatch({
    path: '/',
    exact: true,
  });
  const isProjectPage = useRouteMatch('/projects/');

  useEffect(() => {
    (window as any).Canny('initChangelog', {
      appID: '62033de15bbd1f33c812fffb',
      position: 'bottom',
      align: 'right',
    });
  });

  useEffect(() => {
    if (mobileMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
  }, [mobileMenuOpen]);

  navigation = [
    { name: 'Home', href: '/', current: Boolean(isMainPage) },
    { name: 'All Projects', href: '/projects', current: Boolean(isProjectPage) },
    { name: 'Participants', href: '/participants', current: Boolean(isParticipantPage) },
    { name: 'Templates', href: '/templates', current: Boolean(isTemplatesPage) },
  ];

  if (match?.isExact) {
    return null;
  }

  return (
    <nav className="bg-white z-100 border-b border-gray-200 sticky top-0">
      <div className="mx-auto px-2 sm:px-5 lg:px-5 flex py-[4px]">
        {!isPhone && (
          <div className="flex items-center">
            <Link to={'/'}>
              <OrgLogo />
            </Link>
          </div>
        )}
        <div className="relative flex items-center justify-between h-13 flex-1">
          <div className="inset-y-0 left-0 flex items-center md:hidden">
            {navigation.length > 0 && (
              <div
                onClick={() => setMobileMenuOpen(true)}
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-600 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              >
                <span className="sr-only">Open main menu</span>
                {mobileMenuOpen ? (
                  <XIcon className="block h-6 w-6" aria-hidden="true" />
                ) : (
                  <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                )}
              </div>
            )}
          </div>
          <div className="flex items-center justify-center sm:items-stretch sm:justify-start">
            <div className="hidden md:block sm:ml-6">
              <div className="flex space-x-1 lg:space-x-4">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className={classNames(
                      item.current ? 'bg-secondary-purple-light' : '',
                      'px-2 py-1 lg:px-3 lg:py-2 rounded-md font-medium text-primary-purple whitespace-nowrap'
                    )}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
            </div>
          </div>
          <div className="flex flex-1 justify-between">
            <GlobalSearch />
            {canEditData && (
              <NewButtonWrapper>
                <NewDocButton />
              </NewButtonWrapper>
            )}
          </div>
          <div className="flex sm:ml-6">
            <div className="inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:pr-0 pl-2">
              {showTrialUpgradeButton && canChangePlan && (
                <div className="hidden md:flex">
                  <TrialUpgradeButton trialExpireAt={org.trialExpireAt} />
                </div>
              )}
              <button data-canny-changelog className="mr-4">
                <BellIcon className="w-5 h-5" />
              </button>
              <DropDownMenu
                button={
                  <Menu.Button className="flex flex-row items-center flex-shrink">
                    <img className="rounded-full h-6 w-6" src={currImage.url} />
                    <div className="pr-2 pl-1">
                      <ChevronDownIcon className=" w-2 h-2" />
                    </div>
                  </Menu.Button>
                }
              >
                <Menu.Item>
                  <Link
                    to={`/settings/${
                      isOnFreePlan && canChangePlan ? 'plans' : canEditTeam ? 'users' : 'profile'
                    }`}
                    className="group flex items-center px-4 py-2 text-sm hover:text-gray-500 hover:bg-gray-100"
                  >
                    <CogIcon className="mr-3 h-5 w-5" />
                    Settings
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <a
                    href="https://notably.canny.io"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="group flex items-center px-4 py-2 text-sm hover:text-gray-500 hover:bg-gray-100"
                  >
                    <ChatIcon className="mr-3 h-5 w-5" />
                    Give feedback
                  </a>
                </Menu.Item>
                <Menu.Item>
                  <a
                    rel="noreferrer"
                    href="https://help.notably.ai"
                    target="_blank"
                    className="group flex items-center px-4 py-2 text-sm hover:text-gray-500 hover:bg-gray-100"
                  >
                    <QuestionMarkCircleIcon className="mr-3 h-5 w-5" />
                    Help Center
                  </a>
                </Menu.Item>
                <Menu.Item>
                  <a
                    href="#"
                    onClick={() =>
                      logout({
                        returnTo: `${window.location.protocol}//${window.location.hostname}${
                          window.location.port ? ':' + window.location.port : ''
                        }`,
                      })
                    }
                    className="group flex items-center px-4 py-2 text-sm hover:text-gray-500 hover:bg-gray-100"
                  >
                    <LogoutIcon className="mr-3 h-5 w-5" />
                    Logout
                  </a>
                </Menu.Item>
              </DropDownMenu>
              {showUpgradeBtn && canChangePlan && (
                <UpgradeSection>
                  <UpgradeLink to="/settings/plans">Upgrade</UpgradeLink>
                </UpgradeSection>
              )}
            </div>
          </div>
        </div>
      </div>

      <MobileMenuContainer isOpen={mobileMenuOpen}>
        <MobileLogoWrapper>
          <Link to={'/'}>
            <FullLogoContainer src={FullLogo} />
          </Link>

          <CloseButton onClick={() => setMobileMenuOpen(false)}>
            <Icon24.Close />
          </CloseButton>
        </MobileLogoWrapper>

        {showTrialUpgradeButton && canChangePlan && (
          <TrialUpgradeButton trialExpireAt={org.trialExpireAt} />
        )}

        {navigation.map((item) => (
          <MobileMenuLink
            key={item.name}
            href={item.href}
            selected={!!item.current}
            aria-current={item.current ? 'page' : undefined}
          >
            {item.name}
          </MobileMenuLink>
        ))}
      </MobileMenuContainer>
    </nav>
  );
};

const UpgradeSection = styled.div`
  border-left: 1px solid #d7d3dc;
  padding-left: 8px;
  display: flex;
`;
const UpgradeLink = styled(Link)`
  font-weight: 500;
  font-size: 16px;
`;

function TrialUpgradeButton({ trialExpireAt }: { trialExpireAt: string }): JSX.Element {
  const daysLeft = Math.max(moment(trialExpireAt).diff(moment(), 'days') + 1, 0);
  const daysWord = daysLeft === 1 ? 'day' : 'days';
  return (
    <div className="flex items-center space-before ml-auto mb-4 md:mb-0">
      <div className="flex flex-1 items-center text-sm pr-4 justify-end md:hidden lg:flex">{`${daysLeft} ${daysWord} left in trial`}</div>
      <TrialUpgradeLink to="/settings/plans">Upgrade</TrialUpgradeLink>
    </div>
  );
}

export default Header2;
