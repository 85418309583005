import styled from 'styled-components';
import { breakpoint } from '../../Shared';

export const Wrapper = styled.div<{ isOpen: boolean }>`
  position: fixed;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  padding: 0 20px;

  ${({ isOpen }) =>
    isOpen &&
    `
    width: 100%;
    height: 100%;
  `}

  ${breakpoint.Tablet`
    padding: 0;
  `}
`;

export const Overlay = styled.div<{ isOpen: boolean }>`
  position: fixed;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  background-color: #a39ea9;
  transition: opacity 0.25s ease;
  opacity: 0;
  z-index: -1;

  ${({ isOpen }) =>
    isOpen &&
    `
    width: 100%;
    height: 100%;
    opacity: 0.4;
    z-index: 999;
  `}
`;

export const Container = styled.div<{ isOpen: boolean; maxHeight: number; maxWidth: number }>`
  width: 100%;
  height: 100%;
  max-width: ${({ maxWidth }) => maxWidth}px;
  max-height: ${({ maxHeight }) => maxHeight}px;
  background-color: #ffffff;
  border-radius: 6px;
  filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.1));
  transition: opacity 0.12s ease-in;
  pointer-events: none;
  z-index: 1;
  opacity: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  ${({ isOpen }) =>
    isOpen &&
    `
    z-index: 9999;
    pointer-events: all;
    opacity: 1;
  `}

  ${breakpoint.Tablet`
    max-width: 100%;
    max-height: 100%;
  `}
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
`;

export const Title = styled.div`
  font-size: 18px;
  line-height: 32px;
  font-weight: 600;
`;

export const CloseIcon = styled.div`
  cursor: pointer;
`;

export const TasksContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;
