import { Editor } from '@tiptap/react';
import React, { FC, useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { Icon24 } from '../../Icons/Icon';
import NestedDropdown from '../NestedDropdown';
import { TNestedDropdownItem } from '../NestedDropdown/NestedDropdown';
import { fillerWords } from '../../Consts/fillerWords';

import { EditorContext } from '../../Context/EditorContext';
import PortalNew from '../PortalNew';
import SummaryGenerationModal from '../SummaryGenerationModal';
import { useDashboardTags } from '../../Hooks/useTags';
import { ProjectChecklistContext } from '../../Context/ProjectChecklistContext';
import { useGenerateSummary } from '../../Hooks/useSummary';
import { TagWithDetails } from '../../Models';
import { PropmptTemplate } from '../../Consts/promptTemplates';
import DefaultButton from '../Button';

import {
  Container,
  CleanConfirmationButton,
  CleanConfirmationButtons,
  CleanConfirmationPanel,
  CleanConfirmationText,
  HighlightPopupWrapper,
} from './styles';
import SearchAndReplacePanel from '../SearchAndReplacePanel';
import HighlightPopup from '../HighlightPopup';

export type SummaryGenerationData = {
  content: string[];
  documentId?: string;
  transcriptId?: string;
};

interface PostyAIButtonProps {
  dashboardId: string;
  getSummaryGenerationData?(editor: Editor | null): SummaryGenerationData;
  getHighlightsGenerationData?(editor: Editor | null): { content: string };
}

const PostyAIButton: FC<PostyAIButtonProps> = ({
  dashboardId,
  getSummaryGenerationData,
  getHighlightsGenerationData,
}) => {
  const [showConfirmClean, setShowConfirmClean] = useState(false);
  const [showPIIEmptyResultPanel, setShowPIIEmptyResultPanel] = useState(false);
  const [showPIICleanConfirm, setShowPIICleanConfirm] = useState(false);
  const [showEmptyResultPanel, setShowEmptyResultPanel] = useState(false);
  const [generationModalOpen, setGenerationModalOpen] = useState(false);
  const [, tags, refetchTags] = useDashboardTags(dashboardId, true, true);
  const [showHighlightPopup, setShowHighlightPopup] = useState(false);
  const { highlightAll, highlightsGenerating, editor, setShowSearchAndReplace } = useContext(
    EditorContext
  );
  const { markSummaryAdded } = useContext(ProjectChecklistContext);
  const { generateDataSummary } = useGenerateSummary();
  const cleanResults = editor?.storage.documentCleaning?.results || [];
  const piiResults = editor?.storage.cleanPII?.results || [];
  const highlightPopupRef = useRef<HTMLDivElement>(null);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.keyCode === 114 || ((e.ctrlKey || e.metaKey) && e.keyCode === 70)) {
        e.preventDefault();
        e.stopPropagation();
        setShowSearchAndReplace(true);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (cleanResults && cleanResults.length === 1 && cleanResults[0].type === 'empty') {
      setShowEmptyResultPanel(true);
      setTimeout(() => {
        setShowEmptyResultPanel(false);
        editor?.commands.setFillerWords(null);
      }, 2000);
      return;
    }

    if (cleanResults && cleanResults.length) {
      setShowConfirmClean(true);
    }
  }, [cleanResults]);

  useEffect(() => {
    if (piiResults && piiResults.length === 1 && piiResults[0].type === 'empty') {
      setShowPIIEmptyResultPanel(true);
      setTimeout(() => {
        setShowPIIEmptyResultPanel(false);
        editor?.commands.togglePIISearch(false);
      }, 2000);
      return;
    }

    if (piiResults && piiResults.length) {
      setShowPIICleanConfirm(true);
    }
  }, [piiResults]);

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (!highlightPopupRef.current?.contains(e.target as Node)) {
        e.stopPropagation();
        setShowHighlightPopup(false);
      }
    };

    window.addEventListener('mousedown', handleClick);

    return () => {
      window.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const handleCancelCleaning = (e: React.MouseEvent) => {
    e.stopPropagation();
    editor?.commands.setFillerWords(null);
    setShowConfirmClean(false);
  };

  const handleConfirmCleaning = (e: React.MouseEvent) => {
    e.stopPropagation();
    editor?.commands.cleanAll();
    setShowConfirmClean(false);
    editor?.commands.setFillerWords(null);
  };

  const handleConfirmPII = (e: React.MouseEvent) => {
    e.stopPropagation();
    editor?.commands.cleanPII();
    setShowPIICleanConfirm(false);
    editor?.commands.togglePIISearch(false);
  };

  const handleCancelPII = (e: React.MouseEvent) => {
    e.stopPropagation();
    editor?.commands.togglePIISearch(false);
    setShowPIICleanConfirm(false);
  };

  const handleFireSearchAndReplace = () => {
    setShowSearchAndReplace(true);
  };

  const cleanTranscript = () => {
    if (!editor) return;
    editor.commands.setFillerWords(fillerWords);
    setShowSearchAndReplace(false);
    forceUpdate();
  };

  const cleanPII = () => {
    if (!editor) return;
    editor?.commands.togglePIISearch(true);
    forceUpdate();
  };

  const handleGenerateSummaryClick = () => {
    setGenerationModalOpen(true);
  };

  const handleHighlightAll = async (message: string, enableTags: boolean) => {
    if (!editor || !tags || !getHighlightsGenerationData) return;

    setShowHighlightPopup(false);
    await highlightAll(dashboardId, tags as TagWithDetails[], {
      message,
      withTags: enableTags,
      callback: () => refetchTags(),
    });
  };

  const handleGenerateSummary = async (template: PropmptTemplate) => {
    if (!getSummaryGenerationData || !editor) return;
    const { content, documentId, transcriptId } = getSummaryGenerationData(editor);

    const { summaryId } = await generateDataSummary({
      quotes: content,
      documentId,
      transcriptId,
      templateId: template.id,
    });

    const newSummaryNode = {
      type: 'summary',
      attrs: {
        id: summaryId,
        jobId: summaryId,
        status: 'generating',
        templateId: template.id,
        templateTitle: template.title,
      },
      content: [],
    };

    markSummaryAdded();
    setTimeout(() => editor.chain().focus().insertContentAt(0, newSummaryNode).run(), 0);
  };

  const postyItems = useMemo((): TNestedDropdownItem[] => {
    const items = [];
    if (getSummaryGenerationData) {
      items.push(
        ...[
          {
            icon: <Icon24.PostyStars />,
            title: 'Summarize',
            onClick: handleGenerateSummaryClick,
          },
          {
            icon: <Icon24.PostyTag />,
            title: 'Highlight & Tag',
            showSpinner: highlightsGenerating,
            onClick: () => setShowHighlightPopup(true),
          },
          {
            icon: <Icon24.PII />,
            title: 'Redact PII',
            onClick: cleanPII,
          },
        ]
      );
    }

    items.push({
      icon: <Icon24.Broomstick />,
      title: 'Tidy Up',
      items: [
        {
          icon: <Icon24.PostySearch />,
          title: 'Find and replace',
          onClick: handleFireSearchAndReplace,
        },
        {
          icon: <Icon24.PostyQuotes />,
          title: 'Remove filler words',
          onClick: cleanTranscript,
        },
      ],
    });
    return items as TNestedDropdownItem[];
  }, [highlightsGenerating, editor]);

  const fillerWordsResultsCount = editor?.storage.documentCleaning?.results?.length || 0;
  const piiResultsCount = editor?.storage.cleanPII?.results?.length || 0;

  return (
    <Container>
      <NestedDropdown
        title="Use AI"
        titleIcon={<Icon24.PostySmall />}
        items={postyItems}
        showSpinner={highlightsGenerating}
        type="primary"
      />
      {showConfirmClean && (
        <CleanConfirmationPanel>
          <CleanConfirmationText>
            Do you want to delete {fillerWordsResultsCount} filler words?
          </CleanConfirmationText>
          <CleanConfirmationButtons>
            <CleanConfirmationButton>
              <DefaultButton type="secondary" onClick={handleConfirmCleaning}>
                Delete
              </DefaultButton>
            </CleanConfirmationButton>
            <CleanConfirmationButton>
              <DefaultButton type="secondary" onClick={handleCancelCleaning}>
                Cancel
              </DefaultButton>
            </CleanConfirmationButton>
          </CleanConfirmationButtons>
        </CleanConfirmationPanel>
      )}

      {showPIICleanConfirm && (
        <CleanConfirmationPanel>
          <CleanConfirmationText>
            Do you want to redact {piiResultsCount} PII?
          </CleanConfirmationText>
          <CleanConfirmationButtons>
            <CleanConfirmationButton>
              <DefaultButton type="secondary" onClick={handleConfirmPII}>
                Redact
              </DefaultButton>
            </CleanConfirmationButton>
            <CleanConfirmationButton>
              <DefaultButton type="secondary" onClick={handleCancelPII}>
                Cancel
              </DefaultButton>
            </CleanConfirmationButton>
          </CleanConfirmationButtons>
        </CleanConfirmationPanel>
      )}

      {showEmptyResultPanel && (
        <CleanConfirmationPanel size="small">
          <CleanConfirmationText withoutMargin>
            Transcript clean of filler words
          </CleanConfirmationText>
        </CleanConfirmationPanel>
      )}

      {showPIIEmptyResultPanel && (
        <CleanConfirmationPanel size="small">
          <CleanConfirmationText withoutMargin>Transcript clean of PII</CleanConfirmationText>
        </CleanConfirmationPanel>
      )}

      {getSummaryGenerationData && (
        <PortalNew wrapperId="summaryGenerationModalToolbar">
          <SummaryGenerationModal
            isOpen={generationModalOpen}
            onConfirm={(template) => handleGenerateSummary(template)}
            onClose={() => {
              setGenerationModalOpen(false);
            }}
          />
        </PortalNew>
      )}

      {getSummaryGenerationData && showHighlightPopup && (
        <HighlightPopupWrapper ref={highlightPopupRef}>
          <HighlightPopup
            onGenerateHighlights={handleHighlightAll}
            onClose={() => setShowHighlightPopup(false)}
          />
        </HighlightPopupWrapper>
      )}
    </Container>
  );
};

export default PostyAIButton;
