/* eslint-disable @typescript-eslint/no-empty-function */
import React, { FC, useEffect, useState } from 'react';
import {
  TCreateEvidance,
  useCreateEvidence,
  useDeleteEvidence,
  useFetchInsightEvidences,
} from '../Hooks/useEvidences';
import { ID } from '../Models';
import { difference } from 'lodash-es';

export type TEvidence = {
  id: ID;
  dashboardId: ID;
  parentInsightId: ID;
  entityId: string;
  insight: any | null;
  insightId: ID | null;
  note: any | null;
  noteId: ID | null;
  tag: any | null;
  tagId: ID | null;
  theme: any | null;
  themeId: ID | null;
  transcript: any | null;
  transcriptId: ID | null;
  document: any | null;
  documentId: ID | null;
};

export type TEditorEvidence = {
  id: ID;
  dashboardId: ID;
};

interface EvidenceContextProps {
  isInitialized: boolean;
  addEvidence: (evidence: TCreateEvidance) => void;
  findEvidenceById: (noteId: ID) => TEvidence | undefined;
  setEditorEvidencesList: (evidences: TEditorEvidence[]) => void;
}

export const EvidenceContext = React.createContext<EvidenceContextProps>({
  isInitialized: false,
  addEvidence: () => {},
  findEvidenceById: () => undefined,
  setEditorEvidencesList: () => {},
});

interface EvidenceContextProviderProps {
  children: React.ReactNode;
  loading: boolean;
  data: TEvidence[];
  refetch: () => void;
}

export const EvidenceContextProvider: FC<EvidenceContextProviderProps> = ({
  loading,
  data,
  refetch,
  children,
}) => {
  const [evidences, setEvidences] = useState<TEvidence[]>([]);
  const [createEvidence] = useCreateEvidence();
  const [deleteEvidence] = useDeleteEvidence();

  const [isInitialized, setIsInitialized] = useState(false);
  const [editorEvidences, setEditorEvidences] = useState<TEditorEvidence[]>([]);
  const [checkerInitialized, setCheckerInitialized] = useState(false);
  const [editorEvidencesGenerated, setEditorEvidencesGenerated] = useState(false);
  const evidencesRef = React.useRef(evidences);
  const editorEvidencesRef = React.useRef(editorEvidences);

  useEffect(() => {
    evidencesRef.current = evidences;
    editorEvidencesRef.current = editorEvidences;
  }, [evidences, editorEvidences]);

  useEffect(() => {
    if (!loading && data) {
      setEvidences(data);
      setIsInitialized(true);
    }
  }, [data, loading]);

  useEffect(() => {
    if (!editorEvidencesGenerated) return;
    const intervalId = setInterval(async () => {
      const evidencesToDelete = difference(
        evidencesRef.current.map((evidence) => evidence.entityId),
        editorEvidencesRef.current.map((evidence) => evidence.id)
      );

      let deleted = false;
      for (const entityId of evidencesToDelete) {
        const evidence = evidencesRef.current.find((item) => item.entityId === entityId);
        if (!evidence) continue;
        try {
          await deleteEvidence(evidence.id);
          deleted = true;
        } catch (error) {}
      }

      if (deleted) {
        refetch();
      }
      setCheckerInitialized(true);
    }, 2000);

    return () => clearInterval(intervalId);
  }, [editorEvidencesGenerated, checkerInitialized]);

  const setEditorEvidencesList = (evidences: TEditorEvidence[]) => {
    setEditorEvidences(evidences);
    setEditorEvidencesGenerated(true);
  };

  const addEvidence = async (evidence: TCreateEvidance) => {
    await createEvidence(evidence);
    setIsInitialized(false);
    refetch();
  };

  const findEvidenceById = (entityId: ID) => {
    return evidences.find((evidence) => evidence.entityId === entityId);
  };

  return (
    <EvidenceContext.Provider
      value={{
        isInitialized,
        addEvidence,
        findEvidenceById,
        setEditorEvidencesList,
      }}
    >
      {children}
    </EvidenceContext.Provider>
  );
};
