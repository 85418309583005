import styled from 'styled-components';

export const GradientText = styled.div`
  background: linear-gradient(90deg, #8e84ee 0%, #351a8a 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 4px;
  margin-top: 4px;
`;
