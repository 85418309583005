export const tiers = new Map<string, any>([
  [
    'free',
    {
      id: 'free',
      name: 'Free',
      href: '#',
      lookupKey: {
        monthly: 'free',
        yearly: 'free',
      },
      price: {
        monthly: 0,
        yearly: 0,
      },
      per: 'editor',
      description: 'For students or individuals to run an end-to-end research project.',
      editors: { default: 1, isAdjustable: false, step: 1, min: 1, max: 1 },
      viewers: { max: 2 },
      features: new Map<string, string | boolean | { default: number }>([
        ['viewers', '2'],
        ['projects', '1'],
        ['aiCredits', '10'],
        ['transcriptionHours', '5'],
        ['suggestedTags', false],
        ['globalTags', false],
        ['globalSearch', false],
        ['participants', '10'],
        ['slack', false],
        ['multiLang', false],
        ['sso', false],
        ['accountManager', false],
      ]),
    },
  ],
  [
    'pro',
    {
      id: 'pro',
      name: 'Pro',
      href: '#',
      lookupKey: {
        monthly: 'pro_0823',
        yearly: 'pro_yearly_0823',
      },
      price: {
        monthly: 35,
        yearly: 25,
      },
      per: 'editor',
      description: 'For consultants or freelancers conducting regular research.',
      editors: { default: 1, isAdjustable: false, step: 1, min: 1, max: 1 },
      viewers: { max: 5 },
      features: new Map<string, string | boolean>([
        ['viewers', '5'],
        ['projects', '5'],
        ['participants', '50'],
        ['transcriptionHours', '10 monthly'],
        ['slack', true],
        ['aiCredits', '30 monthly'],
        ['sentimentAnalysis', true],
        ['globalSearch', true],
        ['globalTags', false],
        ['suggestedTags', false],
        ['multiLang', false],
        ['sso', false],
        ['accountManager', false],
      ]),
    },
  ],
  [
    'team',
    {
      id: 'team',
      name: 'Teams',
      href: '#',
      lookupKey: {
        monthly: 'team_0823',
        yearly: 'team_yearly_0823',
      },
      price: {
        monthly: 60,
        yearly: 50,
      },
      per: 'each 5 editors',
      description: 'For teams to collaborate on ongoing research projects.',
      editors: { default: 5, isAdjustable: true, step: 5, min: 5, max: 25 },
      viewers: { max: 50 },
      features: new Map<string, string | boolean>([
        ['viewers', '50'],
        ['projects', '25'],
        ['participants', '250'],
        ['transcriptionHours', '20 monthly'],
        ['slack', true],
        ['aiCredits', '100 monthly'],
        ['sentimentAnalysis', true],
        ['globalSearch', true],
        ['globalTags', true],
        ['suggestedTags', true],
        ['multiLang', false],
        ['sso', false],
        ['accountManager', false],
        ['customAiTemplates', true],
      ]),
    },
  ],
  [
    'enterprise',
    {
      id: 'enterprise',
      name: 'Enterprise',
      href: '#',
      lookupKey: {
        monthly: 'enterprise_1222',
        yearly: 'enterprise_yearly_1222',
      },
      price: {
        monthly: 125,
        yearly: 100,
      },
      viewers: { max: 1000 },
      // priceTitle: 'Custom quote',
      priceTitle: null as null | string,
      description: 'For teams to scale research impact across an organization.',
      editors: { default: 5, isAdjustable: false, step: 5, min: 5, max: 50 },
      features: new Map<string, string | boolean>([
        ['viewers', 'Unlimited'],
        ['projects', 'Unlimited'],
        ['participants', 'Unlimited'],
        ['transcriptionHours', 'Unlimited'],
        ['slack', true],
        ['aiCredits', '250 monthly'],
        ['sentimentAnalysis', true],
        ['globalSearch', true],
        ['globalTags', true],
        ['suggestedTags', true],
        ['multiLang', true],
        ['sso', true],
        ['accountManager', true],
        ['customAiTemplates', true],
        ['promptInstructions', true],
        ['securityReviews', true],
        ['customContracts', true],
      ]),
    },
  ],
]);

export const tiersArray = Array.from(tiers.values());

export default tiers;
