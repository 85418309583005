import React, { FC, useEffect, useRef, useState } from 'react';
import type { Identifier, XYCoord } from 'dnd-core';

import {
  CollapseButton,
  Container,
  Footer,
  Header,
  LeftSide,
  DotsButton,
  NewTagButton,
  NewTagButtonIcon,
  RightSide,
  TagNumber,
  TableContainer,
  Menu,
  MenuButton,
  NameWrapper,
  SortableContainer,
} from './styles';

import TagsTable from '../TagsTable';
import { Icon24 } from '../../Icons/Icon';
import { Checkbox } from '@chakra-ui/react';

import { DropArea } from '../DragAndDrop';
import { ID, TTagGroupWithTags, TagWithDetails } from '../../Models';
import { useDeleteTagGroup, useUpdateTag, useUpdateTagGroup } from '../../Hooks/useTags';
import AutoScalingInput from '../AutoScalingInput';
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd';

interface TagGroupProps {
  group: TTagGroupWithTags;
  index: number;
  disabled?: boolean;
  checkedTags: TagWithDetails[];
  isGlobal: boolean;
  disableChangeScope?: boolean;
  onCreateNewTag(groupId: ID): void;
  onTagClick?(tagId: string): void;
  onCheckedTagsChange(tags: TagWithDetails[]): void;
  onTagDrop(): void;
  onChange(): void;
  moveGroup: (dragIndex: number, hoverIndex: number) => void;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

const TagGroup: FC<TagGroupProps> = ({
  group,
  index,
  disabled,
  checkedTags,
  isGlobal,
  onCreateNewTag,
  onCheckedTagsChange,
  onTagClick,
  onTagDrop,
  onChange,
  moveGroup,
}) => {
  const updateTagGroup = useUpdateTagGroup();
  const deleteTagGroup = useDeleteTagGroup();

  const updateTag = useUpdateTag();
  const tableRef = useRef<HTMLDivElement>(null);
  const [groupName, setGroupName] = useState(group.name);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [tableHeight, setTableHeight] = useState(0);
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const allChecked =
    !!group.tags.length &&
    group.tags.every((tag) => !!checkedTags.find((checkedTag) => checkedTag.id === tag.id));
  const isIndeterminate =
    !allChecked &&
    group.tags.some((tag) => !!checkedTags.find((checkedTag) => checkedTag.id === tag.id));

  useEffect(() => {
    if (tableRef.current) setTableHeight(tableRef.current.getBoundingClientRect().height);
  }, [tableRef, group.tags]);

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(e.target as Node)) {
        e.stopPropagation();
        setShowMenu(false);
      }
    };
    window.addEventListener('mousedown', handleClick);

    return () => {
      window.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const handleNameFieldBlur = () => {
    if (groupName.trim() !== group.name && groupName.trim() !== '') {
      updateTagGroup(group.id, { name: groupName.trim() });
    }
  };

  const handleDropItem = async (item: TagWithDetails) => {
    if (disabled) return;
    await updateTag(item.id, { groupId: group.id });
    onTagDrop();
  };

  const handleDeleteGroup = async () => {
    if (disabled) return;
    await deleteTagGroup(group.id);
    onChange();
  };

  // Allowing user to change from global to project scope should not be allowed
  const disableChangeScope = true;

  const handleChangeScope = async () => {
    if (disabled || disableChangeScope) return;

    await updateTagGroup(group.id, { isGlobal: !isGlobal });
    onChange();

    await Promise.all(group.tags.map((tag) => updateTag(tag.id, { isGlobal: !isGlobal })));
    onChange();
  };

  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId, hoverDirection }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null; hoverDirection: string | null }
  >({
    accept: 'sortableTagGroup',
    collect(monitor: DropTargetMonitor<DragItem>) {
      return {
        handlerId: monitor.getHandlerId(),
        hoverDirection: monitor.isOver() ? (monitor.getItem().index > index ? 'up' : 'down') : null,
      };
    },
    drop: (item: DragItem, monitor) => {
      if (disabled) return;
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      moveGroup(dragIndex, hoverIndex);
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'sortableTagGroup',
    item: () => {
      return { id: group.id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));
  const opacity = isDragging ? 0 : 1;

  return (
    <SortableContainer
      ref={ref}
      data-handler-id={handlerId}
      hoverDirection={hoverDirection}
      style={{ opacity }}
    >
      <DropArea<TagWithDetails> onDrop={handleDropItem} type="tagGroup">
        <Container key={group.id} isOver={false}>
          <Header>
            <LeftSide>
              {!disabled && (
                <Checkbox
                  as="div"
                  colorScheme="purple"
                  isChecked={allChecked}
                  isIndeterminate={isIndeterminate}
                  onChange={(e) => onCheckedTagsChange(e.target.checked ? group.tags : [])}
                ></Checkbox>
              )}

              {!disabled ? (
                <NameWrapper>
                  <AutoScalingInput
                    value={groupName}
                    onChange={setGroupName}
                    onBlur={handleNameFieldBlur}
                    onEnter={handleNameFieldBlur}
                  />
                </NameWrapper>
              ) : (
                <NameWrapper>{groupName}</NameWrapper>
              )}
              {!!group.tags.length && <TagNumber>{group.tags.length}</TagNumber>}
            </LeftSide>

            <RightSide>
              {!disabled && (
                <DotsButton onClick={() => setShowMenu(!showMenu)} ref={menuRef}>
                  <Icon24.Dots />

                  <Menu isOpen={showMenu}>
                    {!disableChangeScope && (
                      <MenuButton onClick={handleChangeScope}>
                        {isGlobal ? 'Add to project tags' : 'Add to global tags'}
                      </MenuButton>
                    )}
                    <MenuButton onClick={handleDeleteGroup}>
                      {group.tags.length > 0 ? 'Ungroup' : 'Delete group'}
                    </MenuButton>
                  </Menu>
                </DotsButton>
              )}
              <CollapseButton
                onClick={() => setIsCollapsed(!isCollapsed)}
                isCollapsed={isCollapsed}
              >
                <Icon24.ChevronDown />
              </CollapseButton>
            </RightSide>
          </Header>

          <TableContainer
            isCollapsed={isCollapsed}
            contentHeight={tableRef?.current ? tableHeight : 0}
          >
            <TagsTable
              tags={group.tags}
              disabled={disabled}
              checkedTags={checkedTags}
              onTagClick={onTagClick}
              onCheckedTagsChange={onCheckedTagsChange}
              ref={tableRef}
            />
          </TableContainer>

          {!disabled && (
            <Footer>
              <NewTagButton onClick={() => onCreateNewTag(group.id)}>
                <NewTagButtonIcon>
                  <Icon24.Plus />
                </NewTagButtonIcon>
                New tag
              </NewTagButton>
            </Footer>
          )}
        </Container>
      </DropArea>
    </SortableContainer>
  );
};

export default TagGroup;
