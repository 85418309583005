import styled from 'styled-components';

export const SuggestedPromptsWrapper = styled.div`
  position: relative;
`;

export const SuggestedPrompts = styled.div`
  position: relative;
  width: 100%;
  max-height: 100px;
  overflow-y: auto;
  margin-top: 10px;
  padding-bottom: 10px;
`;

export const SuggestedPromptsOverlay = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  height: 20px;
  width: 100%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
`;

export const SuggestedPrompt = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  cursor: pointer;
`;

export const SuggestedPromptIcon = styled.div`
  color: rgba(177, 168, 185, 0.8);
  margin-right: 10px;
`;

export const SuggestedPromptName = styled.div`
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
`;

export const SuggestedPromptsTitle = styled.div`
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: rgba(59, 38, 81, 0.4);
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(215, 211, 220, 1);
  margin: 5px 0;
`;
