import React, { FC } from 'react';

interface Props {
  children?: React.ReactNode;
}

const Icon32: FC<Props> = ({ children }) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      {children}
    </svg>
  );
};

const Tags: FC = () => {
  return (
    <Icon32>
      <path
        d="M11 11H11.01M11 7H16C16.5119 6.99999 17.0237 7.19525 17.4142 7.58579L24.4143 14.5858C25.1953 15.3668 25.1953 16.6332 24.4143 17.4142L17.4142 24.4142C16.6332 25.1953 15.3668 25.1953 14.5858 24.4142L7.58579 17.4142C7.19526 17.0237 7 16.5118 7 16V11C7 8.79086 8.79086 7 11 7Z"
        stroke="#3B2651"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon32>
  );
};

const Analysis: FC = () => {
  return (
    <Icon32>
      <path
        d="M6 10.125C6 9.504 6.504 9 7.125 9H13.125C13.746 9 14.25 9.504 14.25 10.125V13.875C14.25 14.496 13.746 15 13.125 15H7.125C6.82663 15 6.54048 14.8815 6.3295 14.6705C6.11853 14.4595 6 14.1734 6 13.875V10.125ZM18 11.625C18 11.004 18.504 10.5 19.125 10.5H24.375C24.996 10.5 25.5 11.004 25.5 11.625V19.875C25.5 20.496 24.996 21 24.375 21H19.125C18.8266 21 18.5405 20.8815 18.3295 20.6705C18.1185 20.4595 18 20.1734 18 19.875V11.625ZM7.5 19.125C7.5 18.504 8.004 18 8.625 18H13.875C14.496 18 15 18.504 15 19.125V21.375C15 21.996 14.496 22.5 13.875 22.5H8.625C8.32663 22.5 8.04048 22.3815 7.8295 22.1705C7.61853 21.9595 7.5 21.6734 7.5 21.375V19.125Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon32>
  );
};

export default { Tags, Analysis };
