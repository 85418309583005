import { gql } from '@apollo/client/core';

export const composeSearchEvidence = (
  searchText?: string,
  participantFilterEnabled = false,
  tagsFilterEnabled = false,
  hasVideo = false
) => {
  const searchNotEmpty = searchText && searchText.length > 2;
  return gql`
    query searchEvidence(
      ${searchNotEmpty ? '$search: String!' : ''}
      $withNotes: Boolean!
      $withData: Boolean!
      $withTags: Boolean!
      $withInsights: Boolean!
      $dashboardIds: [BigInt!]
      $tags: [BigInt!]
      ${participantFilterEnabled ? '$participantIds: [BigInt!]' : ''}
    ) {
      notes(filter: {${
        searchNotEmpty ? 'textSearch: { matches: $search }' : ''
      }, dashboardId: { in: $dashboardIds }${
    participantFilterEnabled ? ', participantId: { in: $participantIds }' : ''
  }${hasVideo ? ', transcriptionId: {isNull: false}' : ''} ${
    tagsFilterEnabled
      ? `, tagsNotesConnection: {
    some: { tagId: { in: $tags } },
  }`
      : ''
  } })
        @include(if: $withNotes) {
        id
        text
        dashboardId
        createdAt
        updatedAt

        participant {
          id
          name
          color
          picture
        }
        tagsList(filter: { id: { in: $tags }}) @include(if: $withTags){
          name
          id
        }
        transcriptionId
      }
      projectInsights(
        filter: { ${
          searchNotEmpty ? 'textSearch: { matches: $search }' : ''
        }, dashboardId: { in: $dashboardIds } }
      ) @include(if: $withInsights) {
        id
        clientId
        title
        cover
        customCover
        dashboardId
        createdAt
        updatedAt
        dashboard {
          id
          name
          cover
        }
      }
      documents(
        filter: { ${
          searchNotEmpty ? 'textSearch: { matches: $search }' : ''
        }, dashboardId: { in: $dashboardIds } }
      ) @include(if: $withData) {
        id
        name
        createdAt
        dashboardId
        createdAt
        updatedAt
        dashboard {
          id
          name
          cover
        }
      }
      transcriptions(
        filter: { ${
          searchNotEmpty ? 'textSearch: { matches: $search }' : ''
        }, dashboardId: { in: $dashboardIds } }
      ) @include(if: $withData) {
        id
        name
        dashboardId
        createdAt
        file {
          signedVideoUrl
        }
        dashboard {
          id
          name
          cover
        }
      }
    }
  `;
};

export const SEARCH_EVERYTHING = gql`
  query searchEverything(
    $withData: Boolean!
    $withNotes: Boolean!
    $withInsights: Boolean!
    $documentsFilter: DocumentFilter!
    $transcriptsFilter: TranscriptionFilter!
    $notesFilter: NoteFilter!
    $insightsFilter: ProjectInsightFilter!
  ) {
    documents(orderBy: CREATED_AT_DESC, filter: $documentsFilter) @include(if: $withData) {
      content
      createdBy
      id
      name
      dashboardId
      createdAt
      updatedAt
    }
    notes(orderBy: CREATED_AT_DESC, filter: $notesFilter) @include(if: $withNotes) {
      id
      text
      dashboardId
      dashboard {
        id
        name
        cover
      }
      createdAt
      updatedAt
      userByCreatedBy {
        name
      }
      participant {
        id
        name
        picture
      }
    }
    projectInsights(orderBy: CREATED_AT_DESC, filter: $insightsFilter) @include(if: $withInsights) {
      id
      clientId
      title
      cover
      customCover
      dashboardId
      createdAt
      updatedAt
      dashboard {
        id
        name
      }
    }
    transcriptions(orderBy: CREATED_AT_DESC, filter: $transcriptsFilter) @include(if: $withData) {
      dashboardId
      id
      name
      fileId
      createdAt
      updatedAt
      file {
        type
      }
    }
  }
`;

export default SEARCH_EVERYTHING;
