import { useQuery, gql, useMutation } from '@apollo/client';
import { useMemo } from 'react';

const SUMMARIES = gql`
  query summaries($promptType: String) {
    summaries(condition: { type: $promptType }) {
      templateId
      createdAt
    }
  }
`;

type TSummary = {
  templateId: string;
  createdAt: number;
};

type TUseRecentPromptsProps = {
  type: string;
};

export const useRecentPrompts = (
  options?: TUseRecentPromptsProps
): [boolean, string[], () => void] => {
  const { type } = options || {};
  const variables = useMemo(() => (type ? { type: type } : {}), [type]);

  const { data, loading, refetch } = useQuery(SUMMARIES, {
    variables: variables,
  });

  const summaries = useMemo(() => {
    const sortedSummaries = [...(data?.summaries || [])]
      .map((summary) => ({ ...summary, createdAt: new Date(summary.createdAt).getTime() }))
      .sort((a: TSummary, b: TSummary) => b.createdAt - a.createdAt);
    const recentTemplateIds: string[] = [];
    let i = 0;
    while (i < sortedSummaries?.length && recentTemplateIds.length < 10) {
      const summary = sortedSummaries[i];
      if (!!summary.templateId && !recentTemplateIds.includes(summary.templateId)) {
        recentTemplateIds.push(summary.templateId);
      }
      i++;
    }
    return recentTemplateIds;
  }, [data?.summaries]);

  return [loading, summaries, refetch];
};
