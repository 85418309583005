import { gql } from '@apollo/client/core';
import {
  BOARD_CORE_FIELDS,
  FILE_CORE_FIELDS,
  NOTE_CORE_FIELDS,
  THEME_CORE_FIELDS,
  TRANSCRIPT_CORE_FIELDS,
  PROJECT_INFO_CORE_FIELDS,
  PROJECT_INSIGHT_CORE_FIELDS,
  DOCUMENT_CORE_FIELDS,
  PARTICIPANT_CORE_FIELDS,
  HIGHLIGHT_CORE_FIELDS,
} from './fragments';

const FETCH_NOTES = gql`
  ${NOTE_CORE_FIELDS}
  ${THEME_CORE_FIELDS}
  query getNotes($condition: NoteCondition) {
    notes(condition: $condition) {
      ...NoteCoreFields
      theme {
        ...ThemeCoreFields
      }
      participant {
        id
        name
        color
      }
    }
  }
`;

const FETCH_DASHBOARD_TAGS = gql`
  query getDashboardTags($dashboardId: BigInt!) {
    tags(condition: { dashboardId: $dashboardId }) {
      name
      id
      dashboardId
      isGlobal
      color
    }
  }
`;

const FETCH_DASHBOARD_TAGS_DETAILED = gql`
  query getDashboardTags($dashboardId: BigInt!) {
    tags(condition: { dashboardId: $dashboardId }) {
      id
      name
      color
      dashboardId
      isGlobal
      keywords
      description
      tagsDocumentsConnection {
        totalCount
      }
      tagsNotesConnection {
        totalCount
      }
      tagsTranscriptionsConnection {
        totalCount
      }
    }
  }
`;

const FETCH_THEMES = gql`
  ${THEME_CORE_FIELDS}
  query getThemesByDashboardId($dashboardId: BigInt!) {
    themes(condition: { dashboardId: $dashboardId }) {
      ...ThemeCoreFields
    }
  }
`;

const FETCH_THEMES_WITH_NOTES_COUNT = gql`
  ${THEME_CORE_FIELDS}
  query getThemesByDashboardId($dashboardId: BigInt!) {
    themes(condition: { dashboardId: $dashboardId }) {
      ...ThemeCoreFields
      notesConnection {
        totalCount
      }
    }
  }
`;

const FETCH_THEME = gql`
  query getTheme($themeId: BigInt!) {
    theme(id: $themeId) {
      id
      name
      notesConnection {
        totalCount
      }
    }
  }
`;

const FETCH_DASHBOARD = gql`
  ${BOARD_CORE_FIELDS}
  ${THEME_CORE_FIELDS}
  ${PROJECT_INFO_CORE_FIELDS}
  query getDashboard($dashboardId: BigInt!) {
    dashboard(id: $dashboardId) {
      ...BoardCoreFields
      themes {
        ...ThemeCoreFields
      }
      projectInfo {
        ...ProjectInfoCoreFields
      }
    }
  }
`;

const FETCH_DASHBOARD_ALL = gql`
  ${BOARD_CORE_FIELDS}
  ${NOTE_CORE_FIELDS}
  ${THEME_CORE_FIELDS}
  ${PROJECT_INFO_CORE_FIELDS}
  ${FILE_CORE_FIELDS}
  ${TRANSCRIPT_CORE_FIELDS}
  ${DOCUMENT_CORE_FIELDS}
  query getDashboardAll($dashboardId: BigInt!) {
    dashboard(id: $dashboardId) {
      ...BoardCoreFields
      themes {
        ...ThemeCoreFields
      }
      notes {
        ...NoteCoreFields
        theme {
          ...ThemeCoreFields
        }
      }
      files {
        ...FileCoreFields
        transcription {
          ...TranscriptionCoreFields
        }
      }
      documents {
        ...DocumentCoreFields
      }
      projectInfo {
        ...ProjectInfoCoreFields
      }
      transcriptions {
        ...TranscriptionCoreFields
      }
    }
  }
`;

const FETCH_DASHBOARDS = gql`
  ${BOARD_CORE_FIELDS}
  query getDashboards($userId: BigInt) {
    dashboards(condition: { createdBy: $userId }) {
      ...BoardCoreFields
      # notes {
      #   id
      # }
      # themes {
      #   id
      # }
    }
  }
`;

const FETCH_LIGHT_DASHBOARDS = gql`
  query getDashboards($userId: BigInt) {
    dashboards(condition: { createdBy: $userId }) {
      id
      name
      cover
      createdAt
    }
  }
`;

const FETCH_RECENT_DASHBOARDS = gql`
  ${BOARD_CORE_FIELDS}
  query fetchRecentDashboards($first: Int = 5) {
    dashboards(orderBy: LAST_ACTIVITY_AT_DESC, first: $first) {
      ...BoardCoreFields
      folder {
        id
        name
        createdAt
        updatedAt
        __typename
      }
    }
  }
`;

const FETCH_TAGS = gql`
  query tags($filter: TagFilter, $withDetails: Boolean = false) {
    tags(filter: $filter) {
      id
      name
      color
      dashboardId
      isGlobal
      groupId

      keywords @include(if: $withDetails)
      description @include(if: $withDetails)
      tagsDocumentsConnection @include(if: $withDetails) {
        totalCount
      }
      tagsNotesConnection @include(if: $withDetails) {
        totalCount
      }
      tagsTranscriptionsConnection @include(if: $withDetails) {
        totalCount
      }
      createdWithAi
    }
  }
`;

const FETCH_TAG = gql`
  query fetchTag($tagId: BigInt!) {
    tag(id: $tagId) {
      color
      dashboardId
      description
      isDefault
      isGlobal
      keywords
      name
      id
      createdAt
      createdWithAi
      createdBy
      tagsNotes {
        note {
          id
          text
          dashboardId
          dashboard {
            id
            name
            cover
          }
          createdAt
          participant {
            id
            name
            picture
          }
        }
      }
      tagsTranscriptions {
        transcription {
          id
          name
          dashboardId
          createdAt
          file {
            type
          }
        }
      }
      tagsDocuments {
        document {
          id
          name
          dashboardId
          createdAt
        }
      }
    }
  }
`;

const FETCH_TRANSCRIPTS = gql`
  ${TRANSCRIPT_CORE_FIELDS}
  query fetchTranscriptions($dashboardId: BigInt!) {
    transcriptions(condition: { dashboardId: $dashboardId }) {
      ...TranscriptionCoreFields
    }
  }
`;

const FETCH_ALL_TRANSCRIPTS = gql`
  query fetchTranscriptions {
    transcriptions {
      id
      name
      createdAt
      fileId
      folderId
      customDate
      file {
        type
      }
    }
  }
`;

const FETCH_TRANSCRIPTS_WITH_TEXT = gql`
  ${TRANSCRIPT_CORE_FIELDS}
  query fetchTranscriptions($dashboardId: BigInt!) {
    transcriptions(condition: { dashboardId: $dashboardId }) {
      ...TranscriptionCoreFields
      text
    }
  }
`;

const FETCH_TRANSCRIPT = gql`
  ${TRANSCRIPT_CORE_FIELDS}
  ${FILE_CORE_FIELDS}
  query fetchTranscription($id: BigInt!) {
    transcription(id: $id) {
      ...TranscriptionCoreFields
      text
      file {
        ...FileCoreFields
        signedVideoUrl
      }
      participantId
      participant {
        id
        name
        picture
      }
    }
  }
`;

const FETCH_FILES = gql`
  ${FILE_CORE_FIELDS}
  ${TRANSCRIPT_CORE_FIELDS}
  query fetchFiles($dashboardId: BigInt!) {
    files(condition: { dashboardId: $dashboardId }) {
      ...FileCoreFields
      transcription {
        ...TranscriptionCoreFields
      }
    }
  }
`;

const FETCH_ALL_FILES = gql`
  ${FILE_CORE_FIELDS}
  ${TRANSCRIPT_CORE_FIELDS}
  query fetchFiles {
    files {
      ...FileCoreFields
      transcription {
        ...TranscriptionCoreFields
      }
    }
  }
`;

const FETCH_FILE = gql`
  ${FILE_CORE_FIELDS}
  ${TRANSCRIPT_CORE_FIELDS}
  query fetchFile($id: BigInt!) {
    file(id: $id) {
      ...FileCoreFields
      signedVideoUrl
      transcription {
        ...TranscriptionCoreFields
        text
      }
    }
  }
`;

const FETCH_PROJECT_INFO = gql`
  ${PROJECT_INFO_CORE_FIELDS}
  query fetchProjectInfo($id: BigInt!) {
    projectInfo(id: $id) {
      ...ProjectInfoCoreFields
    }
  }
`;

const FETCH_PROJECT_INFO_BY_DASHBOARD_ID = gql`
  ${PROJECT_INFO_CORE_FIELDS}
  query fetchProjectInfo($dashboardId: BigInt!) {
    projectInfoByDashboardId(dashboardId: $dashboardId) {
      ...ProjectInfoCoreFields
    }
  }
`;

const FETCH_PROJECT_INSIGHTS = gql`
  ${PROJECT_INSIGHT_CORE_FIELDS}
  query fetchProjectInsights($dashboardId: BigInt!) {
    projectInsights(condition: { dashboardId: $dashboardId }, orderBy: UPDATED_AT_DESC) {
      ...ProjectInsightCoreFields
    }
  }
`;

const FETCH_PROJECT_INSIGHT_BY_CLIENT_ID = gql`
  ${PROJECT_INSIGHT_CORE_FIELDS}
  query fetchProjectInsight($clientId: String!) {
    projectInsightByClientId(clientId: $clientId) {
      ...ProjectInsightCoreFields
      evidencesByParentInsightIdConnection {
        totalCount
      }
      views
      dashboard {
        id
        name
        cover
      }
    }
  }
`;

const FETCH_RECENT_INSIGHTS = gql`
  ${PROJECT_INSIGHT_CORE_FIELDS}
  query fetchRecentInsights($first: Int = 9) {
    projectInsights(orderBy: UPDATED_AT_DESC, first: $first) {
      ...ProjectInsightCoreFields
    }
  }
`;

const FETCH_INTEGRATIONS = gql`
  query fetchIntegrations {
    integrations {
      id
      type
      createdBy
    }
  }
`;

const FETCH_ZOOM_CLOUD_RECORDINGS = gql`
  query fetchZoom($pageSize: Int, $continuationToken: String, $from: String, $to: String) {
    zoomCloudRecordings(
      pageSize: $pageSize
      continuationToken: $continuationToken
      from: $from
      to: $to
    ) {
      nextPageToken
      recordings {
        topic
        startTime
        shareUrl
        videoDownloadUrl
        audioDownloadUrl
        transcript
        duration
        size
      }
    }
  }
`;

const FETCH_DOCUMENTS = gql`
  ${DOCUMENT_CORE_FIELDS}
  query fetchDocuments($dashboardId: BigInt!) {
    documents(condition: { dashboardId: $dashboardId }, orderBy: UPDATED_AT_DESC) {
      ...DocumentCoreFields
    }
  }
`;

const FETCH_ALL_DOCUMENTS = gql`
  query fetchDocuments {
    documents {
      id
      name
    }
  }
`;

const FETCH_DOCUMENTS_WITH_TEXT = gql`
  ${DOCUMENT_CORE_FIELDS}
  query fetchDocuments($dashboardId: BigInt!) {
    documents(condition: { dashboardId: $dashboardId }, orderBy: UPDATED_AT_DESC) {
      ...DocumentCoreFields
      content
    }
  }
`;

const FETCH_DOCUMENT = gql`
  ${DOCUMENT_CORE_FIELDS}
  query fetchDocument($id: BigInt!) {
    document(id: $id) {
      ...DocumentCoreFields
      content
      notesConnection {
        totalCount
      }
      notes {
        id
      }
      participantId
      participant {
        id
        name
        tagsList {
          id
          name
          color
        }
      }
    }
  }
`;

const FETCH_LIGHT_DOCUMENTS = gql`
  query fetchLightDocuments {
    lightDocuments {
      id
      name
      content
      createdAt
      userByCreatedBy {
        id
        name
        email
        picture
      }
    }
  }
`;

const FETCH_LIGHT_DOCUMENT = gql`
  query fetchLightDocument($id: BigInt!) {
    lightDocument(id: $id) {
      id
      name
      content
      participantId
      participant {
        id
        name
      }
      userByCreatedBy {
        id
        name
        email
        picture
      }
      createdAt
    }
  }
`;

const FETCH_AUDIO_RECORDINGS = gql`
  query fetchAudioRecordings {
    audioRecordings {
      id
      name
      duration
      mimeType
      metadata
      synced
      finished
      clientId
      createdAt
      userByCreatedBy {
        id
        name
        email
        picture
      }
    }
  }
`;

const FETCH_AUDIO_RECORDING = gql`
  query fetchAudioRecording($id: BigInt!) {
    audioRecording(id: $id) {
      id
      name
      duration
      mimeType
      metadata
      clientId
      synced
      finished
      participantId
      participant {
        id
        name
      }
      userByCreatedBy {
        id
        name
        email
        picture
      }
      createdAt
    }
  }
`;

const FETCH_PARTICIPANTS = gql`
  ${PARTICIPANT_CORE_FIELDS}
  query fetchParticipants {
    participants(orderBy: NAME_ASC) {
      ...ParticipantCoreFields
      notesConnection {
        totalCount
      }
      documentsConnection {
        totalCount
      }
      transcriptionsConnection {
        totalCount
      }
      tagsList {
        id
        name
        color
      }
    }
  }
`;

const FETCH_PARTICIPANT_NAMES = gql`
  query fetchParticipantNames {
    participants(orderBy: NAME_ASC) {
      id
      name
      color
    }
  }
`;

const FETCH_PARTICIPANT = gql`
  ${PARTICIPANT_CORE_FIELDS}
  query fetchParticipant($id: BigInt!) {
    participant(id: $id) {
      ...ParticipantCoreFields
      documents {
        id
        dashboardId
        name
        updatedAt
        createdAt
        userByCreatedBy {
          name
        }
        dashboard {
          id
          name
          cover
        }
      }
      transcriptions {
        id
        dashboardId
        name
        updatedAt
        userByCreatedBy {
          name
        }
        dashboard {
          id
          name
          cover
        }
      }
      transcriptionsByNoteParticipantIdAndTranscriptionIdList {
        id
        dashboardId
        name
        updatedAt
        userByCreatedBy {
          name
        }
        dashboard {
          id
          name
          cover
        }
      }
      tagsList {
        id
        name
        color
      }
    }
  }
`;

const FETCH_NOTE = gql`
  query fetchNote($id: BigInt!) {
    note(id: $id) {
      customDate
      color
      documentId
      participantId
      sentimentScore
      themeId
      transcriptionId
      url
      x
      y
      id
      text
      dashboardId
      options
      userByCreatedBy {
        id
        name
        email
        picture
      }
      transcription {
        id
        name
        file {
          type
          id
        }
      }
      tagsList {
        id
        name
      }
    }
  }
`;

const FETCH_USERS = gql`
  query fetchUsers {
    users {
      id
      name
      email
      avatar
      role
      inviteAccepted
    }
  }
`;

const CURRENT_ORG = gql`
  query currentOrganization {
    currentOrganization {
      id
      plan
      planInterval
      name
      logo
      isTeam
      durationTotal
      durationThisMonth
      prepaidSeats
      projectsCount
      projectsLimitExceeded
      durationLimitExceeded
      fileLimitExceeded
      durationLimitTotal
      durationLimitMonthly
      projectsLimitTotal
      fileSizeLimitTotal
      onLegacyPlan
      trialStatus
      trialStartAt
      trialExpireAt
      aiCredits
      aiCreditLimitTotal
      aiCreditLimitMonthly
      createdAt
    }
  }
`;

const FETCH_DASHBOARD_FOLDERS = gql`
  query fetchDashboardFolders {
    dashboardFolders {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

const FETCH_DATA_FOLDERS = gql`
  query fetchDataFolders($dashboardId: BigInt!) {
    dataFolders(condition: { dashboardId: $dashboardId }) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

const FETCH_EVIDENCES = gql`
  query getEvidences($dashboardId: BigInt!) {
    notes(condition: { dashboardId: $dashboardId }) {
      id
      text
      dashboardId
      createdAt
      userByCreatedBy {
        id
        name
        email
        picture
      }
    }
  }
`;

const FETCH_TEXT_NOTES = gql`
  query fetchTextNotes($dashboardId: BigInt!) {
    textNotes(condition: { dashboardId: $dashboardId }) {
      id
      styles
      text
      x
      y
      dashboardId
      userByCreatedBy {
        id
        name
        email
        picture
      }
    }
  }
`;

const FETCH_TEXT_NOTE = gql`
  query fetchTextNote($id: BigInt!) {
    textNote(id: $id) {
      id
      styles
      text
      x
      y
      dashboardId
      userByCreatedBy {
        id
        name
        email
        picture
      }
    }
  }
`;

const FETCH_CANVAS_ELEMENTS = gql`
  query fetchCanvasElements($dashboardId: BigInt!) {
    textNotes(condition: { dashboardId: $dashboardId }) {
      id
      styles
      text
      x
      y
      dashboardId
      userByCreatedBy {
        id
        name
        email
        picture
      }
    }
    lines(condition: { dashboardId: $dashboardId }) {
      id
      styles
      points
      x
      y
      dashboardId
      userByCreatedBy {
        id
        name
        email
        picture
      }
    }
    shapes(condition: { dashboardId: $dashboardId }) {
      id
      styles
      x
      y
      dashboardId
      userByCreatedBy {
        id
        name
        email
        picture
      }
    }
    canvasImages(condition: { dashboardId: $dashboardId }) {
      id
      styles
      x
      y
      dashboardId
      signedImageUrl
      s3ImagePath
      userByCreatedBy {
        id
        name
        email
        picture
      }
    }
  }
`;

const FETCH_LINES = gql`
  query fetchLines($dashboardId: BigInt!) {
    lines(condition: { dashboardId: $dashboardId }) {
      id
      styles
      points
      x
      y
      dashboardId
      userByCreatedBy {
        id
        name
        email
        picture
      }
    }
  }
`;

const FETCH_LINE = gql`
  query fetchLine($id: BigInt!) {
    line(id: $id) {
      id
      styles
      points
      x
      y
      dashboardId
      userByCreatedBy {
        id
        name
        email
        picture
      }
    }
  }
`;

const FETCH_SHAPES = gql`
  query fetchShapes($dashboardId: BigInt!) {
    shapes(condition: { dashboardId: $dashboardId }) {
      id
      styles
      x
      y
      dashboardId
      userByCreatedBy {
        id
        name
        email
        picture
      }
    }
  }
`;

const FETCH_SHAPE = gql`
  query fetchShape($id: BigInt!) {
    shape(id: $id) {
      id
      styles
      x
      y
      dashboardId
      userByCreatedBy {
        id
        name
        email
        picture
      }
    }
  }
`;

const FETCH_CANVAS_IMAGE = gql`
  query fetchCanvasImage($id: BigInt!) {
    canvasImage(id: $id) {
      id
      styles
      x
      y
      dashboardId
      signedImageUrl
      userByCreatedBy {
        id
        name
        email
        picture
      }
    }
  }
`;

const FETCH_CANVAS_IMAGES = gql`
  query fetchCanvasImages($dashboardId: BigInt!) {
    canvasImages(condition: { dashboardId: $dashboardId }) {
      id
      styles
      x
      y
      dashboardId
      signedImageUrl
      userByCreatedBy {
        id
        name
        email
        picture
      }
    }
  }
`;

const FETCH_USER_DASHBOARDS = gql`
  query getUserDashboards {
    dashboards(condition: { isSample: null }, orderBy: CREATED_AT_DESC) {
      createdAt
      name
      cover
      id
    }
  }
`;

const FETCH_SUMMARY = gql`
  query getSummaryById($id: BigInt!) {
    summary(id: $id) {
      content
      title
      status
      type
      source
      id
    }
  }
`;

const FETCH_HIGHLIGHTS = gql`
  ${HIGHLIGHT_CORE_FIELDS}
  query FetchHighlights($condition: HighlightCondition) {
    highlights(condition: $condition) {
      ...HighlightCoreFields
    }
  }
`;

const FETCH_TAG_GROUPS = gql`
  query fetchTagGroups($filter: TagGroupFilter) {
    tagGroups(filter: $filter) {
      id
      name
      isGlobal
      dashboardId
    }
  }
`;
const FETCH_INSIGHT_EVIDENCES = gql`
  query fetchEvidences($parentInsightId: BigInt!) {
    evidences(condition: { parentInsightId: $parentInsightId }) {
      dashboardId
      entityId
      id
      insightId
      noteId
      parentInsightId
      tagId
      themeId
      theme {
        name
        id
        dashboardId
        color
        notesConnection {
          totalCount
        }
        createdBy
      }
      note {
        id
        dashboardId
        color
        createdBy
        createdAt
        options
        text
        transcription {
          id
          name
          file {
            id
            type
            signedVideoUrl
          }
        }
      }
      tag {
        color
        createdBy
        id
        name
        tagsNotes {
          note {
            dashboardId
          }
        }
        dashboardId
      }
      insight {
        title
        clientId
        cover
        customCover
        dashboardId
        dashboard {
          cover
          name
        }
        clientId
        content
        createdBy
        createdAt
      }
      transcript {
        id
        name
        dashboardId
        createdAt
        file {
          id
          signedVideoUrl
        }
      }
      document {
        id
        name
        dashboardId
        createdAt
      }
    }
  }
`;

const FETCH_TAG_GROUP_SORTINGS = gql`
  query tagGroupSortings($filter: TagGroupSortingFilter) {
    tagGroupSortings(filter: $filter) {
      id
      isGlobal
      sorting
      dashboardId
    }
  }
`;

const GET_SAMPLE_TRANSCRIPT = gql`
  query getSampleTranscript {
    transcriptions(condition: { name: "Luis Passenger Interview " }) {
      name
      dashboardId
      file {
        description
        dashboardId
        id
        language
        mimeType
        metadata
        name
        importUrl
        s3AudioPath
        s3TranscodedVideoPath
        s3VideoPath
        signedVideoUrl
        size
        status
        statusText
        type
      }
      id
      participantId
      text
      tagsList {
        id
        name
        color
      }
    }
  }
`;

const GET_NOTES_METRICS = gql`
  query getNotesMetrics(
    $dashboardId: BigInt!
    $groupBy: [GetNotesMetricsGroup]!
    $filterByTags: [BigInt]
    $filterByTagGroups: [BigInt]
    $filterByThemes: [BigInt]
    $filterByParticipants: [BigInt]
  ) {
    getNotesMetrics(
      dashboardId: $dashboardId
      groupBy: $groupBy
      filterByTags: $filterByTags
      filterByTagGroups: $filterByTagGroups
      filterByThemes: $filterByThemes
      filterByParticipants: $filterByParticipants
    ) {
      theme
      themeId
      tagGroup
      tagGroupId
      tag
      tagId
      participant
      participantId
      color

      avgSentiment
      count
    }
  }
`;

const FETCH_NOTES_WITH_FILTERS = gql`
  query getNotes($filter: NoteFilter) {
    notes(filter: $filter) {
      id
      text
      dashboard {
        id
        name
        cover
      }
      createdAt
      dashboardId
      participantId
      participant {
        name
        picture
      }
    }
  }
`;

const FETCH_INSIGHT_REACTIONS = gql`
  query getInsightReactions($insightId: BigInt!) {
    reactions(condition: { insightId: $insightId }) {
      id
      clusterId
      emoji
      userByCreatedBy {
        id
        name
        email
        avatar
        picture
      }
      createdBy
      __typename
    }
  }
`;

const FETCH_INSIGHT_COMMENTS = gql`
  query getInsightReactions($insightId: BigInt!) {
    comments(condition: { insightId: $insightId }) {
      __typename
      id
      content
      likes {
        id
        userByCreatedBy {
          id
          name
          email
          avatar
          picture
        }
      }
      createdBy
      userByCreatedBy {
        id
        name
        email
        avatar
        picture
      }
      createdAt
    }
  }
`;

const FETCH_PROJECT_CHECKLIST = gql`
  query getProjectChecklists($createdBy: BigInt!) {
    projectChecklists(condition: { createdBy: $createdBy }) {
      id
      dataAdded
      highlightAdded
      summaryAdded
      insightAdded
      notesAdded
      customTemplateAdded
      teammateAdded
    }
  }
`;

const FETCH_TRANSCRIPT_PARTICIPANTS = gql`
  query fetchTranscriptParticipants($transcriptionId: BigInt = "") {
    transcriptionsParticipants(condition: { transcriptionId: $transcriptionId }) {
      id
      speakerEntityId
      transcriptionId
      updatedAt
      createdAt
      participantId
      participant {
        id
        name
        lastParticipated
        picture
        updatedAt
        createdAt
        color
        tagsList {
          id
          name
          color
        }
      }
    }
  }
`;

const CURRENT_USER = gql`
  query currentUsr {
    currentUsr {
      id
      avatar
      picture
      name
      role
      createdAt
    }
  }
`;

const TASK_STATUS = gql`
  query TaskStatus($id: BigInt!) {
    asyncTask(id: $id) {
      result
      status
      type
    }
  }
`;

const FETCH_SHORT_PROMPTS = gql`
  query shortPrompts($promptType: String, $filter: ShortPromptFilter) {
    shortPrompts(condition: { type: $promptType }, filter: $filter) {
      id
      name
      content
      type
      visibility
      createdBy
      createdAt
      updatedAt
    }
  }
`;

export {
  FETCH_USERS,
  FETCH_NOTES,
  FETCH_DASHBOARD,
  FETCH_DASHBOARDS,
  FETCH_LIGHT_DASHBOARDS,
  FETCH_RECENT_DASHBOARDS,
  FETCH_THEMES,
  FETCH_THEMES_WITH_NOTES_COUNT,
  FETCH_TAGS,
  FETCH_TRANSCRIPTS,
  FETCH_ALL_TRANSCRIPTS,
  FETCH_TRANSCRIPTS_WITH_TEXT,
  FETCH_TRANSCRIPT,
  FETCH_FILE,
  FETCH_FILES,
  FETCH_ALL_FILES,
  FETCH_PROJECT_INFO,
  FETCH_PROJECT_INFO_BY_DASHBOARD_ID,
  FETCH_DASHBOARD_ALL,
  FETCH_PROJECT_INSIGHTS,
  FETCH_PROJECT_INSIGHT_BY_CLIENT_ID,
  FETCH_RECENT_INSIGHTS,
  FETCH_INTEGRATIONS,
  FETCH_ZOOM_CLOUD_RECORDINGS,
  FETCH_DOCUMENTS,
  FETCH_ALL_DOCUMENTS,
  FETCH_DOCUMENTS_WITH_TEXT,
  FETCH_DOCUMENT,
  FETCH_PARTICIPANTS,
  FETCH_PARTICIPANT,
  CURRENT_ORG,
  FETCH_DASHBOARD_TAGS,
  FETCH_DASHBOARD_TAGS_DETAILED,
  FETCH_DASHBOARD_FOLDERS,
  FETCH_DATA_FOLDERS,
  FETCH_EVIDENCES,
  FETCH_INSIGHT_EVIDENCES,
  FETCH_NOTE,
  FETCH_TEXT_NOTES,
  FETCH_TEXT_NOTE,
  FETCH_CANVAS_ELEMENTS,
  FETCH_LINE,
  FETCH_LINES,
  FETCH_SHAPES,
  FETCH_SHAPE,
  FETCH_USER_DASHBOARDS,
  FETCH_CANVAS_IMAGES,
  FETCH_CANVAS_IMAGE,
  FETCH_PARTICIPANT_NAMES,
  FETCH_TAG,
  FETCH_THEME,
  FETCH_SUMMARY,
  FETCH_HIGHLIGHTS,
  FETCH_TAG_GROUPS,
  FETCH_TAG_GROUP_SORTINGS,
  GET_SAMPLE_TRANSCRIPT,
  GET_NOTES_METRICS,
  FETCH_NOTES_WITH_FILTERS,
  FETCH_INSIGHT_REACTIONS,
  FETCH_INSIGHT_COMMENTS,
  FETCH_LIGHT_DOCUMENTS,
  FETCH_LIGHT_DOCUMENT,
  FETCH_PROJECT_CHECKLIST,
  FETCH_TRANSCRIPT_PARTICIPANTS,
  CURRENT_USER,
  TASK_STATUS,
  FETCH_AUDIO_RECORDINGS,
  FETCH_AUDIO_RECORDING,
  FETCH_SHORT_PROMPTS,
};
