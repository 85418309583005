import { Extension, Range, Dispatch } from '@tiptap/core';
import { DecorationSet } from '@tiptap/pm/view';
import { Plugin, PluginKey, Transaction } from '@tiptap/pm/state';
import { Node as PMNode } from '@tiptap/pm/model';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    highlightParser: {
      deleteCurrentBraces: (
        beginResult: Range,
        endResult: Range,
        tagsBeginResult: Range | null,
        tagsEndResult: Range | null
      ) => ReturnType;
      deleteTempSymbols: () => ReturnType;
      deleteCurrentTags: (range: Range) => ReturnType;
      findNextHighlight: () => ({
        editor,
      }: {
        editor: any;
      }) => {
        beginResult: Range;
        endResult: Range;
        tagsBeginResult: Range | null;
        tagsEndResult: Range | null;
      } | null;
    };
  }
}

interface TextNodesWithPosition {
  text: string;
  pos: number;
}

const findNextHighlight = (doc: PMNode) => {
  let beginResult: Range | null = null;
  let endResult: Range | null = null;
  let tagsBeginResult: Range | null = null;
  let tagsEndResult: Range | null = null;

  let textNodesWithPosition: TextNodesWithPosition[] = [];
  let summaryBlock: PMNode | null = null;

  doc?.descendants((node, pos) => {
    if (node.type.name === 'summary' && !summaryBlock) {
      summaryBlock = node;
    }
  });

  if (!summaryBlock) return { beginResult, endResult };

  (summaryBlock as PMNode).descendants((node, pos) => {
    if (node.isText && (node.text?.includes('{{{') || node.text?.includes('}}}'))) {
      textNodesWithPosition.push({
        text: `${node.text}`,
        pos: pos + 1,
      });
    }
  });

  textNodesWithPosition = textNodesWithPosition.filter(Boolean);

  for (const element of textNodesWithPosition) {
    const { text, pos } = element;
    const matchFrom = text.indexOf('{{{');
    const matchTo = text.indexOf('}}}');
    const matchTagsFrom = text.indexOf('[[[');
    const matchTagsTo = text.indexOf(']]]');

    if (matchFrom > -1) {
      beginResult = { from: pos + matchFrom, to: pos + matchFrom + 3 };
    }

    if (matchTo > -1) {
      endResult = { from: pos + matchTo, to: pos + matchTo + 3 };
    }

    if (matchTagsFrom > -1) {
      tagsBeginResult = { from: pos + matchTagsFrom, to: pos + matchTagsFrom + 3 };
    }

    if (matchTagsTo > -1) {
      tagsEndResult = { from: pos + matchTagsTo, to: pos + matchTagsTo + 3 };
    }

    if (beginResult && endResult) break;
  }
  return { beginResult, endResult, tagsBeginResult, tagsEndResult };
};

const rebaseNextResult = (
  replaceTerm: string,
  index: number,
  lastOffset: number,
  results: Range[]
): [number, Range[]] | null => {
  const nextIndex = index + 1;

  if (!results[nextIndex]) return null;

  const { from: currentFrom, to: currentTo } = results[index];
  const offset = currentTo - currentFrom - replaceTerm.length + lastOffset;
  const { from, to } = results[nextIndex];

  results[nextIndex] = {
    to: to - offset,
    from: from - offset,
  };

  return [offset, results];
};

const deleteCurrentBraces = (
  beginResult: Range,
  endResult: Range,
  { tr, dispatch }: { tr: Transaction; dispatch: Dispatch },
  tagsBeginResult: Range | null,
  tagsEndResult: Range | null
) => {
  tr.insertText('§§§', beginResult.from, beginResult.to);
  tr.insertText('§§§', endResult.from, endResult.to);

  if (tagsBeginResult) {
    tr.insertText('§§§', tagsBeginResult.from, tagsBeginResult.to);
  }

  if (tagsEndResult) {
    tr.insertText('§§§', tagsEndResult.from, tagsEndResult.to);
  }
  dispatch?.(tr);
};

const deleteCurrentTags = (
  range: Range,
  { tr, dispatch }: { tr: Transaction; dispatch: Dispatch }
) => {
  tr.insertText('', range.from, range.to);
  dispatch?.(tr);
};

const deleteTempSymbols = (
  doc: PMNode,
  { tr, dispatch }: { tr: Transaction; dispatch: Dispatch }
) => {
  const starsResults: Range[] = [];

  let textNodesWithPosition: TextNodesWithPosition[] = [];
  let summaryBlock: PMNode | null = null;

  doc?.descendants((node, pos) => {
    if (node.type.name === 'summary' && !summaryBlock) {
      summaryBlock = node;
    }
  });

  if (!summaryBlock) return;

  (summaryBlock as PMNode).descendants((node, pos) => {
    if (node.isText) {
      textNodesWithPosition.push({
        text: `${node.text}`,
        pos: pos + 1,
      });
    }
  });

  textNodesWithPosition = textNodesWithPosition.filter(Boolean);

  for (const element of textNodesWithPosition) {
    const { text, pos } = element;
    const matches = Array.from(text.matchAll(new RegExp('§', 'g'))).filter(([matchText]) =>
      matchText.trim()
    );

    for (const m of matches) {
      if (m[0] === '') break;

      if (m.index !== undefined) {
        const addedIndex = m[0][m[0].length - 1] === ' ' ? 1 : 0;
        starsResults.push({
          from: pos + m.index,
          to: pos + m.index + m[0].length - addedIndex,
        });
      }
    }
  }

  let offset = 0;
  let resultsCopy = starsResults.slice();

  if (!resultsCopy.length) return;

  for (let i = 0; i < resultsCopy.length; i += 1) {
    const { from, to } = resultsCopy[i];

    tr.insertText('', from, to);
    const rebaseNextResultResponse = rebaseNextResult('', i, offset, resultsCopy);

    if (!rebaseNextResultResponse) continue;

    offset = rebaseNextResultResponse[0];
    resultsCopy = rebaseNextResultResponse[1];
  }

  dispatch?.(tr);
};

export const highlightParserPluginKey = new PluginKey('highlightParserPlugin');

export interface HighlightParserOptions {
  searchResultClass: string;
}

export interface HighlightParserStorage {
  beginResults: Range[];
  endResults: Range[];
  beginResult: Range | null;
  endResult: Range | null;
  deletingBracesFinished: boolean;
  active: boolean;
}

export const HighlightParser = Extension.create<HighlightParserOptions, HighlightParserStorage>({
  name: 'highlightParser',

  addOptions() {
    return {
      searchResultClass: 'clean-result',
    };
  },

  addStorage() {
    return {
      beginResults: [],
      endResults: [],
      beginResult: null,
      endResult: null,
      active: false,
      deletingBracesFinished: false,
    };
  },

  addCommands() {
    return {
      deleteCurrentBraces: (
        beginResult: Range,
        endResult: Range,
        tagsBeginResult: Range | null,
        tagsEndResult: Range | null
      ) => ({ editor, tr, dispatch }) => {
        deleteCurrentBraces(
          beginResult,
          endResult,
          { tr, dispatch },
          tagsBeginResult,
          tagsEndResult
        );
        return false;
      },
      findNextHighlight: () => ({ editor }) => {
        const { beginResult, endResult, tagsBeginResult, tagsEndResult } = findNextHighlight(
          editor.state.doc
        );

        if (!beginResult || !endResult) return null;
        return {
          beginResult,
          endResult,
          tagsBeginResult: tagsBeginResult || null,
          tagsEndResult: tagsEndResult || null,
        };
      },
      deleteTempSymbols: () => ({ editor, tr, dispatch }) => {
        deleteTempSymbols(editor.state.doc, { tr, dispatch });
        return false;
      },
      deleteCurrentTags: (range: Range) => ({ tr, dispatch }) => {
        deleteCurrentTags(range, { tr, dispatch });
        return false;
      },
    };
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: highlightParserPluginKey,
        state: {
          init: () => DecorationSet.empty,
          apply({ doc, docChanged }, oldState) {
            return DecorationSet.empty;
          },
        },
        props: {
          decorations(state) {
            return this.getState(state);
          },
        },
      }),
    ];
  },
});

export default HighlightParser;
