import React, { PropsWithChildren } from 'react';
import { Container } from './styles';

type SecondaryToolbarProps = {
  sticky?: boolean;
};

export default function SecondaryToolbar({
  children,
  sticky = false,
}: PropsWithChildren<SecondaryToolbarProps>): JSX.Element {
  return <Container sticky={sticky}>{children}</Container>;
}
