import React, { FC, useMemo } from 'react';
import { OptionTypeBase } from 'react-select';
import languageOptions from '../../Consts/languageOptions';
import { Icon24 } from '../../Icons/Icon';
import Button from '../Button';
import HighlightPopup from '../HighlightPopup';
import Select from '../Select';
import { ITask } from '../TaskManager/TaskManager';

import {
  BottomButtons,
  CloseButton,
  Container,
  Content,
  CreditPrice,
  Description,
  Header,
  OptionsList,
  Option,
  Overlay,
  PostyIcon,
  SaveButton,
  SelectTitle,
  Title,
  TopContent,
  Wrapper,
} from './styles';

interface TasksDetailsProps {
  isOpen: boolean;
  currentTask: ITask | null;
  currentLanguage?: OptionTypeBase;
  currentHighlightPrompt?: string;
  currentTagsEnabled?: boolean;
  onClose(): void;
  onCurrentLanguageChange?: (language: OptionTypeBase) => void;
  onRemoveTask(task: ITask): void;
  onChangeHighlightPrompt: (highlightPrompt: string) => void;
  onTagsEnabledChange: (tagsEnabled: boolean) => void;
}

interface TaskDetails {
  title: string;
  description: string;
  showLanguageSettings?: boolean;
  showPromptField?: boolean;
}

const TaskDetails: FC<TasksDetailsProps> = ({
  isOpen,
  currentTask,
  currentLanguage,
  currentHighlightPrompt,
  currentTagsEnabled,
  onClose,
  onCurrentLanguageChange,
  onRemoveTask,
  onChangeHighlightPrompt,
  onTagsEnabledChange,
}) => {
  const taskTedatils = useMemo(() => {
    if (currentTask?.id === 'transcribe') {
      return {
        title: currentTask.name,
        description: currentTask.description,
        showLanguageSettings: true,
      };
    } else if (currentTask?.id === 'highlight') {
      return {
        title: 'Highlight and Tag',
        description:
          'Tell Posty what to highlight and optionally, tag inside of the provided data file(s).',
        showPromptField: true,
      };
    }

    return {
      title: currentTask?.name || '',
      description: currentTask?.description || '',
    };
  }, [currentTask]);

  const creditWord = useMemo(() => (currentTask?.creditsPrice === 1 ? 'credit' : 'credits'), [
    currentTask,
  ]);

  return (
    <Wrapper isOpen={isOpen}>
      <Overlay isOpen={isOpen} onClick={onClose} />
      <Container isOpen={isOpen}>
        <TopContent>
          <Header>
            <CloseButton onClick={onClose}>
              <Icon24.Close />
            </CloseButton>
          </Header>

          <Content>
            <Title>{taskTedatils?.title}</Title>
            <Description>{taskTedatils?.description}</Description>
            {currentTask?.optionsList && (
              <OptionsList>
                {currentTask.optionsList.map((option) => (
                  <Option key={option}>{option}</Option>
                ))}
              </OptionsList>
            )}

            {taskTedatils?.showLanguageSettings && (
              <>
                <SelectTitle>Language</SelectTitle>
                <Select
                  options={languageOptions}
                  onChange={(option) => {
                    if (!option) return;
                    onCurrentLanguageChange && onCurrentLanguageChange(option);
                  }}
                  value={currentLanguage}
                  isSearchable={true}
                  elementWithDividerNumber={2}
                />
              </>
            )}

            {taskTedatils?.showPromptField && (
              <HighlightPopup
                onChangeMessage={onChangeHighlightPrompt}
                onChangeTags={onTagsEnabledChange}
                currentMessage={currentHighlightPrompt}
                currentTagsEnabled={currentTagsEnabled}
                withoutBorder
                withoutButton
                hidePriceText
              />
            )}
          </Content>
        </TopContent>

        <BottomButtons>
          {currentTask?.creditsPrice && (
            <CreditPrice>
              <PostyIcon>
                <Icon24.PostyGray />
              </PostyIcon>
              {currentTask.creditsPrice} {creditWord}
            </CreditPrice>
          )}
          {currentTask?.withSettings && (
            <SaveButton type="primary" onClick={onClose}>
              Save task
            </SaveButton>
          )}
          <Button type="secondary" onClick={() => currentTask && onRemoveTask(currentTask)}>
            Remove task
          </Button>
        </BottomButtons>
      </Container>
    </Wrapper>
  );
};

export default TaskDetails;
