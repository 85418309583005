import React, { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  Container,
  HighlightAndTagButton,
  HighlightAndTagButtonText,
  HighlightAndTagButtonWrapper,
  HighlightPopupWrapper,
  Text,
  TextContainer,
} from './styles';

import Icon24 from '../../Icons/Icon/Icon24';
import { Icon48 } from '../../Icons/Icon';
import HighlightPopup from '../HighlightPopup';
import PortalNew from '../PortalNew';
import { EditorContext } from '../../Context/EditorContext';
import { useParams } from 'react-router-dom';
import { useDashboardTags } from '../../Hooks/useTags';
import { TagWithDetails } from '../../Models';
import Spinner from '../Spinner';
import Icon68 from '../../Icons/Icon/Icon68';

interface TagsPanelEmptyStateProps {
  disabled?: boolean;
}

const TagsPanelEmptyState: FC<TagsPanelEmptyStateProps> = ({ disabled }) => {
  const highlightButtonRef = useRef<HTMLDivElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);
  const [showHighlightPopup, setShowHighlightPopup] = useState(false);
  const { highlightAll, highlightsGenerating } = useContext(EditorContext);
  const { dashboardId } = useParams<{ dashboardId: string }>();
  const [loadingTags, tags, refetchTags] = useDashboardTags(dashboardId, true, true);

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (popupRef.current && !popupRef.current.contains(e.target as Node)) {
        e.stopPropagation();
        setShowHighlightPopup(false);
      }
    };

    window.addEventListener('mousedown', handleClick);
    return () => {
      window.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const handleGenerateHighlights = async (message: string, withTags: boolean) => {
    setShowHighlightPopup(false);
    await highlightAll(dashboardId, tags as TagWithDetails[], {
      callback: () => refetchTags(),
      withTags,
      message,
    });
  };

  const highlightPopupPosition = useMemo(() => {
    if (!highlightButtonRef.current) return null;
    const { top, left, width } = highlightButtonRef.current.getBoundingClientRect();

    return {
      top,
      left: left - 354 + width,
    };
  }, [highlightButtonRef.current, showHighlightPopup]);

  const currentText = useMemo(() => {
    if (disabled) {
      return `When the transcript is ready you can ask Posty to summarize, highlight and tag for you.`;
    }

    return highlightsGenerating
      ? 'Posty is working...'
      : `Highlights show up here. Ask Posty to highlight and tag anything for you.`;
  }, [disabled, highlightsGenerating]);

  return (
    <Container>
      {highlightsGenerating ? <Icon68.Posty /> : <Icon68.PostyGray />}

      <TextContainer>
        {highlightsGenerating && <Spinner size="small" />}
        <Text isGenerating={highlightsGenerating}>{currentText}</Text>
      </TextContainer>

      {!highlightsGenerating && (
        <HighlightAndTagButtonWrapper ref={highlightButtonRef} disabled={!!disabled}>
          <HighlightAndTagButton onClick={() => !disabled && setShowHighlightPopup(true)}>
            {disabled ? <Icon24.PostyPenGray /> : <Icon24.PostyPen />}
            <HighlightAndTagButtonText>Highlight and Tag</HighlightAndTagButtonText>
          </HighlightAndTagButton>
        </HighlightAndTagButtonWrapper>
      )}

      {showHighlightPopup && highlightPopupPosition && (
        <PortalNew wrapperId="highlightPopup">
          <HighlightPopupWrapper
            top={highlightPopupPosition.top}
            left={highlightPopupPosition.left}
            ref={popupRef}
          >
            <HighlightPopup
              onGenerateHighlights={handleGenerateHighlights}
              onClose={() => setShowHighlightPopup(false)}
            />
          </HighlightPopupWrapper>
        </PortalNew>
      )}
    </Container>
  );
};

export default TagsPanelEmptyState;
