import React, { FC, useEffect, useMemo, useState } from 'react';
import { useFetchPromptById } from '../../Hooks/usePrompts';
import PromptDefaultIcon from '../../assets/projects/default-icons/Red-Thread.svg';
import Input from '../../Components/Input/Input';

import {
  ChevronLabelText,
  Container,
  Controls,
  CreatedBy,
  CreatedByText,
  CtaButton,
  CtaInfo,
  CtaLabel,
  CtaOverlay,
  Header,
  IconWrapper,
  InfoContainer,
  InputContainer,
  LeftSide,
  OutputResult,
  TemplateDescription,
  TemplateIcon,
  TemplateName,
} from './styles';
import Tabs from '../Tabs';
import { TabOption } from '../Tabs/Tabs';
import { Icon24 } from '../../Icons/Icon';
import Avatar from '../Avatar';
import { formatDistance } from 'date-fns';
import { PureTipTapEditor } from '../TipTapEditor/TipTapEditor';
import { PROMPT_ICONS } from '../../Consts/promptTemplates';
import { useOrganization } from '../../Hooks/useOrganization';
import { tiersArray as tiers } from '../../Consts/tiers-08-23';
import Button from '../Button';
import { useIntercom } from 'react-use-intercom';
import Showdown from 'showdown';

interface TemplatePreviewProps {
  templateId: string;
}

const tabOptions: TabOption[] = [
  {
    value: 'preview',
    label: 'Preview',
  },
  {
    value: 'prompt',
    label: 'Prompt',
  },
];

const TemplatePreview: FC<TemplatePreviewProps> = ({ templateId }) => {
  const [loading, template] = useFetchPromptById(templateId);
  const [, organization] = useOrganization();
  const currentTier = tiers.find((x) => x.id === organization.plan);
  const isEnterprise = currentTier?.id === 'enterprise';
  const isPublicTemplate = !!template?.isPublic;
  const [currentOption, setCurrentOption] = useState(tabOptions[0]);
  const intercom = useIntercom();

  useEffect(() => {
    if (template?.preview) {
      setCurrentOption(tabOptions[0]);
    } else {
      setCurrentOption(tabOptions[1]);
    }
  }, [template?.preview]);

  const handleContactUsClick = () => {
    intercom.showNewMessage('Hi, I want to upgrade to enterprise. ');
  };

  const markdownPreview = useMemo(() => {
    const converter = new Showdown.Converter();
    return converter.makeHtml((template?.preview || '').trim());
  }, [template?.preview]);

  if (loading) {
    return null;
  }

  return (
    <Container>
      <Header>
        <LeftSide>
          <TemplateIcon
            src={template?.icon || PROMPT_ICONS.get(template.defaultIcon) || PromptDefaultIcon}
          />
          <InfoContainer>
            <TemplateName>{template?.title}</TemplateName>
            <TemplateDescription>{template?.description}</TemplateDescription>
          </InfoContainer>
        </LeftSide>
      </Header>

      <Controls>
        <Tabs
          currentOption={currentOption}
          options={!template?.preview ? [tabOptions[1]] : tabOptions}
          onChange={(option) => setCurrentOption(option)}
        />

        <CreatedBy>
          <IconWrapper>
            <Icon24.Clock />
          </IconWrapper>
          <CreatedByText>
            Created{' '}
            {formatDistance(
              template?.updatedAt ? new Date(template?.updatedAt) : new Date(),
              new Date(),
              { addSuffix: true }
            )}{' '}
            by
          </CreatedByText>
          <IconWrapper>
            <Avatar user={template?.userByCreatedBy} />
          </IconWrapper>
          <CreatedByText>{template?.userByCreatedBy?.name || 'Notably'}</CreatedByText>
        </CreatedBy>
      </Controls>

      <InputContainer>
        {currentOption.value === 'prompt' && (
          <>
            <Input
              value={template.mainPrompt}
              textarea
              fullHeight
              disabled
              disableScroll={isPublicTemplate && !isEnterprise}
            />

            {isPublicTemplate && !isEnterprise && (
              <CtaOverlay>
                <CtaLabel>
                  <Icon24.Badge />
                  <ChevronLabelText>Enterprise only</ChevronLabelText>
                </CtaLabel>
                <CtaInfo>
                  Upgrade to Enterprise to view and edit prompts that power our library of AI
                  templates.
                </CtaInfo>
                <CtaButton>
                  <Button type="primary" onClick={handleContactUsClick}>
                    Contact us
                  </Button>
                </CtaButton>
              </CtaOverlay>
            )}
          </>
        )}

        {currentOption.value === 'preview' && (
          <OutputResult>
            <PureTipTapEditor content={markdownPreview} editable={false} />
          </OutputResult>
        )}
      </InputContainer>
    </Container>
  );
};

export default TemplatePreview;
