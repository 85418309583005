import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { Popover } from '@headlessui/react';
import QuestionMarkIcon from '../../Icons/QuestionMarkIcon';
import Button from '../Button';
import Loader from '../Loader';
import ReactTooltip from 'react-tooltip';
import useDisableBackgroundScroll from '../../Hooks/useDisableBackgroundScroll';
import tagsEmptyImage from '../../assets/empty-states/tags.png';
import {
  ButtonText,
  Container,
  Overlay,
  ModalWindow,
  Content,
  Header,
  CloseButton,
  LeftPanel,
  RightPanel,
  Buttons,
  ButtonDanger,
  InputWrapper,
  InputField,
  InputTitle,
  TextArea,
  InputTitleText,
  EmptyState,
  EmptyStateImage,
  EmptyStateText,
  ColorIcon,
  EditableRowWrapper,
  Panels,
  DetailsText,
  CreatedByWrapper,
  SmartAnalysisText,
  SmartAnalysisIcon,
  SmartAnalysisTextWrapper,
} from './styles';
import { Icon24 } from '../../Icons/Icon';
import { useQuery } from '@apollo/client';
import { FETCH_TAG } from '../../GraphQL/queries';
import Masonry from 'react-masonry-css';
import { documentRoute, noteRoute, transcriptRoute } from '../../Utils/routeBuilder';
import Avatar from '../Avatar';
import {
  DocumentIcon,
  DocumentTextIcon,
  MusicNoteIcon,
  VideoCameraIcon,
  TrashIcon,
  HashtagIcon,
} from '@heroicons/react/outline';

import { useDeleteTag, useUpdateTag } from '../../Hooks/useTags';
import { STICKY_COLORS } from '../../Consts';
import useFeatures from '../../Hooks/useFeatures';
import { toast } from 'react-toastify';
import { defaultProjectIcons } from '../../Consts/projectIcons';
import { ID } from '../../Models';

interface TagsModalProps {
  tagId: ID;
  onClose(): void;
}

const DEFAULT_COLOR = '#d8c0f880';

const TagsModal: FC<TagsModalProps> = ({ tagId, onClose }) => {
  useDisableBackgroundScroll();
  const history = useHistory();

  const { globalTagsEnabled, globalTagsEditable } = useFeatures();

  const { loading, data, refetch } = useQuery(FETCH_TAG, {
    variables: {
      tagId,
    },
  });

  const deleteTag = useDeleteTag();
  const updateTag = useUpdateTag();

  useEffect(() => {
    refetch({ tagId });
  }, [tagId]);

  if (loading)
    return (
      <Container>
        <Overlay />
        <ModalWindow>
          <Loader />
        </ModalWindow>
      </Container>
    );

  const tag = data?.tag;
  const notes = tag?.tagsNotes;
  const documents = tag?.tagsDocuments;
  const transcriptions = tag?.tagsTranscriptions;
  const instancesCount = notes?.length + transcriptions?.length + documents?.length || 0;
  return (
    <Container>
      <Overlay onClick={onClose} />
      <ModalWindow>
        <Header>
          <TagNameInput
            tag={tag}
            onChange={(name) => {
              updateTag(tag.id, { name });
            }}
            disabled={tag.isGlobal && !globalTagsEditable}
          />
          <CloseButton onClick={onClose}>
            <Icon24.Close />
          </CloseButton>
        </Header>
        <Panels>
          <LeftPanel>
            <Content>
              <div>
                <InputTitle>Description</InputTitle>
                <TextArea
                  placeholder="Describe this tag..."
                  maxLength={200}
                  defaultValue={tag.description}
                  onBlur={(e) => {
                    updateTag(tag.id, { description: e.target.value });
                  }}
                  readOnly={tag.isGlobal && !globalTagsEditable}
                />
              </div>
              <div>
                <InputTitle>
                  <InputTitleText>Keywords</InputTitleText>
                  <div data-tip data-for="DetailsQuestionMark">
                    <QuestionMarkIcon color="#3B2651" />
                  </div>
                  <ReactTooltip
                    id="DetailsQuestionMark"
                    arrowColor="#3B2651"
                    eventOff="none"
                    effect="solid"
                    place="right"
                    backgroundColor="#3B2651"
                    textColor="white"
                  >
                    <span className="text-sm font-medium">
                      Specific words or short phrases to look for during analysis.
                      <br />
                      Separate with commas.
                    </span>
                  </ReactTooltip>
                </InputTitle>
                <TextArea
                  placeholder="Add keywords..."
                  maxLength={200}
                  defaultValue={tag.keywords}
                  onBlur={(e) => {
                    updateTag(tag.id, { keywords: e.target.value });
                  }}
                  readOnly={tag.isGlobal && !globalTagsEditable}
                />
              </div>
              <EditableRowWrapper>
                <InputTitle>
                  <ColorIcon />
                  Color
                </InputTitle>
                <Popover className="relative flex items-center">
                  <Popover.Button disabled={tag.isGlobal && !globalTagsEditable}>
                    <div
                      className="h-5 w-5 rounded-xl"
                      style={{
                        backgroundColor: tag.color || DEFAULT_COLOR,
                      }}
                    />
                  </Popover.Button>
                  <Popover.Panel className="absolute z-10 mt-1 p-2 bg-white border shadow-md -top-3 -ml-2">
                    <div className="grid gap-1 grid-cols-5 w-[132px]">
                      {STICKY_COLORS.map((fillColor, index) => (
                        <a
                          href="#"
                          onClick={() => {
                            updateTag(tag.id, { color: fillColor });
                          }}
                          key={index}
                        >
                          <svg
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="11" cy="11" r="11" fill={fillColor} />
                            {tag.color === fillColor && (
                              <path
                                d="M7 10.143L10.4286 13.5714L15 8.42871"
                                stroke="#FFF"
                                strokeWidth="1.5"
                              />
                            )}
                          </svg>
                        </a>
                      ))}
                    </div>
                  </Popover.Panel>
                </Popover>
              </EditableRowWrapper>
              <EditableRowWrapper>
                <InputTitle>
                  <HashtagIcon className="w-5 h-5 mr-2" />
                  Instances
                </InputTitle>
                <DetailsText>{instancesCount}</DetailsText>
              </EditableRowWrapper>
              {tag.createdWithAi && (
                <CreatedByWrapper>
                  <InputTitle>Created with</InputTitle>
                  <SmartAnalysisTextWrapper>
                    <SmartAnalysisIcon>
                      <Icon24.SmartTemplate />
                    </SmartAnalysisIcon>
                    <SmartAnalysisText>Smart Analysis</SmartAnalysisText>
                  </SmartAnalysisTextWrapper>
                </CreatedByWrapper>
              )}
            </Content>
            <Buttons>
              {globalTagsEnabled && globalTagsEditable && !tag.isGlobal && (
                <Button
                  type="secondary"
                  onClick={() => {
                    toast.success(
                      `"${tag.name}" has been added to global tags and can be used across all projects in your workspace.`,
                      {
                        autoClose: 5000,
                      }
                    );
                    updateTag(tag.id, { isGlobal: true });
                  }}
                >
                  <ButtonText>Add to global</ButtonText>
                </Button>
              )}

              {(!tag.isGlobal || globalTagsEditable) && (
                <ButtonDanger
                  type="secondary"
                  onClick={async () => {
                    await deleteTag(tag.id);
                    toast.warning(
                      `"${tag.name}" has been deleted and removed from ${instancesCount} instances.`,
                      {
                        autoClose: 5000,
                      }
                    );
                    onClose();
                  }}
                >
                  <TrashIcon className="w-4 h-4 mr-2" />
                  Delete tag
                </ButtonDanger>
              )}
            </Buttons>
          </LeftPanel>
          <RightPanel>
            {(!notes || notes.length === 0) &&
              (!documents || documents.length === 0) &&
              (!transcriptions || transcriptions.length === 0) && (
                <EmptyState>
                  <EmptyStateImage src={tagsEmptyImage} />
                  <EmptyStateText>
                    There’s no data using this tag, yet. Data will show up here once this tag is
                    used.
                  </EmptyStateText>
                </EmptyState>
              )}
            <Content>
              {notes && notes.length > 0 && (
                <div>
                  <div className="text-sm font-medium py-3 text-primary-purple-light-40">
                    Notes ({notes.length})
                  </div>

                  <Masonry
                    breakpointCols={2}
                    className="basic-masonry-grid"
                    columnClassName="basic-masonry-grid_column"
                  >
                    {notes.map(({ note }: any) => {
                      return <NoteCard note={note} key={note.id} />;
                    })}
                  </Masonry>
                </div>
              )}

              {documents && transcriptions && documents.length + transcriptions.length > 0 && (
                <div>
                  <div className="text-sm font-medium py-3 text-primary-purple-light-40">
                    Data ({documents.length + transcriptions.length})
                  </div>
                  <div>
                    {transcriptions.map(({ transcription }: any) => {
                      let TranscriptIcon = DocumentTextIcon;
                      if (transcription.file?.type === 'audio') TranscriptIcon = MusicNoteIcon;
                      if (transcription.file?.type === 'video') TranscriptIcon = VideoCameraIcon;
                      return (
                        <div
                          key={transcription.id}
                          onClick={() => {
                            history.push(
                              transcriptRoute(transcription.dashboardId, transcription.id)
                            );
                            onClose();
                          }}
                          className="flex py-1.5 px-2 items-center text-md cursor-pointer hover:bg-primary-purple-hover rounded"
                        >
                          <TranscriptIcon className="w-6 h-6 mr-3" />
                          {transcription.name}
                        </div>
                      );
                    })}
                    {documents.map(({ document }: any) => {
                      return (
                        <div
                          key={document.id}
                          onClick={() => {
                            history.push(documentRoute(document.dashboardId, document.id));
                            onClose();
                          }}
                          className="flex py-1.5 px-2 items-center text-md cursor-pointer hover:bg-primary-purple-hover  rounded"
                        >
                          <DocumentIcon className="w-6 h-6 mr-3" />
                          {document.name}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </Content>
          </RightPanel>
        </Panels>
      </ModalWindow>
    </Container>
  );
};

const TagNameInput: FC<{ tag: any; disabled?: boolean; onChange: (name: string) => void }> = ({
  tag,
  disabled,
  onChange,
}) => {
  const handleTagNameInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.parentElement) {
      e.target.parentElement.dataset.value = e.target.value;
    }
  };
  return (
    <InputWrapper data-value={tag.name || ' '} color={tag.color}>
      <InputField
        defaultValue={tag.name}
        autoFocus={!tag.name}
        onInput={handleTagNameInput}
        onBlur={(e) => onChange(e.target.value)}
        placeholder="New tag"
        size={7}
        readOnly={disabled}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            e.currentTarget.blur();
          }
        }}
      ></InputField>
    </InputWrapper>
  );
};

const NoteCard: FC<{ note: any }> = ({ note }) => {
  const history = useHistory();

  const icon =
    defaultProjectIcons.find((icon) => icon.name === note?.dashboard?.cover) ||
    defaultProjectIcons[0];
  return (
    <div
      className="border border-primary-purple-light rounded shadow-notes cursor-pointer mb-5"
      onClick={() => {
        history.push(noteRoute(note.dashboardId, note.id));
      }}
    >
      <div className="p-3">
        <div className="flex items-center font-medium mb-3 text-sm">
          <img
            className="m-0 mr-2 rounded-md"
            src={icon?.url}
            alt="Project cover"
            style={{
              width: 24,
              height: 24,
              objectFit: 'cover',
            }}
          />
          <div className="text-ellipsis whitespace-nowrap overflow-hidden max-w-[224px]">
            {note?.dashboard?.name || 'Untitled project'}
          </div>
        </div>

        <div className="font-medium text-sm">{note.text}</div>
        <div className="flex items-center text-sm font mt-3">
          {note.participant && (
            <>
              <div className="flex items-center">
                <Avatar user={note.participant} />
                <div className="ml-2 font-medium">{note.participant?.name}</div>
              </div>
              <div className="rounded-full bg-primary-purple-light h-1 w-1 mx-1"></div>
            </>
          )}
          <div className="text-right max-w-[96px] text-primary-purple-light-40">
            {format(new Date(note.createdAt), 'LLL d, yyyy')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TagsModal;
