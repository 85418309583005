import React, { FC } from 'react';
import { Input, Label, RoundSlider } from './styles';

interface CheckboxSliderProps {
  checked: boolean;
  disabled?: boolean;
  onChange: (checked: boolean) => void;
}

const CheckboxSlider: FC<CheckboxSliderProps> = ({ checked, disabled, onChange }) => {
  return (
    <Label>
      <Input
        type="checkbox"
        id="checkbox"
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
        disabled={!!disabled}
      />
      <RoundSlider />
    </Label>
  );
};

export default CheckboxSlider;
