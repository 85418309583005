import styled, { css } from 'styled-components';

export const Label = styled.label`
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
`;

export const RoundSlider = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  border-radius: 10px;
  background: rgba(229, 229, 229, 1);
  transition: background-color 0.2s;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    bottom: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    background-color: #ffffff;
    transition: transform 0.2s;
    border-radius: 50%;
    z-index: 1;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(124.02deg, #8e84ee -14.1%, #d2cef8 70.15%);
    opacity: 0;
    transition: opacity 0.2s;
    z-index: 0;
    border-radius: 10px;
  }
`;

export const Input = styled.input<{ disabled: boolean }>`
  display: none;

  &:checked + ${RoundSlider}:after {
    ${({ disabled }) => (disabled ? 'opacity: 0' : 'opacity: 1')};
  }

  &:checked + ${RoundSlider}:before {
    transform: translateX(20px);
  }
`;
