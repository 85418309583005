import React, { FC } from 'react';
import DataUploader from '../../Components/DataUploader';
import { Container } from './styles';
import { CloudFile } from '../../Models';

interface UploadDataStepProps {
  isUploadingCloudFiles: boolean;
  zoomEnabled?: boolean;
  googleDriveEnabled?: boolean;
  oneDriveEnabled?: boolean;
  onZoomButtonClick?: () => void;
  onOneDriveButtonClick?: () => void;
  onFinish?: () => void;
  onCurrentFilesChange: (files: File[] | null) => void;
  onCurrentCloudFilesChange?: (files: CloudFile[]) => void;
}

const UploadDataStep: FC<UploadDataStepProps> = ({
  isUploadingCloudFiles,
  zoomEnabled,
  googleDriveEnabled,
  oneDriveEnabled,
  onZoomButtonClick,
  onOneDriveButtonClick,
  onFinish,
  onCurrentFilesChange,
  onCurrentCloudFilesChange,
}) => {
  return (
    <Container>
      <DataUploader
        isGoogleDriveUploading={isUploadingCloudFiles}
        onCurrentFilesChange={onCurrentFilesChange}
        onCurrentCloudFilesChange={onCurrentCloudFilesChange}
        onZoomButtonClick={onZoomButtonClick}
        onOneDriveButtonClick={onOneDriveButtonClick}
        onFinish={onFinish}
        googleDriveEnabled={googleDriveEnabled}
        zoomEnabled={zoomEnabled}
      />
    </Container>
  );
};

export default UploadDataStep;
