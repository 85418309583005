import React, { PropsWithChildren } from 'react';

const sizeMap = {
  xs:
    'inline-flex items-center px-2.5 py-1.5 border text-xs font-medium rounded-sm-md shadow-sm disabled:opacity-75 disabled:cursor-default focus:outline-none shrink-0 ',
  sm:
    'inline-flex items-center px-3 py-2 border text-base leading-4 font-medium rounded-sm-md shadow-sm disabled:opacity-75 disabled:cursor-default focus:outline-none shrink-0 ',
  md:
    'inline-flex items-center px-4 py-2 border text-base font-medium rounded-sm-md shadow-sm disabled:opacity-75 disabled:cursor-default focus:outline-none shrink-0 ',
  lg:
    'inline-flex items-center px-4 py-2 border text-base font-medium rounded-sm-md shadow-sm disabled:opacity-75 disabled:cursor-default focus:outline-none shrink-0 ',
  xl:
    'inline-flex items-center px-6 py-3 border text-base font-medium rounded-sm-md shadow-sm disabled:opacity-75 disabled:cursor-default focus:outline-none shrink-0 ',
};

const typeMap = {
  primary:
    'bg-secondary-purple-dark focus:ring-secondary-purple-dark text-white hover:bg-[#5F4082] cursor-pointer outline-none',
  primaryLight:
    'bg-[#D2CEF8] focus:ring-secondary-purple-dark hover:bg-[#E3E0FF] cursor-pointer border-secondary-purple-light',
  secondary:
    'bg-white text-secondary-purple-dark focus:ring-secondary-purple-dark border-gray-200 hover:bg-secondary-hover disabled:hover:bg-white cursor-pointer outline-none',
};

const activeClasses = 'outline-none ring-2 ring-offset-2 opacity-75 ring-secondary-purple-dark';

type ButtonProps = {
  size?: 'sm' | 'xs' | 'md' | 'lg' | 'xl';
  icon?: JSX.Element;
  className?: string;
  disabled?: boolean;
  type?: 'primary' | 'primaryLight' | 'secondary';
  onClick?: React.MouseEventHandler<HTMLButtonElement | HTMLDivElement>;
  onMouseDown?: React.MouseEventHandler<HTMLButtonElement | HTMLDivElement>;
  active?: boolean;
  as?: 'button' | 'div';
};

export default function Button({
  icon,
  size = 'sm',
  type = 'primary',
  children,
  className,
  disabled = false,
  active = false,
  as = 'button',
  onClick,
  onMouseDown,
}: PropsWithChildren<ButtonProps>): JSX.Element {
  return as === 'button' ? (
    <button
      type={'submit'}
      disabled={disabled}
      className={[sizeMap[size], className, typeMap[type], active ? activeClasses : '']
        .filter(Boolean)
        .join(' ')}
      onClick={onClick}
      onMouseDown={onMouseDown}
    >
      {icon}
      {children}
    </button>
  ) : (
    <div
      className={[sizeMap[size], className, typeMap[type], active ? activeClasses : '']
        .filter(Boolean)
        .join(' ')}
      onClick={onClick}
      onMouseDown={onMouseDown}
    >
      {icon}
      {children}
    </div>
  );
}
