import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Toolbar = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 10px 6px 20px;
  border-bottom: 1px solid #d7d3dc;
`;

export const Breadcrumbs = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
`;

export const Controls = styled.div`
  display: flex;
  align-items: center;
`;

export const CreatedBy = styled.div`
  display: flex;
  align-items: center;
`;

export const CreatedByText = styled.div`
  color: rgba(59, 38, 81, 0.6);
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  margin-right: 20px;
`;

export const IconWrapper = styled.div`
  margin-right: 5px;
`;

export const DotsButton = styled.div`
  position: relative;
  padding: 5px;
  margin-left: 10px;
  cursor: pointer;
`;

export const TemplateInfo = styled.div`
  width: 100%;
  padding: 15px 20px 20px 10px;
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid #d7d3dc;
`;

export const BackButton = styled.div`
  cursor: pointer;
  margin-right: 20px;
`;

export const TemplateInfoContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

export const TemplateDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 20px;
`;

export const CustomTemplateText = styled.div`
  color: rgba(59, 38, 81, 0.4);
  font-size: 12px;
  line-height: 24px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 10px;
`;

export const TemplateDetailsContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

export const TemplateIcon = styled.div<{ src: string }>`
  position: relative;
  width: 68px;
  height: 68px;
  margin-right: 10px;
  background-image: url(${({ src }) => src});
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
`;

export const TemplateDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const TemplateName = styled.input`
  font-size: 18px;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 10px;
  border: none;
  outline: none;
  width: 100%;
  max-width: 600px;

  &:disabled {
    background-color: #ffffff;
  }
`;

export const TemplateDescription = styled.input`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  border: none;
  outline: none;
  width: 100%;
  max-width: 1000px;

  &:disabled {
    background-color: #ffffff;
  }
`;

export const Filters = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.div`
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  margin-right: 10px;
`;

export const MainContent = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
`;

export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 20px 20px 40px;
  border-right: 1px solid #d7d3dc;
  max-width: 730px;
  width: 100%;
  flex: 1;
  overflow-y: auto;
`;

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 20px;
`;

export const Title = styled.div`
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
  margin-bottom: 5px;
`;

export const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;

  &:last-child {
    margin: 0;
  }
`;

export const FakeDropdown = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 10px;
  border-radius: 20px;
  background-color: #efedf1;
  cursor: pointer;
`;

export const ProjectIcon = styled.div<{ src: string }>`
  width: 24px;
  height: 24px;
  background-image: url(${({ src }) => src});
  background-size: contain;
  background-repeat: no-repeat;
`;

export const Options = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px;
  overflow: hidden;
`;

export const OutputResult = styled.div`
  display: flex;
  flex: 1;
  border: 1px solid #d7d3dc;
  border-radius: 6px;
  padding: 10px;
  max-height: 670px;
  overflow-y: auto;
`;

export const RightSideWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FakeDropdownText = styled.div<{ isPlaceholder: boolean }>`
  ${({ isPlaceholder }) => isPlaceholder && `color: rgba(59, 38, 81, 0.4)`};
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  margin: 0 5px;
`;

export const MenuPanel = styled.div<{ show: boolean }>`
  position: absolute;
  top: 36px;
  right: 0;
  white-space: nowrap;
  display: none;
  ${({ show }) => show && `display: block;`}

  border-radius: 3px;
  border: 1px solid var(--Light-Purple-Gray, #d7d3dc);
  background-color: #ffffff;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.1);
  z-index: 2;
`;

export const MenuPanelItem = styled.div<{ itemColor?: string }>`
  width: 100%;
  padding: 6px 40px 6px 10px;
  cursor: pointer;
  user-select: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;

  ${({ itemColor }) => itemColor === 'red' && `color: #EA4335;`}

  &:hover {
    background: #f8f8f8;
  }
`;

export const EmptyStateText = styled.div`
  color: rgba(59, 38, 81, 0.6);
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  max-width: 350px;
`;

export const EmptyStateButton = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

export const InputContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const StartWithOutline = styled.div`
  position: absolute;
  left: 10px;
  top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(59, 38, 81, 0.6);
  font-size: 14px;
  line-height: 28px;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
`;

export const StartWithOutlineIcon = styled.div`
  margin-right: 5px;
`;

export const IconsDropdown = styled.div<{ left: number; top: number; show: boolean }>`
  position: absolute;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  display: ${({ show }) => (show ? 'grid' : 'none')};
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  width: 100%;
  max-width: 244px;
  background-color: #ffffff;
  border-radius: 6px;
  z-index: 2;
  border: 1px solid #d7d3dc;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.1);
  padding: 10px;
`;

export const IconOption = styled.div<{ src: string }>`
  width: 48px;
  height: 48px;
  border-radius: 6px;
  cursor: pointer;
  background-image: url(${({ src }) => src});
  background-size: contain;
  background-repeat: no-repeat;
`;

export const CtaOverlay = styled.div`
  position: absolute;
  bottom: 7px;
  left: 2px;
  right: 2px;
  height: 60%;
  border-radius: 0 0 4px 4px;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px -40px 30px 0px #fafafa;
`;

export const CtaButton = styled.div``;

export const CtaLabel = styled.div`
  display: flex;
  align-items: center;
  color: rgba(59, 38, 81, 0.6);
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 16px;
`;

export const CtaInfo = styled.div`
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
  max-width: 400px;
  text-align: center;
  margin-bottom: 16px;
`;

export const ChevronLabelText = styled.div`
  margin-left: 5px;
`;

export const UpgradeTitle = styled.div`
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
  margin-bottom: 5px;
`;

export const UpgradeSubtitle = styled.div`
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 16px;
`;

export const CreditInfo = styled.div<{ isRed?: boolean }>`
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  margin-top: 16px;
  color: ${({ isRed }) => (isRed ? `#EA4335` : `rgba(59, 38, 81, 0.4)`)};
  ${({ isRed }) => isRed && `cursor: pointer;`}
`;

export const UpgradeFeatureList = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

export const UpgradeFeatrueItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const UpgradeFeatureText = styled.div`
  margin-left: 5px;
  font-size: 14px;
  line-height: 22.199px;
  font-weight: 500;
`;

export const UpgradeIconWrapper = styled.div`
  color: rgba(59, 38, 81, 0.4);
`;
