/* eslint-disable @typescript-eslint/no-empty-function */
import React, { FC, useContext, useEffect, useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { useDashboardTags } from '../Hooks/useTags';
import { TagWithDetails } from '../Models';
import { ITaskWithData } from '../Pages/UploadData/UploadData';
import { EditorContext } from './EditorContext';

interface PostyTasksContextProps {
  tasksProcessing: boolean;
  processingTaskId: string;
  startProcessing: () => void;
  currentTaskFinished: () => void;
}

export const PostyTasksContext = React.createContext<PostyTasksContextProps>({
  tasksProcessing: false,
  processingTaskId: '',
  startProcessing: () => {},
  currentTaskFinished: () => {},
});

interface PostyTasksContextProviderProps {
  children: React.ReactNode;
  dashboardId: string;
  documentId: string;
}

const tasksOrder = ['tidy-up', 'pii', 'summarize', 'highlight'];

export const PostyTasksContextProvider: FC<PostyTasksContextProviderProps> = ({
  children,
  dashboardId,
  documentId,
}) => {
  const [postyTasks, setPostyTasks] = useLocalStorage<ITaskWithData[]>(
    `TASKS_${dashboardId}_${documentId}`,
    []
  );
  const [currentPostyTasks, setCurrentPostyTasks] = useState<ITaskWithData[]>([]);
  const [isProcessing, setIsProcessing] = useState(postyTasks.length > 0);
  const [currentTaskIndex, setCurrentTaskIndex] = useLocalStorage(
    `CURRENT_TASK_${dashboardId}_${documentId}`,
    -1
  );
  const [currentTaskId, setCurrentTaskId] = useState('');
  const { cleanFillerWords, cleanPII, highlightAll } = useContext(EditorContext);
  const [, tags, refetchTags] = useDashboardTags(dashboardId, true, true);

  useEffect(() => {
    const isArrayDifferent =
      postyTasks.length !== currentPostyTasks.length ||
      postyTasks
        .map((task) => task.id)
        .some((task) => !currentPostyTasks.map((task) => task.id).includes(task));
    if (isArrayDifferent) {
      setCurrentPostyTasks(postyTasks);
    }
  }, [postyTasks, currentPostyTasks]);

  useEffect(() => {
    if (currentTaskIndex === -1) return;
    const taskExists = currentPostyTasks.find((task) => task.id === currentTaskId);
    if (!taskExists) {
      processNextTask();
      return;
    }
    switch (currentTaskId) {
      case 'tidy-up':
        processTidyUp();
        break;
      case 'pii':
        processPii();
        break;
      case 'summarize':
        processSummarize();
        break;
      case 'highlight':
        processHighlight();
        break;
      default:
        break;
    }
  }, [currentTaskId, currentPostyTasks]);

  const processNextTask = () => {
    if (currentTaskIndex === tasksOrder.length - 1) {
      setPostyTasks([]);
      setCurrentTaskIndex(-1);
      setCurrentTaskId('');
      setIsProcessing(false);
      return;
    }

    setCurrentTaskIndex(currentTaskIndex + 1);
    setCurrentTaskId(tasksOrder[currentTaskIndex + 1]);
  };

  const processTidyUp = () => {
    console.log('Tidy up started');
    setTimeout(() => cleanFillerWords(), 0);
    setTimeout(() => processNextTask(), 3000);
  };

  const processPii = () => {
    console.log('PII started');
    setTimeout(() => cleanPII(), 0);
    processNextTask();
  };

  const processSummarize = () => {
    console.log('Summarization started');
  };

  const processHighlight = async () => {
    console.log('Highlighting started');
    const highlightTask = currentPostyTasks.find((task) => task.id === 'highlight');
    await highlightAll(dashboardId, tags as TagWithDetails[], {
      message: highlightTask?.prompt,
      withTags: highlightTask?.tagsEnabled,
      callback: () => {
        processNextTask();
        console.log('Highlighting finished');
        refetchTags();
      },
    });
  };

  const startProcessing = () => {
    if (!currentPostyTasks.length) return;
    setIsProcessing(true);
    processNextTask();
  };

  const currentTaskFinished = () => {
    processNextTask();
  };

  return (
    <PostyTasksContext.Provider
      value={{
        tasksProcessing: isProcessing,
        processingTaskId: currentTaskId,
        startProcessing,
        currentTaskFinished,
      }}
    >
      {children}
    </PostyTasksContext.Provider>
  );
};
