import { Popover } from '@headlessui/react';
import { TrashIcon } from '@heroicons/react/outline';
import React from 'react';
import { STICKY_COLORS } from '../Consts';
import { ThemeIcon } from '../Icons';
import { Icon24 } from '../Icons/Icon';

// todo tailwind => styled components (can i reuse any of these menu items from the other menus??)

export default function GroupSelectionMenu({
  color,
  onTheme,
  onCluster,
  onDelete,
  onColorChange,
}: {
  color: string;
  onTheme: () => void;
  onCluster: () => void;
  onDelete: () => void;
  onColorChange: (color: string) => void;
}): JSX.Element {
  return (
    <div
      className={'-mt-12 center'}
      style={{
        marginLeft: '-50%',
      }}
    >
      <span className="inline-flex shadow-md rounded-md items-center">
        <button
          data-tip="theme"
          onClick={onTheme}
          className="relative inline-flex items-center px-3 py-2 rounded-l-md border border-gray-300 bg-white text-md font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
        >
          <span className="mr-1">
            <ThemeIcon />
          </span>
          <p style={{ fontSize: '14px', lineHeight: '16px' }}>Theme</p>
        </button>
        <button
          data-tip="cluster"
          onClick={onCluster}
          className="-ml-px relative inline-flex items-center px-2 border border-gray-300 bg-white text-md font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
        >
          <span className="mr-1">
            <Icon24.Cluster />
          </span>
          <p className="py-2" style={{ fontSize: '14px', lineHeight: '16px' }}>
            Cluster
          </p>
        </button>

        <Popover className="relative">
          <Popover.Button className="-ml-px relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-md font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
            <div
              className={'h-4 w-4 rounded-lg'}
              style={{
                backgroundColor: color,
              }}
            />
          </Popover.Button>
          <Popover.Panel className="absolute z-10 mt-1 p-2 bg-white border shadow-md">
            <div className="grid gap-1 grid-cols-5 w-[132px]">
              {STICKY_COLORS.map((x, index) => (
                <a href="#" key={index} onClick={() => onColorChange(x)}>
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="11" cy="11" r="11" fill={x} />
                    {color == x && (
                      <path
                        d="M7 10.143L10.4286 13.5714L15 8.42871"
                        stroke="#382152"
                        strokeWidth="1.5"
                      />
                    )}
                  </svg>
                </a>
              ))}
            </div>
          </Popover.Panel>
        </Popover>
        <button
          onClick={() => onDelete()}
          className="-ml-px relative inline-flex items-center px-2 py-1.5 border border-gray-300 bg-white text-md font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 rounded-r-md"
        >
          <TrashIcon stroke="#3B2651" className={'h-5 w-5'} />
        </button>
      </span>
    </div>
  );
}
