import React, { useEffect, useState } from 'react';

import {
  Container,
  OutOfCreditsText,
  TaskDescription,
  TaskIcon,
  TaskInfo,
  TaskItem,
  TasksContainer,
  TaskTitle,
  Title,
} from './styles';
import { ITask } from '../TaskManager/TaskManager';

interface TasksTableProps {
  tasks: ITask[];
  currentTasks: ITask[];
  hasNoCredits: boolean;
  onChooseTask(task: ITask): void;
}

const TasksTable: React.FC<TasksTableProps> = ({
  tasks,
  currentTasks,
  hasNoCredits,
  onChooseTask,
}) => {
  const [searchText, setSearchText] = useState('');
  const [tasksToShow, setTasksToShow] = useState<ITask[]>([]);

  useEffect(() => {
    const currentTasksIds = currentTasks.map((task) => task.id);
    const filteredTasks = tasks.filter((task) => !currentTasksIds.includes(task.id));

    setTasksToShow(
      filteredTasks.filter((task) => task.name.toLowerCase().includes(searchText.toLowerCase()))
    );
  }, [searchText, tasks, currentTasks]);

  const checkIfTaskIsDisabled = (task: ITask) => {
    return hasNoCredits && !!task.creditsPrice && task.creditsPrice > 0;
  };

  return (
    <Container>
      {!!tasksToShow.length && <Title>{tasksToShow.length} Posty Tasks</Title>}
      <TasksContainer>
        {tasksToShow.map((task) => (
          <TaskItem
            key={task.id}
            onClick={() => !checkIfTaskIsDisabled(task) && onChooseTask(task)}
            disabled={checkIfTaskIsDisabled(task)}
          >
            {checkIfTaskIsDisabled(task) && (
              <OutOfCreditsText>{`You're out of credits`}</OutOfCreditsText>
            )}
            <TaskIcon>{task.icon}</TaskIcon>
            <TaskInfo>
              <TaskTitle>{task.name}</TaskTitle>
              <TaskDescription>{task.description}</TaskDescription>
            </TaskInfo>
          </TaskItem>
        ))}
      </TasksContainer>
    </Container>
  );
};

export default TasksTable;
