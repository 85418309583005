import React, { FC } from 'react';
import { OptionTypeBase } from 'react-select';
import FilesColumn from '../../Components/FilesColumn/FilesColumn';
import TaskManager from '../../Components/TaskManager';
import { ITask } from '../../Components/TaskManager/TaskManager';
import { PropmptTemplate } from '../../Consts/promptTemplates';
import { Icon24 } from '../../Icons/Icon';
import { CloudFile } from '../../Models';
import { isVideoOrAudio } from '../../Utils/fileTypes';
import { LeftSide, RightSide, TasksStepContent } from './styles';

const tasks = [
  {
    id: 'tidy-up',
    name: 'Tidy Up',
    description: 'Remove duplicate and filler words in transcripts.',
    icon: <Icon24.TaskBroomstick />,
  },
  {
    id: 'pii',
    name: 'Redact PII',
    description:
      'Posty will automatically redact Personally Identifiable Information (PII) such as: Names, Phone Number, Emails, and Addresses.',
    icon: <Icon24.TaskTranscription />,
  },
  {
    id: 'summarize',
    name: 'Summarize',
    creditsPrice: 1,
    description: 'Automatically summarize data using our library of AI templates.',
    icon: <Icon24.TaskSummarization />,
  },
  {
    id: 'highlight',
    name: 'Highlight & Tag',
    creditsPrice: 1,
    description:
      'Tell Posty what to highlight and optionally, tag inside of the provided data file(s).',
    icon: <Icon24.TaskHighlighting />,
    withSettings: true,
  },
];

interface TasksStepProps {
  currentFiles: File[] | null;
  currentCloudFiles?: CloudFile[];
  currentDashboardId?: string;
  currentLanguage: OptionTypeBase;
  currentTemplate: PropmptTemplate | null;
  currentHighlightPrompt: string;
  tagsEnabled: boolean;
  currentTasks: ITask[];
  onCurrentTasksChange: (tasks: ITask[]) => void;
  onCurrentTemplateChange: (template: PropmptTemplate | null) => void;
  onCurrentLanguageChange: (language: OptionTypeBase) => void;
  onCurrentHighlightPromptChange: (highlightPrompt: string) => void;
  onTagsEnabledChange: (tagsEnabled: boolean) => void;
  updateCurrentFiles: (files: File[] | null) => void;
  onCancelFile: (filename?: string) => void;
  onCancelCloudFile: (filename?: string) => void;
}

const TasksStep: FC<TasksStepProps> = ({
  currentFiles,
  currentCloudFiles,
  currentLanguage,
  currentTemplate,
  currentHighlightPrompt,
  tagsEnabled,
  currentTasks,
  onCurrentTasksChange,
  onCurrentTemplateChange,
  onCurrentLanguageChange,
  onCurrentHighlightPromptChange,
  onTagsEnabledChange,
  updateCurrentFiles,
  onCancelFile,
  onCancelCloudFile,
}) => {
  const hasVideo =
    currentFiles?.some((file) => isVideoOrAudio(file.name)) ||
    currentCloudFiles?.some((file) => isVideoOrAudio(file.name));

  return (
    <TasksStepContent>
      <LeftSide>
        <FilesColumn
          currentFiles={currentFiles}
          currentCloudFiles={currentCloudFiles}
          onCancelFile={onCancelFile}
          onCancelCloudFile={onCancelCloudFile}
          updateCurrentFiles={updateCurrentFiles}
        />
      </LeftSide>
      <RightSide>
        <TaskManager
          tasks={tasks}
          currentTasks={currentTasks}
          currentTemplate={currentTemplate}
          currentLanguage={currentLanguage}
          onCurrentLanguageChange={onCurrentLanguageChange}
          onTasksChange={onCurrentTasksChange}
          onTemplateChange={onCurrentTemplateChange}
          onChangeHighlightPrompt={onCurrentHighlightPromptChange}
          onTagsEnabledChange={onTagsEnabledChange}
          currentHighlightPrompt={currentHighlightPrompt}
          currentTagsEnabled={tagsEnabled}
          hasVideo={hasVideo}
        />
      </RightSide>
    </TasksStepContent>
  );
};

export default TasksStep;
