import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import ReactModal from 'react-modal';
import { XIcon, SearchIcon, FilterIcon } from '@heroicons/react/outline';
import { Input } from '@chakra-ui/react';
import Button from '../Components/Button';
import { Checkbox, CheckboxGroup } from '@chakra-ui/react';
import { useApolloClient } from '@apollo/client';
import { useDebouncedCallback } from 'use-debounce';
import { defaultProjectIcons } from '../Consts/projectIcons';
import Masonry from 'react-masonry-css';
import { format } from 'date-fns';
import Avatar from './Avatar';
import { composeSearchEvidence } from '../GraphQL/queries/searchEverything';
import { defaultCoverImages } from '../Consts/coverImages';
import { useFetchParticipantNames } from '../Hooks/useParticipants';
import Select from './Select';
import { OptionsType, OptionTypeBase } from 'react-select/src/types';
import Loader from './Loader';
import useTags from '../Hooks/useTags';
import { ID, Insight, Tag } from '../Models';
import TagsPicker from './TagsPicker';
import ProjectsDropdown from './ProjectsDropdown';
import useBoards, { Folder, useFetchFolders } from '../Hooks/useBoards';
import { getDashboards_dashboards } from '../GraphQL/__generated__/getDashboards';
import { ProjectDropDownOption } from './ProjectsDropdown/ProjectsDropdown';
import { Icon24 } from '../Icons/Icon';
import VideoClip from './VideoClip';

const INSIGHTS_SEARCH_ID = 'insights';
const NOTES_SEARCH_ID = 'notes';
const DATA_SEARCH_ID = 'data';
const DEFAULT_SEARCH_TYPES = [NOTES_SEARCH_ID];

interface Props extends ReactModal.Props {
  classNames?: string;
  onClose: () => void;
  dashboardId: string;
  insightId: ID;
  onSubmit: (evidences: any) => void;
}

type SearchResult = {
  notes: Note[] | undefined;
  projectInsights: Insight[] | undefined;
  documents: any[] | undefined;
  transcriptions: any[] | undefined;
};

type Note = {
  id: string;
  text: string;
  dashboardId: string;
  createdAt: string;
  userByCreatedBy: {
    name: string;
    picture: string;
  };
  participant?: {
    name: string;
    picture: string;
  };
  tagsList?: {
    id: string;
    name: string;
  }[];
};

const useDisableBackgroundScroll = (isEnabled: boolean) => {
  useEffect(() => {
    document.body.style.overflow = isEnabled ? 'hidden' : 'unset';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isEnabled]);
};

export default function EvidenceModal({
  // classNames,
  dashboardId,
  insightId,
  isOpen = true,
  onClose,
  onSubmit,
  ...rest
}: React.PropsWithChildren<Props>): JSX.Element {
  useDisableBackgroundScroll(isOpen);
  const client = useApolloClient();
  const { fetchTags } = useTags();

  const [, tags] = fetchTags();

  const [selectedNotes, setSelectedNotes] = useState<string[]>([]);
  const [selectedInsights, setSelectedInsights] = useState<string[]>([]);
  const [selectedDocuments, setSelectedDocuments] = useState<string[]>([]);
  const [selectedTranscriptions, setSelectedTranscriptions] = useState<string[]>([]);
  const [selectedParticipants, setSelectedParticipants] = useState<OptionsType<OptionTypeBase>>([]);
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
  const [loadingEvidences, setLoadingEvidences] = useState(false);
  const [foldersLoading, folders] = useFetchFolders();

  const [loadingParticipants, participants] = useFetchParticipantNames();
  const { fetchBoards } = useBoards();
  const [loadingDashboards, dashboards] = fetchBoards();
  const [currentProjectFilterOptions, setCurrentProjectFilterOptions] = useState<
    ProjectDropDownOption[]
  >([]);
  const [hasVideo, setHasVideo] = useState(false);
  const [selectedSearchTypes, setSelectedSearchTypes] = useState<string[]>(DEFAULT_SEARCH_TYPES);

  const [searchResult, setSearchResult] = useState<SearchResult>({
    notes: [],
    projectInsights: [],
    documents: [],
    transcriptions: [],
  });

  const [searchText, setSearchText] = useState<string>('');

  const debouncedFetchSearchData = useDebouncedCallback(
    async (
      searchText: string,
      dashboardIds,
      searchTypes: string[],
      participantIds,
      tags,
      hasVideo: boolean
    ) => {
      const { data } = await client.query({
        query: composeSearchEvidence(
          searchText,
          participantIds && participantIds.length > 0,
          tags && tags.length > 0,
          hasVideo
        ),
        variables: {
          search: searchText,
          withNotes: searchTypes.includes(NOTES_SEARCH_ID),
          withInsights: searchTypes.includes(INSIGHTS_SEARCH_ID),
          withData: searchTypes.includes(DATA_SEARCH_ID),
          dashboardIds,
          participantIds,
          withTags: Boolean(tags && tags.length > 0),
          tags,
        },
      });
      setSearchResult(data);
      setLoadingEvidences(false);
    },
    500
  );

  useEffect(() => {
    setLoadingEvidences(true);
    debouncedFetchSearchData(
      searchText,
      currentProjectFilterOptions.map((option) => option.value),
      selectedSearchTypes,
      selectedParticipants.length > 0 ? selectedParticipants.map((x) => x.value) : null,
      selectedTags.length > 0 ? selectedTags.map((x) => x.id) : [],
      hasVideo
    );
  }, [
    searchText,
    selectedSearchTypes,
    currentProjectFilterOptions,
    selectedParticipants,
    hasVideo,
    selectedTags,
  ]);

  const projectFolderOptions = useMemo(() => {
    return (
      folders?.map((folder: Folder) => {
        const projectsInFolder = dashboards?.filter(
          (project: getDashboards_dashboards) => project.folderId === folder.id
        );
        return {
          folderId: folder.id,
          folderName: folder.name,
          options:
            projectsInFolder?.map((project) => {
              const iconData = defaultProjectIcons.find((icon) => icon.name === project.cover);
              return {
                value: project.id,
                label: project.name || 'Untitled project',
                icon: iconData?.url || defaultProjectIcons[0].url,
              };
            }) || [],
        };
      }) || []
    );
  }, [dashboards, folders]);

  const projectsWithoutFolderOptions = useMemo(() => {
    return (
      dashboards
        ?.filter((project: getDashboards_dashboards) => !project.folderId)
        .map((project) => {
          const iconData = defaultProjectIcons.find((icon) => icon.name === project.cover);
          return {
            value: project.id,
            label: project.name || 'Untitled project',
            icon: iconData?.url || defaultProjectIcons[0].url,
          };
        }) || []
    );
  }, [dashboards]);

  useEffect(() => {
    if (projectFolderOptions.length || projectsWithoutFolderOptions.length) {
      const options = projectFolderOptions
        .flatMap((folder) => folder.options)
        .concat(projectsWithoutFolderOptions);
      const defaultOption = options.find((option) => option.value === dashboardId);
      setCurrentProjectFilterOptions(defaultOption ? [defaultOption] : []);
    }
  }, [projectFolderOptions, projectsWithoutFolderOptions, dashboardId]);

  const handleClose = () => {
    onClose?.();
    setSelectedNotes([]);
    setSearchText('');
  };

  const selectedCount =
    selectedNotes.length +
    selectedInsights.length +
    selectedDocuments.length +
    selectedTranscriptions.length;

  const filteredNotes = searchResult.notes
    ? searchResult.notes.filter((note) => {
        if (selectedTags.length > 0 && note.tagsList && note.tagsList.length === 0) return false;
        return true;
      })
    : [];

  return (
    <ReactModal onRequestClose={handleClose} isOpen={isOpen} {...rest}>
      <div className="bg-white shadow-md rounded h-[calc(100vh-32px)]">
        <div className="flex items-center justify-between p-3 pt-5">
          <h2 className="text-lg font-semibold">Add Evidence</h2>
          <button className="cursor-pointer" onClick={handleClose}>
            <XIcon className="w-5 h-5" />
          </button>
        </div>
        <div className="mx-3">
          <div className="flex items-center mb-5">
            <div
              className={`py-2 px-3 rounded-md mr-1 font-medium text-md cursor-pointer ${
                selectedSearchTypes.includes(NOTES_SEARCH_ID) ? 'bg-primary-badge-gray' : ''
              }`}
              onClick={() => {
                setSelectedSearchTypes([NOTES_SEARCH_ID]);
              }}
            >
              Notes
            </div>
            <div
              className={`py-2 px-3 mr-1 rounded-md font-medium text-md cursor-pointer ${
                selectedSearchTypes.includes(INSIGHTS_SEARCH_ID) ? 'bg-primary-badge-gray' : ''
              }`}
              onClick={() => {
                setSelectedSearchTypes([INSIGHTS_SEARCH_ID]);
              }}
            >
              Insights
            </div>
            <div
              className={`py-2 px-3 mr-1 rounded-md font-medium text-md cursor-pointer ${
                selectedSearchTypes.includes(DATA_SEARCH_ID) ? 'bg-primary-badge-gray' : ''
              }`}
              onClick={() => {
                setSelectedSearchTypes([DATA_SEARCH_ID]);
              }}
            >
              Data
            </div>
          </div>
          <div className="flex flex-1 items-center  mb-3">
            <div className="flex flex-1 items-center rounded-full bg-primary-search-gray">
              <SearchIcon className="w-6 h-6 ml-4" />
              <div className="h-12 flex flex-1 items-center ml-2">
                <Input
                  variant="unstyled"
                  autoFocus={true}
                  value={searchText}
                  onChange={(event) => setSearchText(event.target.value)}
                  placeholder="Search evidence…"
                />
              </div>
            </div>

            <div>
              <ProjectsDropdown
                folderOptions={projectFolderOptions}
                projectOptions={projectsWithoutFolderOptions}
                currentOptions={currentProjectFilterOptions}
                onChange={setCurrentProjectFilterOptions}
                type="ghost"
              />
            </div>
          </div>
        </div>
        <div className="flex">
          {selectedSearchTypes.includes(NOTES_SEARCH_ID) && (
            <div className=" ml-1 w-64 overflow-y-scroll h-[calc(100vh-240px)]">
              <div className="font-medium m-3">
                <div className="flex items-center mb-4">
                  <Icon24.Filter />
                  <h3 className="ml-[5px]">Filter</h3>
                </div>
              </div>

              {selectedSearchTypes.includes(NOTES_SEARCH_ID) && (
                <>
                  <div className="m-3">
                    <div className="flex justify-between items-center">
                      <h3 className="font-medium">Tags</h3>
                      <div
                        className="font-semibold text-xs cursor-pointer"
                        onClick={() => {
                          setSelectedTags([]);
                        }}
                      >
                        Clear
                      </div>
                    </div>

                    <div className="mt-2">
                      {tags && (
                        <TagsPicker
                          tags={selectedTags}
                          onChange={(tags) => setSelectedTags(tags)}
                          dashboardId={dashboardId}
                          hideManageButton
                        />
                      )}
                    </div>
                  </div>
                  <div className="m-3">
                    <div className="flex justify-between items-center">
                      <h3 className="font-medium">Participants</h3>
                      <div
                        className="font-semibold text-xs cursor-pointer"
                        onClick={() => {
                          setSelectedParticipants([]);
                        }}
                      >
                        Clear
                      </div>
                    </div>
                    <div className="mt-2">
                      {participants && (
                        <Select
                          isMulti
                          isClearable={false}
                          placeholder="Select participants"
                          options={[
                            ...participants.map((participant) => ({
                              value: participant.id,
                              label: participant.name,
                              color: participant.color,
                            })),
                          ]}
                          onChange={(option) => {
                            setSelectedParticipants((option as OptionsType<OptionTypeBase>) || []);
                          }}
                          isSearchable
                          value={selectedParticipants}
                        />
                      )}
                    </div>
                  </div>
                  {selectedSearchTypes.includes(NOTES_SEARCH_ID) && (
                    <div className="m-3">
                      <Checkbox
                        className="flex"
                        colorScheme="purple"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          setHasVideo(event.target.checked);
                        }}
                      >
                        <span className="font-medium text-sm">Has video</span>
                      </Checkbox>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
          <div className="flex-1">
            <div className="flex-1 p-5 overflow-y-scroll h-[calc(100vh-258px)]">
              {loadingEvidences && <Loader />}
              {!loadingEvidences && (
                <>
                  {/* NOTES */}
                  {selectedSearchTypes.includes(NOTES_SEARCH_ID) && (
                    <div>
                      {filteredNotes.length === 0 && (
                        <div className="pb-2 text-primary-purple-light-40">
                          No results. Try expanding your filters to include more results.
                        </div>
                      )}
                      {filteredNotes.length > 0 && (
                        <CheckboxGroup
                          colorScheme="purple"
                          value={selectedNotes}
                          onChange={(value: string[]) => setSelectedNotes(value)}
                        >
                          <Masonry
                            breakpointCols={2}
                            className="basic-masonry-grid"
                            columnClassName="basic-masonry-grid_column"
                          >
                            {filteredNotes.map(
                              (note: Note): JSX.Element => (
                                <div
                                  key={note.id}
                                  className="border border-primary-purple-light rounded shadow-notes cursor-pointer mb-5"
                                >
                                  <div className="p-3">
                                    <Checkbox
                                      key={note.id}
                                      value={`${note.id}:${note.dashboardId}`}
                                      className="flex"
                                      style={{
                                        alignItems: 'flex-start',
                                      }}
                                      variant="card"
                                    >
                                      <div className="font-medium text-sm">
                                        {note.text || 'Untitled'}
                                      </div>
                                    </Checkbox>
                                    <hr className="my-2" />
                                    <div className="flex items-center text-sm mt-2">
                                      {note.participant && (
                                        <>
                                          <div className="flex items-center">
                                            <Avatar user={note.participant} />
                                            <div className="ml-2">{note.participant?.name}</div>
                                          </div>
                                          <div className="rounded-full bg-primary-purple-light h-1 w-1 mx-1"></div>
                                        </>
                                      )}
                                      <div className="text-right max-w-[96px] text-primary-purple-light-40">
                                        {format(new Date(note.createdAt), 'LLL d, yyyy')}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                          </Masonry>
                        </CheckboxGroup>
                      )}
                    </div>
                  )}
                  {/* Insights */}
                  {selectedSearchTypes.includes(INSIGHTS_SEARCH_ID) && (
                    <div>
                      {(!searchResult.projectInsights ||
                        searchResult.projectInsights.length === 0 ||
                        (searchResult.projectInsights.length === 1 &&
                          searchResult.projectInsights[0]?.id === insightId)) && (
                        <div className="pb-2 text-primary-purple-light-40">
                          No results. Try expanding your filters to include more results.
                        </div>
                      )}
                      {searchResult.projectInsights && searchResult.projectInsights?.length > 0 && (
                        <CheckboxGroup
                          colorScheme="purple"
                          value={selectedInsights}
                          onChange={(value: string[]) => setSelectedInsights(value)}
                        >
                          <Masonry
                            breakpointCols={3}
                            className="basic-masonry-grid"
                            columnClassName="basic-masonry-grid_column"
                          >
                            {searchResult.projectInsights.map(
                              (insight: Insight): JSX.Element => {
                                if (insight.id === insightId) return <div key={insight.id}></div>;
                                const coverImage = insight?.customCover
                                  ? {
                                      name: 'custom',
                                      url: insight.customCover,
                                      position: '50% 50%',
                                    }
                                  : defaultCoverImages.find(
                                      (image) => image.name === insight?.cover
                                    );
                                const icon =
                                  defaultProjectIcons.find(
                                    (icon) => icon.name === insight?.dashboard?.cover
                                  ) || defaultProjectIcons[0];
                                return (
                                  <div
                                    key={insight.id}
                                    className="border border-primary-purple-light rounded shadow-notes cursor-pointer mb-5"
                                  >
                                    {coverImage && (
                                      <img
                                        className="m-0 rounded-t"
                                        src={coverImage.url}
                                        alt="cover image"
                                        style={{
                                          width: '100%',
                                          height: 80,
                                          objectFit: 'cover',
                                          objectPosition: coverImage.position,
                                        }}
                                      />
                                    )}
                                    <div className="p-3">
                                      <Checkbox
                                        key={insight.id}
                                        value={`${insight.id}:${insight.dashboardId}:${insight.clientId}`}
                                        className="flex"
                                        style={{
                                          alignItems: 'flex-start',
                                        }}
                                        variant="card"
                                      >
                                        <div className="font-medium text-sm">
                                          {insight.title || 'Untitled'}
                                        </div>
                                      </Checkbox>

                                      <hr className="m-0 my-2" />
                                      <div className="flex items-center text-sm">
                                        <div className="flex items-center font-medium max-w-[160px] ">
                                          <img
                                            className="m-0 mr-2 rounded-md"
                                            src={icon?.url}
                                            alt="Project cover"
                                            style={{
                                              width: 24,
                                              height: 24,
                                              objectFit: 'cover',
                                            }}
                                          />
                                          <div className="text-ellipsis whitespace-nowrap overflow-hidden">
                                            {insight?.dashboard?.name || 'Untitled project'}
                                          </div>
                                        </div>
                                        <div className="flex items-center text-right text-primary-purple-light-40 ml-2">
                                          <div className="rounded-full bg-primary-purple-light h-1 w-1 mr-2"></div>
                                          {format(new Date(insight.createdAt), 'LLL d, yyyy')}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </Masonry>
                        </CheckboxGroup>
                      )}
                    </div>
                  )}

                  {/* DATA */}
                  {selectedSearchTypes.includes(DATA_SEARCH_ID) && (
                    <div>
                      {(!searchResult.documents || searchResult.documents.length === 0) &&
                        (!searchResult.transcriptions ||
                          searchResult.transcriptions.length === 0) && (
                          <div className="pb-2 text-primary-purple-light-40">
                            No results. Try expanding your filters to include more results.
                          </div>
                        )}
                      {((searchResult.documents && searchResult.documents?.length > 0) ||
                        (searchResult.transcriptions &&
                          searchResult.transcriptions?.length > 0)) && (
                        <>
                          <CheckboxGroup
                            colorScheme="purple"
                            value={selectedTranscriptions}
                            onChange={(value: string[]) => setSelectedTranscriptions(value)}
                          >
                            <Masonry
                              breakpointCols={3}
                              className="basic-masonry-grid"
                              columnClassName="basic-masonry-grid_column"
                            >
                              {searchResult?.transcriptions?.map(
                                (transcript: any): JSX.Element => {
                                  const icon =
                                    defaultProjectIcons.find(
                                      (icon) => icon.name === transcript?.dashboard?.cover
                                    ) || defaultProjectIcons[0];
                                  return (
                                    <div
                                      key={transcript.id}
                                      className="border border-primary-purple-light rounded shadow-notes cursor-pointer mb-5"
                                    >
                                      <div className="p-3">
                                        <Checkbox
                                          key={transcript.id}
                                          value={`${transcript.id}:${transcript.dashboardId}`}
                                          className="flex"
                                          style={{
                                            alignItems: 'flex-start',
                                          }}
                                          variant="card"
                                        >
                                          <div className="font-medium text-sm">
                                            {transcript.name || 'Untitled'}
                                          </div>
                                        </Checkbox>
                                        {transcript.file?.signedVideoUrl && (
                                          <div className="my-2">
                                            <VideoClip
                                              src={transcript.file?.signedVideoUrl}
                                              offset={0.9}
                                              disableMinimization
                                            />
                                          </div>
                                        )}

                                        <hr className="m-0 my-2" />
                                        <div className="flex items-center text-sm">
                                          <div className="flex items-center font-medium max-w-[160px] ">
                                            <img
                                              className="m-0 mr-2 rounded-md"
                                              src={icon?.url}
                                              alt="Project cover"
                                              style={{
                                                width: 24,
                                                height: 24,
                                                objectFit: 'cover',
                                              }}
                                            />
                                            <div className="text-ellipsis whitespace-nowrap overflow-hidden">
                                              {transcript?.dashboard?.name || 'Untitled project'}
                                            </div>
                                          </div>
                                          <div className="flex items-center text-right text-primary-purple-light-40 ml-2">
                                            <div className="rounded-full bg-primary-purple-light h-1 w-1 mr-2"></div>
                                            {format(new Date(transcript.createdAt), 'LLL d, yyyy')}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </Masonry>
                          </CheckboxGroup>
                          <CheckboxGroup
                            colorScheme="purple"
                            value={selectedDocuments}
                            onChange={(value: string[]) => setSelectedDocuments(value)}
                          >
                            <Masonry
                              breakpointCols={3}
                              className="basic-masonry-grid"
                              columnClassName="basic-masonry-grid_column"
                            >
                              {searchResult?.documents?.map(
                                (document: any): JSX.Element => {
                                  const icon =
                                    defaultProjectIcons.find(
                                      (icon) => icon.name === document?.dashboard?.cover
                                    ) || defaultProjectIcons[0];
                                  return (
                                    <div
                                      key={document.id}
                                      className="border border-primary-purple-light rounded shadow-notes cursor-pointer mb-5"
                                    >
                                      <div className="p-3">
                                        <Checkbox
                                          key={document.id}
                                          value={`${document.id}:${document.dashboardId}`}
                                          className="flex"
                                          style={{
                                            alignItems: 'flex-start',
                                          }}
                                          variant="card"
                                        >
                                          <div className="font-medium text-sm">
                                            {document.name || 'Untitled'}
                                          </div>
                                        </Checkbox>
                                        {/* {transcript.file?.signedVideoUrl && (
                                          <div className="my-2">
                                            <VideoClip
                                              src={transcript.file?.signedVideoUrl}
                                              offset={0.9}
                                              disableMinimization
                                            />
                                          </div>
                                        )} */}

                                        <hr className="m-0 my-2" />
                                        <div className="flex items-center text-sm">
                                          <div className="flex items-center font-medium max-w-[160px] ">
                                            <img
                                              className="m-0 mr-2 rounded-md"
                                              src={icon?.url}
                                              alt="Project cover"
                                              style={{
                                                width: 24,
                                                height: 24,
                                                objectFit: 'cover',
                                              }}
                                            />
                                            <div className="text-ellipsis whitespace-nowrap overflow-hidden">
                                              {document?.dashboard?.name || 'Untitled project'}
                                            </div>
                                          </div>
                                          <div className="flex items-center text-right text-primary-purple-light-40 ml-2">
                                            <div className="rounded-full bg-primary-purple-light h-1 w-1 mr-2"></div>
                                            {format(new Date(document.createdAt), 'LLL d, yyyy')}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </Masonry>
                          </CheckboxGroup>
                        </>
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="absolute bottom-0 w-full">
          <hr />
          <div className="p-3 flex items-center justify-end mr-2">
            <div className="mr-2 font-medium text-md">{selectedCount} selected</div>
            <Button
              type={'primary'}
              onClick={() => {
                onSubmit({
                  notes: selectedNotes.map((item) => {
                    const [id, dashboardId] = item.split(':');
                    return { id, dashboardId };
                  }),
                  insights: selectedInsights.map((item) => {
                    const [id, dashboardId, clientId] = item.split(':');
                    return { id, dashboardId, clientId };
                  }),
                  transcriptions: selectedTranscriptions.map((item) => {
                    const [id, dashboardId] = item.split(':');
                    return { id, dashboardId };
                  }),
                  documents: selectedDocuments.map((item) => {
                    const [id, dashboardId] = item.split(':');
                    return { id, dashboardId };
                  }),
                });
                setSelectedNotes([]);
                setSelectedInsights([]);
                setSelectedDocuments([]);
                setSelectedTranscriptions([]);
                setSearchText('');
              }}
              disabled={selectedCount === 0}
            >
              Add Evidence
            </Button>
            <Button type={'secondary'} className="ml-2" onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </ReactModal>
  );
}
