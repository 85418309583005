import React, { FC, useEffect, useState } from 'react';
import { Icon24 } from '../../Icons/Icon';
import { DotsButton, MenuPanel, MenuPanelItem, MenuPanelItemIcon } from './styles';

export interface DotsMenuItem {
  label: string;
  icon?: React.ReactNode;
  color?: 'red';
  action: () => void;
}

interface DotsMenuProps {
  items: DotsMenuItem[];
}

const DotsMenu: FC<DotsMenuProps> = ({ items }) => {
  const [showMenuPanel, setShowMenuPanel] = useState(false);

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      e.stopPropagation();
      setShowMenuPanel(false);
    };
    window.addEventListener('mousedown', handleClick);

    return () => {
      window.removeEventListener('mousedown', handleClick);
    };
  }, []);

  return (
    <DotsButton
      onClick={() => {
        setShowMenuPanel(!showMenuPanel);
      }}
    >
      <Icon24.Dots />

      <MenuPanel show={showMenuPanel}>
        {items.map((item) => (
          <MenuPanelItem
            key={item.label}
            itemColor={item.color}
            onMouseDown={(e) => e.stopPropagation()}
            onClick={item.action}
          >
            <MenuPanelItemIcon>{item.icon && item.icon}</MenuPanelItemIcon>
            {item.label}
          </MenuPanelItem>
        ))}
      </MenuPanel>
    </DotsButton>
  );
};

export default DotsMenu;
