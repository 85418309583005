import React, { FC, useContext, useRef, useState } from 'react';
import { Icon24, Icon48 } from '../../Icons/Icon';
import uploadingIllustration from '../../assets/illustrations/uploading.png';
import { CloudFile } from '../../Models';
import GoogleDrivePicker from '../GoogleDrivePicker';

import {
  UploadArea,
  Illustration,
  TextDivider,
  ZoomButton,
  ZoomButtonImage,
  ZoomButtonText,
  GoogleButton,
  Subtitle,
  FAQLink,
  UploadIcon,
  UploadButton,
  SecondaryLabel,
  CloudButtons,
} from './styles';

import { UploadFilesContext } from '../../Context/UploadFilesContext';
import { OptionTypeBase } from 'react-select';
import OneDrivePickerButton from '../OneDrive/OneDrivePickerButton';

interface DataUploaderProps {
  zoomEnabled?: boolean;
  googleDriveEnabled?: boolean;
  oneDriveEnabled?: boolean;
  hideFaq?: boolean;
  isGoogleDriveUploading?: boolean;
  skipCardsStep?: boolean;
  onZoomButtonClick?: () => void;
  onOneDriveButtonClick?: () => void;
  onFinish?: () => void;
  onCurrentFilesChange: (files: File[] | null) => void;
  onCurrentCloudFilesChange?: (files: CloudFile[]) => void;
}

const availableExtensions = ['mp4', 'm4a', 'mp3', 'srt', 'vtt', 'txt', 'docx', 'pdf'];

const DataUploader: FC<DataUploaderProps> = ({
  zoomEnabled,
  googleDriveEnabled,
  oneDriveEnabled,
  hideFaq,
  onCurrentFilesChange,
  onCurrentCloudFilesChange,
  onFinish,
  onZoomButtonClick,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [dragActive, setDragActive] = useState(false);
  const { uploadingFiles, addUploadingFile } = useContext(UploadFilesContext);

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length) {
      const files = Array.from(e.target.files);
      files.forEach((file) =>
        addUploadingFile({
          file,
          isUploading: false,
          uploadingProgress: 0,
        })
      );

      onCurrentFilesChange(files);
      onFinish && onFinish();
    }
  };

  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (e.dataTransfer.files && e.dataTransfer.files.length) {
      const files = Array.from(e.dataTransfer.files).filter((file) =>
        availableExtensions.some((format) => file.name.toLowerCase().endsWith(format.toLowerCase()))
      );
      files.forEach((file) => {
        if (
          !availableExtensions.some((format) =>
            file.name.toLowerCase().endsWith(format.toLowerCase())
          )
        ) {
          return;
        }

        addUploadingFile({
          file,
          isUploading: false,
          uploadingProgress: 0,
        });
      });

      onCurrentFilesChange(files);
      onFinish && onFinish();
    }
  };

  const handleBrowseFilesClick = () => {
    inputRef.current?.click();
  };

  return (
    <>
      <UploadArea
        onDragOver={handleDrag}
        onDragLeave={handleDrag}
        onDrop={handleDrop}
        dragActive={dragActive}
      >
        <Illustration src={uploadingIllustration} />

        <UploadButton onClick={handleBrowseFilesClick}>Choose File(s)</UploadButton>

        <>
          {(zoomEnabled || googleDriveEnabled) && <TextDivider>or</TextDivider>}
          <CloudButtons>
            {zoomEnabled && (
              <ZoomButton onClick={onZoomButtonClick}>
                <ZoomButtonImage>
                  <Icon24.ZoomIcon />
                </ZoomButtonImage>
                <ZoomButtonText>Import from Zoom</ZoomButtonText>
              </ZoomButton>
            )}

            {googleDriveEnabled && (
              <GoogleDrivePicker
                onFileSelected={(files: CloudFile[]) => {
                  onCurrentCloudFilesChange && onCurrentCloudFilesChange(files);

                  onFinish && onFinish();
                }}
              >
                <GoogleButton>
                  <ZoomButtonImage>
                    <Icon24.GoogleDriveIcon />
                  </ZoomButtonImage>
                  <ZoomButtonText>Import from Google</ZoomButtonText>
                </GoogleButton>
              </GoogleDrivePicker>
            )}

            {oneDriveEnabled && (
              <OneDrivePickerButton
                onFileSelected={(files) => {
                  onCurrentCloudFilesChange && files && onCurrentCloudFilesChange(files);
                }}
              />
            )}
          </CloudButtons>
        </>

        <Subtitle>
          Video (.mp4, .mov, .m4a ) Audio (.mp3) Docs (.txt, .docx, .pdf{' '}
          <SecondaryLabel>Beta</SecondaryLabel>) Transcript (.srt, .vtt)
        </Subtitle>
        {!hideFaq && (
          <Subtitle>
            <FAQLink
              href="https://help.notably.ai/en/articles/6909600-which-languages-are-supported-in-notably"
              target="_blank"
            >
              Notably transcribes in 20+ languages. Learn more.
            </FAQLink>
          </Subtitle>
        )}
      </UploadArea>
      <input
        id={'video_upload'}
        name={'video_upload'}
        accept={
          'video/mp4, video/quicktime, audio/mp3, audio/mp4, audio/x-m4a, .srt, .vtt, .txt, .docx, .pdf'
        }
        type={'file'}
        onChange={handleFileInputChange}
        className={'hidden'}
        ref={inputRef}
        multiple
      />
      <UploadIcon show={dragActive}>
        <Icon48.Upload />
      </UploadIcon>
    </>
  );
};

export default DataUploader;
