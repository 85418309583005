import React, { FC, useState } from 'react';

import { Container, Option } from './styles';

export type TabOption = {
  value: string;
  label: string;
};

interface TabsProps {
  currentOption: TabOption;
  options: TabOption[];
  onChange(option: TabOption): void;
}

const Tabs: FC<TabsProps> = ({ currentOption, options, onChange }) => {
  return (
    <Container>
      {options.map((option) => (
        <Option
          key={option.value}
          active={option.value === currentOption.value}
          onClick={() => onChange(option)}
        >
          {option.label}
        </Option>
      ))}
    </Container>
  );
};

export default Tabs;
