import { useApolloClient, useMutation } from '@apollo/client';
import { GENERATE_HIGHLIGHTS, CLUSTERIZE_NOTES } from '../GraphQL/mutations';
import { TASK_STATUS } from '../GraphQL/queries';
import useAnalytics from './useAnalytics';

type baseNote = { id: string; text: string; themeId: string; tags: string[]; participant: string };
type baseTheme = { id: string; name: string };

type useOpenAIApi = {
  generateHighlights: (options: {
    content: string;
    userMessage: string;
    dashboardId: string;
    enableTags: boolean;
  }) => any;
  clusterizeNotes: (
    notes: baseNote[],
    themes: baseTheme[],
    applyIds: string[],
    userMessage?: string
  ) => any;
  checkTaskStatus: (taskId: string) => any;
};

export const useOpenAIApi = (): useOpenAIApi => {
  const client = useApolloClient();
  const { analytics } = useAnalytics();
  const [generateHighlightsMutation] = useMutation(GENERATE_HIGHLIGHTS);
  const [clusterizeNotesMutation] = useMutation(CLUSTERIZE_NOTES);

  const clusterizeNotes = async (
    notes: baseNote[],
    themes: baseTheme[],
    applyIds: string[],
    userMessage?: string
  ) => {
    const res = await clusterizeNotesMutation({
      variables: {
        notes,
        themes,
        applyIds,
        userMessage,
      },
    });
    analytics.sendEvent('Hooks_ClusterizedNotes', {});
    return res?.data?.clusterizeNotes;
  };

  const generateHighlights = async ({
    content,
    userMessage,
    dashboardId,
    enableTags,
  }: {
    content: string;
    userMessage: string;
    dashboardId: string;
    enableTags: boolean;
  }) => {
    const res = await generateHighlightsMutation({
      variables: {
        content,
        userMessage,
        dashboardId,
        enableTags,
      },
    });
    analytics.sendEvent('Hooks_GeneratedHighlights', {});
    return res?.data?.highlightText;
  };

  const checkTaskStatus = async (taskId: string): Promise<any> => {
    const { data } = await client.query({
      query: TASK_STATUS,
      variables: { id: taskId },
      fetchPolicy: 'network-only',
    });
    return data?.asyncTask;
  };

  return {
    clusterizeNotes,
    generateHighlights,
    checkTaskStatus,
  };
};
