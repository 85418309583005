import GreenLeaf from '../assets/projects/default-icons/Idea-Seed.svg';
import RedHerring from '../assets/projects/default-icons/Red-Herring.svg';
import SyntesisSpark from '../assets/projects/default-icons/Synthesis-Spark.svg';
import TalkingIcon from '../assets/projects/default-icons/User-Testing.svg';
import RedThread from '../assets/projects/default-icons/Red-Thread.svg';
import Observe from '../assets/projects/default-icons/Observation.svg';
import BlueSky from '../assets/projects/default-icons/Discovery.svg';

import SacredCow from '../assets/projects/default-icons/Sacred-Cow.svg';
import Square from '../assets/projects/default-icons/2x2.svg';
import AI from '../assets/projects/default-icons/AI.svg';
import Discussion from '../assets/projects/default-icons/Discussion.svg';
import Eye from '../assets/projects/default-icons/Eye.svg';
import Idea from '../assets/projects/default-icons/Idea.svg';
import Journey from '../assets/projects/default-icons/Journey.svg';
import Money from '../assets/projects/default-icons/Money.svg';
import ResearchDocs from '../assets/projects/default-icons/Research-Docs.svg';
import Space from '../assets/projects/default-icons/Space.svg';
import Sparkles from '../assets/projects/default-icons/Sparkles.svg';
import Sticky from '../assets/projects/default-icons/Sticky.svg';
import Voting from '../assets/projects/default-icons/Voting.svg';

export const PROMPT_ICONS = new Map<string, string>([
  ['GreenLeaf', GreenLeaf],
  ['RedHerring', RedHerring],
  ['SyntesisSpark', SyntesisSpark],
  ['TalkingIcon', TalkingIcon],
  ['RedThread', RedThread],
  ['Observe', Observe],
  ['BlueSky', BlueSky],

  ['SacredCow', SacredCow],
  ['Square', Square],
  ['AI', AI],
  ['Discussion', Discussion],
  ['Eye', Eye],
  ['Idea', Idea],
  ['Journey', Journey],
  ['Money', Money],
  ['ResearchDocs', ResearchDocs],
  ['Space', Space],
  ['Sparkles', Sparkles],
  ['Sticky', Sticky],
  ['Voting', Voting],
]);

export type PropmptTemplate = {
  id: string;
  title: string;
  description: string;
  icon: string;
  category: string;
  isSmart?: boolean;
};

export const CATEGORIES = [
  {
    id: 'design_research',
    title: 'Design & Research',
  },
  {
    id: 'customer_experience',
    title: 'Customer Experience',
  },
  {
    id: 'business',
    title: 'Business',
  },
  {
    id: 'marketing',
    title: 'Marketing',
  },
  {
    id: 'sales',
    title: 'Sales',
  },
  {
    id: 'education',
    title: 'Education',
  },
  {
    id: 'life_sciences',
    title: 'Life Sciences',
  },
];

export const insightTemplates: PropmptTemplate[] = [
  {
    id: 'discovery-insight',
    title: 'Discovery Insight',
    description:
      'Use the standard insight framework “what you saw and heard, what it means, and why it matters.”',
    icon: GreenLeaf,
    category: 'design_research',
  },
  {
    id: 'persona',
    title: 'Persona',
    description:
      'Brainstorm personas by extracting “what they’re thinking, doing, feeling, and saying.”',
    icon: TalkingIcon,
    category: 'design_research',
  },
  {
    id: 'swot',
    title: 'SWOT',
    description:
      'Generate a SWOT analysis with “strengths, weaknesses, opportunities, and threats.”',
    icon: RedHerring,
    category: 'design_research',
  },
  {
    id: 'extremes_mainstreams',
    title: 'Extremes and Mainstreams',
    description:
      'An IDEO inspired framework to ideate on extremes, in order to solve for mainstreams.',
    icon: RedThread,
    category: 'design_research',
  },
  {
    id: '6_hats',
    title: '6 Thinking Hats',
    description: 'An innovation template to generate new ideas, inspired by author Edward De Bono.',
    icon: BlueSky,
    category: 'design_research',
  },
  {
    id: 'impact_ladder',
    title: 'Impact Ladder',
    description:
      'An IDEO inspired framework to discover short-term & near-term innovation opportunities.',
    icon: RedHerring,
    category: 'design_research',
  },
  {
    id: '4_whats',
    title: '4 What’s',
    description: 'What Is? What if? What Wows? What Works? An idea generating design framework.',
    icon: GreenLeaf,
    category: 'design_research',
  },
  {
    id: 'zooming_inout',
    title: 'Zoom in, out, and across',
    description: 'Zooming in and out on a problem area in order to see it differently.',
    icon: RedThread,
    category: 'design_research',
  },
  {
    id: 'insight_combination',
    title: 'Insight combination',
    description:
      'Combining insights from your data with market trends, technology advancements, and design patterns to generate new ideas.',
    icon: BlueSky,
    category: 'design_research',
  },
];

export const summaryTemplates: PropmptTemplate[] = [
  {
    id: 'discovery-interview',
    title: 'Discovery',
    description:
      'Debrief a discovery interview, identifying challenges, goals, surprising takeaways, emerging patterns, and key moments.',
    icon: GreenLeaf,
    category: 'design_research',
  },
  {
    id: 'usability-test',
    title: 'Usability test',
    description:
      'Summarize user pain points, areas of success, and recommendations to improve an experience.',
    icon: TalkingIcon,
    category: 'design_research',
  },
  {
    id: 'focus-group',
    title: 'Focus group',
    description:
      'Identify themes from a group conversation, including areas of agreement and disagreement, implications and recommendations.',
    icon: SyntesisSpark,
    category: 'design_research',
  },
  {
    id: 'es_of_customer_journey',
    title: 'The 5 E’s of Customer Journey',
    description: 'Key moments in an experience, including the highs, lows, and greatest gift.',
    icon: Observe,
    category: 'design_research',
  },
  {
    id: 'stakeholder',
    title: 'Stakeholder Interview',
    description: 'Key takeaways from a stakeholder interview.',
    icon: TalkingIcon,
    category: 'business',
  },
  {
    id: 'intercept',
    title: 'Intercept',
    description: 'Intercept surveys or interviews with a targeted audience.',
    icon: Observe,
    category: 'design_research',
  },
  {
    id: 'customer_support_session',
    title: 'Customer Support Session ',
    description: 'Find patterns & opportunities from customer support experiences.',
    icon: TalkingIcon,
    category: 'customer_experience',
  },
  {
    id: 'account_management_review',
    title: 'Account Management Review',
    description: 'A formula to gain insights from key customer conversations.',
    icon: TalkingIcon,
    category: 'customer_experience',
  },
  {
    id: 'product_demo_recap',
    title: 'Product Demo Recap',
    description: 'A framework to recap demos & track feedback for product insights.',
    icon: RedHerring,
    category: 'sales',
  },
  {
    id: 'parent_teacher',
    title: 'Parent <> Teacher Conference',
    description: 'Track educational goals & inspire learning beyond the classroom.',
    icon: RedHerring,
    category: 'education',
  },
  {
    id: 'lecture_review',
    title: 'Lecture Review',
    description: 'Understand student engagement and inspire future lesson planning.',
    icon: RedHerring,
    category: 'education',
  },
  {
    id: 'secondary_research',
    title: 'Secondary Research',
    description:
      'Summarize desk research, evaluate it, and look for gaps in knowledge to fill with more secondary or primary research.',
    icon: RedThread,
    category: 'education',
  },
];
