import React from 'react';
import { Menu } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { DropDownMenu } from './DropDownMenu';
import { useFetchBoard } from '../Hooks/useBoards';
import { exportToCsv } from '../Utils/exportToCSV';
import { useApolloClient } from '@apollo/client';
import { FETCH_NOTES } from '../GraphQL/queries';
import { isNil } from 'lodash-es';
import QuestionMarkIcon from '../Icons/QuestionMarkIcon';
import { Tag } from '../Models';
import usePermissions from '../Hooks/usePermissions';
import { Icon24 } from '../Icons/Icon';
import styled from 'styled-components';
import { useCSVImport, useMiroImport } from '../Hooks/useDataImport';

type Props = {
  dashboardId: string;
  getCanvas: () => string | undefined;
};

const getSentimentLabel = (sentimentScore?: number): string | null | undefined => {
  if (isNil(sentimentScore)) return sentimentScore;
  if (sentimentScore > 0.5) return 'positive';
  if (sentimentScore < -0.5) return 'negative';
  return 'neutral';
};

export default function ExportMenu({ dashboardId, getCanvas }: Props): JSX.Element {
  const [loadingDashboard, dashboard] = useFetchBoard(dashboardId);

  const { canCreateNotes } = usePermissions();
  const client = useApolloClient();

  const [handleImportFromMiro] = useMiroImport(dashboardId);

  const [handleImportFromCsv] = useCSVImport(dashboardId);

  const handleExportToCsv = async () => {
    const {
      data: { notes },
    } = await client.query({
      query: FETCH_NOTES,
      variables: {
        condition: {
          dashboardId,
        },
      },
    });

    exportToCsv(`${dashboard.name}.csv`, [
      ['content', 'theme', 'tags', 'participant', 'sentiment'],
      ...notes.map((note: any) => [
        note.text,
        note.theme?.name || '',
        (note.tagsList?.map((tag: Tag) => tag.name) || []).join(', '),
        note.participant?.name || '',
        getSentimentLabel(note.sentimentScore),
      ]),
    ]);
  };

  const handleExportToPng = async () => {
    // getCanvas
    const base64image = getCanvas();
    if (!base64image) return;
    const link = document.createElement('a');
    link.download = `${dashboard.name}.png`;
    link.href = base64image;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <DropDownMenu
      alignLeft={true}
      button={
        <Menu.Button className="flex flex-row items-center flex-shrink font-medium m-3 text-sm">
          File
          <ChevronDownIcon className="ml-2 h-3 w-3" />
        </Menu.Button>
      }
    >
      <div className="px-4 pt-4 pb-2 select-none text-gray-700 text-opacity-40 text-sm font-medium font-['Inter'] leading-[14px] tracking-tight">
        Import
      </div>
      <Menu.Item disabled={!canCreateNotes}>
        <div
          onClick={handleImportFromCsv}
          className={`group flex items-center px-4 py-2 text-sm hover:bg-secondary-hover font-medium cursor-pointer ${
            !canCreateNotes && 'text-primary-purple-light-60'
          }`}
        >
          <div className="flex justify-start items-center">
            <div className="mr-2">
              <Icon24.Spreadsheet />
            </div>
            <div className="text-violet-950 text-base font-medium tracking-tight mr-2">
              Spreadsheet{' '}
            </div>
            <div className="text-gray-700 text-opacity-40 text-base font-medium tracking-tight">
              .csv
            </div>
          </div>
        </div>
      </Menu.Item>
      <Menu.Item disabled={!canCreateNotes}>
        <div
          onClick={handleImportFromMiro}
          className={`group flex items-center px-4 py-2 text-sm hover:bg-secondary-hover font-medium cursor-pointer ${
            !canCreateNotes && 'text-primary-purple-light-60'
          }`}
        >
          <div className="flex justify-start items-center">
            <div className="mr-2">
              <Icon24.Miro />
            </div>
            <div className="text-violet-950 text-base font-medium tracking-tight mr-2">
              Miro Board
            </div>
            <SecondaryLabel>Beta</SecondaryLabel>
          </div>
        </div>
      </Menu.Item>
      {/* <div className="w-[242px] h-[98px] pt-2.5 border-t border-zinc-300 flex-col justify-start items-start gap-2.5 inline-flex"></div> */}
      <div className="px-4 pt-4 pb-2 select-none border-t border-zinc-300 text-gray-700 text-opacity-40 text-sm font-medium font-['Inter'] leading-[14px] tracking-tight">
        Export
      </div>
      <Menu.Item>
        <div
          onClick={handleExportToCsv}
          className="group flex items-center px-4 py-2 text-sm hover:bg-secondary-hover font-medium cursor-pointer"
        >
          <div className="flex justify-start items-center">
            <div className="mr-2">
              <Icon24.Spreadsheet />
            </div>
            <div className="text-violet-950 text-base font-medium tracking-tight mr-2">
              Spreadsheet{' '}
            </div>
            <div className="text-gray-700 text-opacity-40 text-base font-medium tracking-tight">
              .csv
            </div>
          </div>
        </div>
      </Menu.Item>
      <Menu.Item>
        <div
          onClick={handleExportToPng}
          className="group flex items-center px-4 py-2 text-sm hover:bg-secondary-hover font-medium cursor-pointer"
        >
          <div className="flex justify-start items-center">
            <div className="mr-2">
              <Icon24.Canvas />
            </div>
            <div className="text-violet-950 text-base font-medium tracking-tight mr-2">Canvas </div>
            <div className="text-gray-700 text-opacity-40 text-base font-medium tracking-tight">
              .png
            </div>
          </div>
        </div>
      </Menu.Item>
      <hr />
      <Menu.Item>
        <a
          rel="noreferrer"
          href="https://help.notably.ai/en/collections/3509008-importing-exporting"
          target="_blank"
          className="group flex items-center px-4 py-2 text-sm text-primary-purple-light-40"
        >
          <QuestionMarkIcon />
          <div className="ml-1">Help with import &amp; export</div>
        </a>
      </Menu.Item>
    </DropDownMenu>
  );
}

const SecondaryLabel = styled.span`
  margin-left: 2px;
  margin-right: 2px;
  padding: 2px 4px;
  gap: 5px;
  border-radius: 3px;
  background: rgba(56, 33, 82, 0.2);
  color: #382152;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
`;
