import React, { FC, useEffect } from 'react';

import {
  Container,
  SelectedContainer,
  Title,
  Icon,
  Menu,
  MenuItem,
  Placeholder,
  MenuItemLabel,
  OptionIcon,
  TickIconWrapper,
  GroupTitle,
  Divider,
  MenuItemContent,
  MenuItemSubtitle,
} from './styles';
import { Icon16 } from '../../Icons/Icon';
import Portal from '../PortalNew/PortalNew';
import { Checkbox, CheckboxGroup } from '@chakra-ui/react';

export type DropdownOption = {
  label: string;
  value: string;
  icon?: React.ReactNode;
  subtitle?: string;
};

interface DroDownProps {
  options: DropdownOption[];
  currentOptions?: DropdownOption[];
  currentOption?: DropdownOption | null;
  placeholder?: string;
  multiselect?: boolean;
  size?: 'small' | 'big';
  onChangeMultiple?: (options: DropdownOption[]) => void;
  onChange?: (option: DropdownOption) => void;
  menuWidth?: number;
  // use `true` if the dropdown is used in a modal where higher z-index is needed
  overlay?: boolean;
  maxHeight?: number;
  align?: 'bottom' | 'top';
  dontCutTitle?: boolean;
  disabled?: boolean;
  title?: string;
  dividerAfterIndex?: number;
}

const Dropdown: FC<DroDownProps> = ({
  options,
  currentOption,
  placeholder,
  multiselect,
  currentOptions,
  onChange,
  onChangeMultiple,
  menuWidth,
  maxHeight,
  size = 'small',
  overlay = true,
  align = 'bottom',
  dontCutTitle,
  disabled,
  title,
  dividerAfterIndex,
}) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const menuRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (
        containerRef.current &&
        menuRef.current &&
        !containerRef.current.contains(e.target as Node) &&
        !menuRef.current.contains(e.target as Node)
      ) {
        e.stopPropagation();
        setIsOpen(false);
      }
    };
    window.addEventListener('mousedown', handleClick);

    return () => {
      window.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const handleChoose = (option: DropdownOption) => {
    onChange && onChange(option);
    setIsOpen(false);
  };

  const toggleOption = (value: string): DropdownOption[] => {
    const currentOptionsArr = currentOptions || [];
    if (currentOptionsArr?.find((option) => option.value === value)) {
      return currentOptionsArr?.filter((option) => option.value !== value);
    }
    const optionToAdd = options.find((option) => option.value === value);
    return optionToAdd ? [...currentOptionsArr, optionToAdd] : currentOptionsArr;
  };

  const getTitle = () => {
    let title = multiselect
      ? currentOptions?.map((option) => option.label).join(', ') || ''
      : currentOption?.label || '';

    if (title && multiselect && currentOptions?.length === options.length) {
      title = 'Everything';
    }

    return title ? (
      <Title size={size} dontCutTitle={!!dontCutTitle}>
        {title}
      </Title>
    ) : (
      <Placeholder size={size}>{placeholder || 'Choose option'}</Placeholder>
    );
  };

  const renderMenuItems = () => {
    const firstPart = options.slice(0, dividerAfterIndex ? dividerAfterIndex : options.length);
    const secondPart = options.slice(dividerAfterIndex || options.length, options.length);

    return (
      <>
        {firstPart.map((option) => (
          <MenuItem key={option.value} onClick={() => handleChoose(option)}>
            <MenuItemContent>
              <MenuItemLabel>
                {option.icon && <OptionIcon size={size}>{option.icon}</OptionIcon>}
                {option.label}
              </MenuItemLabel>
              {option.subtitle && <MenuItemSubtitle>{option.subtitle}</MenuItemSubtitle>}
            </MenuItemContent>
            {currentOption?.value === option.value && !option.icon && (
              <TickIconWrapper>
                <Icon16.Tick />
              </TickIconWrapper>
            )}
          </MenuItem>
        ))}

        {dividerAfterIndex && (
          <>
            <Divider />

            {secondPart.map((option) => (
              <MenuItem key={option.value} onClick={() => handleChoose(option)}>
                <MenuItemContent>
                  <MenuItemLabel>
                    {option.icon && <OptionIcon size={size}>{option.icon}</OptionIcon>}
                    {option.label}
                  </MenuItemLabel>
                  {option.subtitle && <MenuItemSubtitle>{option.subtitle}</MenuItemSubtitle>}
                </MenuItemContent>
                {currentOption?.value === option.value && !option.icon && (
                  <TickIconWrapper>
                    <Icon16.Tick />
                  </TickIconWrapper>
                )}
              </MenuItem>
            ))}
          </>
        )}
      </>
    );
  };

  const top = containerRef.current?.getBoundingClientRect().bottom || 0;
  const bottom = window.innerHeight - (containerRef.current?.getBoundingClientRect().top || 0);
  const right = window.innerWidth - (containerRef.current?.getBoundingClientRect()?.right || 0);

  return (
    <Container ref={containerRef}>
      <SelectedContainer onClick={() => !disabled && setIsOpen(!isOpen)}>
        {currentOption?.icon && <OptionIcon size={size}>{currentOption?.icon}</OptionIcon>}
        {getTitle()}
        <Icon isOpen={isOpen}>
          <Icon16.Arrow />
        </Icon>
      </SelectedContainer>

      <Portal wrapperId={Math.floor(Math.random() * Date.now()).toString(16)}>
        <Menu
          isOpen={isOpen}
          right={right}
          bottom={bottom - window.scrollY}
          top={top + window.scrollY}
          width={menuWidth || containerRef.current?.getBoundingClientRect().width || 0}
          ref={menuRef}
          zIndex={overlay ? 999 : 10}
          maxHeight={maxHeight}
          align={align}
        >
          {title && <GroupTitle>{title}</GroupTitle>}
          {!multiselect ? (
            renderMenuItems()
          ) : (
            <CheckboxGroup
              colorScheme="purple"
              value={currentOptions?.map((option) => option.value)}
              onChange={(value: string[]) =>
                onChangeMultiple &&
                onChangeMultiple(options.filter((option) => value.includes(option.value)))
              }
            >
              {options.map((option) => (
                <MenuItem
                  key={option.value}
                  onClick={() => onChangeMultiple && onChangeMultiple(toggleOption(option.value))}
                >
                  <MenuItemLabel>{option.label}</MenuItemLabel>
                  <Checkbox key={option.value} value={option.value} />
                </MenuItem>
              ))}
            </CheckboxGroup>
          )}
        </Menu>
      </Portal>
    </Container>
  );
};

export default Dropdown;
