import styled from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';
import { CalendarIcon, TagIcon, UserIcon } from '@heroicons/react/outline';
import { breakpoint } from '../../Shared';

export const EditorContainer = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: calc(100vh - 108px);
  overflow: hidden;
`;

export const EditorLeftPanel = styled.div`
  flex: 1;
  margin-left: 48px;
  padding-right: 48px;
  overflow-y: auto;
  position: relative;

  ${breakpoint.Tablet`
    margin-left: 0;
    padding-right: 0;
    width: 100%;
  `}
`;

export const EditorRightPanel = styled.div`
  ${breakpoint.Tablet`
    display: none;
  `}
`;

export const NameInput = styled(TextareaAutosize)`
  outline: none;
  transition: 0.3s ease 0s;
`;

export const Details = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 0 12px;
`;

export const DetailsRow = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  align-items: center;
`;

export const LabelWithIcon = styled.div`
  display: flex;
  width: 144px;
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
  color: ${(props) => props.theme.colors.primary.purple};
`;

export const UserIconI = styled(UserIcon)`
  cursor: pointer;
  height: 24px;
  margin: 4px 8px 4px 0;
`;
export const CalendarIconI = styled(CalendarIcon)`
  height: 24px;
  margin: 4px 8px 4px 0;
`;

export const TagIconI = styled(TagIcon)`
  height: 24px;
  margin: 4px 8px 4px 0;
`;

export const UserName = styled.span`
  margin-left: 8px;
`;

export const TranscriptionStatus = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
  margin: 16px auto 0;
`;

export const TranscribingTitle = styled.div`
  display: flex;
  align-items: center;
`;

export const TranscribingSubtitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: rgba(59, 38, 81, 0.4);
  margin-top: 10px;
`;

export const TranscriptionStatusText = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  background: linear-gradient(90deg, rgba(142, 132, 238, 0.8) 0%, rgba(53, 26, 138, 0.8) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-left: 10px;
`;

export const LegacyParticipantContainer = styled.div`
  margin: 10px 0;
  padding: 10px;
  border-radius: 12px;
  gap: 10px;
  background: #3821521a;
  color: #3b2651;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
`;

export const ParticipantNameBlock = styled.div`
  display: flex;
  margin: 10px 0;
`;

export const SpeakerNamesBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;

export const SpeakerWrapper = styled.div`
  display: flex;
  cursor: pointer;
  margin: 5px;
`;

export const SpeakerName = styled.div`
  margin-left: 5px;
  margin-right: 10px;
`;

export const SpeakerDropdownWrapper = styled.div<{ left: number; top: number }>`
  position: absolute;
  left: ${({ left }) => left}px;
  top: ${({ top }) => top}px;
  display: flex;
  flex-direction: column;
  z-index: 100;
`;

export const PostyButtonWrapper = styled.div`
  margin-right: 10px;
`;

export const ToolbarContainer = styled.div`
  display: flex;
  padding: 12px 16px;
  justify-content: space-between;
  width: 100%;

  ${breakpoint.Tablet`
    flex-direction: column;
  `}
`;

export const ToolbarButtons = styled.div`
  display: flex;

  ${breakpoint.Tablet`
    margin-top: 10px;
  `}
`;
