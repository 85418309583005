import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SpeakersContext, TSpeakerBlock } from '../../Context/SpeakersContext';
import { useCreateParticipant, useFetchParticipantsLazy } from '../../Hooks/useParticipants';
import {
  useDeleteParticipantLink,
  useTranscriptLinkedParticipants,
} from '../../Hooks/useTranscripts';
import { Icon16 } from '../../Icons/Icon';
import Input from '../Input/Input';
import LetteredAvatar from '../LetteredAvatar';
import Avatar from '../Avatar';

import {
  Container,
  CreateParticipantButton,
  CreateParticipantText,
  Divider,
  DropdownTitle,
  NestedDropdown,
  NestedDropdownContainer,
  NestedDropdownIcon,
  NestedDropdownItem,
  NestedDropdownList,
  NestedDropdownTitle,
  PaddingWrapper,
  SearchInputWrapper,
  SpeakerName,
  SpinnerWrapper,
  UnlinkIconWrapper,
  UnlinkParticipantButton,
  UnlinkParticipantText,
} from './styles';
import Spinner from '../Spinner';

interface ParticipantLinkingDropdownProps {
  isOpen: boolean;
  onClose(): void;
  onLinkParticipant(participant: any, speakerBlock: TSpeakerBlock): void;
  onUnlinkParticipant(): void;
}
interface SpeakerLinkerProps {
  speakerBlock: any;
  participants: any[];
  handleLinkParticipant(participant: any, speakerBlock: TSpeakerBlock): void;
  handleCreateParticipant(name: string, speakerBlock: TSpeakerBlock): void;
}

const SpeakerLinker: React.FC<SpeakerLinkerProps> = ({
  speakerBlock,
  participants,
  handleLinkParticipant,
  handleCreateParticipant,
}) => {
  const [searchText, setSearchText] = useState('');
  const [isAddingLink, setIsAddingLink] = useState(false);

  const speakerName = speakerBlock.speakerName;

  const currentParticipants = useMemo(() => {
    const name = searchText || speakerBlock.speakerName;
    return participants.filter(
      (participant) =>
        participant.name && participant.name.toLowerCase().includes(name.toLowerCase())
    );
  }, [searchText, participants, speakerName]);
  return (
    <>
      <PaddingWrapper>
        <DropdownTitle>Link participant</DropdownTitle>
      </PaddingWrapper>

      <SearchInputWrapper>
        <Input
          smallSize
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          placeholder="Find or create new"
        />
      </SearchInputWrapper>

      <NestedDropdownContainer>
        {!searchText?.length && currentParticipants.length > 0 && (
          <PaddingWrapper>
            <DropdownTitle>Suggested</DropdownTitle>
          </PaddingWrapper>
        )}
        {currentParticipants.map((participant) => (
          <NestedDropdownItem
            key={participant.id}
            onClick={() => handleLinkParticipant(participant, speakerBlock)}
          >
            <Avatar user={participant} />
            <SpeakerName>{participant.name}</SpeakerName>
          </NestedDropdownItem>
        ))}

        <Divider />
        <PaddingWrapper>
          <CreateParticipantButton
            onClick={async () => {
              setIsAddingLink(true);
              await handleCreateParticipant(searchText || speakerBlock.speakerName, speakerBlock);
              setIsAddingLink(false);
            }}
          >
            {isAddingLink && (
              <SpinnerWrapper>
                <Spinner size="small" />
              </SpinnerWrapper>
            )}
            <CreateParticipantText>{isAddingLink ? 'Creating' : 'Create'}</CreateParticipantText>
            <Avatar />
            <CreateParticipantText>{searchText || speakerBlock.speakerName}</CreateParticipantText>
          </CreateParticipantButton>
        </PaddingWrapper>
      </NestedDropdownContainer>
    </>
  );
};

const ParticipantLinkingDropdown: React.FC<ParticipantLinkingDropdownProps> = ({
  isOpen,
  onLinkParticipant,
  onUnlinkParticipant,
  onClose,
}) => {
  const [fetch, { loading, called }, participants] = useFetchParticipantsLazy();
  const { speakerBlocks } = useContext(SpeakersContext);
  const { transcriptId } = useParams<{ dashboardId: string; transcriptId: string }>();
  const [, , , getLinkedParticipant, getRowIdByParticipantId] = useTranscriptLinkedParticipants(
    transcriptId
  );
  const containerRef = useRef<HTMLDivElement>(null);
  const [deleteParticipantLink] = useDeleteParticipantLink();
  const [isDeletingLink, setIsDeletingLink] = useState(false);

  const [createParticipant] = useCreateParticipant();

  useEffect(() => {
    if (isOpen && !called) fetch();
  }, [isOpen, called]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
  }, [isOpen]);

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (containerRef.current && containerRef.current.contains(e.target as Node)) return;
      onClose();
    };
    window.addEventListener('mousedown', handleClick);

    return () => {
      window.removeEventListener('mousedown', handleClick);
    };
  }, [containerRef]);

  const handleLinkParticipant = async (participant: any, speakerBlock: TSpeakerBlock) => {
    onLinkParticipant(participant, speakerBlock);
    // onClose();
  };

  const handleCreateParticipant = async (name: string, speakerBlock: TSpeakerBlock) => {
    const newParticipant = await createParticipant({ name });
    handleLinkParticipant(newParticipant, speakerBlock);
    fetch();
  };

  const handleUnlinkParticipant = async (speakerId: any) => {
    if (isDeletingLink) return;
    const currentParticipant = getLinkedParticipant(speakerId);
    if (!currentParticipant) return;
    const rowId = getRowIdByParticipantId(currentParticipant.id);
    setIsDeletingLink(true);
    await deleteParticipantLink(rowId);
    setIsDeletingLink(false);
    onUnlinkParticipant();
  };

  return (
    <Container isOpen={isOpen} ref={containerRef}>
      <>
        <PaddingWrapper>
          <DropdownTitle>Speakers</DropdownTitle>
        </PaddingWrapper>

        {speakerBlocks.map((speakerBlock) => {
          const linkedParticipant = getLinkedParticipant(speakerBlock.id);
          return (
            <div key={speakerBlock.id}>
              <NestedDropdown>
                <NestedDropdownTitle>
                  {/* {speakerBlock.speakerName} */}
                  <div className="flex">
                    {linkedParticipant ? (
                      <Avatar user={linkedParticipant} />
                    ) : (
                      <LetteredAvatar name={speakerBlock.speakerName} />
                    )}
                    <SpeakerName>{speakerBlock.speakerName}</SpeakerName>
                  </div>
                </NestedDropdownTitle>
                <NestedDropdownIcon>
                  <Icon16.Arrow />
                </NestedDropdownIcon>
                <NestedDropdownList>
                  {linkedParticipant && (
                    <>
                      <PaddingWrapper>
                        <DropdownTitle>Linked participant</DropdownTitle>
                        <UnlinkParticipantButton>
                          {isDeletingLink && (
                            <SpinnerWrapper>
                              <Spinner size="small" />
                            </SpinnerWrapper>
                          )}
                          <Avatar user={linkedParticipant} />
                          <SpeakerName>{linkedParticipant.name}</SpeakerName>
                          <UnlinkIconWrapper
                            onClick={() => handleUnlinkParticipant(speakerBlock.id)}
                          >
                            <Icon16.CloseSmall />
                          </UnlinkIconWrapper>
                        </UnlinkParticipantButton>
                      </PaddingWrapper>
                      <Divider />
                      <PaddingWrapper>
                        <CreateParticipantButton
                          onClick={() => handleUnlinkParticipant(speakerBlock.id)}
                        >
                          <UnlinkParticipantText>
                            Unlink {speakerBlock.speakerName}
                          </UnlinkParticipantText>
                        </CreateParticipantButton>
                      </PaddingWrapper>
                    </>
                  )}

                  {!linkedParticipant && (
                    <SpeakerLinker
                      speakerBlock={speakerBlock}
                      participants={participants}
                      handleLinkParticipant={handleLinkParticipant}
                      handleCreateParticipant={handleCreateParticipant}
                    />
                  )}
                </NestedDropdownList>
              </NestedDropdown>
            </div>
          );
        })}
      </>
    </Container>
  );
};

export default ParticipantLinkingDropdown;
