/* eslint-disable @typescript-eslint/no-empty-function */
import React, { FC, useState } from 'react';

interface SpeakersContextProps {
  speakerNames: string[];
  speakerBlocks: TSpeakerBlock[];
  setSpeakerNames(speakerNames: string[]): void;
  setSpeakerBlocks(speakerBlocks: TSpeakerBlock[]): void;
}

export const SpeakersContext = React.createContext<SpeakersContextProps>({
  speakerNames: [],
  speakerBlocks: [],
  setSpeakerNames: () => {},
  setSpeakerBlocks: () => {},
});

interface SpeakersContextProviderProps {
  children: React.ReactNode;
}

export type TSpeakerBlock = {
  id?: string;
  speakerName: string;
};

export type TSpeakerBlockUpdate = {
  id: string;
  participantId: string;
};

export const SpeakersContextProvider: FC<SpeakersContextProviderProps> = ({ children }) => {
  const [speakerNames, setSpeakerNames] = useState<Set<string>>(new Set());
  const [speakerBlocks, setSpeakerBlocks] = useState<TSpeakerBlock[]>([]);

  const addSpeakerNames = (speakerNames: string[]) => {
    setSpeakerNames((currentSpeakerNames) => new Set([...speakerNames]));
  };

  const addSpeakerBlocks = (newBlocks: TSpeakerBlock[]) => {
    const blocksToAdd: TSpeakerBlock[] = [];
    newBlocks.forEach((block) => {
      if (!blocksToAdd.find((item) => item.speakerName === block.speakerName)) {
        blocksToAdd.push({
          id: block.id || Math.floor(Math.random() * Date.now()).toString(16),
          speakerName: block.speakerName,
        });
      }
    });
    setSpeakerBlocks((currentSpeakerBlocks) => blocksToAdd);
  };

  return (
    <SpeakersContext.Provider
      value={{
        speakerNames: [...speakerNames],
        speakerBlocks,
        setSpeakerNames: addSpeakerNames,
        setSpeakerBlocks: addSpeakerBlocks,
      }}
    >
      {children}
    </SpeakersContext.Provider>
  );
};
