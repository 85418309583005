import React, { FC, useEffect, useState } from 'react';
import { PropmptTemplate } from '../../Consts/promptTemplates';
import { useOrganization } from '../../Hooks/useOrganization';
import { Icon24 } from '../../Icons/Icon';
import Button from '../Button';
import SearchInput from '../SearchInput';
import SmartTemplateIcon from '../SmartTemplateIcon';

import {
  Container,
  TemplateCard,
  IconContainer,
  TemplateName,
  TemplateDescription,
  ButtonWrapper,
  TemplateNameWrapper,
  ChevronIcon,
  TemplatesContainer,
  Title,
  Subtitle,
  SeearchWrapper,
  FilterDropdown,
  FilterText,
  DropdownPanel,
  DropdownPanelItem,
  TemplateCardBorderWrapper,
  SmartTemplateIconWrapper,
} from './styles';

interface PromptTemplatesProps {
  templates: PropmptTemplate[];
  currentTemplateId: string | null;
  currentCategoryId: string;
  withSmartTemplates?: boolean;
  onClickDetails(template: PropmptTemplate): void;
  onChange(template: PropmptTemplate): void;
  onFilterBySearch(text: string): void;
  onFilterByType?(filter: 'all' | 'smart'): void;
  renderMobileCategories?(): React.ReactNode;
}

const PromptTemplates: FC<PromptTemplatesProps> = ({
  templates,
  currentTemplateId,
  currentCategoryId,
  withSmartTemplates,
  onFilterBySearch,
  onFilterByType,
  onClickDetails,
  onChange,
  renderMobileCategories,
}) => {
  const [searchText, setSearchText] = useState('');
  const [showDropdownPanel, setShowDropdownPanel] = useState(false);
  const [currentFilter, setCurrentFilter] = useState<'all' | 'smart'>('all');
  const [, org] = useOrganization();

  useEffect(() => {
    if (templates.length > 0) {
      onChange(templates[0]);
    }
  }, [templates, currentCategoryId]);

  useEffect(() => {
    onFilterBySearch(searchText);
  }, [searchText]);

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      e.stopPropagation();
      setShowDropdownPanel(false);
    };

    window.addEventListener('mousedown', handleClick);
    return () => {
      window.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const handleChangeFilter = (filter: 'all' | 'smart') => {
    setCurrentFilter(filter);
    onFilterByType && onFilterByType(filter);
  };

  const category = {
    all: {
      title: 'All Templates',
      description: 'Explore the entire collection of AI-powered templates.',
    },
    recommended: {
      title: 'Recommended',
      description: 'A curated selection of recently used, popular, and new templates.',
    },
    favorites: {
      title: 'Your Favorites',
      description: 'Quick access to all of your favorite templates.',
    },
    design_research: {
      title: 'Design & Research',
      description: 'Templates inspired by design and research frameworks.',
    },
    customer_experience: {
      title: 'Customer Experience',
      description: 'Templates to evaluate customer experience.',
    },
    sales: {
      title: 'Sales',
      description: 'Templates created from business strategies and sales tactics.',
    },
    marketing: {
      title: 'Marketing',
      description: 'Templates designed for market research and analysis.',
    },
    education: {
      title: 'Education',
      description: 'Templates created for analyzing educational research.',
    },
    custom: {
      title: 'Custom',
      description: `One of a kind AI-powered templates for ${org?.name || 'your workspace'}.`,
    },
  }[currentCategoryId];

  return (
    <Container>
      <SeearchWrapper>
        <SearchInput
          value={searchText}
          onChange={setSearchText}
          placeholder={'Search Summary Templates'}
        />

        {withSmartTemplates && (
          <FilterDropdown onClick={() => setShowDropdownPanel(!showDropdownPanel)}>
            <Icon24.Filter />
            <FilterText>Filter {currentFilter === 'smart' && '(1)'}</FilterText>
            <Icon24.ChevronDown />

            <DropdownPanel show={showDropdownPanel}>
              <DropdownPanelItem
                active={currentFilter === 'all'}
                onMouseDown={(e) => e.stopPropagation()}
                onClick={() => handleChangeFilter('all')}
              >
                All
              </DropdownPanelItem>
              <DropdownPanelItem
                active={currentFilter === 'smart'}
                onMouseDown={(e) => e.stopPropagation()}
                onClick={() => handleChangeFilter('smart')}
              >
                Smart templates
              </DropdownPanelItem>
            </DropdownPanel>
          </FilterDropdown>
        )}
      </SeearchWrapper>

      {renderMobileCategories && renderMobileCategories()}

      {!!searchText.length && !!templates.length && (
        <Title>{`${templates.length} "${searchText}" results`}</Title>
      )}

      {category && !!templates.length && !searchText.length && (
        <>
          <Title>{category.title}</Title>
          <Subtitle>{category.description}</Subtitle>
        </>
      )}

      <TemplatesContainer>
        {templates.map((template) => (
          <TemplateCardBorderWrapper
            key={template.id}
            selected={template.id === currentTemplateId}
            isSmart={!!template.isSmart}
          >
            <TemplateCard onClick={() => onChange(template)}>
              {template.isSmart && (
                <SmartTemplateIconWrapper>
                  <SmartTemplateIcon />
                </SmartTemplateIconWrapper>
              )}
              <ChevronIcon show={template.id === currentTemplateId}>
                <Icon24.Chevron />
              </ChevronIcon>
              <TemplateNameWrapper>
                <IconContainer src={template.icon} />
                <TemplateName>{template.title}</TemplateName>
              </TemplateNameWrapper>

              <TemplateDescription>{template.description}</TemplateDescription>
              <ButtonWrapper>
                <Button type="secondary" onClick={() => onClickDetails(template)}>
                  Details
                </Button>
              </ButtonWrapper>
            </TemplateCard>
          </TemplateCardBorderWrapper>
        ))}
      </TemplatesContainer>
    </Container>
  );
};

export default PromptTemplates;
