import React from 'react';
import styled from 'styled-components';
import { useUpdateParticipant } from '../Hooks/useParticipants';
import TextareaAutosize from 'react-textarea-autosize';
import { CompanyBuildingIcon, IdIcon, CalendarIcon, MailIcon, TagIcon } from '../Icons';
import { PhoneIcon } from '@heroicons/react/outline';
import moment from 'moment';
import usePermissions from '../Hooks/usePermissions';
import { defaultUserImages } from '../Consts/userImages';
import { Popover } from '@headlessui/react';
import { STICKY_COLORS } from '../Consts';
import { DEFAULT_STICKY_COLOR } from '../Models/Sticky';
import TagsPicker from '../Components/TagsPicker';

type Props = {
  participant: any;
};

const DetailsRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const TagPickerContainer = styled.div`
  font-weight: 500;
  font-size: 14px;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 24px;
  margin-right: 12px;
`;

const DetailLabel = styled.label`
  width: 120px;
  font-weight: 600;
  font-size: 14px;
`;

const DetailHeaderInput = styled.input`
  border: none;
  border-bottom: 1px solid transparent;
  appearance: none;
  transition: border 0.3s ease 0s;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  min-width: 192px;
  &:focus {
    outline: none;
    border-bottom: 1px solid rgba(56, 33, 82, 0.4);
  }
  &::placeholder {
    color: rgba(56, 33, 82, 0.4);
  }
`;

const DetailInput = styled.input`
  border: none;
  border-bottom: 1px solid transparent;
  appearance: none;
  transition: border 0.3s ease 0s;
  font-weight: 500;
  font-size: 14px;
  min-width: 192px;
  &:focus {
    outline: none;
    border-bottom: 1px solid rgba(56, 33, 82, 0.4);
  }
  &::placeholder {
    color: rgba(56, 33, 82, 0.4);
  }
`;

const DetailText = styled.div`
  font-weight: 500;
  font-size: 14px;
`;

const DescriptionInput = styled(TextareaAutosize)`
  outline: none;
  width: 100%;
  margin-top: 16px;
  font-weight: 500;
  font-size: 14px;
  &::placeholder {
    color: rgba(56, 33, 82, 0.4);
  }
`;

const TabHeader = styled.h2`
  display: inline-block;
  font-weight: 600;
  font-size: 14px;
  margin-top: 24px;
`;

const ImagePicker = ({
  picture,
  onChange,
  className,
}: {
  className?: string;
  picture?: string;
  onChange: (imageName: string) => void;
}): JSX.Element => {
  const participantPicture =
    defaultUserImages.find((image) => image.name === picture) || defaultUserImages[0];
  return (
    <Popover className={`relative ${className || ''}`}>
      <Popover.Button className="">
        <img
          className="rounded-full cursor-pointer"
          src={participantPicture.url2x}
          style={{ height: '128px', width: '128px' }}
        />
      </Popover.Button>
      <Popover.Panel
        className={
          'absolute bg-white rounded-sm-md p-3'
          // (position === 'right' ? ' right-0' : ' left-0')
        }
        style={{
          width: '275px',
          boxShadow: '0px 3px 4px rgba(0, 0, 0, 0.1)',
          border: '1px solid #D7D3DC',
        }}
      >
        <div className="grid grid-cols-4 gap-2">
          {defaultUserImages.map((image, index) => {
            return (
              <div
                key={`cover-image${index}`}
                className="hover:cursor-pointer"
                onClick={() => {
                  onChange(image.name);
                }}
              >
                <img
                  src={image.url}
                  alt="cover image"
                  style={{
                    width: 56,
                    height: 56,
                    objectFit: 'cover',
                  }}
                />
              </div>
            );
          })}
        </div>
      </Popover.Panel>
    </Popover>
  );
};

const ParticipantEditor = ({ participant }: Props): JSX.Element => {
  let [updateParticipant] = useUpdateParticipant();
  const { canEditParticipants } = usePermissions();
  if (!canEditParticipants) updateParticipant = () => false;

  const participantColor = participant.color || DEFAULT_STICKY_COLOR;

  return (
    <>
      <div className="flex">
        <div className="flex justify-center" style={{ marginRight: '20px' }}>
          <ImagePicker
            className="hidden xl:block"
            picture={participant?.picture}
            onChange={(imageName) => updateParticipant(participant.id, { picture: imageName })}
          />
        </div>
        <div>
          <div className="flex">
            <ImagePicker
              className="block xl:hidden mr-7 mb-5"
              picture={participant?.picture}
              onChange={(imageName) => updateParticipant(participant.id, { picture: imageName })}
            />
            <DetailsRow>
              <DetailHeaderInput
                autoFocus={!participant.name}
                type="text"
                placeholder="participant name"
                size={participant.name?.length}
                defaultValue={participant.name}
                onBlur={(e) => updateParticipant(participant.id, { name: e.target.value })}
                onKeyDown={(e) => e.code === 'Enter' && e.currentTarget.blur()}
                readOnly={!canEditParticipants}
              />
            </DetailsRow>
          </div>
          <DetailsRow>
            <IconContainer>
              <CompanyBuildingIcon />
            </IconContainer>
            <DetailLabel>Company</DetailLabel>
            <DetailInput
              type="text"
              placeholder="company name"
              defaultValue={participant.company}
              size={participant.company?.length}
              onBlur={(e) => updateParticipant(participant.id, { company: e.target.value })}
              onKeyDown={(e) => e.code === 'Enter' && e.currentTarget.blur()}
              readOnly={!canEditParticipants}
            />
          </DetailsRow>

          <DetailsRow>
            <IconContainer>
              <IdIcon />
            </IconContainer>
            <DetailLabel>Role</DetailLabel>
            <DetailInput
              type="text"
              placeholder="role"
              defaultValue={participant.role}
              size={participant.role?.length}
              onBlur={(e) => updateParticipant(participant.id, { role: e.target.value })}
              onKeyDown={(e) => e.code === 'Enter' && e.currentTarget.blur()}
              readOnly={!canEditParticipants}
            />
          </DetailsRow>

          <DetailsRow>
            <IconContainer>
              <MailIcon />
            </IconContainer>
            <DetailLabel>Email</DetailLabel>
            <DetailInput
              type="email"
              placeholder="person@example.com"
              defaultValue={participant.email}
              size={participant.email?.length}
              onBlur={(e) => updateParticipant(participant.id, { email: e.target.value })}
              onKeyDown={(e) => e.code === 'Enter' && e.currentTarget.blur()}
              readOnly={!canEditParticipants}
            />
          </DetailsRow>

          <DetailsRow>
            <IconContainer>
              <PhoneIcon />
            </IconContainer>
            <DetailLabel>Phone</DetailLabel>
            <DetailInput
              type="tel"
              placeholder="999-999-9999"
              defaultValue={participant.phone}
              size={participant.phone?.length}
              onBlur={(e) => updateParticipant(participant.id, { phone: e.target.value })}
              onKeyDown={(e) => e.code === 'Enter' && e.currentTarget.blur()}
              readOnly={!canEditParticipants}
            />
          </DetailsRow>

          <DetailsRow>
            <IconContainer>
              <CalendarIcon />
            </IconContainer>
            <DetailLabel>Date added</DetailLabel>
            <DetailText>{moment(participant.createdAt).format('MM/DD/YYYY')}</DetailText>
          </DetailsRow>

          <DetailsRow>
            <IconContainer>
              <CalendarIcon />
            </IconContainer>
            <DetailLabel>Last updated</DetailLabel>
            <DetailText>{moment(participant.updatedAt).format('MM/DD/YYYY')}</DetailText>
          </DetailsRow>
          <DetailsRow>
            <IconContainer>
              <div className="h-5 w-5 rounded-xl border-primary-purple border-[1.5px]" />
            </IconContainer>
            <DetailLabel>Color</DetailLabel>
            <div>
              <Popover className="relative flex items-center">
                <Popover.Button className="">
                  <div
                    className="h-5 w-5 rounded-xl"
                    style={{
                      backgroundColor: participantColor,
                    }}
                  />
                </Popover.Button>
                <Popover.Panel className="absolute z-10 mt-1 p-2 bg-white border shadow-md -top-3 -ml-2">
                  <div className="grid gap-1 grid-cols-5 w-[132px]">
                    {STICKY_COLORS.map((fillColor, index) => (
                      <a
                        href="#"
                        onClick={() => {
                          updateParticipant(participant.id, { color: fillColor });
                        }}
                        key={index}
                      >
                        <svg
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="11" cy="11" r="11" fill={fillColor} />
                          {participantColor === fillColor && (
                            <path
                              d="M7 10.143L10.4286 13.5714L15 8.42871"
                              stroke="#FFF"
                              strokeWidth="1.5"
                            />
                          )}
                        </svg>
                      </a>
                    ))}
                  </div>
                </Popover.Panel>
              </Popover>
            </div>
          </DetailsRow>
          <DetailsRow>
            <IconContainer>
              <TagIcon />
            </IconContainer>
            <DetailLabel>Tags</DetailLabel>
            <TagPickerContainer>
              <TagsPicker
                tags={participant.tagsList || []}
                onChange={async (tags) => {
                  await updateParticipant(participant.id, {
                    tagsParticipants: {
                      deleteOthers: true,
                      create: tags.map((tag) => ({ tagId: tag.id })) || [],
                    },
                  });
                }}
                onlyGlobalTags={true}
                readOnly={!canEditParticipants}
              />
            </TagPickerContainer>
          </DetailsRow>
        </div>
      </div>
      <TabHeader>Notes</TabHeader>
      <DescriptionInput
        defaultValue={participant.description}
        onBlur={(e) => updateParticipant(participant.id, { description: e.target.value })}
        placeholder="add some notes about this participant…"
        readOnly={!canEditParticipants}
      />
    </>
  );
};

export default ParticipantEditor;
