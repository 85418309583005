import { Extension, Range, Dispatch } from '@tiptap/core';
import { Decoration, DecorationSet } from '@tiptap/pm/view';
import { Plugin, PluginKey, Transaction } from '@tiptap/pm/state';
import { Node as PMNode } from '@tiptap/pm/model';
import { SyncRedactor } from 'redact-pii';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    cleanPII: {
      togglePIISearch: (enabled: boolean | null) => ReturnType;
      setCleaningReplaceTerm: (replaceTerm: string) => ReturnType;
      cleanPII: () => ReturnType;
    };
  }
}

interface TextNodesWithPosition {
  text: string;
  pos: number;
}

interface ProcessedSearches {
  decorationsToReturn: DecorationSet;
  results: Range[];
}

function processSearches(doc: PMNode, searchResultClass: string): ProcessedSearches {
  const decorations: Decoration[] = [];
  const results: Range[] = [];

  let textNodesWithPosition: TextNodesWithPosition[] = [];
  let index = 0;

  doc?.descendants((node, pos) => {
    if (node.isText) {
      if (textNodesWithPosition[index]) {
        textNodesWithPosition[index] = {
          text: textNodesWithPosition[index].text + node.text,
          pos: textNodesWithPosition[index].pos,
        };
      } else {
        textNodesWithPosition[index] = {
          text: `${node.text}`,
          pos,
        };
      }
      index += 1;
    } else {
      index += 1;
    }
  });

  textNodesWithPosition = textNodesWithPosition.filter(Boolean);
  const redactor = new SyncRedactor({
    globalReplaceWith: '[PII]',
  });

  for (const textNode of textNodesWithPosition) {
    const { text, pos } = textNode;
    const redactedText = redactor.redact(text);
    if (text === redactedText) continue;

    const splitedText = redactedText.split('[PII]');
    splitedText.forEach((part, index) => {
      let startPos = -1;
      let endPos = -1;

      if (index === 0 && part === '') {
        startPos = 0;
        endPos = splitedText[index + 1] ? [index + 1].length : 0;
      } else if (index === splitedText.length - 1) {
        if (part === '') {
          startPos = text.indexOf(splitedText[index - 1]) + splitedText[index - 1].length;
          endPos = text.length;
        } else {
          return;
        }
      } else {
        startPos = text.indexOf(part) + part.length;
        endPos = text.indexOf(splitedText[index + 1]);
      }

      if (
        startPos === -1 ||
        endPos === -1 ||
        endPos < startPos ||
        text.slice(startPos, endPos) === '[PII]'
      )
        return;

      results.push({
        from: pos + startPos,
        to: pos + endPos,
      });
    });
  }

  results.sort((a, b) => a.from - b.from);

  for (let i = 0; i < results.length; i += 1) {
    const r = results[i];
    const decoration: Decoration = Decoration.inline(r.from, r.to, {
      class: searchResultClass,
    });

    decorations.push(decoration);
  }

  return {
    decorationsToReturn: DecorationSet.create(doc, decorations),
    results,
  };
}

const rebaseNextResult = (
  replaceTerm: string,
  index: number,
  lastOffset: number,
  results: Range[]
): [number, Range[]] | null => {
  const nextIndex = index + 1;

  if (!results[nextIndex]) return null;

  const { from: currentFrom, to: currentTo } = results[index];
  const offset = currentTo - currentFrom - replaceTerm.length + lastOffset;
  const { from, to } = results[nextIndex];

  results[nextIndex] = {
    to: to - offset,
    from: from - offset,
  };

  return [offset, results];
};

const cleanPII = (
  replaceTerm: string,
  results: Range[],
  { tr, dispatch }: { tr: Transaction; dispatch: Dispatch }
) => {
  let offset = 0;
  let resultsCopy = results.slice();

  if (!resultsCopy.length) return;

  for (let i = 0; i < resultsCopy.length; i += 1) {
    const { from, to } = resultsCopy[i];

    tr.insertText(replaceTerm, from, to);
    const rebaseNextResultResponse = rebaseNextResult(replaceTerm, i, offset, resultsCopy);

    if (!rebaseNextResultResponse) continue;

    offset = rebaseNextResultResponse[0];
    resultsCopy = rebaseNextResultResponse[1];
  }

  dispatch?.(tr);
};

export const cleanPIIPluginKey = new PluginKey('cleanPIIPlugin');

export interface CleanPIIOptions {
  searchResultClass: string;
  disableRegex: boolean;
}

export interface CleanPIIOptionsStorage {
  enabled: boolean | null;
  replaceTerm: string;
  results: Range[];
  lastEnabled: boolean | null;
}

export const CleanPII = Extension.create<CleanPIIOptions, CleanPIIOptionsStorage>({
  name: 'cleanPII',

  addOptions() {
    return {
      searchResultClass: 'clean-result',
      disableRegex: true,
    };
  },

  addStorage() {
    return {
      enabled: false,
      replaceTerm: '[PII]',
      results: [],
      lastEnabled: false,
    };
  },

  addCommands() {
    return {
      togglePIISearch: (enabled: boolean | null) => ({ editor }) => {
        editor.storage.cleanPII.enabled = !!enabled;
        return false;
      },
      setCleaningReplaceTerm: (replaceTerm: string) => ({ editor }) => {
        editor.storage.cleanPII.replaceTerm = replaceTerm;

        return false;
      },
      cleanPII: () => ({ editor, tr, dispatch }) => {
        const { replaceTerm, results } = editor.storage.cleanPII;

        cleanPII(replaceTerm, results, { tr, dispatch });
        return false;
      },
    };
  },

  addProseMirrorPlugins() {
    const editor = this.editor;
    const { searchResultClass } = this.options;

    const setLastEnabled = (t: string) => (editor.storage.cleanPII.lastEnabled = t);

    return [
      new Plugin({
        key: cleanPIIPluginKey,
        state: {
          init: () => DecorationSet.empty,
          apply({ doc, docChanged }, oldState) {
            const { enabled, lastEnabled } = editor.storage.cleanPII;

            if (!docChanged && !!lastEnabled === !!enabled) return oldState;

            setLastEnabled(enabled);

            if (!enabled) {
              editor.storage.cleanPII.results = [];
              return DecorationSet.empty;
            }

            const { decorationsToReturn, results } = processSearches(doc, searchResultClass);

            if (!results.length) {
              editor.storage.cleanPII.results = [{ type: 'empty' }];
              return DecorationSet.empty;
            }

            editor.storage.cleanPII.results = results;
            return decorationsToReturn;
          },
        },
        props: {
          decorations(state) {
            return this.getState(state);
          },
        },
      }),
    ];
  },
});

export default CleanPII;
