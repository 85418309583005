import React, { ChangeEvent, useContext, useState } from 'react';
import { SearchIcon } from '@heroicons/react/outline';
import { DropDownMenu } from '../DropDownMenu';
import { Menu } from '@headlessui/react';
import { OptionType } from '../MultiSelect';
import { DEFAULT_STICKY_COLOR, Tag, TagWithDetails, Theme } from '../../Models';
import styled from 'styled-components';
import { ChevronDownIcon } from '@heroicons/react/solid';
import ProjectMenu from '../ProjectMenu';
import ExportMenu from '../ExportMenu';
import Badge from '../Badge1';
import { Icon24 } from '../../Icons/Icon';

import { IconWrapper, DropdownItem, ButtonIconWrapper, LabelIconWrapper } from './styles';
import { InsightGenerationContext } from '../../Context/InsightGenerationContext';
import EntityDropdownWithTags from '../../Hocs/EntityDropdownWithTags/EntityDropdownWithTags';
import EntityDropdown, { TEntityOption } from '../EntityDropdown/EntityDropdown';
import usePermissions from '../../Hooks/usePermissions';
import Button from '../Button';

interface Props {
  themes: Theme[];
  participants: { id: any; name: string; color?: string }[];
  dashboardId: string;
  onSearchChange?: (query: string) => void;
  onTagChange?: (tags: Tag[]) => void;
  onThemeChange?: (themes: number[]) => void;
  onGroupByChange?: (value: [string, any?]) => void;
  onParticipantChange?: (participants: any[]) => void;
  getCanvas: () => string | undefined;
  runSentimentAnalysis: () => void;
  showClusterPopup: () => void;
  sentimentCount?: number;
  sentimentInProgress?: boolean;
}

const StyledChevronIcon = styled(ChevronDownIcon)`
  height: 12px;
`;

const DropDownTitle = styled.div`
  display: flex;
  align-items: center;
  margin: 0 4px;
`;

const Capitalized = styled.span`
  background-color: #efedf1;
  text-transform: capitalize;
`;

const AIinsightButton = styled.div`
  border-radius: 6px;
  border: 1px solid var(--sticky-purple, #d8c0f8);
  background: linear-gradient(90deg, #d1deff 0%, #d8c0f8 100%);
  /* Card Shadow */
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.1);
`;

export default function SearchToolBar({
  dashboardId,
  onSearchChange,
  onThemeChange,
  onTagChange,
  onParticipantChange,
  onGroupByChange,
  themes,
  participants,
  getCanvas,
  runSentimentAnalysis,
  showClusterPopup,
  sentimentCount = 0,
  sentimentInProgress = false,
}: Props): JSX.Element {
  const [selectedTags, setSelectedTags] = useState<TagWithDetails[]>([]);
  const [selectedGroupBy, setSelectedGroupBy] = useState<[string, any?]>(['theme']);
  const [selectedThemes, setSelectedThemes] = useState<TEntityOption[]>([]);
  const [selectedParticipants, setSelectedParticipants] = useState<OptionType[]>([]);
  const { setGenerationModalOpen } = useContext(InsightGenerationContext);
  const { canEditInsights } = usePermissions();

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onSearchChange) {
      onSearchChange(event.target.value);
    }
  };

  const handleThemeChange = (values: TEntityOption[]) => {
    setSelectedThemes(values);
    if (onThemeChange) {
      onThemeChange(values.map((x) => parseInt(x.value)));
    }
  };

  const handleTagChange = (values: TagWithDetails[]) => {
    setSelectedTags(values);
    if (onTagChange) {
      onTagChange(values);
    }
  };

  const handleParticipantChange = (values: OptionType[]) => {
    setSelectedParticipants(values);
    if (onParticipantChange) {
      onParticipantChange(values.map((x) => x.value));
    }
  };

  const handleGroupBy = (value: [string, any?]) => {
    setSelectedGroupBy(value);
    if (onGroupByChange) {
      onGroupByChange(value);
    }
  };

  const renderButtons = () => {
    return (
      <>
        <div className="flex">
          <DropDownMenu
            button={
              <Menu.Button className="-ml-px h-full bg-white relative inline-flex items-center px-3 py-3 text-sm font-medium text-secondary-purple-dark cursor-pointer rounded-sm-md focus:outline-none">
                <LabelIconWrapper>
                  <Icon24.Filter />
                </LabelIconWrapper>

                <DropDownTitle>Filter</DropDownTitle>
                <StyledChevronIcon />
              </Menu.Button>
            }
          >
            <Menu.Item>
              <EntityDropdown
                options={themes.map((x: Theme) => ({
                  value: x.id.toString(),
                  label: x.name,
                  properties: {
                    iconColor: x.color,
                    count: x.notes?.length ?? 0,
                  },
                }))}
                multiselect
                placeholder="Themes"
                noOptionsPlaceholder={'No themes'}
                onChange={(value) => handleThemeChange(value)}
                value={selectedThemes}
                alignMenu="right"
                ghost
              />
            </Menu.Item>
            <Menu.Item>
              <EntityDropdownWithTags
                multiselect={true}
                placeholder="Tags"
                noOptionsPlaceholder={'No tags'}
                onChange={(value) => handleTagChange(value)}
                value={selectedTags}
                alignMenu="right"
                ghost
              />
            </Menu.Item>
            <Menu.Item>
              <EntityDropdown
                options={participants.map((x: any) => ({
                  value: x.id.toString(),
                  label: x.name,
                  properties: {
                    iconColor: x.color,
                  },
                }))}
                multiselect
                placeholder="Participants"
                noOptionsPlaceholder={'No participants'}
                onChange={(value) => handleParticipantChange(value)}
                value={selectedParticipants}
                alignMenu="right"
                ghost
              />
            </Menu.Item>
          </DropDownMenu>

          <DropDownMenu
            button={
              <Menu.Button className="-ml-px h-full bg-white relative inline-flex items-center text-sm font-medium text-secondary-purple-dark cursor-pointer rounded-sm-md focus:outline-none">
                <DropDownTitle>
                  Color by
                  <DropdownItem>
                    <Capitalized>
                      {selectedGroupBy[0] && ` ${!selectedGroupBy[1] ? selectedGroupBy[0] : ''}`}{' '}
                    </Capitalized>
                    {selectedGroupBy[0] === 'participant' && selectedGroupBy[1].value && (
                      <Badge
                        name={selectedGroupBy[1].label}
                        color={selectedGroupBy[1].color || DEFAULT_STICKY_COLOR}
                      />
                    )}
                    {selectedGroupBy[1] && selectedGroupBy[0] === 'tag' && (
                      <Badge
                        name={selectedGroupBy[1].name}
                        color={selectedGroupBy[1].color || 'rgba(56, 33, 82, 0.2)'}
                      />
                    )}
                    <ButtonIconWrapper>
                      <StyledChevronIcon />
                    </ButtonIconWrapper>
                  </DropdownItem>
                </DropDownTitle>
              </Menu.Button>
            }
          >
            {['theme', 'sentiment'].map((x, i) => {
              return (
                <Menu.Item key={i}>
                  <a
                    href="#"
                    onClick={() => handleGroupBy([x])}
                    className="flex text-secondary-purple-dark font-semibold hover:bg-primary-purple-hover hover:cursor-default rounded-sm-md m-1.5 p-1.5 text-sm"
                  >
                    {x[0].toUpperCase() + x.substr(1)}
                  </a>
                </Menu.Item>
              );
            })}
            <Menu.Item>
              <EntityDropdownWithTags
                placeholder="Tag"
                noOptionsPlaceholder="No tags"
                onChange={(value) =>
                  !!value.length ? handleGroupBy(['tag', value[0]]) : handleGroupBy(['theme'])
                }
                value={
                  selectedGroupBy.length == 2 && selectedGroupBy[0] === 'tag'
                    ? [selectedGroupBy[1]]
                    : []
                }
                alignMenu="right"
                ghost
              />
            </Menu.Item>
            <Menu.Item>
              <EntityDropdown
                options={[
                  {
                    value: 'all',
                    label: 'All Participants',
                    properties: { iconColor: DEFAULT_STICKY_COLOR },
                  },
                  ...participants.map((x) => ({
                    value: x.id,
                    label: x.name,
                    properties: { iconColor: x.color },
                  })),
                ]}
                value={
                  selectedGroupBy.length && selectedGroupBy[0] === 'participant'
                    ? [selectedGroupBy[1] as TEntityOption]
                    : undefined
                }
                onChange={(value) =>
                  value.length
                    ? handleGroupBy([
                        'participant',
                        {
                          value: value[0].value,
                          label: value[0].label,
                          properties: { iconColor: value[0].properties?.iconColor },
                        } as TEntityOption,
                      ])
                    : handleGroupBy(['theme'])
                }
                placeholder="Participant"
                noOptionsPlaceholder={'No participants'}
                alignMenu="right"
                ghost
              />
            </Menu.Item>
          </DropDownMenu>
        </div>
      </>
    );
  };

  return (
    <div
      className="w-full bg-white z-[999] p-1"
      style={{
        boxShadow: '0px 1px 0px #e5e5e5',
      }}
    >
      <div className="flex justify-end">
        <div className={'flex w-full justify-between'}>
          <div className="flex items-center flex-shrink-0">
            <ProjectMenu dashboardId={dashboardId} />

            <ExportMenu dashboardId={dashboardId} getCanvas={getCanvas} />
          </div>

          <div className={'flex w-full justify-between'}>
            <div className="hidden min-[1120px]:flex">
              {renderButtons()}
              {/* <div
                className="flex items-center font-medium cursor-pointer text-sm"
                onClick={() => {
                  runClusterByTheme();
                }}
              >
                <IconWrapper>
                  <Icon24.Cluster />
                </IconWrapper>
                <span>Cluster By Theme</span>
              </div> */}
            </div>

            <div className="bg-white relative flex">
              <div
                onClick={() => {
                  runSentimentAnalysis();
                }}
                className="flex items-center justify-between cursor-pointer py-2 px-3 select-none font-medium text-sm whitespace-nowrap"
              >
                Sentiment
                <div className="ml-3 bg-primary-purple-hover py-1 px-2 rounded">
                  {!sentimentInProgress && `${sentimentCount}`}
                  {sentimentInProgress && '...'}
                </div>
              </div>
              <div className="flex flex-col justify-center">
                <Button
                  type={'secondary'}
                  className="mr-2 font-medium text-sm"
                  onClick={() => {
                    showClusterPopup();
                  }}
                >
                  <IconWrapper>
                    <Icon24.Cluster />
                  </IconWrapper>
                  <span>Cluster</span>
                </Button>
              </div>
              {canEditInsights && (
                <div className="flex flex-col justify-center">
                  <Button
                    type="primary"
                    onClick={() => {
                      setGenerationModalOpen(true);
                    }}
                    className="font-medium text-sm"
                    // className="flex items-center cursor-pointer py-1 px-2 select-none font-medium text-sm whitespace-nowrap"
                  >
                    <IconWrapper>
                      <Icon24.Plus />
                    </IconWrapper>
                    <span>New Insight</span>
                  </Button>
                </div>
              )}

              <div className="inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <SearchIcon className="h-5 w-5 text-secondary-purple-dark" />
              </div>
              <input
                onChange={onChange}
                type="text"
                name="search"
                id="search"
                className="focus:outline-none block w-48 pl-2 sm:text-sm"
                placeholder="Search notes"
                autoComplete="off"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between mr-3 min-[1120px]:hidden">{renderButtons()}</div>
    </div>
  );
}
