import React from 'react';
import { InformationCircleIcon } from '@heroicons/react/outline';
import { capitalize } from 'lodash-es';
import moment from 'moment';
import currency from 'currency.js';
import { useHistory } from 'react-router';
import { useOrganization } from '../Hooks/useOrganization';
import useUsers from '../Hooks/useUsers';
import { tiersArray as tiers } from '../Consts/tiers-08-23';

import SecondaryToolbar from '../Components/SecondaryToolbar/SecondaryToolbar';
import Button from '../Components/Button';
import Loader from '../Components/Loader';
import {
  HeaderCell,
  Table,
  TableHeader,
  TableRow,
  TableCell,
} from '../Components/TableWithBorders';
import { useBillingInfo } from '../Hooks/usePayments';

const Billing = (): JSX.Element => {
  const [loading, info] = useBillingInfo();
  const history = useHistory();
  const [loadingOrg, organization] = useOrganization();
  const { fetchUsers } = useUsers();

  const [loadingUsers, users] = fetchUsers();
  const editors = users ? users.filter((user: any) => user.role !== 'viewer') : [];

  if (loading || loadingOrg || loadingUsers) return <Loader />;
  const { card, portalUrl, upcomingInvoice, invoices } = info;

  const currentTier = tiers.find((x) => x.id === organization.plan);
  const currentBillingInterval = organization.planInterval === 'year' ? 'yearly' : 'monthly';
  return (
    <div>
      <SecondaryToolbar>
        <div className="flex w-full flex-row py-3 px-4 justify-between">
          <h1 className={'text-l font-medium mt-1'}>Settings / Billing</h1>
        </div>
      </SecondaryToolbar>
      <div className="max-w-800 mx-auto">
        {/* <div className="bg-tooltip-purple flex flex-row p-3 rounded-lg my-5">
          <div>
            <InformationCircleIcon className={'w-5 h-5'} />
          </div>
          <div className="mx-2 font-medium">
            {organization?.plan?.includes('free') &&
              `You’re on the free plan. Upgrade to a paid plan for more viewer seats and to add
            additional editors to your workspace.`}
            {!organization?.plan?.includes('free') && (
              <>
                Your team is on the {capitalize(organization.plan)} plan
                {organization?.plan?.includes('enterprise')
                  ? ''
                  : ` for $${currentTier?.price[currentBillingInterval]}/month per ${currentTier?.per}`}
                . There are {editors.length} editors in this workspace.
                {upcomingInvoice &&
                  ` Your next payment of $${
                    currency(upcomingInvoice.amount, { fromCents: true }).value
                  } will be on ${moment.unix(upcomingInvoice.created).format('MMMM Do, YYYY')}.`}
              </>
            )}
          </div>
          {!organization?.plan?.includes('free') && (
            <div className="flex-none">
              <Button type="secondary" onClick={() => history.push('/settings/plans')}>
                View Plans
              </Button>
            </div>
          )}
        </div> */}
        {/* <hr className="text-primary-purple-gray mb-5" /> */}
        <div className="text-xl font-medium pb-3">Payment</div>
        <div className="text-sm font-medium">
          {card?.brand ? `${capitalize(card.brand)} ending in ${card.last4}` : 'No card on file'}
        </div>
        {portalUrl && (
          <div className="pt-3">
            <Button type="secondary" onClick={() => (window.location = portalUrl)}>
              Update
            </Button>
          </div>
        )}
        <hr className="text-primary-purple-gray my-5" />
        {/* <div className="text-xl font-medium pb-4">Billing Address</div>
        <div className="flex flex-col">
          <div className="flex flex-row">
            <div className="pb-4 w-1/2 pr-1">
              <label htmlFor="address-line-1" className="block text-sm font-medium">
                Address line 1<span className="text-[#EA4335]"> *</span>
              </label>
              <div className="mt-1">
                <input
                  id="address-line-1"
                  name="address-line-1"
                  defaultValue=""
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-sm-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-secondary-purple-dark focus:border-secondary-purple-dark sm:text-sm"
                />
              </div>
            </div>
            <div className="pb-4 w-1/2 pl-1">
              <label htmlFor="address-line-2" className="block text-sm font-medium">
                Address line 2
              </label>
              <div className="mt-1">
                <input
                  id="address-line-2"
                  name="address-line-2"
                  defaultValue=""
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-sm-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-secondary-purple-dark focus:border-secondary-purple-dark sm:text-sm"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-row">
            <div className="pb-4 w-1/3 pr-1">
              <label htmlFor="city" className="block text-sm font-medium">
                City
                <span className="text-[#EA4335]"> *</span>
              </label>
              <div className="mt-1">
                <input
                  id="city"
                  name="city"
                  defaultValue=""
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-sm-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-secondary-purple-dark focus:border-secondary-purple-dark sm:text-sm"
                />
              </div>
            </div>
            <div className="pb-4 w-1/3 px-1">
              <label htmlFor="zip-code" className="block text-sm font-medium">
                Zip Code
                <span className="text-[#EA4335]"> *</span>
              </label>
              <div className="mt-1">
                <input
                  id="zip-code"
                  name="zip-code"
                  defaultValue=""
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-sm-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-secondary-purple-dark focus:border-secondary-purple-dark sm:text-sm"
                />
              </div>
            </div>
            <div className="pb-4 w-1/3 pl-1">
              <label htmlFor="state" className="block text-sm font-medium">
                State
                <span className="text-[#EA4335]"> *</span>
              </label>
              <div className="mt-1">
                <input
                  id="state"
                  name="state"
                  defaultValue=""
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-sm-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-secondary-purple-dark focus:border-secondary-purple-dark sm:text-sm"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-row">
            <div className="pb-4 w-full pr-1">
              <label htmlFor="country" className="block text-sm font-medium">
                Country<span className="text-[#EA4335]"> *</span>
              </label>
              <div className="mt-1">
                <input
                  id="country"
                  name="country"
                  defaultValue=""
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-sm-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-secondary-purple-dark focus:border-secondary-purple-dark sm:text-sm"
                />
              </div>
            </div> */}
        {/* </div> */}
        <div className="text-xl font-medium py-4">Invoices</div>
        <div className="shadow overflow-hidden sm:rounded-md mb-10">
          <Table className="min-w-full divide-y divide-white border-r border-white">
            <TableHeader>
              <tr>
                <HeaderCell scope="col" className="pl-4 py-3 text-left">
                  Date
                </HeaderCell>
                <HeaderCell scope="col" className="pl-4 py-3 text-left">
                  Status
                </HeaderCell>
                <HeaderCell scope="col" className="pl-4 py-3 text-left">
                  Amount
                </HeaderCell>
                <HeaderCell scope="col" className="pl-4 py-3 text-left"></HeaderCell>
              </tr>
            </TableHeader>
            {invoices && invoices.length > 0 && (
              <tbody className="bg-white divide-y divide-gray-200">
                {invoices.map((invoice: any) => (
                  <TableRow key={invoice.url}>
                    <TableCell className="px-4 py-4 whitespace-nowrap">
                      {moment.unix(invoice.created).format('MMMM DD YYYY')}
                    </TableCell>
                    <TableCell className="px-4 py-4 whitespace-nowrap">
                      {capitalize(invoice.status)}
                    </TableCell>
                    <TableCell className="px-4 py-4 whitespace-nowrap">
                      ${currency(invoice.amount, { fromCents: true }).value}
                    </TableCell>
                    <TableCell className="px-4 py-4 whitespace-nowrap">
                      <a target="_blank" rel="noreferrer" href={invoice.url}>
                        View invoice
                      </a>
                    </TableCell>
                  </TableRow>
                ))}
              </tbody>
            )}
            {(!invoices || invoices.length == 0) && (
              <div className="py-3 text-sm pl-2">No Invoices</div>
            )}
          </Table>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default Billing;
